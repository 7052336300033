import React, { CSSProperties, useRef } from 'react';
import { useHoverDirty } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { classnames } from '../../util/classnames';
import Spinner from '../Spinner/Spinner';
import css from './ActionButton.module.scss';

export interface ActionButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /**
     * type of the button for form control
     * @default 'button'
     * */
    type?: 'submit' | 'reset' | 'button';
    /**
     * width of the button. Can receive a string with any CSS width rules
     * @default 'auto'
     */
    width?: 'auto' | 'small' | 'medium' | 'large' | string;
    /**
     * the button is either or not in loading state
     * @default false
     * */
    loading?: boolean;
    /** size of the button */
    size?: 'small' | 'medium' | 'large' | 'xlarge';
    /** variant of the button */
    variant?: 'primary' | 'secondary' | 'terciary' | 'danger' | 'warning' | 'success';
    /** name of the icon to append in the button */
    icon?: string;
    /** tabindex to control if the element should be focusable */
    tabIndex?: -1 | undefined;
    /** defines if the button is disabled */
    disabled?: boolean;
    /** defines if the button is quiet - can be used with any of the other attributes */
    quiet?: boolean;
    /** defines the click event on the button element */
    onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    /** the id for the button */
    id?: string;
    /** nodes of children */
    children: React.ReactNode;
    /** function to render the button content with hover callback to allow hover control outside the parent */
    renderFunction?: (isHovering: boolean) => React.ReactNode;
    /**
     * Styles to be applied to the modal
     * @default {}
     */
    style?: CSSProperties;
}

/**
 * Action Button component
 *
 * If inside a Form, and not provided an onClick event, submit event will be triggered.
 *
 * Outside of a Form, must be used as a Controlled Component, providing a onClick event.
 *
 */
export default function ActionButton({
    size = 'medium',
    className,
    variant = 'primary',
    loading,
    quiet,
    disabled,
    width = 'auto',
    type,
    style,
    children,
    renderFunction,
    onClick,
    ...props
}: ActionButtonProps) {
    const id = props.id || uuidv4();
    const ref = useRef<HTMLButtonElement>(null);

    const isHovering = useHoverDirty(ref);

    let styles: { width?: string; size?: string } = {};

    if (width !== 'small' && width !== 'medium' && width !== 'large' && width !== 'auto') {
        styles = { width };
    }

    if (size !== 'small' && size !== 'medium' && size !== 'large' && size !== 'xlarge') {
        styles = { size };
    }

    function handleClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
        if (disabled || loading) return;
        if (onClick) onClick(event);
    }

    const spinnerColor = {
        primary: 'var(--accent-800)',
        secondary: 'var(--accent-800)',
        danger: 'var(--red-700)',
        success: 'var(--green-700)',
        warning: 'var(--yellow-700)',
        terciary: 'var(--accent-800)',
    }[variant];

    return (
        <button
            disabled={disabled || loading}
            ref={ref}
            type={type || 'button'}
            onClick={handleClick}
            tabIndex={disabled ? -1 : 0}
            className={classnames(
                disabled && css.disabled,
                quiet && css.quiet,
                css.actionButtonElement,
                !styles?.width && css[width],
                !styles?.size && css['size-' + size],
                loading && css.loading,
                css[variant],
                className,
            )}
            id={id}
            style={style}
            {...props}
        >
            {loading ? (
                <>
                    <div style={{ opacity: '0' }}>{children}</div>
                    <Spinner speed={0.96} style={{ position: 'absolute' }} size={size} color={spinnerColor} />
                </>
            ) : renderFunction ? (
                renderFunction(isHovering)
            ) : (
                children
            )}
        </button>
    );
}
