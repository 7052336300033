import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import PageTitle from '@/components/PageTitle/PageTitle';
import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import usePermissions from '@/hooks/usePermissions';
import { IntegrationSystemData } from '@/types/interfaces';
import { ActionButton, Stack, Text } from '@vg-react/components';
import { PlusLight } from '@vg-react/icons/light';
import { CircleExclamationLight } from '@vg-react/icons/v6/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CardIntegration } from './components/CardIntegration';
import { CardWithoutIntegration } from './components/CardWithoutIntegration';
import ModalCreateIntegration from './components/ModalCreateIntegration';

export interface IxcIntegration {
    name: 'ixc';
    value: {
        clientId: string;
        clientSecret: string;
        url: string;
    };
    createdAt: Date;
    active: boolean;
    lastSync?: Date;
    syncErrorMessage?: string;
}

type Integration = IxcIntegration;

export default function IntegrationsScreen() {
    const [modalCreateIntegration, setModalCreateIntegration] = useState<boolean>(false);
    const { data: integrations, refresh: refreshIntegrations } = useFetch<Integration[]>('/app/integrations/system/');
    const { data: dataIntegration, refresh: refreshDataIntegration } = useFetch<IntegrationSystemData[]>(
        '/app/integrations/system/data',
    );
    const { t } = useTranslation();
    const { validatePermission } = usePermissions();

    return (
        <>
            <Screen>
                <Breadcrumb />
                <Stack
                    spacing="medium"
                    style={{
                        height: 'calc(100% - var(--spacing-200))',
                    }}
                >
                    <Stack align="center" direction="row" justify="space-between">
                        <PageTitle
                            title={t('ToolsScreen.IntegrationsScreen.title')}
                            description={t('ToolsScreen.IntegrationsScreen.description')}
                        />
                        {validatePermission('/app/integrations/system/create', 'post') && (
                            <ActionButton
                                onClick={() => setModalCreateIntegration(true)}
                                disabled={integrations && integrations.length > 0 ? true : false}
                            >
                                <PlusLight color="currentColor" size="16" />
                                {t('ToolsScreen.IntegrationsScreen.create')}
                            </ActionButton>
                        )}
                    </Stack>
                    {integrations && integrations.length > 0 ? (
                        integrations.map((integration) => (
                            <CardIntegration
                                key={integration.name}
                                integration={integration}
                                countDevices={dataIntegration?.length || 0}
                                refreshIntegrations={refreshIntegrations}
                                refreshDataIntegration={refreshDataIntegration}
                                dataIntegration={dataIntegration}
                            />
                        ))
                    ) : (
                        <>
                            <div
                                style={{
                                    flex: 1,
                                    height: '100%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }}
                            >
                                <CardWithoutIntegration />
                                <Stack spacing="large" width="100%" height="100%" align="center" justify="center">
                                    <Stack align="center" spacing="small">
                                        <CircleExclamationLight size="70" color="var(--yellow-500)" />
                                        <Text variant="h3" color="var(--gray-600)">
                                            {t('ToolsScreen.IntegrationsScreen.noIntegration')}
                                        </Text>
                                    </Stack>
                                </Stack>
                            </div>
                        </>
                    )}
                </Stack>
            </Screen>
            {modalCreateIntegration && (
                <ModalCreateIntegration
                    activeModal={modalCreateIntegration}
                    onClose={() => setModalCreateIntegration(false)}
                    refreshIntegrations={refreshIntegrations}
                />
            )}
        </>
    );
}
