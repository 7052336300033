import { Tooltip } from '@vg-react/components';
import {
    UserAlienDuotone,
    UserAstronautDuotone,
    UserBountyHunterDuotone,
    UserCowboyDuotone,
    UserCrownDuotone,
    UserHeadsetDuotone,
    UserHelmetSafetyDuotone,
    UserNinjaDuotone,
    UserPoliceTieDuotone,
    UserSecretDuotone,
} from '@vg-react/icons/v6/duotone';
import { useTranslation } from 'react-i18next';

const SortedIcon = ({ size = '18' }: { size?: string }) => {
    const icons: JSX.Element[] = [
        <UserSecretDuotone key="1" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserBountyHunterDuotone key="2" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserPoliceTieDuotone key="3" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserHelmetSafetyDuotone key="4" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserNinjaDuotone key="5" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserHeadsetDuotone key="6" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserCrownDuotone key="7" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserCowboyDuotone key="8" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserAstronautDuotone key="9" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
        <UserAlienDuotone key="10" size={size} color={['var(--gray-700)', 'var(--gray-400)']} />,
    ];

    const lastMinuteNumber = new Date().getMinutes();

    return icons[lastMinuteNumber % icons.length];
};

export default SortedIcon;

const ExperimentalFeatureLabel = ({ size = '18' }: { size?: string }) => {
    const { t } = useTranslation();
    return (
        <Tooltip>
            <Tooltip.Trigger>
                <SortedIcon size={size} />
            </Tooltip.Trigger>
            <Tooltip.Content>{t('SSOUser.tooltip')}</Tooltip.Content>
        </Tooltip>
    );
};

export { ExperimentalFeatureLabel };
