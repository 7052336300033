import ActionButton from '@/components/ActionButton/ActionButton';
import Stack from '@/components/Stack/Stack';
import { CopyLight, PenToSquareLight } from '@vg-react/icons/v6/light';
import { FC } from 'react';
import Field, { FieldProps } from '../base/Field';

interface ButtonFieldProps extends FieldProps {
    buttonLabel: string;
    disable?: boolean;
    type?: 'copy' | 'edit';
    onButtonClick?: () => void;
    buttonIcon?: FC;
    updatingByAI?: boolean;
}

export default function ButtonField({
    buttonLabel,
    disable = false,
    type = 'edit',
    onButtonClick,
    updatingByAI,
    buttonIcon,
    ...props
}: ButtonFieldProps): JSX.Element {
    return (
        <Field
            element={
                <ActionButton
                    variant="terciary"
                    onClick={() => onButtonClick && onButtonClick()}
                    width="auto"
                    disabled={disable}
                    style={disable ? { opacity: 0.7 } : {}}
                >
                    <Stack align="center" justify="center" spacing="var(--spacing-75)" direction="row">
                        <>
                            {buttonIcon ? (
                                buttonIcon({ size: '14', color: 'currentColor' })
                            ) : type === 'edit' ? (
                                <PenToSquareLight size="14" color="currentColor" />
                            ) : (
                                <CopyLight size="14" color="currentColor" />
                            )}
                            {buttonLabel}
                        </>
                    </Stack>
                </ActionButton>
            }
            updatingByAI={updatingByAI}
            {...props}
        />
    );
}