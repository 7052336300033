import React, { useEffect, useRef } from 'react';

export interface OutsideClickHandlerProps {
    /** Element to listen for Outside Clicks */
    children: React.ReactNode;
    /** Callback triggered on click outside */
    onClickOutside: (event?: MouseEvent) => void;
}

const OutsideClickHandler = React.forwardRef<HTMLDivElement, OutsideClickHandlerProps>((props, _) => {
    const { children, onClickOutside } = props;

    const ref = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (ref.current && !(ref.current as any).contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('mousedown', handleClickOutside, true);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [onClickOutside]);

    return <div ref={ref}>{children}</div>;
});

OutsideClickHandler.displayName = 'OutsideClickHandler';

/**
 * Creates a listener for outside clicks
 *
 * if any click is registered outside of OutsideClickHandler,
 * the `onClickOutside` callback will be run.
 *
 * @example
 * <OutsideClickHandler onClickOutside={() => console.log('click')}>
 *     <div>
 *         // any click outside of this div will trigger `onClickOutside`
 *     </div>
 * </OutsideClickHandler>
 */
export default OutsideClickHandler;
