import { AnimatePresence, motion } from 'framer-motion';
import { ReactNode } from 'react';
import { useAccordion } from './Accordion';
import styles from './Accordion.module.scss';

export interface ContentProps {
    children: ReactNode;
}

export default function Content({ children }: ContentProps) {
    const { open } = useAccordion();

    return (
        <AnimatePresence initial={true}>
            <motion.section
                className={styles.contentContainer}
                initial={open}
                animate={{
                    height: open ? 'auto' : '0px',
                }}
                transition={{ duration: 0.2 }}
                style={{ maxHeight: '100%' }}
            >
                {children}
            </motion.section>
        </AnimatePresence>
    );
}
