import { Stack, Text } from '@vg-react/components';
import { CircleDownRegular, CircleUpRegular } from '@vg-react/icons/v6/regular';
import { TriangleExclamationSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ChartLastResults } from '../index/ChartLastResults';
import { CountUpAnimation, Result, TestHistoryData } from '../index/SpeedTestResult';
import calcRate, { convertToGbps } from '../utils/calcRate';

interface ResultCardProps {
    result: Result | null;
    type: 'Download' | 'Upload';
    testHistory: TestHistoryData[];
}

export const ResultCard = ({ result, type, testHistory }: ResultCardProps) => {
    const { t } = useTranslation();
    const widthScreen = window.innerWidth;

    let resultRate =
        type === 'Download'
            ? calcRate({
                  bytes: result?.data?.download.bytes ?? 0,
                  milliseconds: result?.data?.download.milliseconds ?? 0,
              })
            : calcRate({
                  bytes: result?.data?.upload.bytes ?? 0,
                  milliseconds: result?.data?.upload.milliseconds ?? 0,
              });

    const unitRate = resultRate > 1000 ? 'Gbps' : 'Mbps';

    if (resultRate > 1000) resultRate = convertToGbps(resultRate);

    const resultDecimalPlaces = resultRate?.toString().split('.')[1]?.length || 0;

    const errorTest =
        type === 'Download' ? result?.data.download.status === 'failed' : result?.data.upload.status === 'failed';

    let isRunning = true;

    isRunning =
        type === 'Download'
            ? Boolean(result && result.data.download.status === 'awaiting')
            : Boolean(result && result.data.upload.status === 'awaiting');

    const verifyLastResults = () => {
        if (testHistory.length === 0) return false;

        if (type === 'Download') {
            const resultMajorZero = testHistory.find((result) => result.download > 0);
            return resultMajorZero ? true : false;
        }

        const resultMajorZero = testHistory.find((result) => result.upload > 0);
        return resultMajorZero ? true : false;
    };

    return (
        <Stack
            direction="row"
            align="center"
            justify="center"
            spacing="var(--spacing-300)"
            width="calc(100% - var(--spacing-100))"
        >
            {isRunning ? (
                <Stack direction="column" align="center" justify="center" style={{ padding: 'var(--spacing-500)' }}>
                    <Skeleton
                        width={230}
                        height={200}
                        borderRadius={'var(--radius-medium)'}
                        baseColor="var(--background-color)"
                        style={{ opacity: 0.7 }}
                    />
                </Stack>
            ) : (
                <Stack
                    direction="column"
                    align="center"
                    justify="center"
                    spacing="var(--spacing-50)"
                    style={{ paddingInline: 'var(--spacing-200)' }}
                >
                    <Stack direction="row" align="center" justify="center" spacing="var(--spacing-50)">
                        {type === 'Download' ? (
                            <CircleDownRegular color="var(--download-color)" />
                        ) : (
                            <CircleUpRegular color="var(--upload-color)" />
                        )}
                        <Text
                            size="sm"
                            weight="regular"
                            color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                        >
                            {type}
                        </Text>
                    </Stack>
                    {!errorTest && !isNaN(resultRate) ? (
                        <Stack
                            direction="row"
                            align="center"
                            justify="center"
                            spacing="var(--spacing-50)"
                            style={{
                                padding: '50px',
                            }}
                        >
                            <Text
                                size="exl"
                                weight="bold"
                                color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                            >
                                <CountUpAnimation duration={1500} decimalPlaces={resultDecimalPlaces}>
                                    {resultRate > 1000 ? convertToGbps(resultRate) : resultRate}
                                </CountUpAnimation>
                            </Text>
                            <Text
                                size="lg"
                                weight="regular"
                                color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                            >
                                {unitRate}
                            </Text>
                        </Stack>
                    ) : (
                        <Stack
                            align="center"
                            justify="center"
                            spacing="var(--spacing-100)"
                            style={{
                                paddingBlock: '30px',
                            }}
                        >
                            <TriangleExclamationSolid size="26" color="var(--lightness-400)" />
                            <Text size="xs" weight="regular" color="var(--lightness-400)">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ResultCard.error')}
                            </Text>
                        </Stack>
                    )}

                    <Stack
                        direction="row"
                        style={{
                            width: widthScreen < 1400 ? '80%' : '60%',
                            height: '30px',
                        }}
                    >
                        {verifyLastResults() && (
                            <Stack direction="row" align="end" justify="center" spacing="0">
                                <Stack style={{ width: '120px' }}>
                                    <Text size="xxs" weight="regular" color="var(--lightness-300)">
                                        {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ResultCard.lastResults')}
                                    </Text>
                                </Stack>

                                <Stack style={{ width: '50%' }}>
                                    <ChartLastResults
                                        color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                                        values={testHistory}
                                        type={type}
                                    />
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};
