import Pagination from '../Pagination/Pagination';
import RowsPerPage from '../RowsPerPage/RowsPerPage';
import Stack from '../Stack/Stack';
import Text from '../Text/Text';
import { PaginationVariant } from './DataTable';

export interface ResultantRowsLabelProps {
    firstRow: number;
    lastRow: number;
    totalRows: number;
}

export interface SelectedRowsLabelProps {
    numberOfSelectedRows: number;
}

interface DataTableFooterProps {
    autoRowsPerPage: boolean;
    numberOfRows: number;
    currentPage: number;
    rowsPerPage: number;
    rowsPerPageOptions: number[];
    onRowsPerPageChange: (rowsPerPage: number) => void;
    paginationVariant: PaginationVariant;
    numberOfSelectedRows: number;
    onPaginationChange: (page: number) => void;
    paginationLoop: boolean;
    rowsPerPageLabel?: string;
    selectedRowsLabel?: (rowsInfo: SelectedRowsLabelProps) => string;
    resultantRowsLabel?: (rowsInfo: ResultantRowsLabelProps) => string;
}

export function DataTableFooter({
    autoRowsPerPage,
    numberOfRows,
    currentPage,
    rowsPerPage,
    paginationVariant,
    numberOfSelectedRows,
    onPaginationChange,
    rowsPerPageOptions,
    onRowsPerPageChange,
    paginationLoop,
    rowsPerPageLabel,
    selectedRowsLabel,
    resultantRowsLabel,
}: DataTableFooterProps): JSX.Element {
    const numberOfPages = Math.ceil(numberOfRows / rowsPerPage);

    const firstRow = (currentPage - 1) * rowsPerPage + 1;
    const lastRow = Math.min(currentPage * rowsPerPage, numberOfRows);
    let paginationText = `Results: ${firstRow} - ${lastRow} of ${numberOfRows}`;

    if (resultantRowsLabel) {
        paginationText = resultantRowsLabel({ firstRow, lastRow, totalRows: numberOfRows });
    }

    let selectedRowsText = `Selected: ${numberOfSelectedRows}`;

    if (selectedRowsLabel) {
        selectedRowsText = selectedRowsLabel({ numberOfSelectedRows });
    }

    return (
        <Stack direction="row" justify="space-between" align="center">
            {numberOfRows >= 1 && (
                <Stack direction="row" align="center" spacing="small">
                    <Text size="xs" weight="regular" color="var(--gray-600)">
                        {paginationText}
                    </Text>
                    {numberOfSelectedRows >= 1 && (
                        <Text size="xs" color="var(--gray-600)" weight="regular">
                            {selectedRowsText}
                        </Text>
                    )}
                </Stack>
            )}
            <Stack direction="row" align="center" spacing="small">
                {rowsPerPageOptions.length >= 1 && !autoRowsPerPage && (
                    <RowsPerPage
                        label={rowsPerPageLabel}
                        options={rowsPerPageOptions}
                        value={rowsPerPage}
                        onChange={onRowsPerPageChange}
                    />
                )}
                {numberOfPages > 1 && (
                    <Pagination
                        variant={paginationVariant}
                        rowsPerPage={rowsPerPage}
                        numberOfRows={numberOfRows}
                        currentPage={currentPage}
                        onChange={onPaginationChange}
                        loop={paginationLoop}
                    />
                )}
            </Stack>
        </Stack>
    );
}
