import Stack from '@/components/Stack/Stack';
import { classnames } from '@/util/classnames';
import { ReactNode } from 'react';
import FieldLabel from '../FieldLabel';
import FieldText from '../FieldText';
import styles from './Field.module.scss';

export interface FieldProps {
    children?: ReactNode;
    label?: string | ReactNode;
    text?: string;
    updating?: boolean;
    style?: React.CSSProperties;
    sso?: boolean;
    updatingByAI?: boolean;
    iconInfo?: {
        icon: JSX.Element;
        text: string;
    };
    /** @deprecated Use iconInfo instead */
    information?: string;
}

interface InternalFieldProps extends FieldProps {
    element: ReactNode;
}

export default function Field({
    children,
    label,
    element,
    text,
    sso = false,
    updating = false,
    updatingByAI,
    information = '',
    iconInfo,
    style,
}: InternalFieldProps): JSX.Element {
    return (
        <Stack
            direction="row"
            justify="space-between"
            align="center"
            className={classnames(styles.field)}
            style={style}
        >
            <Stack spacing="6px">
                {label ? (
                    <FieldLabel
                        sso={sso}
                        updating={updating}
                        updatingByAI={updatingByAI}
                        information={information}
                        iconInfo={iconInfo}
                    >
                        {label}
                    </FieldLabel>
                ) : null}
                <Stack spacing="6px" style={{ paddingLeft: 'var(--spacing-25)' }}>
                    {text ? <FieldText value={text} updating={updating} information={information} /> : null}
                    {children}
                </Stack>
            </Stack>
            <Stack spacing="8px" direction="column">
                {element}
            </Stack>
        </Stack>
    );
}