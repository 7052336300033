import { Tooltip } from '@vg-react/components';
import { MoonLight, SunBrightLight } from '@vg-react/icons/v6/light';
import { useTranslation } from 'react-i18next';
import { Theme } from '../../../contexts/Theme/ThemeContext';
import styles from '../Login.module.scss';

interface ThemeButtonProps {
    theme: string;
    setTheme: (theme: Theme) => void;
    style?: React.CSSProperties;
}

export function ThemeButton({ theme, setTheme, style }: ThemeButtonProps) {
    const { t } = useTranslation();
    return (
        <Tooltip>
            <Tooltip.Trigger
                style={
                    style ?? {
                        position: 'absolute',
                        top: 25,
                        right: 25,
                    }
                }
            >
                <button className={styles.darkModeButton} onClick={() => setTheme(theme === 'dark' ? 'light' : 'dark')}>
                    {theme === 'dark' ? (
                        <SunBrightLight size="14" color="var(--lightness-200)" />
                    ) : (
                        <MoonLight size="14" color="var(--gray-700)" />
                    )}
                </button>
            </Tooltip.Trigger>
            <Tooltip.Content>
                {theme === 'dark' ? t('ThemeButton.lightMode') : t('ThemeButton.darkMode')}
            </Tooltip.Content>
        </Tooltip>
    );
}
