import { useMemo } from 'react';
import ReactFlow, {
    Background,
    BackgroundVariant,
    ConnectionMode,
    Node,
    Panel,
    ReactFlowProvider,
    useEdgesState,
    useNodesState,
    useOnSelectionChange,
} from 'reactflow';
import FloatingConnectionLine from '../edges/FloatingConnectionLine';
import { EDGE_TYPES, getNodesAndEdges, NODE_TYPES, prepareNodes } from '../utils';
import { LegendPanel } from './LegendPanel';

export type RootNode = {
    id: string;
    data: any;
    position?: {
        x: number;
        y: number;
    };
    type: 'controller' | 'agent' | 'host';
    draggable: boolean;
};

interface FlowProps {
    rootNodes: RootNode[] | undefined;
    onSelectNode: (node: Node[]) => void;
}

const Flow = ({ rootNodes, onSelectNode }: FlowProps) => {
    const { nodes: initialNodes, edges: initialEdges } = useMemo(() => {
        const calcNodes = prepareNodes(rootNodes || []);
        return getNodesAndEdges(calcNodes as Node<{ children: string[] }>[]);
    }, [rootNodes]);

    const [nodes, , onNodesChange] = useNodesState(initialNodes as Node<{ children: string[] }>[]);
    const [edges, , onEdgesChange] = useEdgesState(initialEdges);

    useOnSelectionChange({
        onChange: ({ nodes }) => onSelectNode(nodes),
    });

    return (
        <ReactFlow
            nodes={nodes}
            edges={edges}
            onNodesChange={onNodesChange}
            onEdgesChange={onEdgesChange}
            nodeTypes={NODE_TYPES}
            connectionMode={ConnectionMode.Loose}
            fitView
            fitViewOptions={{ padding: 0.2 }}
            edgeTypes={EDGE_TYPES}
            connectionLineComponent={FloatingConnectionLine}
            proOptions={{
                hideAttribution: true,
            }}
        >
            <Background variant={BackgroundVariant.Dots} gap={50} size={1} />
            <Panel position="bottom-left">
                <LegendPanel />
            </Panel>
        </ReactFlow>
    );
};

export default function ({ rootNodes, onSelectNode }: FlowProps) {
    return (
        <ReactFlowProvider>
            <Flow rootNodes={rootNodes} onSelectNode={onSelectNode} />
        </ReactFlowProvider>
    );
}
