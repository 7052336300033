import { createContext, ReactNode, RefObject, useContext, useState } from 'react';

interface Component {
    ref: (node: HTMLElement | null) => void;
    props: Record<string, unknown>;
}

export interface Content extends Component {
    arrow?: {
        ref: RefObject<HTMLDivElement>;
        props: Record<string, unknown>;
    };
}

interface TooltipConfig {
    showOnFocus?: boolean;
    arrowClassName?: string;
    tooltipClassName?: string;
    floatingPlacement?: string;
    variant?: string;
}

interface TooltipContextInternalProps {
    trigger: Component;
    setTrigger: (data: Component) => void;
    content: Content;
    setContent: (data: Content) => void;
    open: boolean;
    setOpen: (open: boolean) => void;
    config: TooltipConfig;
    setConfig: (config: TooltipConfig) => void;
}

const Context = createContext({});

interface TooltipContextProps {
    children: ReactNode;
}

export function useTooltip(): TooltipContextInternalProps {
    return useContext(Context) as TooltipContextInternalProps;
}

export default function TooltipContext({ children }: TooltipContextProps) {
    const [trigger, setTrigger] = useState<Component>();
    const [content, setContent] = useState<Content>();
    const [open, setOpen] = useState(false);
    const [config, setConfig] = useState<TooltipConfig>({});

    return (
        <Context.Provider
            value={
                {
                    trigger,
                    setTrigger,
                    content,
                    setContent,
                    open,
                    setOpen,
                    config,
                    setConfig,
                } as TooltipContextInternalProps
            }
        >
            {children}
        </Context.Provider>
    );
}
