import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

interface Dimensions {
    width: number;
    height: number;
}

type BreadCrumbNameProps = {
    name: string;
    path: string;
}

interface ScreenData {
    dimensions: Dimensions;
    setDimensions: (dimensions: Dimensions) => void;
    breadcrumbName: BreadCrumbNameProps;
    setBreadcrumbName: (breadcrumbName: BreadCrumbNameProps) => void;
}

const Context = createContext<ScreenData>({} as ScreenData);

const useScreen = () => {
    return useContext(Context);
};

export { useScreen };

interface ScreenContextProps {
    children: ReactNode;
}

export default function ScreenContext({ children }: ScreenContextProps) {
    const [dimensions, setDimensions] = useState<Dimensions>({ width: 0, height: 0 });
    const [breadcrumbName, setBreadcrumbName] = useState<BreadCrumbNameProps>({name: '', path: ''});

    // Used to tell external components that the dimensions need to be recalculated
    useEffect(() => {
        window.dispatchEvent(new Event('resize'));
    }, [dimensions]);

    return (
        <Context.Provider value={{ dimensions, setDimensions, breadcrumbName, setBreadcrumbName }}>
            {children}
        </Context.Provider>
    );
}