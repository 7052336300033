import DataTable from '@/components/DataTable/DataTable';
import { DeviceProps, useMassUpdateContext } from '@/contexts/MassChange/MassChangeFirmwareContext';
import { Card, DataTableColumn, Input, Stack, Text } from '@vg-react/components';
import { SearchRegular } from '@vg-react/icons/regular';
import { RouterSolid } from '@vg-react/icons/v6/solid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import style from './Devices.module.scss';

type Device = {
    manufacturer: string;
    serialNumber: string;
    status: boolean;
    modelName: string;
    hardwareVersion: string;
    softwareVersion: string;
    pppLogin: string;
};

export default function DeviceTable({ page, setPage }: { page: number; setPage: (page: number) => void }) {
    const { t } = useTranslation();
    const { selectedDevices, setSelectedDevices, deviceList, setBlockNextScreen, isFetchingDevicesList } =
        useMassUpdateContext();
    useEffect(() => {
        setBlockNextScreen(selectedDevices.length === 0);
    }, [selectedDevices]);

    const handleSelect = (rows: DeviceProps[]) => {
        if (!deviceList) return;
        const addOneRow = rows.length - selectedDevices.length === 1;
        const removeOneRow = rows.length - selectedDevices.length === -1;
        const selectAllRows = rows.length - selectedDevices.length > 1;
        const removeAllRows =
            rows.length === 0 &&
            rows.length - selectedDevices.length < 0 &&
            selectedDevices.length === deviceList.length;

        if (addOneRow) {
            setSelectedDevices([...selectedDevices, ...rows.filter((row) => !selectedDevices.includes(row))]);
        } else if (removeOneRow) {
            setSelectedDevices([...selectedDevices.filter((device) => rows.includes(device))]);
        } else if (selectAllRows) {
            setSelectedDevices([...deviceList]);
        } else if (removeAllRows) {
            setSelectedDevices([]);
        } else {
            setSelectedDevices([...rows]);
        }
    };

    const columns: Array<DataTableColumn<Device>> = useMemo(
        () => [
            {
                sortable: false,
                resizable: true,
                label: t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.manufacturer'),
                accessor: 'manufacturer',
                render: (device) => device?.manufacturer,
                operators: ['starts-with', 'non-equal', 'equal'],
            },
            {
                sortable: true,
                resizable: true,
                label: t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.serialNumber'),
                accessor: 'serialNumber',
                render: (device) => device.serialNumber,
                sortFunction: (a, b) => {
                    return a.serialNumber > b.serialNumber ? 1 : -1;
                },
            },
            {
                sortable: true,
                resizable: true,
                label: t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.pppLogin'),
                accessor: 'deviceInfo.pppLogin',
                render: (device) => device.pppLogin,
                sortFunction: (a, b) => {
                    return a.pppLogin > b.pppLogin ? 1 : -1;
                },
            },
            {
                sortable: true,
                resizable: true,
                label: t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.hardware'),
                accessor: 'hardwareVersion',
                render: (device) => device.hardwareVersion,
                sortFunction: (a, b) => {
                    return a.hardwareVersion > b.hardwareVersion ? 1 : -1;
                },
            },
            {
                sortable: true,
                resizable: true,
                label: 'Firmware',
                accessor: 'firmwareVersion',
                render: (device) => device.softwareVersion,
                sortFunction: (a, b) => {
                    return a.softwareVersion > b.softwareVersion ? 1 : -1;
                },
            },
        ],
        [],
    );
    const [searchValue, setSearchValue] = useState<string>('');
    const [deviceListAux, setDevicesListAux] = useState<DeviceProps[]>(deviceList || []);

    useEffect(() => {
        setDevicesListAux(deviceList?.filter((device) => device) || []);
    }, [deviceList]);

    useEffect(() => {
        const timerId = setTimeout(() => {
            if (searchValue === '') return setDevicesListAux(deviceList?.filter((device) => device) || []);
            const searchValuelower = searchValue.toLowerCase().trim();
            setDevicesListAux(
                deviceList?.filter((device) => {
                    return (
                        device.serialNumber.toLowerCase().includes(searchValuelower) ||
                        device.pppLogin?.toLowerCase().includes(searchValuelower) ||
                        device.hardwareVersion.toLowerCase().includes(searchValuelower) ||
                        device.softwareVersion.toLowerCase().includes(searchValuelower)
                    );
                }) || [],
            );
        }, 500);
        return () => clearTimeout(timerId);
    }, [searchValue]);

    return (
        <Card className={style.DeviceTable}>
            <Stack
                style={{
                    margin: 'var(--spacing-75)',
                    overflow: 'auto',
                }}
                height="100%"
            >
                {deviceList?.length || isFetchingDevicesList ? (
                    <>
                        <Stack
                            direction="row"
                            justify="space-between"
                            align="center"
                            style={{ padding: 'var(--spacing-25)' }}
                        >
                            <Text className={style.title}>
                                {t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.title')}
                            </Text>
                            <Input
                                width="300px"
                                value={searchValue}
                                name="column-search"
                                placeholder={t(
                                    'ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.searchPlaceholder',
                                )}
                                onChange={(event) => {
                                    setSearchValue(event.target.value);
                                }}
                                leftIcon={<SearchRegular size="14px" color="var(--accent-900)" />}
                            />
                        </Stack>
                        <Stack style={{ overflow: 'auto' }} height="100%">
                            <DataTable<DeviceProps>
                                rowsHover
                                width="full"
                                tableWidth="full"
                                height="100%"
                                rowsPerPage={9}
                                selectable
                                selectableHasCheckbox
                                selectedRowsLabel={(selectedRows) =>
                                    `${t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.selectedDevices')}: ${
                                        selectedRows.numberOfSelectedRows
                                    }`
                                }
                                isRowSelectable={() => true}
                                onRowsSelectedChange={(rows) => handleSelect([...rows])}
                                resultantRowsLabel={({ firstRow, lastRow, totalRows }) =>
                                    `${t(
                                        'ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.results',
                                    )}: ${firstRow} - ${lastRow} de ${totalRows}`
                                }
                                selectedRows={[...selectedDevices]}
                                columns={[...columns]}
                                rows={
                                    deviceListAux.length
                                        ? [...deviceListAux]
                                        : deviceList?.length
                                        ? [...deviceList]
                                        : []
                                }
                                rowsCount={
                                    (deviceListAux.length
                                        ? [...deviceListAux]
                                        : deviceList?.length
                                        ? [...deviceList]
                                        : []
                                    ).length
                                }
                                loading={isFetchingDevicesList}
                            />
                        </Stack>
                    </>
                ) : (
                    <Stack
                        direction="column"
                        justify="center"
                        align="center"
                        height="100%"
                        spacing="var(--spacing-100)"
                    >
                        <RouterSolid color="var(--lightness-800)" size="66" />
                        <Text variant="h5" color="var(--lightness-600)" align="center">
                            {t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.emptyTable')}
                            <br />
                            {t('ToolsScreen.MassChangeScreen.DeviceScreen.DeviceTable.emptyTableInfo')}
                        </Text>
                    </Stack>
                )}
            </Stack>
        </Card>
    );
}
