import Modal from '@/components/Modal/Modal';
import ProfilePicture from '@/components/ProfilePicture/ProfilePicture';
import useAuth from '@/hooks/useAuth';
import request from '@/util/request';
import { ActionButton, Input, Spinner, Stack, Text } from '@vg-react/components';
import { CheckRegular } from '@vg-react/icons/regular';
import { useState } from 'react';
import style from './ConfirmPasswordModal.module.scss';

export default function ConfirmPasswordModal({
    onClose,
    onConfirm,
    textLabel,
}: {
    onClose: () => void;
    onConfirm: () => Promise<void>;
    textLabel: string;
}) {
    const { user } = useAuth();
    const [password, setPassword] = useState<string>('');
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);

    const handleConfirm = () => {
        if (password.length === 0) {
            setError('Senha inválida');
            return;
        }
        setLoading(true);
        request
            .post('/app/users/self/check-password', { password })
            .then(async () => {
                setLoading(false);
                await onConfirm();
                onClose();
            })
            .catch((err) => {
                setLoading(false);
                setError(err.response.data);
            });
    };
    return (
        <Modal active size="small" onClose={onClose} closeOnClickOutside={false}>
            <Modal.Body>
                <Stack style={{ padding: '14px' }} spacing="var(--spacing-500)">
                    <Stack align="center" style={{ paddingTop: '14px' }}>
                        <div className={style.userContainer}>
                            <ProfilePicture name={user?.name} size={120} />
                        </div>
                        <Stack spacing="var(--spacing-75)" align="center">
                            <Text weight="bold" size="md">
                                {user?.name}
                            </Text>
                            <Text weight="regular" size="xs" align="center">
                                {user?.email}
                            </Text>
                        </Stack>
                    </Stack>
                    <Stack spacing="var(--spacing-100)">
                        <Text color="var(--gray-900)" size="xs" weight="regular" align="center">
                            {textLabel}
                        </Text>
                        <Input
                            onKeyDown={(e) => e.key === 'Enter' && handleConfirm()}
                            name="password"
                            width="100%"
                            type="password"
                            invalid={error.length > 0}
                            value={password}
                            helpText={error}
                            onChange={(input) => {
                                setPassword(input.target.value);
                                setError('');
                            }}
                        />
                    </Stack>
                </Stack>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction="row" align="center" justify="end">
                    <ActionButton onClick={onClose} quiet size="medium">
                        Cancelar
                    </ActionButton>
                    <ActionButton
                        onClick={handleConfirm}
                        disabled={password.length <= 0}
                        variant="primary"
                        size="medium"
                    >
                        {loading ? (
                            <Spinner size="small" color="var(--gray-100)" />
                        ) : (
                            <CheckRegular
                                size="14"
                                color={password.length <= 0 ? 'var(--gray-400)' : 'var(--gray-100)'}
                            />
                        )}
                        Confirmar
                    </ActionButton>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}
