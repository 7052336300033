import { ActionButton, Form, PasswordInput, Stack, Text } from '@vg-react/components';
import { Fields } from '@vg-react/components/dist/components/Form/Form';
import { Buffer } from 'buffer';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ACSLogo from '../../assets/images/ACSLogo';
import useTheme from '../../hooks/useTheme';
import request from '../../util/request';
import { ContainerToast } from './components/ContainerToast';
import { ThemeButton } from './components/ThemeButton';
import styles from './Login.module.scss';

interface ResetPasswordFields extends Fields {
    new_password: {
        value: string;
        strength: number;
    };
    new_password_confirm: {
        value: string;
        strength: number;
    };
}

export default function RecoverPasswordScreen() {
    const { token } = useParams();
    const navigate = useNavigate();
    const { theme, setTheme } = useTheme();
    const [loading, setLoading] = useState(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const { t } = useTranslation();

    const handleOnChange = (data: ResetPasswordFields) => {
        if (data.new_password && Object.keys(data.new_password).length) {
            setDisabled(
                data.new_password.strength !== 100 || data.new_password.value !== data.new_password_confirm.value,
            );
            setInvalid(data.new_password.value !== data.new_password_confirm.value);
        }
    };

    const handleSubmit = (data: ResetPasswordFields) => {
        setLoading(true);
        if (token) {
            request
                .post('/app/login/resetPassword/', {
                    token: Buffer.from(token, 'base64').toString('ascii'),
                    password: data.new_password_confirm.value,
                })
                .then((resp) => {
                    setLoading(false);
                    toast.success(resp.data);
                })
                .catch((err) => {
                    toast.error(err.response.data);
                })
                .finally(() => {
                    setTimeout(() => {
                        navigate('/login');
                    }, 2000);
                });
        } else {
            setLoading(false);
        }
    };

    return (
        <Stack direction="row" width="100vw" height="100vh" spacing="0">
            <div className={styles.loginContainer}>
                <ThemeButton theme={theme} setTheme={setTheme} />
                <Stack
                    className={styles.loginBox}
                    spacing="var(--spacing-600)"
                    direction="column"
                    justify="center"
                    align="center"
                >
                    <div className={styles.loginHeader}>
                        <ACSLogo
                            color="var(--accent-900)"
                            style={{
                                height: '100px',
                                userSelect: 'none',
                                MozUserSelect: 'none',
                            }}
                        />
                    </div>
                    <div className={styles.transitionContainer}>
                        <Stack direction="column" className={styles.loginInternal}>
                            <Text variant="h2" weight="bold">
                                {t('LoginScreen.recoverPassword.title')}
                            </Text>
                            <Text color="var(--gray-700)" weight="regular" align="justify" variant="p">
                                {t('LoginScreen.recoverPassword.description')}
                            </Text>
                            <Form<ResetPasswordFields> onSubmit={handleSubmit} onChange={handleOnChange}>
                                <Stack>
                                    <Stack spacing="small">
                                        <PasswordInput
                                            width="100%"
                                            label={t('LoginScreen.recoverPassword.newPassword')}
                                            name="new_password"
                                            placeholder={t('LoginScreen.recoverPassword.writeNewPassword')}
                                            checkOptions={[
                                                'lowercase-uppercase',
                                                'number',
                                                'special-character',
                                                'character-lenght',
                                            ]}
                                        />
                                        <PasswordInput
                                            width="100%"
                                            invalid={invalid}
                                            label={t('LoginScreen.recoverPassword.confirmPassword')}
                                            name="new_password_confirm"
                                            placeholder={t('LoginScreen.recoverPassword.confirmPassword')}
                                            helpText={
                                                invalid ? t('LoginScreen.recoverPassword.differentPasswords') : ''
                                            }
                                        />
                                    </Stack>
                                    <Stack direction="column" width="100%">
                                        <ActionButton
                                            disabled={disabled}
                                            variant="secondary"
                                            loading={loading}
                                            width="100%"
                                            type="submit"
                                        >
                                            {t('LoginScreen.recoverPassword.save')}
                                        </ActionButton>
                                    </Stack>
                                </Stack>
                            </Form>
                        </Stack>
                    </div>
                </Stack>
            </div>
            <ContainerToast />
        </Stack>
    );
}
