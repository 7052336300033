import en from '@/../locales/en/translation.json';
import es from '@/../locales/es/translation.json';
import pt from '@/../locales/pt/translation.json';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

const language = document.cookie
    .split('; ')
    .find((row) => row.startsWith('tr069_lang='))
    ?.split('=')[1];

export const defaultNS = 'translation';
export const resources = {
    en,
    pt,
    es,
} as const;

i18n.use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        defaultNS,
        lng: language,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
