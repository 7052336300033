import { Listbox } from '@headlessui/react';
import { ReactNode } from 'react';
import css from './Select.module.scss';

interface GroupProps {
    groupName: string;
    children: ReactNode;
}

export default function SelectGroup({ groupName, children }: GroupProps) {
    const id = Math.trunc(Math.random() * 1000);

    return (
        <>
            <Listbox.Option value={id} disabled>
                <div className={css.group}>{groupName}</div>
            </Listbox.Option>
            {children}
        </>
    );
}
