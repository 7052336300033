import { ActionButton, Stack, Text } from '@vg-react/components';
import { CloudCheckRegular, CloudQuestionRegular } from '@vg-react/icons/v6/regular';
import { useTranslation } from 'react-i18next';

const PanelContent = ({ isSynced }: { isSynced: boolean }) => {
    const { t } = useTranslation();

    return (
        <Stack
            onClick={(e) => {
                e.stopPropagation();
            }}
            style={{ width: 420 }}
        >
            <Stack direction="row" align="center" justify="space-between">
                <Stack direction="row" align="center">
                    {isSynced ? (
                        <CloudCheckRegular size="26" color="var(--green-700)" />
                    ) : (
                        <CloudQuestionRegular size="26" color="var(--yellow-700)" />
                    )}
                    <Text color="var(--gray-800)" size="xs" weight="bold">
                        {isSynced
                            ? t('DevicesScreen.DeviceScreen.Connectivity.AutomationPanel.synced')
                            : t('DevicesScreen.DeviceScreen.Connectivity.AutomationPanel.notSynced')}
                    </Text>
                </Stack>
            </Stack>

            <Stack>
                {isSynced ? (
                    <Text color="var(--gray-700)" size="xs" weight="regular">
                        {t('DevicesScreen.DeviceScreen.Connectivity.AutomationPanel.syncedDescription')}
                    </Text>
                ) : (
                    <>
                        <Text color="var(--gray-700)" size="xs" weight="regular">
                            {t('DevicesScreen.DeviceScreen.Connectivity.AutomationPanel.notSyncedDescription')}
                        </Text>

                        <ActionButton
                            variant="secondary"
                            onClick={(e) => {
                                e.stopPropagation();
                            }}
                        >
                            {t('DevicesScreen.DeviceScreen.Connectivity.AutomationPanel.updateData')}
                        </ActionButton>
                    </>
                )}
            </Stack>
        </Stack>
    );
};

export default PanelContent;
