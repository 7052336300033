import { classnames } from '@/util/classnames';
import { ActionButton, Stack } from '@vg-react/components';
import { ArrowLeftSolid, ArrowRightSolid } from '@vg-react/icons/v6/solid';
import { ReactNode, useState } from 'react';
import style from './Stepper.module.scss';

export const Stepper = ({
    steps,
    itemText,
    currentStep,
}: {
    steps: ReactNode[];
    itemText: string[];
    currentStep: number;
}) => {
    const [_currentStep, setCurrentStep] = useState(currentStep);

    const handleNextStep = () => {
        setCurrentStep(currentStep + 1);
    };

    const handlePreviousStep = () => {
        setCurrentStep(currentStep - 1);
    };

    return (
        <Stack className={style.stepper}>
            <Step itemText={itemText} currentStep={_currentStep} />
            <div className="step-content">{steps[_currentStep]}</div>
            <div className="stepper-buttons">
                {_currentStep > 0 && (
                    <ActionButton onClick={handlePreviousStep}>
                        Anterior
                        <ArrowLeftSolid />
                    </ActionButton>
                )}
                {_currentStep < steps.length - 1 && (
                    <ActionButton onClick={handleNextStep}>
                        Próximo
                        <ArrowRightSolid />
                    </ActionButton>
                )}
            </div>
        </Stack>
    );
};

export const Step = ({
    itemText,
    currentStep,
    onClick,
}: {
    itemText: string[];
    currentStep: number;
    separatorLength?: number;
    onClick?: (currentStep: number) => void;
}) => {
    return (
        <Stack className={style.stepper}>
            <div className={style.stepIndicatorContainer}>
                {itemText.map((item, index) => (
                    <div className={style.stepIndicator}>
                        <Stack spacing="var(--spacing-50)" direction="column" align="center" justify="center">
                            <div
                                key={index}
                                className={classnames(
                                    style.step,
                                    currentStep >= index ? style.activeStep : style.inactiveStep,
                                )}
                                onClick={() => currentStep >= index && onClick?.(index)}
                            >
                                {index + 1}
                            </div>

                            <div style={{ color: currentStep >= index ? 'var(--accent-900)' : 'var(--gray-400)' }}>
                                {item}
                            </div>
                        </Stack>
                        {itemText.length - 1 !== index && (
                            <div
                                className={currentStep >= index ? style.stepSeparatorActive : style.stepSeparator}
                            ></div>
                        )}
                    </div>
                ))}
            </div>
        </Stack>
    );
};

export default Stepper;
