import Stack from '@/components/Stack/Stack';
import Text from '@/components/Text/Text';
import Tooltip from '@/components/Tooltip/Tooltip';
import {
    DeviceProps,
    DevicesCount,
    useMassUpdateContext,
    variantsBackward,
    variantsForward,
} from '@/contexts/MassChange/MassChangeFirmwareContext';
import { classnames } from '@/util/classnames';
import { Card } from '@vg-react/components';
import { InfoCircleSolid } from '@vg-react/icons/solid';
import { ChevronLeftRegular, ChevronRightRegular } from '@vg-react/icons/v6/regular';
import { BullseyePointerSolid, ClockSolid, FileLinesSolid, RouterSolid } from '@vg-react/icons/v6/solid';
import useEmblaCarousel from 'embla-carousel-react';
import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Buttons from './Buttons';
import Carousel from './Carousel';
import style from './Resume.module.scss';

export default function Resume() {
    const { t } = useTranslation();
    const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [totalSlides, setTotalSlides] = useState(0);
    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setSelectedIndex(emblaApi.selectedScrollSnap());
    }, [emblaApi]);

    useEffect(() => {
        if (!emblaApi) return;
        setTotalSlides(emblaApi.slideNodes().length);
        emblaApi.on('select', onSelect);
    }, [emblaApi, onSelect]);

    const { selectedDevices, selectedFirmware, direction } = useMassUpdateContext();
    const [startNow, setStartNow] = useState(true);
    const children: DevicesCount[] = [];

    const [deviceWithLongestPeriodic] = selectedDevices.sort(
        (a, b) => b.periodicInformInterval - a.periodicInformInterval,
    );
    function countDevices(devices: DeviceProps[]): DevicesCount[] {
        const deviceCounts: { [key: string]: { [key: string]: { [key: string]: number } } } = {};

        devices.forEach((device) => {
            const { modelName, hardwareVersion, softwareVersion } = device;
            if (!deviceCounts[modelName]) {
                deviceCounts[modelName] = {};
            }
            if (!deviceCounts[modelName][hardwareVersion]) {
                deviceCounts[modelName][hardwareVersion] = {};
            }
            if (!deviceCounts[modelName][hardwareVersion][softwareVersion]) {
                deviceCounts[modelName][hardwareVersion][softwareVersion] = 0;
            }
            deviceCounts[modelName][hardwareVersion][softwareVersion]++;
        });
        const result: DevicesCount[] = [];
        for (const modelName in deviceCounts) {
            for (const hardware in deviceCounts[modelName]) {
                for (const software in deviceCounts[modelName][hardware]) {
                    result.push({
                        model: modelName,
                        hardware: hardware,
                        software: software,
                        total: deviceCounts[modelName][hardware][software],
                    });
                }
            }
        }
        return result;
    }

    for (const deviceCount of countDevices(selectedDevices)) children.push(deviceCount);

    const formatTime = (timeInSec: number) => {
        const hours = Math.floor(timeInSec / 3600);
        const minutes = Math.floor((timeInSec % 3600) / 60);
        const seconds = timeInSec % 60;
        return `${hours.toString().length === 1 ? `0${hours}` : hours}:${
            minutes.toString().length === 1 ? `0${minutes}` : minutes
        }:${seconds.toString().length === 1 ? `0${seconds}` : seconds}`;
    };

    const percentageOffline = Math.round(
        (selectedDevices.filter((device) => !device.status).length / selectedDevices.length) * 100,
    );
    return (
        <motion.div
            style={{ x: 0, height: '100%', overflow: 'hidden' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={direction === 'forward' ? variantsForward : variantsBackward}
        >
            <Stack direction="row" justify="space-between" align="center" style={{ height: '100%' }}>
                <Card className={style.resumeCard}>
                    <Stack
                        height="100%"
                        style={{
                            padding: 'var(--spacing-75)',
                            height: '100%',
                            overflow: 'auto',
                        }}
                        spacing={window.innerHeight < 800 ? 'var(--spacing-200)' : 'var(--spacing-300)'}
                    >
                        <Stack key="title" direction="row" justify="space-between" align="center">
                            <Text className={style.title}>{t('ToolsScreen.MassChangeScreen.ResumeScreen.title')}</Text>
                        </Stack>
                        <Stack
                            direction="row"
                            align="center"
                            height="100%"
                            spacing="var(--spacing-100)"
                            justify="space-between"
                        >
                            <Stack direction="row" height="100%" width="100%" justify="space-between">
                                <Stack justify="start" width="70%" spacing="var(--spacing-300)">
                                    <Stack
                                        direction="row"
                                        height="34%"
                                        justify="center"
                                        align="center"
                                        spacing="var(--spacing-300)"
                                    >
                                        <Stack
                                            align="center"
                                            justify="center"
                                            spacing="var(--spacing-50)"
                                            className={style.statisticsCards}
                                        >
                                            <Stack direction="row" spacing="var(--spacing-75)" align="center">
                                                <ClockSolid color="var(--gray-900)" />
                                                <Text
                                                    variant="h6"
                                                    size="sm"
                                                    style={{
                                                        maxWidth: '92px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textWrap: 'nowrap',
                                                    }}
                                                >
                                                    {formatTime(deviceWithLongestPeriodic.periodicInformInterval)}
                                                </Text>
                                            </Stack>
                                            <Text weight="regular" color="var(--gray-500)">
                                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.time')}
                                            </Text>
                                        </Stack>
                                        <Stack
                                            align="center"
                                            justify="center"
                                            spacing="var(--spacing-50)"
                                            className={style.statisticsCards}
                                        >
                                            <Stack direction="row" spacing="var(--spacing-75)" align="center">
                                                <RouterSolid color="var(--gray-900)" />
                                                <Text
                                                    variant="h6"
                                                    size="sm"
                                                    style={{
                                                        maxWidth: '92px',
                                                        overflow: 'hidden',
                                                        textOverflow: 'ellipsis',
                                                        textWrap: 'nowrap',
                                                    }}
                                                >
                                                    {selectedDevices.length}
                                                </Text>
                                            </Stack>
                                            <Text weight="regular" color="var(--gray-500)">
                                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.devices')}
                                            </Text>
                                        </Stack>
                                        <Stack
                                            align="center"
                                            justify="center"
                                            spacing="var(--spacing-50)"
                                            className={style.statisticsCards}
                                        >
                                            <Tooltip>
                                                <Tooltip.Trigger>
                                                    <Stack
                                                        direction="row"
                                                        spacing="var(--spacing-75)"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <FileLinesSolid color="var(--gray-900)" />
                                                        <Text
                                                            variant="h6"
                                                            size="sm"
                                                            style={{
                                                                maxWidth: '92px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                textWrap: 'nowrap',
                                                            }}
                                                        >
                                                            {selectedFirmware.name}
                                                        </Text>
                                                    </Stack>
                                                </Tooltip.Trigger>
                                                <Tooltip.Content>{selectedFirmware.name}</Tooltip.Content>
                                            </Tooltip>
                                            <Text weight="regular" color="var(--gray-500)">
                                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.selectedFile')}
                                            </Text>
                                        </Stack>
                                        <Stack
                                            align="center"
                                            justify="center"
                                            spacing="var(--spacing-50)"
                                            className={style.statisticsCards}
                                        >
                                            <Tooltip>
                                                <Tooltip.Trigger>
                                                    <Stack
                                                        direction="row"
                                                        spacing="var(--spacing-75)"
                                                        align="center"
                                                        justify="center"
                                                    >
                                                        <BullseyePointerSolid color="var(--gray-900)" />
                                                        <Text
                                                            variant="h6"
                                                            size="sm"
                                                            style={{
                                                                maxWidth: '92px',
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                textWrap: 'nowrap',
                                                            }}
                                                        >
                                                            {selectedFirmware.version}
                                                        </Text>
                                                    </Stack>
                                                </Tooltip.Trigger>
                                                <Tooltip.Content>{selectedFirmware.version}</Tooltip.Content>
                                            </Tooltip>
                                            <Text weight="regular" color="var(--gray-500)">
                                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.targetVersion')}
                                            </Text>
                                        </Stack>
                                    </Stack>
                                    <Stack
                                        style={{ borderTop: '2px solid var(--lightness-900)', paddingTop: '20px' }}
                                        height="66%"
                                        spacing={window.innerHeight < 800 ? 'var(--spacing-200)' : 'var(--spacing-300)'}
                                    >
                                        <Stack>
                                            <Text className={style.title}>
                                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.affectedModels')}
                                            </Text>
                                        </Stack>
                                        <Stack
                                            height="100%"
                                            align="center"
                                            spacing="var(--spacing-50)"
                                            justify="center"
                                        >
                                            {children.length > 1 ? (
                                                <>
                                                    <Stack
                                                        direction="row"
                                                        justify="center"
                                                        className={style.carrouselBody}
                                                    >
                                                        <Stack
                                                            justify="center"
                                                            align="center"
                                                            className={style.carouselButton}
                                                            onClick={() => {
                                                                emblaApi?.scrollPrev();
                                                            }}
                                                        >
                                                            <ChevronLeftRegular
                                                                style={{ flexGrow: '1' }}
                                                                color="var(--gray-800)"
                                                            />
                                                        </Stack>
                                                        <div className={style.carrousel} ref={emblaRef}>
                                                            <div className={style.container}>
                                                                {children.map((item) => (
                                                                    <div
                                                                        className={style.slider}
                                                                        style={{
                                                                            margin:
                                                                                children.length > 1 ? '0 10px' : '0',
                                                                        }}
                                                                    >
                                                                        <Carousel item={item} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                        <Stack
                                                            justify="center"
                                                            align="center"
                                                            className={style.carouselButton}
                                                            onClick={() => {
                                                                emblaApi?.scrollNext();
                                                            }}
                                                        >
                                                            <ChevronRightRegular
                                                                style={{ flexGrow: '1' }}
                                                                color="var(--gray-800)"
                                                            />
                                                        </Stack>
                                                    </Stack>
                                                    <div className={style.pagination}>
                                                        {Array.from({ length: totalSlides }).map((_, index) => (
                                                            <span
                                                                key={index}
                                                                onClick={() => emblaApi?.scrollTo(index)}
                                                                className={classnames(
                                                                    style.paginationButton,
                                                                    index === selectedIndex
                                                                        ? style.paginationButtonSelected
                                                                        : '',
                                                                )}
                                                            ></span>
                                                        ))}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <Stack
                                                        direction="row"
                                                        justify="center"
                                                        className={style.carrouselBody}
                                                    >
                                                        <div className={style.carrousel}>
                                                            <div className={style.container}>
                                                                {children.map((item) => (
                                                                    <div
                                                                        className={style.slider}
                                                                        style={{
                                                                            margin:
                                                                                children.length > 1 ? '0 10px' : '0',
                                                                        }}
                                                                    >
                                                                        <Carousel item={item} />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </Stack>
                                                </>
                                            )}
                                        </Stack>
                                    </Stack>
                                </Stack>
                                <Stack
                                    width="30%"
                                    spacing="var(--spacing-200)"
                                    style={{ borderLeft: '2px solid var(--lightness-900)', paddingLeft: '24px' }}
                                >
                                    <Stack
                                        align="center"
                                        justify={!startNow && window.innerHeight < 800 ? 'start' : 'center'}
                                        height="100%"
                                    >
                                        <Stack
                                            spacing={
                                                window.innerHeight < 800 ? 'var(--spacing-50)' : 'var(--spacing-300)'
                                            }
                                        >
                                            <Stack
                                                direction="row"
                                                spacing="var(--spacing-100)"
                                                align="center"
                                                justify="center"
                                            >
                                                <Text variant="h5">
                                                    {t('ToolsScreen.MassChangeScreen.ResumeScreen.selectWhenStart')}
                                                </Text>
                                                {window.innerHeight < 800 && (
                                                    <Tooltip
                                                        placement="bottom"
                                                        color="rgb(0, 0, 0, .9)"
                                                        arrow={false}
                                                        offset={-2}
                                                    >
                                                        <Tooltip.Trigger>
                                                            <Stack style={{ padding: 6 }}>
                                                                <InfoCircleSolid
                                                                    size="14"
                                                                    color="var(--lightness-600)"
                                                                    style={{ marginTop: '3px' }}
                                                                />
                                                            </Stack>
                                                        </Tooltip.Trigger>
                                                        <Tooltip.Content>
                                                            <Stack
                                                                spacing="var(--spacing-75)"
                                                                justify="start"
                                                                style={{ padding: 'var(--spacing-50)' }}
                                                                direction="row"
                                                                align="start"
                                                            >
                                                                <Stack>
                                                                    <InfoCircleSolid
                                                                        size="14"
                                                                        color="var(--lightness-600)"
                                                                        style={{ marginTop: '3px' }}
                                                                    />
                                                                </Stack>
                                                                <Text color="#EEE" weight="regular">
                                                                    {t(
                                                                        'ToolsScreen.MassChangeScreen.ResumeScreen.tooltip',
                                                                    )}
                                                                </Text>
                                                            </Stack>
                                                        </Tooltip.Content>
                                                    </Tooltip>
                                                )}
                                            </Stack>
                                            <Buttons startNow={startNow} setStartNow={setStartNow} />
                                        </Stack>
                                    </Stack>
                                    {window.innerHeight >= 800 && (
                                        <Stack
                                            direction="row"
                                            spacing="var(--spacing-75)"
                                            align="flex-start"
                                            className={style.messages}
                                        >
                                            <Stack>
                                                <InfoCircleSolid
                                                    size="14"
                                                    color="var(--lightness-600)"
                                                    style={{ marginTop: '3px' }}
                                                />
                                            </Stack>
                                            <Text>{t('ToolsScreen.MassChangeScreen.ResumeScreen.tooltip')}</Text>
                                        </Stack>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    </Stack>
                </Card>
            </Stack>
        </motion.div>
    );
}
