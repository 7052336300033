import Modal from '@/components/Modal/Modal';
import useSocket from '@/hooks/useSocket';
import { ActionButton, Form, Stack, Text } from '@vg-react/components';
import {} from '@vg-react/icons/solid';
import { CheckSolid, XmarkSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import request from '../../../../../../util/request';
import { RemoteAccessProps, WebSocketMessage } from '../Types';

export default function ModalConfirmRemoteAccessEnable({
    active,
    id,
    onClose,
    remoteAccessEnabled,
    refresh,
    setDescriptionDevice,
    onSubmit,
    setIsSentRemoteAccess,
    needsReboot,
}: RemoteAccessProps) {
    const { t } = useTranslation();
    const { socket } = useSocket();

    function submit() {
        setDescriptionDevice({
            message: remoteAccessEnabled
                ? t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.enabling')
                : t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.disabling'),
            status: 'in progress',
        });
        setIsSentRemoteAccess(true);
        request
            .patch(`/app/devices/${id}/security/remoteAccess/`, { enable: remoteAccessEnabled })
            .then(() => {
                socket?.on(`resources:security:${id}`, (response: WebSocketMessage) => {
                    if (response.status === 'in progress') {
                        toast.info(response.message);
                        setDescriptionDevice({ message: response.message, status: 'in progress' });
                        return;
                    }
                    if (response.status === 'success') {
                        toast.success(response.message);
                        setDescriptionDevice(response);
                    } else {
                        toast.error(response.message);
                        setDescriptionDevice(response);
                    }
                    setTimeout(() => {
                        setDescriptionDevice(null);
                    }, 5000);
                    setIsSentRemoteAccess(false);
                    socket.off(`resources:security:${id}`);
                });
            })
            .catch((error) => {
                toast.error(error.response.data);
                setDescriptionDevice({
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.error',
                    ),
                    status: 'error',
                });
                setTimeout(() => {
                    setDescriptionDevice(null);
                    setIsSentRemoteAccess(false);
                    refresh();
                }, 5000);
            });
        onSubmit(remoteAccessEnabled);
    }

    return (
        <Modal size="small" active={active} onClose={() => onClose()}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {remoteAccessEnabled
                        ? t(
                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.enableAccess',
                          )
                        : t(
                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.disableAccess',
                          )}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={submit}>
                <Modal.Body>
                    <Stack>
                        {needsReboot && (
                            <Text weight="bold" variant="p" size="xs">
                                {t(
                                    'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.needsReboot',
                                )}
                            </Text>
                        )}
                        <Text weight="light" variant="p" size="xs">
                            {remoteAccessEnabled
                                ? t(
                                      'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.confirmEnable',
                                  )
                                : t(
                                      'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.confirmDisable',
                                  )}
                        </Text>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={() => onClose()} quiet>
                            {t(
                                'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.cancel',
                            )}
                        </ActionButton>
                        <ActionButton type="submit">
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                {remoteAccessEnabled ? (
                                    <>
                                        <CheckSolid size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.enable',
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <XmarkSolid size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.disable',
                                        )}
                                    </>
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
