import i18n from '@/i18n';
import { t } from 'i18next';

export function formartDate(date: Date): string {
    const month = date.toLocaleString(i18n.language, { month: 'long' });
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month} ${day}, ${year}`;
}

export function howMuchTimeAgo(data: Date): string {
    const timeAgo = new Date().getTime() - data.getTime();
    const months = Math.floor(timeAgo / (1000 * 60 * 60 * 24 * 30));
    const days = Math.floor(timeAgo / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeAgo % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeAgo % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeAgo % (1000 * 60)) / 1000);

    const units = [
        { unit: t('Util.time.month'), plural: t('Util.time.months'), value: months },
        { unit: t('Util.time.day'), plural: t('Util.time.days'), value: days },
        { unit: t('Util.time.hour'), plural: t('Util.time.hours'), value: hours },
        { unit: t('Util.time.minute'), plural: t('Util.time.minutes'), value: minutes },
        { unit: t('Util.time.second'), plural: t('Util.time.seconds'), value: seconds },
    ];

    const result = units
        .map(({ unit, plural, value }) => {
            if (value > 0) {
                return value === 1 ? t('Util.time.ago', { value, unit }) : t('Util.time.ago', { value, unit: plural });
            }
        })
        .filter(Boolean)[0];

    return result || t('Util.time.justNow');
}

interface DateFormatOptions {
    seconds?: 'full' | 'short' | 'hidden';
    minutes?: 'full' | 'short' | 'hidden';
    hours?: 'full' | 'short' | 'hidden';
    days?: 'full' | 'short' | 'hidden';
    months?: 'full' | 'short' | 'hidden';
    onlyMostSignificant?: boolean;
}

export function formatDate(
    seconds: number,
    options: DateFormatOptions = {
        seconds: 'full',
        minutes: 'full',
        hours: 'full',
        days: 'full',
        months: 'full',
        onlyMostSignificant: true,
    },
): string {
    const months = Math.floor(seconds / (60 * 60 * 24 * 30));
    const days = Math.floor(seconds / (60 * 60 * 24));
    const hours = Math.floor((seconds % (60 * 60 * 24)) / (60 * 60));
    const minutes = Math.floor((seconds % (60 * 60)) / 60);
    const formatSeconds = Math.floor(seconds % 60);

    const units = [
        { index: 'months', unit: t('Util.time.month'), plural: t('Util.time.months'), short: 'm', value: months },
        { index: 'days', unit: t('Util.time.day'), plural: t('Util.time.days'), short: 'd', value: days },
        { index: 'hours', unit: t('Util.time.hour'), plural: t('Util.time.hours'), short: 'h', value: hours },
        { index: 'minutes', unit: t('Util.time.minute'), plural: t('Util.time.minutes'), short: 'm', value: minutes },
        {
            index: 'seconds',
            unit: t('Util.time.second'),
            plural: t('Util.time.seconds'),
            short: t('Util.time.secondShort'),
            value: formatSeconds,
        },
    ];

    const result = units
        .map(({ index, unit, plural, value, short }) => {
            if (options[index as keyof DateFormatOptions] === 'hidden') return;
            if (value <= 0) return;
            if (options[index as keyof DateFormatOptions] === 'short') {
                return `${value}${short}`;
            }
            return value === 1 ? `${value} ${unit}` : `${value} ${plural}`;
        })
        .filter(Boolean);

    const selectedResult = options.onlyMostSignificant ? result[0] : result.join(' ');

    return selectedResult || t('Util.time.fewSeconds');
}

export function convertBytes(bytes: number, decimals = 2, displayUnit = true): string {
    if (bytes === 0) return '0 B';

    const k = 1000;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    if (displayUnit) return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];

    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)).toString();
}

export function convertBytesToUnit(bytes: number, decimals = 1) {
    if (bytes === 0) return { value: 0, unit: 'B' };

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['B', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return { value: parseFloat((bytes / Math.pow(k, i)).toFixed(dm)), unit: sizes[i] };
}

// Input: 1000
// Output: $1,000.00
export function formartCurrency(value: number): string {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
    }).format(value);
}

export function getRelativeTime(timestamp: number): string {
    const now = new Date();
    const date = new Date(timestamp);

    const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    const startOfYesterday = new Date(now.getFullYear(), now.getMonth(), now.getDate() - 1);
    const startOfWeek = new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());
    const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);

    if (date >= startOfToday) {
        return t('Util.time.today');
    } else if (date >= startOfYesterday) {
        return t('Util.time.yesterday');
    } else if (date >= startOfWeek) {
        return t('Util.time.thisWeek');
    } else if (date >= startOfMonth) {
        return t('Util.time.thisMonth');
    } else {
        return date.toLocaleString('default', { month: 'long' });
    }
}
