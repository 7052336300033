import { CSSProperties, ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import css from './Text.module.scss';

type Variant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'p' | 'span';
type Weight = 'light' | 'regular' | 'bold' | 'bolder';
type FontSize = 'exs' | 'xxs' | 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl' | 'xxxl' | 'exl' | 'Giant';
type LineHeight = 'default' | 'md' | 'lg' | 'none';

export interface TextProps {
    /**
     * Defines the text content
     * @required
     */
    children: ReactNode;
    /**
     * The text alignment
     * @default 'left'
     */
    align?: CSSProperties['textAlign'];
    /**
     * The text transform
     * @default 'none'
     */
    transform?: CSSProperties['textTransform'];
    /**
     * The text decoration
     * @default 'none'
     */
    decoration?: CSSProperties['textDecoration'];
    /**
     * The text color
     * @default 'var(--gray-900)'
     */
    color?: CSSProperties['color'];
    /**
     * The text weight
     */
    weight?: Weight;
    /**
     * The text variant
     * @default 'p'
     */
    variant?: Variant;
    /**
     * The text size
     */
    size?: FontSize;
    /**
     * The text line height
     */
    lineHeight?: LineHeight;
    /**
     * Defines if the text is monospace
     * @default false
     */
    monospace?: boolean;
    /**
     * Allow to add custom styles
     * @default {}
     */
    style?: CSSProperties;
    /**
     * Allow to add custom classes
     * @default ''
     */
    className?: string;
}

/**
 * The Text component is used to display text.
 */
export default function Text({
    children,
    style,
    className,
    size,
    weight,
    lineHeight,
    color = 'var(--gray-900)',
    align = 'left',
    transform = 'none',
    decoration = 'none',
    variant = 'p',
    monospace = false,
}: TextProps): JSX.Element {
    const Tag = variant;
    return (
        <Tag
            className={classnames(css.text, css[`variant-${variant}`], className || '')}
            style={{
                textAlign: align,
                textTransform: transform,
                textDecoration: decoration,
                fontWeight: weight ? `var(--font-weight-${weight})` : undefined,
                fontSize: size ? `var(--font-size-${size})` : undefined,
                lineHeight: lineHeight ? `var(--line-height-${lineHeight})` : undefined,
                fontFamily: monospace ? 'monospace' : undefined,
                color: color !== 'unset' ? color : undefined,
                ...style,
            }}
        >
            {children}
        </Tag>
    );
}
