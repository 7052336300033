import useSocket from '@/hooks/useSocket';
import { classnames } from '@/util/classnames';
import { formatDate } from '@/util/format';
import { Badge, Spinner, Stack, Text, Tooltip } from '@vg-react/components';
import { TimesRegular } from '@vg-react/icons/regular';
import { RouteLight } from '@vg-react/icons/v6/light';
import { TimerRegular } from '@vg-react/icons/v6/regular';
import { WavePulseSolid } from '@vg-react/icons/v6/solid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import styles from '../index/Latency.module.scss';

export type Hop = {
    index?: number;
    host: string;
    hostAddress: string;
    time: string;
};

export type TestResult = {
    diagnosticsState: 'pending' | 'failed' | 'Complete' | 'Error_CannotResolveHostName' | 'Error_MaxHopCountExceeded';
    destination: string;
    hops: Hop[];
};
export type TestState = {
    destinations: TestResult[];
    maxHops: number;
    timeout: number;
    timestamp: Date;
    state: 'not_started_yet' | 'pending' | 'failed' | 'success';
};

const Traceroute = ({
    state,
    timestamp,
    isFetching,
    isTracerouteRunning,
}: {
    state: TestState | null;
    isFetching: boolean;
    timestamp?: Date;
    isTracerouteRunning?: (value: boolean) => void;
}) => {
    const { t } = useTranslation();
    const [results, setResults] = useState<TestResult[]>(state?.destinations || []);
    const { socket } = useSocket();
    const { id } = useParams();
    const [activeTab, setActiveTab] = useState<string | null>(results[0]?.destination || '');
    const [isRunning, setIsRunning] = useState<boolean>(
        state?.state === 'pending' || state?.state === 'not_started_yet',
    );
    let testExecutedAt = '';
    const [defaultMessage, setDefaultMessage] = useState<string>(
        t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.defaultMessage') + testExecutedAt,
    );

    useEffect(() => {
        if (state?.destinations) {
            setResults(state.destinations);
        }

        if (state?.state === 'pending' || state?.state === 'not_started_yet') {
            setIsRunning(true);
        } else {
            setIsRunning(false);
        }

        if (timestamp) {
            const timeAgo = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
            testExecutedAt =
                ' - ' +
                t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.testExecutedAt', {
                    timeAgo: formatDate(timeAgo),
                });

            setDefaultMessage(
                t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.defaultMessage') + testExecutedAt,
            );
        }
    }, [state]);

    useEffect(() => {
        const filteredResults = results.filter(
            (e) => e.diagnosticsState?.startsWith('Complete') || e.diagnosticsState?.startsWith('Error_Max'),
        )[0];
        if (filteredResults) {
            setActiveTab(filteredResults.destination);
        } else {
            setActiveTab(results[0]?.destination || '');
        }
    }, [results]);

    useEffect(() => {
        isTracerouteRunning?.(isRunning);
        if (isRunning) {
            setDefaultMessage(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.inProgress'));
        }
    }, [isRunning]);

    useEffect(() => {
        socket?.on(`${id}_diagnostics:traceroute:values`, (data: TestResult) => {
            setResults((old) => {
                const index = old.findIndex((result) => result.destination === data.destination);
                if (index !== -1) {
                    const newResults = [...old];
                    newResults[index] = data;
                    return newResults;
                }
                return old;
            });
        });

        socket?.on(`${id}_diagnostics:traceroute:ended`, (data: TestResult) => {
            setDefaultMessage(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.defaultMessage'));
            setIsRunning(false);
        });

        socket?.on(`${id}_diagnostics:traceroute:detailed_info`, (data: { serialNumber: string; message: string }) => {
            setDefaultMessage(data.message);
        });

        return () => {
            socket?.off(`${id}_diagnostics:traceroute:values`);
            socket?.off(`${id}_diagnostics:traceroute:detailed_info`);
            socket?.off(`${id}_diagnostics:traceroute:ended`);
        };
    }, [socket]);
    return (
        <Stack className={styles.resultContainer} justify="start" align="center" spacing="var(--spacing-200)">
            <Stack
                style={{ width: '100%' }}
                direction="row"
                align="center"
                justify="space-between"
                spacing="var(--spacing-100)"
            >
                <Stack direction="row" align="center" justify="space-between" spacing="var(--spacing-75)">
                    {isRunning ? (
                        <Spinner size="large" color="var(--accent-900)" />
                    ) : (
                        <RouteLight size="30" color="var(--accent-900)" />
                    )}
                    <Stack spacing="0">
                        <Text size="md" weight="bold" color="var(--lightness-300)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.title')}
                        </Text>
                        <Text size="xs" weight="regular" color="var(--lightness-600)">
                            {defaultMessage}
                        </Text>
                    </Stack>
                </Stack>
                <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    spacing="var(--spacing-300)"
                    style={{
                        borderRadius: 'var(--radius-small)',
                        border: '1px solid var(--lightness-900)',
                        padding: 'var(--spacing-100)',
                        paddingInline: 'var(--spacing-300)',
                    }}
                >
                    {isFetching ? (
                        <Skeleton width={200} height={16} />
                    ) : (
                        <>
                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-75)">
                                <WavePulseSolid size="16" color="var(--lightness-300)" />
                                <Text size="xxs" weight="bold" color="var(--lightness-400)">
                                    {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.maxHops', {
                                        maxHops: state?.maxHops,
                                    })}
                                </Text>
                            </Stack>

                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-75)">
                                <TimerRegular size="12" color="var(--lightness-300)" />
                                <Text size="xxs" weight="bold" color="var(--lightness-400)">
                                    {state?.timeout}ms timeout
                                </Text>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>

            <Stack
                direction="row"
                className={styles.tabsContainer}
                spacing="var(--spacing-75)"
                justify="space-between"
                align="center"
            >
                {results.map((result, index) =>
                    result.diagnosticsState?.startsWith('Error_CannotResolveHostName') ||
                    (result.diagnosticsState?.startsWith('Complete') && result.hops.length === 0) ||
                    result.diagnosticsState === 'failed' ? (
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: '100%' }}>
                                <IndividualTracerouteTab
                                    key={index}
                                    activeTab={{
                                        activeTab,
                                        setActiveTab,
                                    }}
                                    hasError
                                    host={result.destination}
                                    state={result.diagnosticsState}
                                />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.rejectedDeviceTooltip')}
                            </Tooltip.Content>
                        </Tooltip>
                    ) : (
                        <IndividualTracerouteTab
                            key={index}
                            activeTab={{
                                activeTab,
                                setActiveTab,
                            }}
                            hasError={result.diagnosticsState?.startsWith('Complete') && result.hops.length === 0}
                            host={result.destination}
                            state={result.diagnosticsState}
                        />
                    ),
                )}
            </Stack>

            <Stack direction="column" className={styles.internalContainer} spacing="var(--spacing-200)">
                {results
                    .find((result) => result.destination === activeTab)
                    ?.hops.sort((a, b) => Number(a.index) - Number(b.index))
                    .map((hop) => (
                        <IndividualHopResult
                            key={hop.index}
                            index={`${hop.index}`}
                            host={hop.host}
                            address={hop.hostAddress}
                            latency={hop.time}
                            isLast={
                                hop.index === results.find((result) => result.destination === activeTab)?.hops.length
                            }
                        />
                    ))}
            </Stack>
        </Stack>
    );
};

const IndividualTracerouteTab = ({
    host,
    activeTab,
    state,
    hasError,
}: {
    host: string;
    activeTab: {
        activeTab: string | null;
        setActiveTab: (value: string | null) => void;
    };
    hasError?: boolean;
    state:
        | 'not_started_yet'
        | 'pending'
        | 'failed'
        | 'Complete'
        | 'Error_CannotResolveHostName'
        | 'Error_MaxHopCountExceeded';
}) => {
    const { activeTab: active, setActiveTab } = activeTab;
    const src = `https://www.google.com/s2/favicons?domain=${host}&sz=20`;
    return (
        <Stack
            direction="row"
            align="center"
            justify="center"
            spacing="var(--spacing-75)"
            className={classnames(
                styles.tab,
                active === host
                    ? styles.active
                    : state?.startsWith('Error_CannotResolveHostName') || state === 'failed' || hasError
                    ? styles.errorTab
                    : '',
            )}
            onClick={() =>
                (state?.startsWith('Complete') || state?.startsWith('Error_Max')) && !hasError && setActiveTab(host)
            }
        >
                {!hasError && (state?.startsWith('Complete') || state?.startsWith('Error_Max')) ? (
                <img style={{ width: '20px', height: '20px' }} src={src} />
            ) : state?.startsWith('Error') || state === 'failed' || hasError ? (
                <TimesRegular size="16" color="var(--red-900)" />
            ) : (
                <Spinner size="small" color="var(--accent-900)" />
            )}
            <Text style={{ userSelect: 'none' }} size="xxs" weight="bold" color="var(--lightness-400)">
                {host}
            </Text>
        </Stack>
    );
};

export const IndividualHopResult = ({
    index,
    host,
    address,
    latency,
    isLast,
}: {
    index: string;
    host: string;
    address: string;
    latency: string;
    isLast?: boolean;
}) => {
    const { t } = useTranslation();
    let parsedLatency = latency;

    if (latency.includes(',')) {
        parsedLatency = Math.round(
            latency
                .replaceAll('*', '')
                .split(',')
                .filter((e) => e)
                .reduce((a, b) => parseInt(a.toString()) + parseInt(b), 0) /
                latency
                    .replaceAll('*', '')
                    .split(',')
                    .filter((e) => e)?.length,
        ).toString();
    }

    if (isNaN(parseInt(parsedLatency))) {
        parsedLatency = '* * *';
    } else {
        parsedLatency = parsedLatency + ' ms';
    }
    return (
        <div style={{ position: 'relative', width: '99.5%' }}>
            <div
                style={{
                    paddingInline: 15,
                    paddingBlock: 4,
                    backgroundColor: 'var(--shadow-300)',
                    borderRadius: 'var(--radius-medium)',
                    position: 'relative',
                    overflow: 'hidden',
                    display: 'grid',
                    gridTemplateColumns: '40px 1fr 1fr auto',
                    justifyContent: 'space-between',
                    gap: 'var(--spacing-800)',
                    alignItems: 'center',
                }}
            >
                <Stack direction="column" align="center" justify="center" spacing="0">
                    <Text size="xxs" weight="regular" color="var(--lightness-700)">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.hop')}
                    </Text>
                    <Badge color="primary">{index}º</Badge>
                </Stack>
                <Stack
                    title={host?.length > 18 ? host : ''}
                    style={{ minWidth: 125 }}
                    direction="column"
                    align="center"
                    justify="center"
                    spacing="0"
                >
                    <Text size="xxs" weight="regular" color="var(--lightness-700)">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.host')}
                    </Text>
                    <Text size="sm" weight="regular" color="var(--lightness-400)">
                        {host?.length > 18 ? host.substring(0, 18) + '...' : host || '* * *'}
                    </Text>
                </Stack>

                <Stack
                    title={address?.length > 18 ? address : ''}
                    style={{ minWidth: 125 }}
                    direction="column"
                    align="center"
                    justify="center"
                    spacing="0"
                >
                    <Text size="xxs" weight="regular" color="var(--lightness-700)">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Traceroute.ip')}
                    </Text>
                    <Text size="sm" weight="regular" color="var(--lightness-400)">
                        {address?.length > 18 ? address.substring(0, 18) + '...' : address || '* * *'}
                    </Text>
                </Stack>

                <div style={{ position: 'relative' }}>
                    <div
                        style={{
                            position: 'absolute',
                            backgroundColor: 'var(--accent-900-fixed-dark)',
                            height: 86,
                            width: 80,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            borderRadius: 'var(--radius-round)',
                            right: -20,
                            top: -42,
                        }}
                    >
                        <Text size="sm" weight="regular" color="var(--lightness-400)">
                            {parsedLatency}
                        </Text>
                    </div>
                </div>
            </div>
            {!isLast && (
                <div
                    style={{
                        height: '29.5%',
                        width: 2,
                        backgroundColor: 'var(--accent-900-fixed-dark)',
                        position: 'absolute',
                        top: 53.5,
                        right: 35,
                    }}
                />
            )}
        </div>
    );
};

export default Traceroute;
