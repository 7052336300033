import TableBody from './TableBody';
import TableCell from './TableCell';
import TableHead from './TableHead';
import TableRoot from './TableRoot';
import TableRow from './TableRow';
import TableSortLabel from './TableSortLabel';

export const Table = Object.assign(TableRoot, {
    Head: TableHead,
    Body: TableBody,
    Row: TableRow,
    Cell: TableCell,
    SortLabel: TableSortLabel,
});

export default Table;
