import { createContext, CSSProperties, forwardRef, ReactNode, RefObject, useEffect, useRef, useState } from 'react';
import { classnames } from '../../util/classnames';
import mergeRefs from '../../util/mergeRefs';
import styles from './table.module.scss';

interface TableRootProps {
    /**
     * The content of the table, normally `TableHead` and `TableBody`.
     */
    children: ReactNode;
    /**
     * Define the density of the table.
     */
    density?: 'comfortable' | 'compact';
    /**
     * Define the width of the table.
     */
    width?: 'full' | 'auto';
    /**
     * Defines the height of the table.
     */
    height?: CSSProperties['height'];
    /**
     * Defines if the Head should be sticky.
     */
    stickyHead?: boolean;
    /**
     * Defines a custom class name for the table.
     */
    className?: string;
    /**
     * Defines a custom style for the table.
     */
    style?: CSSProperties;
}

interface TableContextProps {
    /**
     * The HTML Ref of the table.
     */
    ref: RefObject<HTMLTableElement>;
    /**
     * The table props without `children`.
     */
    props: {
        /**
         * Define the density of the table.
         */
        density?: 'comfortable' | 'compact';
        /**
         * Define the width of the table.
         */
        width?: 'full' | 'auto';
        /**
         * Defines the height of the table.
         */
        height?: CSSProperties['height'];
        /**
         * Defines if the Head should be sticky.
         */
        stickyHead?: boolean;
    };
}

type TableContext = TableContextProps;

export const TableContext = createContext<TableContext>({} as TableContext);

/**
 * The root component of the table. It renders a `table` element with table styles.
 * @example
 * <TableRoot>
 *     <TableHead>
 *         ...
 *     </TableHead>
 *     <TableBody>
 *         ...
 *     </TableBody>
 * </TableRoot>
 */

const TableRoot = forwardRef<HTMLDivElement, TableRootProps>(
    ({ children, density = 'comfortable', width = 'full', height, stickyHead = false, className, style }, ref) => {
        const tableRef = useRef<HTMLTableElement>(null);
        const [tableIsScrolled, setTableIsScrolled] = useState<boolean>(false);

        useEffect(() => {
            const table = tableRef.current;
            if (table) {
                const handleScroll = () => {
                    setTableIsScrolled(table.scrollTop > 0);
                };
                table.addEventListener('scroll', handleScroll);
                return () => {
                    table.removeEventListener('scroll', handleScroll);
                };
            }
        }, []);

        // Create a context to share the table width with the children

        const tableContextValues: TableContextProps = {
            ref: tableRef,
            props: {
                density,
                width,
                height,
                stickyHead,
            },
        };

        return (
            <TableContext.Provider value={tableContextValues}>
                <div
                    ref={ref ? mergeRefs(tableRef, ref) : tableRef}
                    className={styles.tableArea}
                    style={{
                        height: height,
                        ...style,
                    }}
                >
                    <table
                        className={classnames(
                            styles.table,
                            styles[`density-${density}`],
                            stickyHead && styles.stickyHead,
                            tableIsScrolled && styles.scrolled,
                            className,
                        )}
                        style={{
                            width: width === 'full' ? '100%' : 'auto',
                        }}
                    >
                        {children}
                    </table>
                </div>
            </TableContext.Provider>
        );
    },
);

TableRoot.displayName = 'TableRoot';

export default TableRoot;
