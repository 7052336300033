import request from '@/util/request';
import { createContext, ReactNode, useState } from 'react';
import { useLocation } from 'react-router-dom';

interface DashboardPermissionsProps {
    devicesStatus: boolean;
    networkStatistics: boolean;
    aiSummary: boolean;
    systemUsage: boolean;
    deviceUsage: boolean;
    resets: boolean;
    hasAllPermissions: boolean;
}

interface DevicePermissionsProps {
    allDevices: boolean;
    resources: boolean;
    diagnostics: boolean;
    files: boolean;
    navigate: string;
}

interface ToolsPermissionsProps {
    massChange: {
        firmware: boolean;
    };
    apiClient?: boolean;
    integrations?: boolean;
    autoprovision?: boolean;
    autoconfiguration?: boolean;
}
interface SystemPermissionsProps {
    update: boolean;
    settings: boolean;
    serverStats: boolean;
    backup: boolean;
    files: boolean;
}

interface PermissionsContextProps {
    getPermissions: (user: string) => void;
    validatePermission: (path: string, method: Methods) => boolean;
    validateDashboardPermissions: () => DashboardPermissionsProps;
    validateDevicePermissions: () => DevicePermissionsProps;
    validateSystemPermissions: () => SystemPermissionsProps;
    validateToolsPermissions: () => ToolsPermissionsProps;
    permissions: { method: string; path: string }[] | 'sso_login';
}

type Methods = 'get' | 'delete' | 'post' | 'edit';

interface PermissionsProviderProps {
    children: ReactNode;
}

export const PermissionsContext = createContext({} as PermissionsContextProps);

export function PermissionsProvider({ children }: PermissionsProviderProps) {
    const [permissions, setPermissions] = useState<{ method: string; path: string }[] | 'sso_login'>([]);

    const getPermissions = (user: string) => {
        if (user === 'sso_login') {
            setPermissions('sso_login');
            return;
        }

        request
            .get(`/app/users/${user}/permissions`)
            .then((response) => {
                if (!response) {
                    return;
                }
                setPermissions(response.data.roleData.permissions);
                return {};
            })
            .catch(() => {
                void 1;
            });
    };

    const validateToolsPermissions = () => {
        if (permissions === 'sso_login')
            return {
                massChange: {
                    firmware: true,
                },
                apiClient: true,
                integrations: true,
                autoprovision: true,
                autoconfiguration: true,
            };

        const massChangeFirmware = validatePermission('/app/massChange/firmware/devices', 'get');
        const apiClient = validatePermission('/app/api_clients/', 'get');
        const integrations = validatePermission('/app/integrations/system/', 'get');
        const autoprovision = validatePermission('/app/autoprovision', 'get');
        const autoconfiguration = validatePermission('/app/autoconfiguration', 'get');

        return {
            massChange: {
                firmware: massChangeFirmware,
            },
            apiClient,
            integrations,
            autoprovision,
            autoconfiguration,
        };
    };

    const validateSystemPermissions = () => {
        if (permissions === 'sso_login')
            return {
                apiClient: true,
                settings: true,
                massUpdate: {
                    firmware: true,
                },
                serverStats: true,
                backup: true,
                files: true,
                update: true,
                integrations: true,
                autoconfiguration: true,
            };
        const backup = validatePermission('/app/integrations/cloud/', 'get');
        const integrations = validatePermission('/app/integrations/system/', 'get');
        const files = validatePermission('/app/files/', 'get');
        const apiClient = validatePermission('/app/api_clients/', 'get');
        const massUpdateFirmware = validatePermission('/app/massChange/firmware/devices', 'get');
        const serverStats = validatePermission('/app/server/statistics', 'get');
        const settings = validatePermission('/app/settings/', 'get');
        const update = validatePermission('/app/att/', 'get');

        return {
            apiClient,
            settings,
            massUpdate: {
                firmware: massUpdateFirmware,
            },
            serverStats,
            backup,
            files,
            update,
            integrations,
        };
    };

    const validateDevicePermissions = () => {
        if (permissions === 'sso_login') {
            return {
                resources: true,
                diagnostics: true,
                files: true,
                allDevices: true,
                navigate: '',
            };
        }

        const allDevices = validatePermission('/app/devices/', 'get');
        if (!allDevices) {
            return {
                resources: false,
                diagnostics: false,
                files: false,
                allDevices: false,
                navigate: '/system',
            };
        }

        const resources = validatePermission('/app/devices/resources/:resource/:id', 'get');
        const diagnostics = validatePermission('/app/devices/diagnostics/capabilities/:id', 'get');
        const files = validatePermission('/app/devices/upload/:id', 'post');

        const [, , deviceId] = useLocation().pathname.split('/');
        let navigateTo = `/devices/${deviceId}/logs`;

        if (resources) navigateTo = `/devices/${deviceId}/`;
        else if (diagnostics) navigateTo = `/devices/${deviceId}/diagnostics/latency`;
        else if (files) navigateTo = `/devices/${deviceId}/files`;

        return {
            resources,
            diagnostics,
            files,
            allDevices,
            navigate: navigateTo,
        };
    };

    const validateDashboardPermissions = () => {
        if (permissions === 'sso_login')
            return {
                devicesStatus: true,
                networkStatistics: true,
                aiSummary: true,
                systemUsage: true,
                deviceUsage: true,
                resets: true,
                hasAllPermissions: true,
            };

        const devicesStatus = validatePermission('dashboard.devicesStatus', 'get');
        const networkStatistics = validatePermission('dashboard.networkStatistics', 'get');
        const aiSummary = validatePermission('dashboard.aiSummary', 'get');
        const systemUsage = validatePermission('dashboard.systemUsage', 'get');
        const deviceUsage = validatePermission('dashboard.deviceUsage', 'get');
        const resets = validatePermission('dashboard.resets', 'get');

        return {
            devicesStatus,
            networkStatistics,
            aiSummary,
            systemUsage,
            deviceUsage,
            resets,
            hasAllPermissions: true,
        };
    };

    const validatePermission = (path: string, method: Methods) => {
        if (permissions === 'sso_login') return true;
        if (!permissions) return false;
        const permission = permissions.find((permission) => {
            if (method === 'edit') {
                return permission.path === path && (permission.method === 'put' || permission.method === 'patch');
            }
            return permission.path === path && permission.method === method;
        });

        return !!permission;
    };

    return (
        <PermissionsContext.Provider
            value={{
                getPermissions,
                validatePermission,
                validateSystemPermissions,
                validateDashboardPermissions,
                validateDevicePermissions,
                validateToolsPermissions,
                permissions,
            }}
        >
            {children}
        </PermissionsContext.Provider>
    );
}
