import { cloneElement } from 'react';

import { useRoutes } from 'react-router-dom';

import { Children, createRef, isValidElement, ReactElement, ReactNode, RefObject, useContext } from 'react';
import { createRoutesFromElements, matchRoutes, Route, RouteObject, UNSAFE_RouteContext } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import styles from './AnimatedRoutes.module.scss';

const useNextPath = (pathname = '') => {
    const { matches: parentMatches } = useContext(UNSAFE_RouteContext);
    const routeMatch = parentMatches[parentMatches.length - 1];
    const parentPathnameBase = routeMatch ? routeMatch.pathnameBase : '/';
    return parentPathnameBase === '/' ? pathname : pathname.slice(parentPathnameBase.length) || '/';
};

type RouteItem = Required<RouteObject> & {
    element: ReactElement & { ref: RefObject<HTMLDivElement> };
};

const getMatch = (routes: RouteItem[], pathname: string) => {
    const matches = matchRoutes(routes, pathname);
    if (matches === null) {
        throw new Error(`Route ${pathname} does not match`);
    }

    const index = routes.findIndex((route) => {
        return matches.some((match) => match.route === route);
    });
    return { index, route: routes[index] };
};

export default function AnimatedRoutes({ children }: { children: ReactNode }) {
    const routeElements = Children.map(children, (child) => {
        if (!isValidElement(child) || child.type !== Route) {
            return child;
        }

        const { element, ...restProps } = child.props;
        if (!element) {
            return child;
        }

        const nodeRef = createRef<HTMLDivElement>();
        const newElement = (
            <div className={styles.child} ref={nodeRef}>
                {element}
            </div>
        );
        return { ...child, props: { ...restProps, element: newElement } };
    });

    const routes = createRoutesFromElements(routeElements) as RouteItem[];
    const routeList = useRoutes(routes, location);
    const nextPath = useNextPath(location.pathname);
    const nextMatch = getMatch(routes, nextPath);

    if (!routeList) return null;

    const rendered = cloneElement(routeList, { classNames: styles.child });

    return (
        <SwitchTransition>
            <CSSTransition
                key={nextMatch.route.path ?? nextMatch.index}
                nodeRef={nextMatch.route.element.ref}
                addEndListener={(done) => {
                    nextMatch.route.element.ref.current?.addEventListener('transitionend', done, true);
                }}
                classNames={{
                    enter: styles.enter,
                    enterActive: styles.enterActive,
                    exit: styles.exit,
                    exitActive: styles.exitActive,
                }}
            >
                {rendered}
            </CSSTransition>
        </SwitchTransition>
    );
}
