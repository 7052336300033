import { currentToolRunningAtom } from '@/atoms/assistantAtom';
import useCapability, { BooleanCapabilityType, CapabilityType } from '@/atoms/capabilityAtom';
import Accordion from '@/components/Accordion';
import Field, { FieldText } from '@/components/Field';
import useFetch from '@/hooks/useFetch';
import useRefresh from '@/hooks/useRefresh';
import { Spinner, Stack, Text, ToggleButton, Tooltip } from '@vg-react/components';
import { CheckLight, QuestionCircleLight } from '@vg-react/icons/light';
import { XmarkRegular } from '@vg-react/icons/v6/regular';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import Screen from '../../../../../../components/Screen/Screen';
import { WebUserData } from '../Types';
import EditWebPortModal from '../components/EditWebPortModal';
import EditWebUserModal from '../components/EditWebUserModal';
import ModalConfirmRemoteAccessEnable from '../components/ModalConfirmRemoteAccessEnable';
import ModalConfirmWebUserEnable from '../components/ModalConfirmWebUserEnable';
import styles from './WebManagement.module.scss';

export default function WebManagementScreen() {
    const { t } = useTranslation();
    const { id } = useParams<{ id: string }>();
    const { addRefreshIntoRefreshList } = useRefresh();
    const [currentToolRunning] = useAtom(currentToolRunningAtom);

    const lastToolCall = currentToolRunning?.[currentToolRunning.length - 1];
    const isAssistantUpdatingThisDevice = lastToolCall?.args.serialNumber === id;

    function isAssistantUpdating(resource: 'webPort' | 'enableRemoteAccess' | 'webUser', path?: string) {
        if (!isAssistantUpdatingThisDevice) return undefined;
        if (!currentToolRunning?.some((tool) => resource in tool.args)) return undefined;

        if (path && resource === 'webUser') {
            if (currentToolRunning?.some((tool) => resource in tool.args && tool.args.webUser.path !== path)) {
                return undefined;
            }
            if (currentToolRunning?.some((tool) => resource in tool.args && tool.args.webUser.path === path && tool.state === 'call')) {
                return true;
            }
            return false;
        } else {
            if (currentToolRunning?.some((tool) => resource in tool.args && tool.state === 'call')) {
                return true;
            }
            return false;
        }
    }

    const { data, refresh } = useFetch<{ enable: boolean; webPort: string; needsReboot?: boolean }>(
        `/app/devices/${id}/security/remoteAccess`,
    );
    addRefreshIntoRefreshList({ name: `/app/devices/${id}/security/remoteAccess`, refresh: refresh });

    const { data: webUserData, refresh: refreshWebUsers } = useFetch<WebUserData[]>(
        `/app/devices/${id}/security/webUsers`,
    );
    addRefreshIntoRefreshList({ name: `/app/devices/${id}/security/webUsers`, refresh: refreshWebUsers });

    const defaultDescriptionDevice = {
        message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.description'),
        status: 'default',
    };

    const [modalEditRemoteAccess, setModalEditRemoteAccess] = useState(false);
    const [remoteAccess, setRemoteAccess] = useState(false);
    const [isSentRemoteAccess, setIsSentRemoteAccess] = useState(false);

    const [editWebPortModal, setEditWebPortModal] = useState(false);
    const [webPort, setWebPort] = useState('');
    const [isSentWebPort, setIsSentWebPort] = useState(false);

    const [descriptionDevice, setDescriptionDevice] = useState(defaultDescriptionDevice);
    const [hasNotCapability] = useCapability();

    const statusIcons = {
        'in progress': <Spinner size="small" color="var(--gray-800)" style={{ position: 'relative' }} />,
        success: <CheckLight size="14" color="var(--green-900)" />,
        error: <XmarkRegular size="14" color="var(--red-900)" />,
        default: <></>,
    };

    useEffect(() => {
        if (lastToolCall?.toolName === 'setWebManagementData' && isAssistantUpdatingThisDevice && lastToolCall.state === 'result') {
            refresh();
            refreshWebUsers();
        }
    }, [lastToolCall?.toolName, lastToolCall?.state]);

    useEffect(() => {
        setRemoteAccess(data?.enable ?? false);
        setWebPort(data?.webPort ?? '');
    }, [data]);

    if (!id) return null;

    return (
        <>
            <Screen
                style={{
                    height: '100%',
                    paddingBlock: 20,
                    borderRadius: 'var(--radius-medium)',
                    backgroundColor: 'var(--foreground-color)',
                }}
            >
                <Stack spacing="small">
                    <Stack direction="row" justify="space-between" style={{ paddingBottom: 'var(--spacing-75)' }}>
                        <Stack spacing="var(--spacing-75)">
                            <Text size="md" weight="bold" color="var(--gray-900)">
                                {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.title')}
                            </Text>
                            <Stack direction="row" align="center" justify="start" spacing="var(--spacing-50)">
                                {statusIcons[descriptionDevice.status as keyof typeof statusIcons]}
                                <Text
                                    size="xs"
                                    weight="regular"
                                    color={
                                        descriptionDevice.status === 'success'
                                            ? 'var(--green-900)'
                                            : descriptionDevice.status === 'error'
                                            ? 'var(--red-900)'
                                            : 'var(--gray-600)'
                                    }
                                    style={{ fontStyle: descriptionDevice.status !== 'default' ? 'italic' : 'normal' }}
                                >
                                    {descriptionDevice.message}
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Field.Toggle
                        label={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.remoteAccess')}
                        name="remoteAccessEnabled"
                        disable={isSentRemoteAccess || hasNotCapability.Security?.enableRemoteAccess}
                        iconInfo={
                            hasNotCapability.Security?.enableRemoteAccess
                                ? {
                                      icon: <QuestionCircleLight size="16" color="var(--yellow-800)" />,
                                      text: 'Este dispositivo não permite alteração de acesso remoto',
                                  }
                                : undefined
                        }
                        value={remoteAccess}
                        updating={isSentRemoteAccess}
                        onChange={(value) => {
                            setModalEditRemoteAccess(true);
                            setRemoteAccess(value);
                        }}
                        text={
                            remoteAccess
                                ? t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.enabled')
                                : t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.disabled')
                        }
                        updatingByAI={isAssistantUpdating('enableRemoteAccess')}
                    />
                    <Field.Button
                        label={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.webPort')}
                        buttonLabel={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.editPort')}
                        disable={isSentWebPort || hasNotCapability.Security?.editWebPort || !webPort}
                        updating={isSentWebPort}
                        onButtonClick={() => setEditWebPortModal(!editWebPortModal)}
                        iconInfo={
                            hasNotCapability.Security?.editWebPort || !webPort
                                ? {
                                      icon: <QuestionCircleLight size="16" color="var(--yellow-800)" />,
                                      text: 'Este dispositivo não permite alteração de porta web',
                                  }
                                : undefined
                        }
                        updatingByAI={isAssistantUpdating('webPort')}
                    >
                        <Text size="xs" weight="regular" color="var(--gray-900)">
                            {webPort || t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.noWebPort')}
                        </Text>
                    </Field.Button>
                    <Stack spacing="small" style={{ paddingTop: '1rem' }}>
                        {webUserData && webUserData.length > 0 && (
                            <Text size="xs" weight="bold" color="var(--gray-900)">
                                {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.webUsers')}
                            </Text>
                        )}
                        {webUserData?.map((user) => (
                            <WebUsersAcorddion
                                key={user.path}
                                user={user}
                                setDescriptionDevice={setDescriptionDevice}
                                defaultDescriptionDevice={defaultDescriptionDevice}
                                refresh={refreshWebUsers}
                                hasNotCapability={hasNotCapability}
                                isOpen={isAssistantUpdating('webUser', user.path) !== undefined}
                                isAssistantUpdating={isAssistantUpdating('webUser', user.path)}
                            />
                        ))}
                    </Stack>
                </Stack>
            </Screen>
            {modalEditRemoteAccess && (
                <ModalConfirmRemoteAccessEnable
                    id={id}
                    active={remoteAccess !== undefined}
                    refresh={refresh}
                    setDescriptionDevice={(v) => setDescriptionDevice(v === null ? defaultDescriptionDevice : v)}
                    onClose={() => {
                        setRemoteAccess((prev) => !prev);
                        setModalEditRemoteAccess(false);
                    }}
                    setIsSentRemoteAccess={setIsSentRemoteAccess}
                    onSubmit={() => {
                        setModalEditRemoteAccess(false);
                    }}
                    remoteAccessEnabled={remoteAccess}
                    needsReboot={data?.needsReboot}
                />
            )}
            {editWebPortModal && (
                <EditWebPortModal
                    refresh={refresh}
                    setDescriptionDevice={(v) => setDescriptionDevice(v === null ? defaultDescriptionDevice : v)}
                    onClose={() => {
                        setEditWebPortModal(false);
                    }}
                    setIsSentWebPort={setIsSentWebPort}
                    webPort={webPort}
                    needsReboot={data?.needsReboot}
                />
            )}
        </>
    );
}

type WebUsersAcorddionProps = {
    user: WebUserData;
    setDescriptionDevice: (...args: any) => void;
    refresh: () => void;
    defaultDescriptionDevice: { message: string; status: string };
    hasNotCapability: Partial<CapabilityType & BooleanCapabilityType>;
    isOpen?: boolean;
    isAssistantUpdating: boolean | undefined;
};

const WebUsersAcorddion = ({
    user,
    refresh,
    setDescriptionDevice,
    defaultDescriptionDevice,
    hasNotCapability,
    isOpen = false,
    isAssistantUpdating,
}: WebUsersAcorddionProps) => {
    const { t } = useTranslation();
    const [modalEditWebUser, setModalEditWebUser] = useState(false);
    const [modalEnableWebUser, setModalEnableWebUser] = useState(false);
    const [isSentUser, setIsSentUser] = useState(false);
    const [isOpenAccordion, setIsOpenAccordion] = useState(isOpen);

    useEffect(() => {
        setIsOpenAccordion(isOpen);
    }, [isOpen]);

    return (
        <>
            <Accordion state={isOpenAccordion} setState={setIsOpenAccordion} >
                <Accordion.Header>
                    <Stack width="100%" direction="row" align="center" justify="space-between">
                        <Stack direction="row" spacing="small">
                            <Tooltip>
                                <Tooltip.Trigger>
                                    <ToggleButton
                                        name="enable"
                                        value={user.userEnable}
                                        disabled={hasNotCapability.Security?.userEnable || isSentUser}
                                        onChange={() => {
                                            setModalEnableWebUser(true);
                                        }}
                                    />
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {user.userEnable
                                        ? t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.enabled')
                                        : t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.disabled')}
                                </Tooltip.Content>
                            </Tooltip>

                            <div className={styles.separator} />
                            <Text
                                weight="bold"
                                color={hasNotCapability.Security?.userEnable ? 'var(--gray-500)' : undefined}
                            >
                                {user.username || t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.user')}
                            </Text>
                        </Stack>
                    </Stack>
                </Accordion.Header>
                <Accordion.Content>
                    <Stack direction="column" spacing="0" style={{ padding: 'var(--spacing-50)' }}>
                        <Field.Button
                            label={
                                <Stack direction="row" spacing="var(--spacing-75)" align="center">
                                    {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.webUsers')}
                                </Stack>
                            }
                            buttonLabel={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.editUser')}
                            disable={isSentUser}
                            updating={isSentUser}
                            onButtonClick={() => setModalEditWebUser(true)}
                            updatingByAI={isAssistantUpdating}
                        >
                            <FieldText
                                title={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.user')}
                                value={user.username || ''}
                            />
                            <FieldText
                                title={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.password')}
                                value="**********"
                            />
                        </Field.Button>
                    </Stack>
                </Accordion.Content>
            </Accordion>
            {modalEditWebUser && (
                <EditWebUserModal
                    user={user}
                    onClose={() => setModalEditWebUser(false)}
                    setIsSentUser={setIsSentUser}
                    setDescriptionDevice={setDescriptionDevice}
                    refresh={refresh}
                    defaultDescriptionDevice={defaultDescriptionDevice}
                    hasNotCapability={hasNotCapability}
                />
            )}
            {modalEnableWebUser && (
                <ModalConfirmWebUserEnable
                    user={user}
                    onClose={() => setModalEnableWebUser(false)}
                    setDescriptionDevice={setDescriptionDevice}
                    refresh={refresh}
                    defaultDescriptionDevice={defaultDescriptionDevice}
                    setIsSentUser={setIsSentUser}
                />
            )}
        </>
    );
};
