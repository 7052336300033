import Tracker from '@openreplay/tracker';
import trackerAssist from '@openreplay/tracker-assist';
import { QueryClientProvider } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { StrictMode, useEffect } from 'react';
import { SkeletonTheme } from 'react-loading-skeleton';
import { BrowserRouter, Routes as ReactRoutes, Route } from 'react-router-dom';
import { queryClient } from './api/queryClient';
import { trackerAtom } from './atoms/trackerAtom';
import { AuthProvider } from './contexts/Auth/AuthContext';
import BreadcrumbProvider from './contexts/Breadcrumb/BreadcrumbContext';
import DataTableContext from './contexts/DataTable/DataTableContext';
import { PermissionsProvider } from './contexts/Permissions/PermissionsContext';
import ScreenContext from './contexts/Screen/ScreenContext';
import { ThemeProvider } from './contexts/Theme/ThemeContext';
import i18n from './i18n';
import Routes from './routes/Routes';

export default function App() {
    const [, setTracker] = useAtom(trackerAtom);
    const tracker = new Tracker({
        projectKey: 'XiPStB5opCqvGBSIgaIW',
        ingestPoint: 'https://open-replay.ixcsoft.com.br/ingest',
        network: {
            capturePayload: true,
            sessionTokenHeader: false,
            failuresOnly: false,
            ignoreHeaders: [],
            captureInIframes: true,
        },
    });
    tracker.use(trackerAssist());
    useEffect(() => {
        setTracker(tracker);

        const observeBodyChildren = () => {
            const callback = (mutationsList: MutationRecord[]) => {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        mutation.addedNodes.forEach((node) => {
                            if (node.nodeType === Node.ELEMENT_NODE && node.parentNode === document.body) {
                                const button = document.querySelector(
                                    '#openreplay-confirm-window-confirm-btn',
                                ) as HTMLElement;
                                if (button) button.click();
                            }
                        });
                    }
                }
            };

            const observer = new MutationObserver(callback);
            observer.observe(document.body, { childList: true });

            return () => observer.disconnect();
        };

        // observeBodyChildren();
    }, []);

    useEffect(() => {
        const isHttps = window.location.protocol === 'https:';
        if (!isHttps) window.location.protocol = 'https:';
    }, [window.location.protocol]);

    useEffect(() => {
        if (localStorage.getItem('tr069_lang')) {
            document.cookie = `tr069_lang=${localStorage.getItem('tr069_lang')};path=/;`;
            return;
        }
        const lang = navigator.language.slice(0, 2);
        document.cookie = `tr069_lang=${lang};path=/;`;
        i18n.changeLanguage(lang);
    }, []);

    return (
        <StrictMode>
            <BrowserRouter>
                <PermissionsProvider>
                    <AuthProvider>
                        <QueryClientProvider client={queryClient}>
                            <ThemeProvider>
                                <ScreenContext>
                                    <DataTableContext>
                                        <BreadcrumbProvider>
                                            <SkeletonTheme
                                                baseColor="var(--background-color)"
                                                highlightColor="var(--lightness-950)"
                                            >
                                                <ReactRoutes>
                                                    <Route path="/*" element={<Routes />} />
                                                </ReactRoutes>
                                            </SkeletonTheme>
                                        </BreadcrumbProvider>
                                    </DataTableContext>
                                </ScreenContext>
                            </ThemeProvider>
                        </QueryClientProvider>
                    </AuthProvider>
                </PermissionsProvider>
            </BrowserRouter>
        </StrictMode>
    );
}
