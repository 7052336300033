import { GetDeviceResponse as Device } from '@/api/devices/device';
import { Host } from '@/api/devices/hosts';
import { speedTestAtom } from '@/atoms/speedTestAtom';
import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import useSocket from '@/hooks/useSocket';
import i18n from '@/i18n';
import { classnames } from '@/util/classnames';
import request from '@/util/request';
import { ActionButton, Button, Spinner, Stack, Text } from '@vg-react/components';
import { CheckLight, ChevronLeftLight } from '@vg-react/icons/v6/light';
import { XmarkRegular } from '@vg-react/icons/v6/regular';
import { PlaySolid, RightLongSolid } from '@vg-react/icons/v6/solid';
import { useAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { AdvancedResult } from '../components/AdvancedResult';
import { ConsumptionPerHost } from '../components/ConsumptionPerHost';
import { ResultCard } from '../components/ResultCard';
import { SpeedTestHistory } from '../SpeedTestHistory/index/SpeedTestHistory.screen';
import calcRate from '../utils/calcRate';
import { IConsumptionPerHost } from './SpeedTest.screen';
import styles from './SpeedTestResult.module.scss';

export interface Result {
    serialNumber: string;
    state:
        | 'requested_first_download'
        | 'requested_second_download'
        | 'requested_first_upload'
        | 'requested_second_upload'
        | 'failed';
    data: {
        serialNumber: string;
        startedTime: string | null;
        fileSizes: {
            download: {
                size1: number;
                size2: number;
            };
            upload: {
                size1: number;
                size2: number;
            };
        };
        download: {
            status: 'awaiting' | 'failed' | 'complete' | 'skipped';
            startedTime: string | null;
            endedTime: string | null;
            bytes: number | null;
            milliseconds: number | null;
        };
        upload: {
            status: 'awaiting' | 'failed' | 'complete' | 'skipped';
            startedTime: string | null;
            endedTime: string | null;
            bytes: number | null;
            milliseconds: number | null;
        };
    };
}

export type Status = 'in progress' | 'success' | 'error' | 'default';

export const statusIcons: { [key in Status]: JSX.Element } = {
    'in progress': <Spinner size="small" color="var(--gray-800)" style={{ position: 'relative' }} />,
    success: <CheckLight size="14" color="var(--green-900)" />,
    error: <XmarkRegular size="14" color="var(--red-900)" />,
    default: <></>,
};

export interface TestHistoryData {
    download: number;
    upload: number;
    date: string;
}

export const SpeedTestResult = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { id } = useParams();
    const { socket } = useSocket();

    const widthScreen = window.innerWidth;

    const context = useOutletContext();
    const { data: device, refresh } = context as {
        data: Device;
        refresh: () => void;
    };

    const [valueSpeedTestAtom, setValueSpeedTestAtom] = useAtom(speedTestAtom);
    const [consumptionPerHost, setConsumptionPerHost] = useState<IConsumptionPerHost[]>([]);
    const [shouldShowWarning, setShouldShowWarning] = useState(false);
    const [result, setResult] = useState<Result | null>(null);
    const { data: testHistory, isFetching } = useFetch<{ speedTestHistory: SpeedTestHistory[] }>(
        `/app/devices/${id}/diagnostics/speedTest/history`,
    );

    const [testHistoryData, setTestHistoryData] = useState<TestHistoryData[]>([]);

    const [typeShowResult, setTypeShowResult] = useState<'normal' | 'advanced'>('normal');
    const [isTestDownRunning, setTestDownRunning] = useState(true);
    const [isTestUpRunning, setTestUpRunning] = useState(true);

    const defaultOperation = t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.description');
    const [deviceOperation, setDeviceOperation] = useState<{ message: string; status: Status }>({
        message: defaultOperation,
        status: 'default',
    });

    const { data: fetchData } = useFetch<Result>(`/app/devices/${id}/diagnostics/speedTest/state`);

    useEffect(() => {
        if (device.hosts.hosts.length > 0) {
            saveBytesSentAndReceivedPerHost(device.hosts.hosts);
        }
    }, [device]);

    function saveBytesSentAndReceivedPerHost(hosts: Host[]) {
        if (consumptionPerHost.length === 0) {
            const dataPerHost: IConsumptionPerHost[] = [];
            hosts.map((host) => {
                if (host.bytesSend || host.bytesReceived) {
                    dataPerHost.push({
                        mac: host.macAddress,
                        ip: host.ipAddress,
                        name: host.hostName,
                        vendor: host.vendorName,
                        bytesSentInit: Number(host.bytesSend),
                        bytesSentFinish: 0,
                        bytesReceivedInit: Number(host.bytesReceived),
                        bytesReceivedFinish: 0,
                    });
                }
            });
            setConsumptionPerHost(dataPerHost);
        } else {
            countConsumptionPerHost({ data: hosts });
        }
    }

    function countConsumptionPerHost({ data }: { data: Host[] }) {
        data.map((host) => {
            setConsumptionPerHost((prevConsumption) => {
                const updatedConsumption = [...prevConsumption];
                const consumptionIndex = updatedConsumption.findIndex(
                    (consumption) => consumption.mac === host.macAddress,
                );
                if (consumptionIndex !== -1) {
                    updatedConsumption[consumptionIndex] = {
                        ...updatedConsumption[consumptionIndex],
                        bytesSentFinish: Number(host.bytesSend),
                        bytesReceivedFinish: Number(host.bytesReceived),
                    };
                }
                return updatedConsumption;
            });
        });
    }

    useEffect(() => {
        if (testHistory?.speedTestHistory && testHistory?.speedTestHistory?.length > 0) {
            const history: TestHistoryData[] = [];

            testHistory.speedTestHistory.slice(-4).forEach((speedTest) => {
                history.push({
                    date: new Date(speedTest.date)
                        .toLocaleString(i18n.language, {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        .replace(',', ''),
                    download:
                        speedTest?.download?.milliseconds && speedTest?.download?.bytes
                            ? calcRate(speedTest.download)
                            : 0,
                    upload:
                        speedTest?.upload?.milliseconds && speedTest?.upload?.bytes ? calcRate(speedTest.upload) : 0,
                });
            });

            setTestHistoryData(history);
        }
    }, [testHistory]);

    useEffect(() => {
        if (fetchData && fetchData.data && fetchData.state) {
            setResult(fetchData);
        }
    }, [fetchData]);

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (result) {
            const isRunningDownload = Boolean(result && result.data.download.status === 'awaiting');
            const isRunningUpload = Boolean(result && result.data.upload.status === 'awaiting');

            interval = setInterval(() => {
                if (
                    (isRunningDownload || isRunningUpload) &&
                    Number(new Date()) - Number(new Date(result?.data.startedTime ?? '')) > 1000 * 60
                ) {
                    setShouldShowWarning(true);
                }
            }, 1000);

            if (!(isRunningDownload || isRunningUpload)) {
                setShouldShowWarning(false);
            }
            setTestDownRunning(isRunningDownload);
            setTestUpRunning(isRunningUpload);
        }

        return () => clearInterval(interval);
    }, [result]);

    useEffect(() => {
        socket?.on(
            'diagnostics:speed_test:detailed_info',
            (data: { message: string; serialNumber: string; status: Status }) => {
                if (data.serialNumber === id) {
                    if (valueSpeedTestAtom) {
                        setValueSpeedTestAtom({
                            download: valueSpeedTestAtom.download,
                            upload: valueSpeedTestAtom.upload,
                            refresh: valueSpeedTestAtom.refresh,
                            statusTest: 'running',
                        });
                    }
                    setDeviceOperation({ message: data.message, status: data.status });
                    if (data.status === 'error') {
                        valueSpeedTestAtom?.refresh();
                        setTimeout(() => {
                            setDeviceOperation({ message: defaultOperation, status: 'default' });
                        }, 5000);
                    }
                }
            },
        );

        socket?.on('diagnostics:speed_test:state_changed', (data: Result) => {
            if (data.serialNumber === id) {
                setResult(data);
                if (
                    (data.data.download.status === 'skipped' && data.data.upload.status === 'complete') ||
                    (data.data.upload.status === 'skipped' && data.data.download.status === 'complete') ||
                    (data.data.upload.status === 'complete' && data.data.download.status === 'complete')
                ) {
                    setDeviceOperation({
                        message: t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.finished'),
                        status: 'success',
                    });
                    setTimeout(() => {
                        setDeviceOperation({ message: defaultOperation, status: 'default' });
                    }, 5000);
                    valueSpeedTestAtom?.refresh();
                }
            }
        });

        socket?.on('diagnostics:speed_test:info', (data: { level: string; message: string; serialNumber: string }) => {
            if (data.serialNumber === id) {
                if (data.level === 'error') toast.error(data.message);
                if (data.level === 'info') toast.info(data.message);
                if (data.level === 'warning') toast.warning(data.message);
            }
        });

        return () => {
            socket?.off('diagnostics:speed_test:state_changed');
            socket?.off('diagnostics:speed_test:detailed_info');
            socket?.off('diagnostics:speed_test:info');
            refresh();
            if (valueSpeedTestAtom) {
                setValueSpeedTestAtom({
                    download: valueSpeedTestAtom.download,
                    upload: valueSpeedTestAtom.upload,
                    refresh: valueSpeedTestAtom.refresh,
                    statusTest: 'finished',
                });
            }
        };
    }, [socket]);

    useEffect(() => {
        requestSpeedTest();
    }, []);

    function requestSpeedTest() {
        request
            .post(`/app/devices/${id}/diagnostics/speedTest/start`, {
                fileSizes: {
                    download: {
                        size1: valueSpeedTestAtom?.download.init,
                        size2: valueSpeedTestAtom?.download.secundary,
                    },
                    upload: {
                        size1: valueSpeedTestAtom?.upload.init,
                        size2: valueSpeedTestAtom?.upload.secundary,
                    },
                },
                testTypes: {
                    download: valueSpeedTestAtom?.download.selected,
                    upload: valueSpeedTestAtom?.upload.selected,
                },
            })
            .catch((data) => {
                toast.error(data.message);
            });
    }

    useEffect(() => {
        if (
            valueSpeedTestAtom &&
            (consumptionPerHost.length > 0 ||
                valueSpeedTestAtom.download.speedContracted ||
                valueSpeedTestAtom.upload.speedContracted)
        )
            setTypeShowResult('advanced');
        else setTypeShowResult('normal');
    }, [valueSpeedTestAtom, consumptionPerHost]);

    return (
        <Screen
            style={{
                height: '100%',
                backgroundColor: 'var(--foreground-color)',
                padding: 20,
                borderRadius: 'var(--radius-medium)',
            }}
        >
            <Stack spacing="var(--spacing-300)" height="100%" width="100%">
                <Stack spacing="var(--spacing-50)">
                    <Stack direction="row" spacing="var(--spacing-50)" justify="space-between" align="center">
                        <Stack direction="row" spacing="var(--spacing-50)" justify="start" align="center">
                            <Button
                                style={{
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                }}
                                onClick={() => navigate(-1)}
                            >
                                <ChevronLeftLight size="16" color="var(--gray-900)" />
                            </Button>
                            <Text size="md" weight="bold" color="var(--gray-900)">
                                {isTestDownRunning || isTestUpRunning
                                    ? t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.running')
                                    : t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.result')}
                            </Text>
                        </Stack>

                        <ActionButton
                            variant="secondary"
                            onClick={() => requestSpeedTest()}
                            disabled={isTestDownRunning || isTestUpRunning}
                        >
                            <PlaySolid size="16" color="currentColor" />
                            {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.refresh')}
                        </ActionButton>
                    </Stack>

                    <Stack
                        direction="row"
                        spacing="var(--spacing-50)"
                        justify="start"
                        align="start"
                        style={{ paddingInline: 'var(--spacing-100)' }}
                    >
                        {statusIcons[deviceOperation.status]}
                        <Text
                            size="xs"
                            weight="regular"
                            color={
                                deviceOperation.status === 'success'
                                    ? 'var(--green-900)'
                                    : deviceOperation.status === 'error'
                                    ? 'var(--red-900)'
                                    : 'var(--gray-600)'
                            }
                            style={{ fontStyle: deviceOperation.status === 'in progress' ? 'italic' : 'default' }}
                        >
                            {deviceOperation.message}
                        </Text>
                    </Stack>
                </Stack>

                <Stack
                    spacing="var(--spacing-50)"
                    style={{ paddingInline: 'var(--spacing-100)', height: '80%', width: '100%' }}
                >
                    {typeShowResult === 'normal' ? (
                        <Stack
                            direction="row"
                            spacing="var(--spacing-500)"
                            justify="center"
                            align="center"
                            style={{
                                flex: 1,
                            }}
                        >
                            <Stack
                                direction="row"
                                spacing={widthScreen < 1000 ? 'small' : 'large'}
                                align="center"
                                justify="center"
                                style={{
                                    height: widthScreen < 1400 ? '100%' : '80%',
                                    border: '1px solid var(--lightness-900)',
                                    borderRadius: 'var(--radius-medium)',
                                    padding: 'var(--spacing-100)',
                                }}
                            >
                                {valueSpeedTestAtom?.download.selected && (
                                    <ResultCard result={result} type="Download" testHistory={testHistoryData} />
                                )}

                                {valueSpeedTestAtom?.upload.selected && valueSpeedTestAtom?.download.selected && (
                                    <div
                                        style={{
                                            width: '1px',
                                            height: '50%',
                                            backgroundColor: 'var(--lightness-900)',
                                        }}
                                    />
                                )}

                                {valueSpeedTestAtom?.upload.selected && (
                                    <ResultCard result={result} type="Upload" testHistory={testHistoryData} />
                                )}
                            </Stack>
                        </Stack>
                    ) : (
                        <Stack
                            direction="row"
                            justify="center"
                            className={classnames(
                                styles.resultContainer,
                                consumptionPerHost.length > 0 ? styles.resultWithHost : undefined,
                            )}
                        >
                            <AdvancedResult
                                result={result}
                                areThereHost={consumptionPerHost.length > 0}
                                isRunningDownload={isTestDownRunning}
                                isRunningUpload={isTestUpRunning}
                                speedDownContractIntegration={valueSpeedTestAtom?.download.speedContracted}
                                speedUpContractIntegration={valueSpeedTestAtom?.upload.speedContracted}
                                testHistory={testHistoryData}
                            />
                            {consumptionPerHost.length > 0 && (
                                <ConsumptionPerHost
                                    hosts={consumptionPerHost}
                                    isRunning={isTestDownRunning || isTestUpRunning}
                                />
                            )}
                        </Stack>
                    )}
                </Stack>

                <Stack
                    justify="center"
                    style={{
                        height: '40px',
                    }}
                >
                    <Link
                        to="../history"
                        style={{
                            position: 'relative',
                        }}
                    >
                        <Stack direction="row" spacing="small" align="center" justify="center">
                            <Text color="var(--accent-900)" size="xs" weight="bold">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.history')}
                            </Text>
                            <RightLongSolid size="16" color="var(--accent-900)" />
                        </Stack>
                    </Link>
                </Stack>
            </Stack>
        </Screen>
    );
};

interface CountUpAnimationProps {
    children: number;
    duration?: number;
    decimalPlaces?: number;
}

export function CountUpAnimation({ children, duration = 1000, decimalPlaces = 0 }: CountUpAnimationProps) {
    const [count, setCount] = useState(0);

    const frameDuration = 1000 / 60;

    const easeOutQuad = (t: number) => t * (2 - t);

    useEffect(() => {
        let frame = 0;
        const totalFrames = Math.round(duration / frameDuration);
        const counter = setInterval(() => {
            frame++;
            const progress = easeOutQuad(frame / totalFrames);
            setCount(children * progress);

            if (frame === totalFrames) {
                clearInterval(counter);
            }
        }, frameDuration);

        return () => clearInterval(counter);
    }, []);

    return <>{count.toFixed(decimalPlaces)}</>;
}
