import { TimesLight } from '@vg-react/icons/light';
import { ReactNode, useContext } from 'react';
import Stack from '../Stack/Stack';
import styles from './Modal.module.scss';
import { ModalContext } from './ModalRoot';

interface Props {
    children: ReactNode;
    generateCloseButton?: boolean;
}

export default function ModalHeader({ children, generateCloseButton = true }: Props): JSX.Element {
    const { onClose } = useContext(ModalContext);

    return (
        <header className={styles.header}>
            <Stack spacing="medium" direction="row" justify="space-between">
                {children}
                {generateCloseButton ? (
                    <button type="button" aria-label="close" className={styles.closeButton} onClick={onClose}>
                        <TimesLight size="20" color="var(--gray-900)" />
                    </button>
                ) : null}
            </Stack>
        </header>
    );
}
