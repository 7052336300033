import { ActionButton, Form, Modal, Stack, Text } from '@vg-react/components';
import { CheckLight, XmarkLight } from '@vg-react/icons/v6/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSocket from '../../../../../../../../hooks/useSocket';
import request from '../../../../../../../../util/request';
import { ModalEnableInterfaceProps, UpdateDataSocket } from './types/Types';

export default function ModalEnableInterface({
    id,
    active,
    newValue,
    instance,
    onClose,
    refresh,
    setToggleInterfaceDisabled,
    setDeviceOperation,
}: ModalEnableInterfaceProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { socket } = useSocket();
    function handleOnSubmit() {
        setLoading(true);
        request
            .patch(`/app/devices/${id}/wifi/${instance}`, {
                enable: newValue,
            })
            .then((response) => {
                if (response.status === 200) {
                    toast.info(
                        t(
                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.tostSending',
                        ),
                    );
                    setDeviceOperation({
                        status: 'in progress',
                        message: t(
                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.sending',
                        ),
                    });
                    socket?.on('updateWifiParameter', (data: UpdateDataSocket) => {
                        setDeviceOperation({ status: data.status, message: data.message });

                        if (data.status === 'in progress') return;

                        if (data.status === 'success') {
                            toast.success(data.message);
                            refresh?.();
                        }

                        setToggleInterfaceDisabled(false);
                        socket.off('updateWifiParameter');

                        setTimeout(() => {
                            setToggleInterfaceDisabled(newValue);
                            setDeviceOperation({
                                message: t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.message',
                                ),
                                status: 'default',
                            });
                        }, 5000);
                    });
                    setToggleInterfaceDisabled(true);
                    onClose();
                }
            })
            .catch((error) => {
                toast.error(error.response.data);
                setLoading(false);
            });
    }
    return (
        <Modal
            size="small"
            active={active}
            onClose={() => {
                onClose();
                setToggleInterfaceDisabled(!newValue);
            }}
        >
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.title')}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={handleOnSubmit}>
                <Modal.Body>
                    <Text variant="p" weight="regular" size="xs">
                        {newValue
                            ? t(
                                  'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.confirmEnable',
                              )
                            : t(
                                  'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.confirmDisable',
                              )}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton
                            onClick={() => {
                                onClose();
                                setToggleInterfaceDisabled(!newValue);
                            }}
                            quiet
                        >
                            {t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.cancel',
                            )}
                        </ActionButton>
                        <ActionButton loading={loading} type="submit">
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                {newValue ? (
                                    <>
                                        <CheckLight size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.enable',
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <XmarkLight size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEnableInterface.disable',
                                        )}
                                    </>
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
