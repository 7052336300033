import { CSSProperties } from 'react';

interface ACSLogoProps {
    color?: string;
    style?: CSSProperties;
    mode?: 'light' | 'dark';
}

export default function ACSLogo({ color, style = {}, mode = 'light' }: ACSLogoProps) {
    return (
        <svg 
            version="1.1" 
            xmlns="http://www.w3.org/2000/svg" 
            x="0px" 
            y="0px"
            style={style}
            viewBox="0 0 210 109.99999"
        >
            <g transform="matrix(0.36820452,0,0,0.36820452,-13.60946,-63.500871)">
                <path style={{ fill: color ? color : mode === 'dark' ? 'rgb(255 255 255 / 80%)' : '#044559' }} 
                       d="m 219.57,337.46 c 2.91,3.79 6.94,5.1 11.61,5.1 3.17,0 6.34,-0.64 8.89,-2.61 2.43,-1.89 3.69,-5.15 4.38,-8.05 l 0.56,-2.34 h 19.75 l -0.27,3.31 c -0.69,8.31 -3.72,15.8 -10.24,21.19 -6.74,5.57 -15.32,7.41 -23.89,7.41 -10.13,0 -19.56,-2.83 -26.46,-10.57 -7.06,-7.91 -9.1,-18.76 -9.1,-29.04 0,-10.43 2.03,-21.6 9.35,-29.5 7.2,-7.79 17.06,-10.53 27.43,-10.53 8.41,0 16.8,1.85 23.32,7.44 6.12,5.24 8.91,12.43 9.59,20.34 l 0.28,3.32 H 252.7 c -4.35,0 -7.65,-2.07 -9.62,-5.93 -0.7,-1.36 -1.57,-2.73 -2.74,-3.74 -2.25,-1.95 -5.92,-2.31 -8.77,-2.31 -4.7,0 -9.01,1.25 -12.06,4.98 -3.44,4.22 -4.18,10.69 -4.18,15.94 0.01,5.32 0.9,11.24 4.24,15.59 m -39.1,22.38 -12.67,-21.23 -12.49,21.23 h -23.87 l 23.8,-38.77 -23.94,-37.24 h 24.02 l 12.45,21.36 9.55,-16.39 c 1.95,-3.34 5.12,-4.97 8.95,-4.97 h 18 l -23.94,37.24 23.8,38.77 z m -53.36,0 h -20.54 v -65.62 c 0,-2.83 1.03,-5.34 3.03,-7.35 2,-2 4.52,-3.03 7.35,-3.03 h 10.17 v 76 z M 37.54,470.19 H 334.03 V 173.7 H 37.54 Z"
                />
                <g>
                    <path style={{ fill: color ? color : mode === 'dark' ? 'rgb(255 255 255 / 80%)' : '#044559' }} 
                           d="m 389,338.31 c 0,-0.39 0.1,-0.81 0.29,-1.27 l 25.78,-61.12 c 0.39,-0.98 0.81,-1.64 1.27,-2 0.45,-0.36 1.11,-0.54 1.95,-0.54 h 5.37 c 0.91,0 1.58,0.18 2,0.54 0.42,0.36 0.83,1.03 1.22,2 l 25.78,61.12 c 0.2,0.46 0.29,0.88 0.29,1.27 0,0.98 -0.55,1.46 -1.66,1.46 h -8.5 c -0.85,0 -1.45,-0.11 -1.81,-0.34 -0.36,-0.23 -0.7,-0.7 -1.03,-1.42 l -5.57,-13.67 h -26.95 l -5.47,13.67 c -0.33,0.72 -0.67,1.19 -1.03,1.42 -0.36,0.23 -0.96,0.34 -1.81,0.34 h -8.5 c -1.07,0 -1.62,-0.49 -1.62,-1.46 z m 42.28,-23.05 -10.35,-25.58 -10.35,25.58 z"
                    />
                    <path style={{ fill: color ? color : mode === 'dark' ? 'rgb(255 255 255 / 80%)' : '#044559' }} 
                           d="m 481.85,332.25 c -6.05,-5.99 -9.08,-14.55 -9.08,-25.68 0,-11.13 2.98,-19.69 8.93,-25.68 5.96,-5.99 14.47,-8.98 25.53,-8.98 4.43,0 8.53,0.46 12.3,1.37 3.77,0.91 6.96,2.21 9.57,3.91 0.59,0.39 0.88,0.81 0.88,1.27 0,0.39 -0.16,0.85 -0.49,1.37 l -2.73,4.69 c -0.39,0.59 -0.75,0.88 -1.07,0.88 -0.26,0 -0.72,-0.16 -1.37,-0.49 -4.1,-2.47 -9.34,-3.71 -15.72,-3.71 -7.36,0 -13.12,2.12 -17.28,6.35 -4.17,4.23 -6.25,10.58 -6.25,19.04 0,8.59 2.15,14.99 6.44,19.19 4.3,4.2 10.09,6.3 17.38,6.3 6.77,0 12.33,-1.27 16.7,-3.81 0.65,-0.33 1.07,-0.49 1.27,-0.49 0.39,0 0.78,0.29 1.17,0.88 l 3.03,5.08 c 0.2,0.39 0.29,0.72 0.29,0.98 0,0.39 -0.36,0.81 -1.07,1.27 -5.01,3.51 -12.6,5.27 -22.75,5.27 -11.06,-0.02 -19.62,-3.02 -25.68,-9.01 z"
                        />
                    <path style={{ fill: color ? color : mode === 'dark' ? 'rgb(255 255 255 / 80%)' : '#044559' }} 
                           d="m 568.51,339.72 c -4.46,-1.01 -8.02,-2.42 -10.69,-4.25 -0.85,-0.59 -1.27,-1.11 -1.27,-1.56 0,-0.45 0.13,-0.85 0.39,-1.17 l 3.22,-5.08 c 0.39,-0.59 0.75,-0.88 1.07,-0.88 0.32,0 0.75,0.16 1.27,0.49 2.41,1.43 5.21,2.59 8.4,3.47 3.19,0.88 6.41,1.32 9.67,1.32 4.43,0 7.76,-0.73 10.01,-2.2 2.25,-1.46 3.37,-3.82 3.37,-7.08 0,-1.95 -0.42,-3.52 -1.27,-4.69 -0.85,-1.17 -2.08,-2.18 -3.71,-3.03 -1.63,-0.85 -4.26,-1.98 -7.91,-3.42 l -2.25,-0.88 c -4.23,-1.69 -7.73,-3.32 -10.5,-4.88 -2.77,-1.56 -5.06,-3.61 -6.88,-6.15 -1.82,-2.54 -2.73,-5.63 -2.73,-9.28 0,-5.92 2.26,-10.5 6.79,-13.72 4.52,-3.22 10.46,-4.83 17.82,-4.83 4.49,0 8.38,0.42 11.67,1.27 3.29,0.85 6.13,2.15 8.54,3.91 0.78,0.59 1.17,1.14 1.17,1.66 0,0.39 -0.13,0.75 -0.39,1.07 l -3.12,5.08 c -0.39,0.59 -0.72,0.88 -0.98,0.88 -0.26,0 -0.68,-0.2 -1.27,-0.59 -4.17,-2.67 -9.34,-4 -15.52,-4 -4.1,0 -7.19,0.75 -9.28,2.25 -2.08,1.5 -3.12,3.87 -3.12,7.13 0,2.02 0.44,3.63 1.32,4.83 0.88,1.21 2.16,2.23 3.86,3.08 1.69,0.85 4.43,1.99 8.2,3.42 l 2.44,0.98 c 4.75,1.89 8.46,3.63 11.13,5.22 2.67,1.6 4.74,3.58 6.2,5.96 1.46,2.38 2.2,5.42 2.2,9.13 0,5.6 -2.1,10.01 -6.3,13.23 -4.2,3.22 -10.17,4.83 -17.92,4.83 -4.63,0 -9.17,-0.51 -13.63,-1.52 z"
                    />
                </g>
                <path style={{ fill: color ? color : mode === 'dark' ? 'rgb(255 255 255 / 80%)' : '#61BFDF' }} 
                   d="m 601.67,371.97 h -98.78 c -2.58,0 -4.67,-2.09 -4.67,-4.67 v 0 c 0,-2.58 2.09,-4.67 4.67,-4.67 h 98.78 c 2.58,0 4.67,2.09 4.67,4.67 v 0 c 0,2.58 -2.09,4.67 -4.67,4.67 z"
                />
            </g>
        </svg>
    );
}
