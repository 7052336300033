import { CSSProperties, MouseEvent, ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import styles from './Backdrop.module.scss';

export interface BackdropProps {
    children?: ReactNode;
    active?: boolean;
    onClick?: (event: MouseEvent<HTMLDivElement>) => void;
    position?: 'center' | 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
    blur?: boolean;
    noPadding?: boolean;
    style?: CSSProperties;
    className?: string;
}

export default function Backdrop({
    children,
    onClick,
    active = true,
    position = 'center',
    blur = false,
    noPadding = false,
    style,
    className,
}: BackdropProps): JSX.Element {
    return (
        <article
            className={classnames(
                styles.backdrop,
                styles[position],
                blur && styles.blur,
                noPadding && styles.noPadding,
                active && styles.active,
                className && className,
            )}
            style={style}
            onClick={onClick}
        >
            {children}
        </article>
    );
}
