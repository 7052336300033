import { ToolInvocation } from 'ai';
import { atom } from 'jotai';

export const aiEnabledAtom = atom(false);
export const isPresentationAtom = atom(true);

export const clickablePromptAtom = atom<{
    prompt: string;
    id: string;
} | null>(null);

export const lastDiagnosticAtom = atom<{
    type: string;
    id: string;
    serialNumber: string;
} | null>(null);

export const wlanUpdateAtom = atom<{
    serialNumber: string;
    parameter: 'ssid' | 'password' | 'channel' | 'enable';
    value: string | boolean;
    instance: number;
    isReloaded: boolean;
    state: 'call' | 'result';
} | null>(null);

export const ethernetUpdateAtom = atom<{
    serialNumber: string;
    parameter: 'username' | 'password';
    value: string;
    isReloaded: boolean;
    state: 'call' | 'result';
} | null>(null);

export const speedTestDownloadAtom = atom<{
    isRunning: boolean;
    data: {
        speed: number;
        progress: number;
        totalSent: number;
    };
}>({
    isRunning: false,
    data: {
        speed: 0,
        progress: 0,
        totalSent: 0,
    },
});

export const speedTestUploadAtom = atom<{
    isRunning: boolean;
    data: {
        speed: number;
        progress: number;
        totalSent: number;
    };
}>({
    isRunning: false,
    data: {
        speed: 0,
        progress: 0,
        totalSent: 0,
    },
});

type PickedToolInvocation = ToolInvocation & {
    aditionalData: unknown;
    chatID: string;
}
export const currentToolRunningAtom = atom<PickedToolInvocation[] | undefined>(undefined);

export const lockChatAtom = atom(false);


