/* eslint-disable @typescript-eslint/no-unnecessary-type-constraint */
/* eslint-disable react/display-name */
import { ForwardedRef, Ref, forwardRef, useState } from 'react';
import Checkbox from '../Checkbox/Checkbox';
import Table from '../Table/Table';
import { DataTableColumn, DataTableSortBy } from './DataTable';
interface DataTableHeadProps<T> {
    selectedRows: T[];
    rowsPaginated: T[];
    rows: T[];
    selectable: boolean;
    sortBy: DataTableSortBy<T>;
    columns: DataTableColumn<T>[];
    selectableHasCheckbox: boolean;
    isRowSelectable: (row: T) => boolean;
    onSelectAllRows: (checked: boolean) => void;
    onSortByChange: (sortBy: DataTableSortBy<T>) => void;
}

const DataTableHead = forwardRef(
    <T extends unknown>(
        {
            sortBy,
            columns,
            selectedRows = [],
            rowsPaginated = [],
            rows = [],
            selectable = false,
            selectableHasCheckbox = false,
            onSortByChange,
            onSelectAllRows,
            isRowSelectable = () => true,
        }: DataTableHeadProps<T>,
        ref: ForwardedRef<HTMLTableSectionElement>,
    ) => {
        const onClickSortLabel = (column: DataTableColumn<T>['accessor']) => {
            if (sortBy.column === column) {
                onSortByChange({ column, direction: sortBy.direction === 'asc' ? 'desc' : 'asc' });
            } else {
                onSortByChange({ column, direction: 'asc' });
            }
        };

        const allRowsIsSelected = selectedRows.length === rows.length;

        const [internalColumnsWidth, setInternalColumnsWidth] = useState<Record<string, number>>({
            ...columns.reduce((acc, column) => {
                if (column.width) {
                    if (typeof column.width === 'number') {
                        acc[column.accessor] = column.width;
                    } else {
                        const onlyNumber = column.width.replace(/[^0-9]/g, '');

                        if (onlyNumber) {
                            acc[column.accessor] = parseInt(onlyNumber);
                        }
                    }
                } else {
                    acc[column.accessor] = 100;
                }
                return acc;
            }, {} as Record<string, number>),
        });

        const onResize = (column: string, movementX: number) => {
            setInternalColumnsWidth({
                ...internalColumnsWidth,
                [column]: internalColumnsWidth[column] + movementX,
            });
        };

        return (
            <Table.Head ref={ref}>
                <Table.Row>
                    {selectable && selectableHasCheckbox && (
                        <Table.Cell minWidth="auto">
                            <Checkbox
                                name="select-all"
                                value={allRowsIsSelected && selectedRows.length >= 1}
                                onChange={onSelectAllRows}
                            />
                        </Table.Cell>
                    )}
                    {columns.map((column) => (
                        <Table.Cell
                            align={column.align}
                            key={column.accessor}
                            accessor={column.accessor}
                            noPadding={column.sortable}
                            resizable={column.resizable}
                            minWidth={internalColumnsWidth[column.accessor]}
                            onResize={(movementX) => onResize(column.accessor, movementX)}
                        >
                            {column.sortable ? (
                                <Table.SortLabel
                                    active={sortBy.column === column.accessor}
                                    direction={sortBy.direction}
                                    onClick={() => onClickSortLabel(column.accessor)}
                                    align={column.align}
                                >
                                    {column.label}
                                </Table.SortLabel>
                            ) : (
                                column.label
                            )}
                        </Table.Cell>
                    ))}
                </Table.Row>
            </Table.Head>
        );
    },
);

export default DataTableHead as <T extends unknown>(
    props: DataTableHeadProps<T> & { ref: Ref<HTMLTableSectionElement> },
) => JSX.Element;
