import { atom } from 'jotai';

export type UpdateStatus = 'idle' | 'scheduled' | 'pulling' | 'pulled' | 'created' | 'started' | 'error' | 'success';

export const updateAtom = atom<UpdateStatus>('idle');

export const scheduledUpdateAtom = atom<{
    hours: string;
    minutes: string;
} | null>(null);

export const isUpdatingAtom = atom<string>(localStorage.getItem('updating_version') || '');

export const updateMetadataAtom = atom<{
    version: string;
    issuerData: {
        type: string;
        issuerId: string;
        name: string;
    };
} | null>(null);

