import { Select } from '@vg-react/components';
import Field, { FieldProps } from '../base/Field';

interface SelectFieldProps extends FieldProps {
    name: string;
    width?: string;
    disable?: boolean;
    groupLabel: string;
    currentValue: number | string;
    options: { id: number; name: string; }[];
    onSelectChange?: (value: number | string) => void;
}

export default function SelectField({ currentValue, options, name, groupLabel, disable, width, onSelectChange, ...props }: SelectFieldProps) {
    return (
        <Field
            element={
                <Select
                    name={name}
                    size='medium'
                    width={width}
                    disabled={disable}
                    value={currentValue}
                    initialValue={options.find((option) => option.id === currentValue)?.name}
                    onChange={(value) => currentValue !== value && onSelectChange?.(value) }
                    textProps={{ variant:'p', weight:'regular', color:'var(--gray-700)', size:'xs' }}
                >
                    <Select.Group groupName={groupLabel}>
                        {
                            options.map((option: { id: number; name: string }) => {
                                return(
                                    <Select.Option key={option.id} value={option.id}>
                                        {option.name}
                                    </Select.Option>
                                );
                            })
                        }
                    </Select.Group>
                </Select>
            }
            {...props}
        />
    );
}

