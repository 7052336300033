import { integrationAtom } from '@/atoms/integrationAtom';
import { RotateSpin } from '@/components/IntegrationIndicator/IntegrationIndicator';
import usePermissions from '@/hooks/usePermissions';
import useSocket from '@/hooks/useSocket';
import { IntegrationSystemData } from '@/types/interfaces';
import { classnames } from '@/util/classnames';
import { howMuchTimeAgo } from '@/util/format';
import request from '@/util/request';
import { ActionButton, Badge, Card, Stack, Text } from '@vg-react/components';
import { RouterSolid } from '@vg-react/icons/solid';
import { PenToSquareLight } from '@vg-react/icons/v6/light';
import { CalendarDayRegular, RotateRegular, TrashCanRegular } from '@vg-react/icons/v6/regular';
import { useAtom } from 'jotai';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import styles from '../Integrations.module.scss';
import { IxcIntegration } from '../Integrations.screen';
import { LogoIxcSvg } from './Logo/LogoIxc';
import ModalDeleteIntegration from './ModalDeleteIntegration';
import ModalEditIntegration from './ModalEditIntegration';
interface CardIntegrationProps {
    integration: IxcIntegration;
    countDevices: number;
    dataIntegration: IntegrationSystemData[] | null;
    refreshIntegrations: () => void;
    refreshDataIntegration: () => void;
}

export const CardIntegration = ({
    integration,
    countDevices,
    refreshIntegrations,
    dataIntegration,
    refreshDataIntegration,
}: CardIntegrationProps) => {
    const { t } = useTranslation();
    const { socket } = useSocket();
    const { validatePermission } = usePermissions();
    const [showModalEditIntegration, setShowModalEditIntegration] = useState(false);
    const [showModalDeleteIntegration, setShowModalDeleteIntegration] = useState(false);
    const [integrationIndicator, setIntegrationIndicator] = useAtom(integrationAtom);
    const [syncing, setSyncing] = useState(false);
    const ref = useRef<SVGSVGElement>(null);

    const errorMessage = (message: string) => {
        if (message.includes('Token de autenticação'))
            return t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.errorToken');
        if (message.includes('Urls inválidas'))
            return t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.errorUrlsInvalid');
        if (message.includes('Client ID')) return message;

        return t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.errorQuery');
    };

    async function handleSyncDataIntegration() {
        let syncError = false;
        setSyncing(true);
        setIntegrationIndicator({
            status: 'syncing',
            percentage: 0,
        });
        request
            .get('/app/integrations/system/callprovider/')
            .then((res) => {
                socket?.on('integrations:ixc:allData', (data) => {
                    const { percentComplete } = data;
                    setIntegrationIndicator({
                        status: 'syncing',
                        percentage: percentComplete,
                    });
                });
            })
            .catch((err) => {
                syncError = true;
                setIntegrationIndicator({
                    status: 'idle',
                    percentage: 0,
                });
                toast.error(t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.errorSync'));
            })
            .finally(() => {
                if (!syncError) {
                    setIntegrationIndicator({
                        status: 'success',
                        percentage: 100,
                    });
                }
                socket?.off('integrations:ixc:allData');
                refreshIntegrations();
                refreshDataIntegration();
                setSyncing(false);
            });
    }

    function handleFormatDate(date: Date) {
        const options: Intl.DateTimeFormatOptions = { day: 'numeric', month: 'long', year: 'numeric' };
        const formatter = new Intl.DateTimeFormat('pt-BR', options);
        return formatter.format(new Date(date));
    }

    return (
        <Card className={classnames(styles.integrationCardNew)}>
            <Stack direction="row" spacing="var(--spacing-300)" align="center" justify="space-between">
                <Stack direction="row" spacing="var(--spacing-300)" align="center">
                    <Stack width="100px">{LogoIxcSvg({ color: 'var(--logo-svg-color)', width: '100px' })}</Stack>
                    <Text size="lg" weight="bold" color="var(--lightness-300)">
                        {integration.name === 'ixc'
                            ? t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.ixcProvider')
                            : integration.name}
                    </Text>
                </Stack>

                <Stack direction="row" spacing="var(--spacing-100)" align="center">
                    <ActionButton
                        variant="secondary"
                        onClick={() => setShowModalEditIntegration(!showModalEditIntegration)}
                        disabled={!validatePermission('/app/integrations/system/update', 'edit')}
                    >
                        <PenToSquareLight size="16" color="currentColor" />
                        {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.edit')}
                    </ActionButton>

                    <ActionButton
                        onClick={() => handleSyncDataIntegration()}
                        disabled={
                            !integration.active ||
                            syncing ||
                            !validatePermission('/app/integrations/system/callprovider/:sn?', 'get')
                        }
                    >
                        {syncing ? (
                            <>
                                <RotateSpin ref={ref} />
                                {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.syncing')}
                            </>
                        ) : (
                            <>
                                <RotateRegular size="16" color="currentColor" />
                                {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.sync')}
                            </>
                        )}
                    </ActionButton>

                    <ActionButton
                        variant="danger"
                        onClick={() => setShowModalDeleteIntegration(!showModalDeleteIntegration)}
                        disabled={!validatePermission('/app/integrations/system/:name', 'delete')}
                    >
                        <TrashCanRegular size="16" color="currentColor" />
                    </ActionButton>
                </Stack>
            </Stack>

            <Stack direction="row" spacing="var(--spacing-100)" align="center" justify="space-between">
                <Stack direction="row" spacing="var(--spacing-300)" align="center">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        {integration.active ? (
                            <Badge color="success" withDot outlined background={false}>
                                {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.enabled')}
                            </Badge>
                        ) : (
                            <Badge color="warning" withDot outlined background={false}>
                                {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.disabled')}
                            </Badge>
                        )}
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <CalendarDayRegular size="16" color="var(--lightness-300)" />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.createdAt')}{' '}
                            <strong>{handleFormatDate(integration.createdAt)}</strong>.
                        </Text>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing="var(--spacing-500)">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <RouterSolid size="18" color="var(--lightness-300)" />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            <strong>{countDevices}</strong>{' '}
                            {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.syncedDevices')}
                        </Text>
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        {integration.lastSync && !integration.syncErrorMessage ? (
                            <>
                                <RotateRegular size="18" color="var(--lightness-300)" />
                                <Text size="xs" weight="regular" color="var(--lightness-300)">
                                    {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.lastSync')}
                                    <strong> {howMuchTimeAgo(new Date(integration.lastSync))}</strong>.
                                </Text>
                            </>
                        ) : integration.syncErrorMessage ? (
                            <>
                                <RotateRegular size="18" color="var(--red-700)" />
                                <Text size="xs" weight="regular" color="var(--red-700)">
                                    {errorMessage(integration.syncErrorMessage)}
                                </Text>
                            </>
                        ) : (
                            <>
                                <RotateRegular size="18" color="var(--toastify-color-warning)" />
                                <Text size="xs" weight="regular" color="var(--toastify-color-warning)">
                                    {t('ToolsScreen.IntegrationsScreen.CardIntegrationComponent.noSync')}
                                </Text>
                            </>
                        )}
                    </Stack>
                </Stack>
            </Stack>

            {showModalEditIntegration && (
                <ModalEditIntegration
                    active={showModalEditIntegration}
                    onClose={() => setShowModalEditIntegration(false)}
                    integration={integration}
                    refreshIntegrations={refreshIntegrations}
                />
            )}
            {showModalDeleteIntegration && (
                <ModalDeleteIntegration
                    onClose={() => setShowModalDeleteIntegration(false)}
                    integrationName={integration.name}
                    refreshIntegrations={refreshIntegrations}
                />
            )}
        </Card>
    );
};
