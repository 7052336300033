import { Listbox } from '@headlessui/react';
import styles from './Select.module.scss';

export default function SelectSeparator() {
    const id = Math.trunc(Math.random() * 1000);

    return (
        <Listbox.Option value={id} disabled>
            <div className={styles.separator} />
        </Listbox.Option>
    );
}
