import { Stack, Text, useForm } from '@vg-react/components';
import { CheckCircleSolid } from '@vg-react/icons/solid';
import { useTranslation } from 'react-i18next';
import { LogoIxcSvg } from '../Logo/LogoIxc';
import styles from './Radio.module.scss';

export default function RadioIntegration() {
    const { onChange, value } = useForm('integrationName', 'ixc');
    const { t } = useTranslation();
    return (
        <Stack spacing="var(--spacing-100)">
            <Text size="xs" weight="bold" color="var(--gray-700)" style={{ paddingLeft: 'var(--spacing-25)' }}>
                {t('ToolsScreen.IntegrationsScreen.RadioIntegrationComponent.selectIntegration')}
            </Text>
            <Stack direction="row" spacing="var(--spacing-75)">
                <button
                    aria-selected={value === 'ixc'}
                    disabled={value === 'ixc'}
                    onClick={() => onChange('ixc')}
                    className={styles.radio}
                    type="button"
                >
                    <Stack direction="row" spacing="var(--spacing-300)" align="center" justify="center">
                        <Stack width="75px">{LogoIxcSvg({ color: 'var(--lightness-200)', width: '80px' })}</Stack>
                        {t('ToolsScreen.IntegrationsScreen.RadioIntegrationComponent.ixcProvedor')}
                    </Stack>

                    <Stack
                        align="end"
                        style={{
                            flex: 1,
                        }}
                    >
                        <CheckCircleSolid size="16" color={value === 'ixc' ? 'var(--accent-900)' : 'currentColor'} />
                    </Stack>
                </button>
            </Stack>
        </Stack>
    );
}
