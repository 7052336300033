import { classnames } from '@/util/classnames';
import { motion } from 'framer-motion';
import { memo, useEffect, useState } from 'react';

export const TypewriterEffectSmooth = memo(
    ({ phrases, className, cursorClassName }: { phrases: string[]; className?: string; cursorClassName?: string }) => {
        const [selectedPhrase, setSelectedPhrase] = useState(Math.floor(Math.random() * (phrases?.length ?? 0)));
        const [gridStyle, setGridStyle] = useState({
            gridTemplateColumns: '0fr',
        });

        const wordedPhrases = phrases?.map((phrase) => {
            const words = phrase?.split(' ');

            return (
                <div>
                    {words?.map((word, idx) => {
                        return (
                            <div key={`word-${idx}`} className="inline-block">
                                {word.split('')?.map((char, index) => (
                                    <span key={`char-${index}`} className="text-white">
                                        {char}
                                    </span>
                                ))}
                                &nbsp;
                            </div>
                        );
                    })}
                </div>
            );
        });

        useEffect(() => {
            const animate = async () => {
                await new Promise((resolve) => setTimeout(resolve, 500));
                setGridStyle({ gridTemplateColumns: '1fr' });
                await new Promise((resolve) => setTimeout(resolve, 5000));
                setGridStyle({ gridTemplateColumns: '0fr' });
                await new Promise((resolve) => setTimeout(resolve, 1000));
                setSelectedPhrase((selectedPhrase + 1) % (phrases?.length ?? 0));
            };

            animate();
            const interval = setInterval(animate, 6500);

            return () => clearInterval(interval);
        }, [selectedPhrase]);

        return (
            <div className={classnames('flex my-6', className)}>
                <div
                    className="grid overflow-hidden transition-[grid-template-columns] duration-[1000ms] ease-linear"
                    style={gridStyle}
                >
                    <div
                        className="font-bold text-3xl overflow-hidden w-fit"
                        style={{
                            whiteSpace: 'nowrap',
                        }}
                    >
                        {wordedPhrases?.[selectedPhrase]}
                    </div>
                </div>
                <motion.span
                    initial={{
                        opacity: 0,
                    }}
                    animate={{
                        opacity: 1,
                    }}
                    transition={{
                        duration: 0.4,

                        repeat: Infinity,
                        repeatType: 'reverse',
                    }}
                    className={classnames('block rounded-sm w-[4px] h-[38px] bg-[#5ec0ed]', cursorClassName)}
                ></motion.span>
            </div>
        );
    },
);

TypewriterEffectSmooth.displayName = 'TypewriterEffectSmooth';
