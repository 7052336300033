import React, { useId } from 'react';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { useForm } from '../Form/useForm';
import css from './NumberInput.module.scss';

export interface NumberInputProps extends React.InputHTMLAttributes<HTMLInputElement>, FieldWrapperProps {
    /** name of the field for form control */
    name: string;

    /**
     * width of the number input. Can receive a string with any CSS width rules
     * @default 'medium'
     */
    width?: 'small' | 'medium' | 'large' | string;

    /**
     * set the number input size
     * @default 'medium'
     */
    inputSize?: 'small' | 'medium' | 'large' | 'xlarge';

    /** defines if the field is readonly */
    readonly?: boolean;

    /** defines if the field is disabled */
    disabled?: boolean;

    /** defines if the field is in invalid state */
    invalid?: boolean;

    /** defines if the field is quiet - can be used with any of the other attributes */
    quiet?: boolean;

    /** the id for the number input field */
    id?: string;

    /** defines the default value of the field when it is not inside a Form */
    numberValue?: number | string | null;

    /**
     * Wether the autocomplete from browser will be on or off
     * @default 'off'
     */
    autoComplete?: 'on' | 'off' | string;
    /**
     * set onChange function to number input
     * @param event
     * @returns
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

/**
 * NumberInput component
 *
 * If inside a Form, value will be automatically captured.
 *
 * Outside of a Form, must be used as a Controlled Component.
 *
 * @example
 * //Inside a form (the value will be output on form callbacks)
 * <Form>
 *     <NumberInput name='my-number-input' label='My Number Input'/>
 * </Form>
 *
 * // Outside a form
 * const [state, setState] = useState('');
 *
 * <NumberInput
 *     name='my-number-input'
 *     label='My Number Input'
 *     value={state}
 *     onChange={(event) => setState(event.target.value)}
 * />
 */
const NumberInput: React.FC<NumberInputProps> = ({
    name,
    label,
    width,
    quiet,
    invalid,
    readonly,
    required,
    helpText,
    disabled,
    className,
    description,
    numberValue = null,
    inputSize = 'medium',
    autoComplete = 'off',
    onChange,
    ...props
}: NumberInputProps) => {
    const id = useId();

    const inputClasses = [
        css.inputOutline,
        ...[readonly && css.readonly],
        ...[invalid && css.invalid],
        ...[disabled && css.disabled],
        ...[quiet && css.quiet],
        className ?? '',
    ];

    const { value: valueForm, onChange: onChangeForm } = useForm<string | null>(name, String(numberValue));

    const handleOnChange = (input: React.ChangeEvent<HTMLInputElement>) => {
        if (input.target.value === '') {
            onChange ? onChange(input) : onChangeForm(null);
        }

        onChange ? onChange(input) : onChangeForm(input.target.value);
    };

    return (
        <FieldWrapper
            id={id}
            label={label}
            width={width}
            invalid={invalid}
            required={required}
            helpText={helpText}
            description={description}
        >
            <div
                className={`
                        ${inputClasses.join(' ')}
                        ${css.inputElementContainer}
                        ${css['input-size-' + inputSize]}
                    `}
            >
                <input
                    id={id}
                    {...props}
                    value={valueForm ?? ''}
                    name={name ?? ''}
                    autoComplete={autoComplete}
                    readOnly={readonly || disabled}
                    tabIndex={readonly || disabled ? -1 : 0}
                    className={`
                            ${css.inputElement}
                            ${css['font-size-' + inputSize]}
                            `}
                    onChange={(input) => {
                        handleOnChange(input);
                    }}
                    type="number"
                />
            </div>
        </FieldWrapper>
    );
};

export default NumberInput;
