import ActionButton from '@/components/ActionButton/ActionButton';
import Input from '@/components/Input/Input';
import Stack from '@/components/Stack/Stack';
import Text from '@/components/Text/Text';
import { FirmwareProps, useMassUpdateContext } from '@/contexts/MassChange/MassChangeFirmwareContext';
import useFetch from '@/hooks/useFetch';
import { convertBytes } from '@/util/format';
import { Card } from '@vg-react/components';
import { SearchRegular } from '@vg-react/icons/regular';
import { ArrowRightRegular, CloudArrowUpRegular } from '@vg-react/icons/v6/regular';
import { useEffect, useRef, useState } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import ModalCreateFile from '../../../../../System/Files/index/ModalCreateFile';
import DragLayer from './DragLayer';
import File from './File';
import style from './Firmware.module.scss';
import SelectedFileContainer from './SelectedFileContainer';

interface FilesGetResponse {
    data: {
        rows: FirmwareProps[];
        page: number;
        total: number;
    };
}

export default function Firmware() {
    const { t } = useTranslation();
    const { selectedDevices, direction } = useMassUpdateContext();
    const [search, setSearch] = useState('');
    const [selected, setSelected] = useState('');
    const { data, isFetching, refresh } = useFetch<FilesGetResponse>('/app/files', { params: { pagination: false } });

    const [modalCreateFileIsActive, setModalCreateFileIsActive] = useState<boolean>(false);
    const highlightedElement = useRef<HTMLDivElement>(null);
    const [isBlinking, setIsBlinking] = useState<boolean>(false);

    const files = data?.data?.rows;
    let filteredFiles: FirmwareProps[] = [];

    const fileTypeName: Record<number, string> = {
        1: t('ToolsScreen.MassChangeScreen.FirmwareScreen.firmwareUpgradeImage'),
        2: t('ToolsScreen.MassChangeScreen.FirmwareScreen.webContent'),
        3: t('ToolsScreen.MassChangeScreen.FirmwareScreen.vendorConfigurationFile'),
        4: t('ToolsScreen.MassChangeScreen.FirmwareScreen.toneFile'),
        5: t('ToolsScreen.MassChangeScreen.FirmwareScreen.ringerFile'),
        6: t('ToolsScreen.MassChangeScreen.FirmwareScreen.storedFirmwareImage'),
    };

    if (files) {
        filteredFiles = files.filter((file) => {
            const regex = new RegExp(`${search}`, 'i');

            if (
                regex.test(fileTypeName[file.fileType]) ||
                regex.test(file.name) ||
                regex.test(convertBytes(Number(file.size))) ||
                regex.test(file.version) ||
                file.tags.some((tag) => regex.test(tag))
            ) {
                return file;
            }
        });
    }

    function onFileUpload() {
        refresh();
        setTimeout(() => {
            highlightedElement.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
        }, 100);
        setIsBlinking(true);
    }

    useEffect(() => {
        if (isBlinking) {
            const timer = setTimeout(() => {
                setIsBlinking(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [isBlinking]);

    return (
        <>
            <DndProvider backend={HTML5Backend}>
                <Stack direction="row" spacing="var(--spacing-100)" align="center" height="100%">
                    <Card
                        style={{
                            width: '75%',
                            height: '100%',
                            paddingInline: 'var(--spacing-300)',
                            paddingTop: 'var(--spacing-300)',
                        }}
                    >
                        <Stack height="100%">
                            <Stack direction="row" justify="space-between" align="center">
                                <Text size="md" weight="bold" color="var(--gray-900)">
                                    {t('ToolsScreen.MassChangeScreen.FirmwareScreen.firmwareFiles')}
                                </Text>
                                <Stack direction="row" justify="end">
                                    <Input
                                        name="search"
                                        value={search}
                                        placeholder={t('ToolsScreen.MassChangeScreen.FirmwareScreen.searchFiles')}
                                        onChange={(input) => setSearch(input.target.value)}
                                        leftIcon={<SearchRegular size="15px" color="var(--accent-900)" />}
                                    />
                                    <ActionButton variant="secondary" onClick={() => setModalCreateFileIsActive(true)}>
                                        {t('ToolsScreen.MassChangeScreen.FirmwareScreen.upload')}
                                        <CloudArrowUpRegular color="var(--accent-900)" />
                                    </ActionButton>
                                </Stack>
                            </Stack>
                            <Stack style={{ gap: 'var(--spacing-75)' }} className={style.fileListContainer}>
                                {isFetching ? (
                                    <>
                                        <Skeleton width="100%" height={50} />
                                        <Skeleton width="100%" height={50} />
                                        <Skeleton width="100%" height={50} />
                                    </>
                                ) : (
                                    filteredFiles &&
                                    filteredFiles.map((file, index) => {
                                        const isLast = index === filteredFiles.length - 1;
                                        return (
                                            <div ref={isLast ? highlightedElement : null}>
                                                <File
                                                    key={file.id}
                                                    file={file}
                                                    selected={selected}
                                                    setSelected={setSelected}
                                                    blink={isLast ? isBlinking : false}
                                                    refresh={refresh}
                                                />
                                            </div>
                                        );
                                    })
                                )}
                            </Stack>
                            <Text align="end" weight="regular" style={{ userSelect: 'none' }}>
                                {filteredFiles.length === 0
                                    ? t('ToolsScreen.MassChangeScreen.FirmwareScreen.noFilesFound')
                                    : filteredFiles.length === 1
                                    ? t('ToolsScreen.MassChangeScreen.FirmwareScreen.oneFileFound')
                                    : t('ToolsScreen.MassChangeScreen.FirmwareScreen.filesFound', {
                                          count: filteredFiles.length,
                                      })}
                            </Text>
                        </Stack>
                    </Card>
                    <ArrowRightRegular size="25" color="var(--lightness-500)" />
                    <Card
                        style={{
                            width: '25%',
                            height: '100%',
                            paddingInline: 'var(--spacing-300)',
                            paddingTop: 'var(--spacing-300)',
                        }}
                    >
                        <SelectedFileContainer
                            device={selectedDevices[0]}
                            selected={selected}
                            files={filteredFiles}
                            setSelected={setSelected}
                        />
                        <DragLayer />
                    </Card>
                </Stack>
            </DndProvider>
            {modalCreateFileIsActive && (
                <ModalCreateFile
                    active={modalCreateFileIsActive}
                    onClose={() => setModalCreateFileIsActive(false)}
                    onRoleCreated={onFileUpload}
                />
            )}
        </>
    );
}
