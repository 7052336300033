import { Stack, ToggleButton } from '@vg-react/components';
import Field, { FieldProps } from '../base/Field';

interface ButtonFieldProps extends FieldProps {
    name: string;
    disable?: boolean;
    size?: 'small' | 'medium' | 'large';
    value?: boolean;
    onChange?: (value: boolean) => void;
}

export default function ButtonField({
    name,
    size,
    value = false,
    disable = false,
    onChange,
    ...props
}: ButtonFieldProps): JSX.Element {
    return (
        <Field
            element={
                <Stack direction="row" spacing="var(--spacing-50)">
                    <ToggleButton size={size} name={name} value={value} disabled={disable} onChange={onChange} />
                </Stack>
            }
            {...props}
        />
    );
}
