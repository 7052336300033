import { flip, size as floatingSize, offset, useFloating } from '@floating-ui/react-dom-interactions';
import { Listbox } from '@headlessui/react';
import { ChevronDownLight, ChevronUpLight } from '@vg-react/icons/light';
import { KeyboardEvent, ReactNode, createContext, useCallback, useEffect, useId, useMemo, useState } from 'react';
import { classnames } from '../../util/classnames';
import mergeRefs from '../../util/mergeRefs';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { useForm } from '../Form/useForm';
import useDimensions from '../hooks/useDimensions';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import Text, { TextProps } from '../Text/Text';
import css from './Select.module.scss';

interface SelectProps extends FieldWrapperProps {
    /**
     * set select name
     */
    name: string;
    style?: React.CSSProperties;
    /**
     * set select label
     */
    label?: string;
    /**
     * set if select is quiet or not
     * @default false
     */
    quiet?: boolean;
    /**
     * set select help text
     */
    helpText?: string;
    /**
     * set if select is invalid or not
     * @default false
     */
    invalid?: boolean;
    /**
     * set if select is disabled or not
     * @default false
     */
    disabled?: boolean;
    /**
     * set if select is required or not
     * @default false
     */
    required?: boolean;
    /**
     * set if select is readonly or not
     * @default false
     */
    readonly?: boolean;
    /**
     * set if select has children
     */
    children: ReactNode;
    /**
     * set select description
     */
    description?: string;
    /**
     * set select placeholder
     */
    placeholder?: string;
    /**
     * set if select has limitedWidth based on its parent
     * @default true
     */
    limitedWidth?: boolean;
    /**
     * set select value
     */
    value?: string | number | boolean;
    /**
     * set value string to be shown on input select when rendered
     */
    initialValue?: string | ReactNode;
    /**
     * set select width
     */
    width?: 'small' | 'medium' | 'large' | string;
    /**
     * set onChange function to select component
     * @param value
     * @returns
     */
    onChange?: (value: string) => void;
    /**
     * Props for the button Text Element
     */
    textProps?: Partial<TextProps>;
    /**
     * set select size
     * @default 'medium'
     */
    size?: 'small' | 'medium' | 'large' | 'xlarge';

    iconInfo?: {
        icon: JSX.Element;
        text: string;
    };
}

interface Option {
    [value: string]: ReactNode | ReactNode[];
}

interface Context {
    register: (data: Option) => void;
    unregister: (value: string) => void;
}

const SelectContext = createContext<Context>({} as Context);

export { SelectContext };

export default function Select({
    name,
    label,
    value,
    children,
    onChange,
    helpText,
    placeholder,
    description,
    style,
    initialValue,
    iconInfo,
    quiet = false,
    textProps = {},
    size = 'medium',
    invalid = false,
    width = 'medium',
    readonly = false,
    required = false,
    disabled = false,
    limitedWidth = true,
}: SelectProps) {
    const { value: valueForm, onChange: onChangeForm } = useForm(name, '');
    const [options, setOptions] = useState<Option>({} as Option);
    const { dimensions, ref } = useDimensions<HTMLButtonElement>();
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [initialDisplay, setInitialDisplay] = useState<ReactNode>(placeholder);
    const cachedValueForm = useMemo(() => {
        return options[valueForm];
    }, [valueForm]);
    const cachedValue = useMemo(() => {
        return options[value as string | number];
    }, [value]);

    // Floating UI
    const { x, y, reference, floating, strategy, update } = useFloating({
        open: showOptions,
        onOpenChange: setShowOptions,
        strategy: 'fixed',
        middleware: [
            offset(2),
            flip({
                fallbackAxisSideDirection: 'start',
            }),
            floatingSize({
                padding: 20,
                apply({ availableHeight, elements }) {
                    Object.assign(elements.floating.style, {
                        maxHeight: `${availableHeight}px`,
                    });
                },
            }),
        ],
    });

    // Registering of children
    const register = useCallback((option: Option) => {
        setOptions((oldOptions) => {
            return { ...oldOptions, ...option };
        });
    }, []);

    const unregister = useCallback((value: string) => {
        setOptions((oldOptions) => {
            const newOptions = oldOptions;
            delete newOptions[value];
            return newOptions;
        });
    }, []);

    const handleChange = useCallback(
        (value: string) => {
            onChange ? onChange(value) : onChangeForm(value);
        },
        [options, value, valueForm],
    );

    const buttonClasses = [
        css.button,
        ...[quiet && css.quiet],
        ...[invalid && css.invalid],
        ...[readonly && css.readonly],
        ...[disabled && css.disabled],
        ...[!value && !valueForm && css.placeholder],
        css['input-size-' + size],
        css['font-size-' + size],
    ];

    const id = useId();

    const handleKeyDown = (event: KeyboardEvent<HTMLButtonElement>) => {
        const { key } = event;
        if (key === 'Esc') {
            setShowOptions(false);
        }
    };

    function verifyOptionSelected() {
        const val = (
            value !== undefined && value
                ? value
                : valueForm !== undefined && valueForm
                ? valueForm
                : initialValue !== undefined && initialValue
                ? initialValue
                : initialDisplay
        ) as string;

        if (options && options[val]) {
            setInitialDisplay(options[val]);
        }
    }

    useEffect(() => {
        verifyOptionSelected();
    }, [options]);

    function handleClick() {
        if (disabled || readonly) return;
        setShowOptions(!showOptions);
        update();
    }

    return (
        <OutsideClickHandler onClickOutside={() => setShowOptions(false)}>
            <SelectContext.Provider value={{ register, unregister }}>
                <FieldWrapper
                    id={id}
                    label={label}
                    width={width}
                    invalid={invalid}
                    helpText={helpText}
                    required={required}
                    description={description}
                    iconInfo={iconInfo}
                >
                    <Listbox value={value ?? valueForm} onChange={handleChange}>
                        {({ open }: { open: boolean }) => (
                            <>
                                <Listbox.Button
                                    id={id}
                                    onKeyDown={handleKeyDown}
                                    onClick={() => handleClick()}
                                    ref={mergeRefs<HTMLButtonElement>(reference, ref)}
                                    className={[...buttonClasses, ...[open && !readonly && !disabled && css.open]].join(
                                        ' ',
                                    )}
                                    style={style}
                                >
                                    <Text
                                        weight="regular"
                                        color={!value && !valueForm ? 'var(--gray-500)' : 'var(--gray-800)'}
                                        {...textProps}
                                    >
                                        {cachedValue || cachedValueForm
                                            ? cachedValue ?? cachedValueForm
                                            : initialDisplay ?? placeholder}
                                    </Text>
                                    {showOptions ? (
                                        <ChevronUpLight size="12" color="var(--gray-800)" className={css.openIcon} />
                                    ) : (
                                        <ChevronDownLight size="12" color="var(--gray-800)" className={css.openIcon} />
                                    )}
                                </Listbox.Button>
                                <Listbox.Options
                                    static
                                    ref={floating}
                                    onClick={() => setShowOptions(false)}
                                    className={classnames(css.optionBox, css['font-size-' + size])}
                                    style={{
                                        ...(!showOptions && { display: 'none' }),
                                        ...(limitedWidth
                                            ? { width: dimensions.width }
                                            : { minWidth: dimensions.width }),
                                        position: strategy,
                                        top: y ?? 0,
                                        left: x ?? 0,
                                        maxHeight: 300,
                                        margin: 0,
                                    }}
                                >
                                    {children}
                                </Listbox.Options>
                            </>
                        )}
                    </Listbox>
                </FieldWrapper>
            </SelectContext.Provider>
        </OutsideClickHandler>
    );
}
