import { AngleLeftSolid, AngleRightSolid } from '@vg-react/icons/solid';
import { KeyboardEvent, useRef } from 'react';
import { classnames } from '../../util/classnames';
import Stack from '../Stack/Stack';
import styles from './pagination.module.scss';

interface PaginationProps {
    /**
     * The current page on pagination.
     */
    currentPage: number;
    /**
     * The number of rows per page.
     * @default 10
     */
    rowsPerPage?: number;
    /**
     * The total number of rows.
     */
    numberOfRows: number;
    /**
     * Allow to set the current page to 0.
     * @default false
     */
    allowPageZero?: boolean;
    /**
     * A function to handle page changes.
     */
    onChange: (page: number) => void;
    /**
     * Variant of the pagination.
     * Allow to change the style of the pagination.
     */
    variant?: 'default' | 'only-arrows' | 'dots' | 'carousel';
    /**
     * The style of the pagination.
     * @default undefined
     */
    style?: React.CSSProperties;
    /**
     * Defines if the pagination can loop.
     * This means that if the user is on the last page and click on the next button,
     * the pagination will go to the first page and vice-versa.
     */
    loop?: boolean;
    /**
     *
     */
    colorVariant?: 'dark' | 'light';
}

/**
 * The pagination component. It renders a pagination bar with page numbers and arrows to navigate between pages.
 * It is used, for example, in the `Table` component. But it can be used in any other component.
 * The component is controlled, so you need to handle the `currentPage` prop.
 * You can use the `onPageChange` prop to handle page changes.
 * @example
 * <Pagination
 *    currentPage={currentPage}
 *    rowsPerPage={rowsPerPage}
 *    numberOfRows={numberOfRows}
 *    onChange={onPageChange}
 * />
 */
export default function Pagination({
    currentPage,
    rowsPerPage = 10,
    numberOfRows,
    onChange,
    allowPageZero = false,
    variant = 'default',
    style,
    loop = false,
    colorVariant,
}: PaginationProps): JSX.Element {
    if (currentPage < 1 && !allowPageZero) {
        throw new Error('The current page cannot be less than 1');
    }

    const previousButtonRef = useRef<HTMLButtonElement>(null);

    const nextButtonRef = useRef<HTMLButtonElement>(null);

    const paginationRef = useRef<HTMLDivElement>(null);

    const numberOfPages: number = Math.ceil(numberOfRows / rowsPerPage);

    if (currentPage > numberOfPages && !allowPageZero) {
        //  throw new Error('The current page cannot be greater than the number of pages');
    }

    const pages: Array<number> = Array.from({ length: numberOfPages }, (_, i) => (allowPageZero ? i : i + 1));

    const pagesToShow: Array<number> =
        pages.length > 5
            ? pages.map((page) => {
                  const isFirstPage = page === 1;
                  const isLastPage = page === numberOfPages;
                  const isCurrentPage = page === currentPage;
                  const isPreviousPage = page === currentPage - 1;
                  const isNextPage = page === currentPage + 1;
                  const isVisible = isFirstPage || isLastPage || isCurrentPage || isPreviousPage || isNextPage;
                  return isVisible ? page : -1;
              })
            : pages;

    const pagesToShowWithoutDuplicates: Array<number> =
        pages.length > 5
            ? pagesToShow.reduce((acc, page) => {
                  const previousPage = acc[acc.length - 1];
                  const isDuplicate = previousPage === page;

                  return isDuplicate ? acc : [...acc, page];
              }, [] as Array<number>)
            : pagesToShow;

    const handlePageClick = (page: number) => {
        if (page !== currentPage && page !== -1) {
            onChange(page);
        }
    };

    const handlePreviousPageClick = () => {
        if (allowPageZero) {
            if (loop) {
                if (currentPage === 0) {
                    onChange(numberOfPages - 1);
                } else {
                    onChange(currentPage - 1);
                }
            } else {
                if (currentPage > 0) {
                    onChange(currentPage - 1);
                }
            }
        } else {
            if (loop) {
                if (currentPage === 1) {
                    onChange(numberOfPages);
                } else {
                    onChange(currentPage - 1);
                }
            } else {
                if (currentPage > 1) {
                    onChange(currentPage - 1);
                }
            }
        }
    };

    const handleNextPageClick = () => {
        if (currentPage < numberOfPages) {
            onChange(currentPage + 1);
        } else if (loop && currentPage === numberOfPages) {
            onChange(1);
        }
    };

    const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
        const { key } = event;

        if (key === 'ArrowLeft') {
            event.preventDefault();

            if (previousButtonRef.current === document.activeElement) {
                nextButtonRef.current?.focus();
            } else {
                let previousFocusableElement: HTMLElement = document.activeElement
                    ?.previousElementSibling as HTMLElement;

                if (previousFocusableElement?.attributes.getNamedItem('tabindex')?.value !== '-1') {
                    previousFocusableElement?.focus();
                } else {
                    previousFocusableElement = previousFocusableElement?.previousElementSibling as HTMLElement;
                    previousFocusableElement?.focus();
                }
            }
        }

        if (key === 'ArrowRight') {
            event.preventDefault();

            if (nextButtonRef.current === document.activeElement) {
                previousButtonRef.current?.focus();
            } else {
                let nextFocusableElement: HTMLElement = document.activeElement?.nextElementSibling as HTMLElement;

                if (nextFocusableElement?.attributes.getNamedItem('tabindex')?.value !== '-1') {
                    nextFocusableElement?.focus();
                } else {
                    nextFocusableElement = nextFocusableElement?.nextElementSibling as HTMLElement;
                    nextFocusableElement?.focus();
                }
            }
        }

        if (key === 'Tab') {
            event.preventDefault();

            const focusableElements = 'button';
            const focusableElementsList = document.querySelectorAll(focusableElements);

            if (event.shiftKey) {
                const previousFocusableElement = Array.from(focusableElementsList).find((element, index) => {
                    const nextElement = focusableElementsList[index + 1];
                    const currentElement = focusableElementsList[index];

                    const isNextElementInsidePagination = nextElement?.closest('div') === paginationRef.current;

                    if (isNextElementInsidePagination) {
                        return currentElement;
                    }
                });

                if (!previousFocusableElement) {
                    focusableElementsList[focusableElementsList.length - 1].focus();
                }
                previousFocusableElement?.focus();
            } else {
                const nextFocusableElement = Array.from(focusableElementsList).find((element, index) => {
                    const nextElement = focusableElementsList[index + 1];
                    const currentElement = focusableElementsList[index];

                    const isNextElementInsidePagination = nextElement?.closest('div') === paginationRef.current;
                    const isCurrentElementInsidePagination = currentElement?.closest('div') === paginationRef.current;

                    if (isCurrentElementInsidePagination && !isNextElementInsidePagination) {
                        return isNextElementInsidePagination;
                    }
                });

                if (!nextFocusableElement) {
                    focusableElementsList[0].focus();
                }

                nextFocusableElement?.focus();
            }
        }

        if (pages.includes(Number(key))) {
            onChange(Number(key));
        }
    };

    return (
        <div
            className={classnames(
                styles.pagination,
                styles[variant],
                colorVariant === 'dark' ? styles.dark : colorVariant === 'light' ? styles.light : '',
            )}
            onKeyDown={handleKeyDown}
            ref={paginationRef}
            style={style && style}
        >
            <button
                type="button"
                onClick={handlePreviousPageClick}
                className={classnames(styles.page, styles[variant + '-previous'])}
                ref={previousButtonRef}
            >
                <AngleLeftSolid size=".75rem" color="var(--color-medium)" />
            </button>
            <Stack direction="row" style={{ gap: variant === 'carousel' ? 'var(--spacing-300)' : '0' }}>
                {variant === 'dots' || variant === 'carousel'
                    ? pages.map((page, index) => (
                          <button
                              type="button"
                              key={`${index}-${page}`}
                              className={classnames(
                                  styles.page,
                                  page === currentPage && styles.currentPage,
                                  styles[variant],
                              )}
                              onClick={() => handlePageClick(page)}
                          ></button>
                      ))
                    : variant === 'default'
                    ? pagesToShowWithoutDuplicates.map((page, index) => (
                          <div key={`${index}-${page}`} className={`${page !== -1 ? styles.container : ''}`}>
                              <button
                                  type="button"
                                  key={`${index}-${page}`}
                                  className={classnames(
                                      styles.page,
                                      page === -1 && styles.nonClickable,
                                      page === currentPage && styles.currentPage,
                                  )}
                                  onClick={() => handlePageClick(page)}
                                  tabIndex={page === -1 ? -1 : 0}
                              >
                                  {page === -1 ? '...' : page}
                              </button>
                          </div>
                      ))
                    : null}
            </Stack>
            <button
                type="button"
                onClick={handleNextPageClick}
                className={classnames(styles.page, styles[variant + '-next'])}
                ref={nextButtonRef}
            >
                <AngleRightSolid size=".75rem" color="var(--color-medium)" />
            </button>
        </div>
    );
}
