import { AngleUpSolid } from '@vg-react/icons/solid';
import { ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import styles from './table.module.scss';

interface TableSortLabelProps {
    /**
     * The content of the component, the same as `children`.
     */
    children: ReactNode;
    /**
     * If `true`, the label will have the active styling (should be true for the sorted column).
     */
    active?: boolean;
    /**
     * Defines the current sort direction.
     */
    direction?: 'asc' | 'desc';
    /**
     * Callback fired when the label is clicked. You can use it to set the sort direction.
     */
    onClick?: () => void;
    /**
     * Defines the align of the label.
     */
    align?: 'left' | 'right' | 'center';
    /**
     * Defines a custom class name for the label.
     */
    className?: string;
    /**
     * Defines a custom style for the label.
     */
    style?: React.CSSProperties;
}

/**
 * The component used to display a sort label in a column header.
 * It renders a `button` element.
 * It should be used inside `TableHead` and `TableCell`.
 */
export default function TableSortLabel({
    children,
    active = false,
    direction = 'asc',
    onClick,
    className,
    style,
    align = 'left',
}: TableSortLabelProps): JSX.Element {
    return (
        <button
            className={classnames(
                styles.sortLabel,
                active && styles.active,
                styles[`direction-${direction}`],
                className,
            )}
            style={{
                direction: align === 'right' ? 'rtl' : 'ltr',
                justifyContent: align === 'center' ? 'center' : undefined,
                ...style,
            }}
            onClick={onClick}
        >
            {children}
            <AngleUpSolid size="1rem" color={'var(--gray-500)'} className={styles.sortIcon} />
        </button>
    );
}
