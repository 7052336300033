import {
    createContext,
    CSSProperties,
    Dispatch,
    FormEvent,
    ReactNode,
    SetStateAction,
    useEffect,
    useState,
} from 'react';

/** Object, where keys are the field names and the values are their value */
export type Fields = Record<string, unknown>;

interface FormContextInternalProps {
    fields: Fields;
    setFields: Dispatch<SetStateAction<Fields>>;
}

const FormContext = createContext<FormContextInternalProps>({} as FormContextInternalProps);

FormContext.displayName = 'FormContext';

export { FormContext };

interface FormContextProps<T> {
    /** Children of the form - if a input element, form
     * will automatically control it
     */
    children: ReactNode;
    /**
     * when a submit event is triggered, this callback
     * will be called with the input values as parameter
     */
    onSubmit?: (values: T) => void;
    /**
     * when any change is made in any field, this callback
     * will be called with the input values as parameter
     */
    onChange?: (values: T) => T | void;
    /** Object that defines the default value for the fields */
    initialValues?: Fields;
    /** Form id, to enable triggering events from outside React */
    id?: string;
    /**
     * Wether the autocomplete from browser will be on or off
     * @default false
     */
    autoComplete?: boolean;
    /**
     * Defines a custom class to the form
     */
    className?: string;
    /**
     * Defines a custom style to the form
     */
    style?: CSSProperties;
    /**
     * state for outside controlled forms
     */
    outsideState?: Fields;
    /**
     * change function for outside controlled forms
     */
    setOutsideState?: Dispatch<SetStateAction<Fields>>;
}

/**
 * Form component
 *
 * Controls every input field inside it, and holds it's values
 *
 * @example
 * function onSubmit(values) {
 *      sendPost(values);
 * }
 *
 * const initialValues = {
 *      check: true,
 *      input1: 'My value 1',
 *      input2: 'My value 2',
 * }
 *
 * <Form onSubmit={onSubmit} initialValues={initialValues}>
 *      <Checkbox name='check'/>
 *      <Input name='input1'/>
 *      <Input name='input2'/>
 * </Form>
 *
 *
 */
export default function Form<T extends Fields>({
    initialValues,
    children,
    onSubmit,
    onChange,
    id,
    autoComplete = false,
    className,
    style,
    outsideState,
    setOutsideState,
}: FormContextProps<T>) {
    const [fields, setFields] = useState(initialValues ?? {});

    useEffect(() => {
        if (onChange) {
            const changedElements = onChange((outsideState && setOutsideState ? outsideState : fields) as T);
            if (changedElements) {
                const changedObject = Object.assign(
                    outsideState && setOutsideState ? outsideState : fields,
                    changedElements,
                );
                if ((outsideState && setOutsideState ? outsideState : fields) !== changedObject) {
                    outsideState && setOutsideState ? setOutsideState(changedObject) : setFields(changedObject);
                }
            }
        }
    }, [outsideState && setOutsideState ? outsideState : fields]);

    function internalSubmit(event: FormEvent) {
        event.preventDefault();
        if (onSubmit) onSubmit((outsideState && setOutsideState ? outsideState : fields) as T);
    }

    return (
        <FormContext.Provider
            value={{
                fields: outsideState && setOutsideState ? outsideState : fields,
                setFields: outsideState && setOutsideState ? setOutsideState : setFields,
            }}
        >
            <form
                target=""
                onSubmit={internalSubmit}
                id={id}
                autoComplete={autoComplete ? 'on' : 'off'}
                className={className}
                style={style}
            >
                {children}
            </form>
        </FormContext.Provider>
    );
}
