import { Stack, Text } from '@vg-react/components';

interface FieldTextProps {
    title?: string;
    value: string;
    tip?: string;
    updating?: boolean;
    information?: string;
}

export default function FieldText({ title, value, tip, updating=false, information = '' }: FieldTextProps) {

    if (!title && !tip) {
        return (
            <Text size='xs' weight='regular' color={updating ? 'var(--gray-400)' : 'var(--gray-600)'}>{value}</Text>
        );
    }

    if (!tip) {
        return (
            <Stack direction='row' spacing='var(--spacing-50)'>
                <Text size='xs' weight='bold' lineHeight='none' color='var(--gray-600)'>{title}</Text>
                <Text size='xs' weight='regular' lineHeight='none' color='var(--gray-600)'>{value}</Text>
            </Stack>
        );
    }

    return null;
}