import { CSSProperties, ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import styles from './Tooltip.module.scss';
import { useTooltip } from './TooltipContext';

interface TooltipRootProps {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
}

export default function TooltipTrigger({ children, style, className }: TooltipRootProps) {
    const { trigger, setOpen, config } = useTooltip();

    return (
        <div
            onFocus={() => config.showOnFocus && setOpen(true)}
            onBlur={() => config.showOnFocus && setOpen(false)}
            className={classnames(styles.trigger, className)}
            ref={trigger?.ref}
            style={style}
            {...trigger?.props}
        >
            {children}
        </div>
    );
}
