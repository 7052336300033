import { massChangeAtom } from '@/atoms/MassChangeAtom';
import { UpdateStatus, isUpdatingAtom, updateAtom, updateMetadataAtom } from '@/atoms/updateAtom';
import { useAtom } from 'jotai';
import { ReactNode, createContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Socket, io } from 'socket.io-client';
import useFetch from '../../hooks/useFetch';
import request from '../../util/request';

interface SocketDataResponse {
    webSocket: string;
}
interface SocketProviderProps {
    children: ReactNode;
}
interface SocketContextProps {
    socket: Socket | null;
    socketConnected: boolean;
}

export const SocketContext = createContext({} as SocketContextProps);
let socket: Socket;

export function SocketProvider({ children }: SocketProviderProps) {
    const { data, isFetching } = useFetch<SocketDataResponse>('/socket/data');
    const [socketConnected, setSocketConnected] = useState(false);
    const [, setUpdateStatus] = useAtom(updateAtom);
    const [, setIsUpdatingTo] = useAtom(isUpdatingAtom);
    const [, setUpdateMetadata] = useAtom(updateMetadataAtom);
    const [, setMassChange] = useAtom(massChangeAtom);
    useEffect(() => {
        if (!isFetching && data && !socket) {
            socket = io(data?.webSocket, {
                transports: ['websocket'],
            });
            socket?.on(
                'startUpdate',
                (data: {
                    version: string;
                    issuerData: {
                        type: string;
                        issuerId: string;
                        name: string;
                    };
                }) => {
                    if (data.issuerData) {
                        setUpdateMetadata({
                            version: data.version,
                            issuerData: data.issuerData,
                        });
                    }
                },
            );

            socket?.on('massChange:firmware:refresh', () => {
                setMassChange((p) => !p);
            });

            socket?.on('updateStatus', (data: { status: string; version: string }) => {
                if (data.version) {
                    setIsUpdatingTo(data.version);
                    localStorage.setItem('updating_version', data.version);
                }
                if (data.status === 'error') {
                    socket.off('updateStatus');
                    toast.error('Erro! A atualização falhou.');
                    setIsUpdatingTo('');
                    setUpdateStatus('error');
                    localStorage.removeItem('updating_version');
                } else if (data.status === 'success') {
                    socket.off('updateStatus');
                    socket.on('restarted', () => {
                        setUpdateStatus('success');
                        localStorage.removeItem('updating_version');
                        setIsUpdatingTo('');
                        socket.off('restarted');
                        setTimeout(() => window.location.reload(), 1000);
                    });
                } else {
                    setUpdateStatus(data.status as UpdateStatus);
                }
            });

            socket.on('authentication_request', async () => {
                request.get('/socket/auth').then((response) => {
                    socket.emit('authentication_response', response.data.hash);
                    setSocketConnected(true);
                });
            });

            socket.on('disconnect', () => {
                localStorage.getItem('updating_version')
                    ? toast.info('Servidor está sendo reinicializado!')
                    : setSocketConnected(false);
            });
        }
    }, [data, isFetching]);

    return <SocketContext.Provider value={{ socket, socketConnected }}>{children}</SocketContext.Provider>;
}
