import { CSSProperties, ReactNode, RefObject } from 'react';

interface StackProps extends React.HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
    style?: CSSProperties;
    width?: CSSProperties['width'];
    ref?: RefObject<HTMLDivElement>;
    height?: CSSProperties['height'];
    spacing?: 'small' | 'medium' | 'large' | string;
    direction?: 'column-reverse' | 'column' | 'row-reverse' | 'row';
    align?: 'normal' | 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'baseline';
    justify?:
    | 'center'
    | 'start'
    | 'end'
    | 'flex-start'
    | 'flex-end'
    | 'left'
    | 'right'
    | 'initial'
    | 'space-between'
    | 'space-around'
    | 'space-evenly'
    | 'stretch';
}

export default function Stack({
    direction = 'column',
    spacing = 'medium',
    justify = 'initial',
    align = 'normal',
    children,
    height,
    style,
    width,
    ref,
    ...props
}: StackProps) {
    const styleProps = {
        width,
        height,
        maxHeight: '100%',
        display: 'flex',
        alignItems: align,
        flexDirection: direction,
        justifyContent: justify,
        gap:
            {
                small: 'var(--spacing-100)',
                medium: 'var(--spacing-300)',
                large: 'var(--spacing-500)',
            }[spacing] || spacing,
        ...style,
    };

    return (
        <div ref={ref} style={styleProps} {...props}>
            {children}
        </div>
    );
}
