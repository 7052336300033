import { integrationAtom } from '@/atoms/integrationAtom';
import { ProgressBar, Stack, Text } from '@vg-react/components';
import { XmarkRegular } from '@vg-react/icons/v6/regular';
import { motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { forwardRef, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './IntegrationIndicator.module.scss';

export default function IntegrationIndicator() {
    const { t } = useTranslation();
    const [integrationIndicator, setIntegrationIndicator] = useAtom(integrationAtom);
    const [animating, setAnimating] = useState(false);
    const ref = useRef<SVGSVGElement>(null);

    useEffect(() => {
        if (!ref) return;

        const animationEnd = () => {
            setAnimating(false);
        };

        if (integrationIndicator.status === 'syncing') {
            setAnimating(true);
        }

        if (integrationIndicator.status === 'error' || integrationIndicator.status === 'success') {
            ref.current?.addEventListener('animationiteration', animationEnd);
            setTimeout(
                () =>
                    setIntegrationIndicator({
                        status: 'idle',
                        percentage: 0,
                    }),
                1000,
            );
        }

        return () => ref.current?.removeEventListener('animationiteration', animationEnd);
    }, [integrationIndicator.status]);

    return (
        <motion.div
            className={styles.downloadBox}
            data-status={integrationIndicator.status}
            transition={{
                type: 'spring',
                stiffness: 400,
                damping: 40,
            }}
            layout
        >
            <Stack spacing="0">
                <Stack
                    direction="row"
                    spacing="var(--spacing-75)"
                    justify="space-between"
                    align="center"
                    className={styles.titleContainer}
                >
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <div data-animating={animating} className={styles.downloadIndicator}>
                            <RotateSpin ref={ref} />
                        </div>
                        <Text color="var(--lightness-300)" weight="bold" size="xs">
                            {(() => {
                                switch (integrationIndicator.status) {
                                    case 'syncing':
                                        return t('IntegrationIndicatorComponent.syncing');
                                    case 'success':
                                        return t('IntegrationIndicatorComponent.success');
                                    case 'error':
                                        return t('IntegrationIndicatorComponent.error');
                                    case 'idle':
                                        return '';
                                    default:
                                        return '';
                                }
                            })()}
                        </Text>
                    </Stack>
                    <button
                        className={styles.dismissButton}
                        onClick={() => setIntegrationIndicator({ status: 'idle', percentage: 0 })}
                    >
                        <XmarkRegular size="16" color="var(--lightness-300)" />
                    </button>
                </Stack>
                <ProgressBar
                    height="3px"
                    progress={integrationIndicator.percentage}
                    indicatorColor="var(--accent-900)"
                    trackColor="var(--lightness-900)"
                />
            </Stack>
        </motion.div>
    );
}

export const RotateSpin = forwardRef<SVGSVGElement>((_, ref) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        height="16"
        width="16"
        viewBox="0 0 512 512"
        fill="currentColor"
        style={{ animation: 'rotate 2s linear infinite' }}
    >
        <style>
            {`
          @keyframes rotate {
            from {
              transform: rotate(0deg);
            }
            to {
              transform: rotate(360deg);
            }
          }
        `}
        </style>
        <path d="M142.9 142.9c62.2-62.2 162.7-62.5 225.3-1L327 183c-6.9 6.9-8.9 17.2-5.2 26.2s12.5 14.8 22.2 14.8H463.5c0 0 0 0 0 0H472c13.3 0 24-10.7 24-24V72c0-9.7-5.8-18.5-14.8-22.2s-19.3-1.7-26.2 5.2L413.4 96.6c-87.6-86.5-228.7-86.2-315.8 1C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5c7.7-21.8 20.2-42.3 37.8-59.8zM16 312v7.6 .7V440c0 9.7 5.8 18.5 14.8 22.2s19.3 1.7 26.2-5.2l41.6-41.6c87.6 86.5 228.7 86.2 315.8-1c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.2 62.2-162.7 62.5-225.3 1L185 329c6.9-6.9 8.9-17.2 5.2-26.2s-12.5-14.8-22.2-14.8H48.4h-.7H40c-13.3 0-24 10.7-24 24z" />
    </svg>
));

RotateSpin.displayName = 'RotateSpin';
