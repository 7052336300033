import Table from '../Table/Table';
import Text from '../Text/Text';
import React from 'react';

interface DataTableOverlayProps {
    message?: string;
}

export function DataTableOverlay({
    message = 'No data available',
}: DataTableOverlayProps): JSX.Element {
    return (
        <Table.Row
            style={{
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'var(--brightness-100)',
                backdropFilter: 'blur(2px)',
                zIndex: 1,
            }}
        >
            <Table.Cell minWidth='100%'>
                <Text
                    align='center'
                    color='gray'
                    size='xl'
                    weight='bold'
                >{message}</Text>
            </Table.Cell>
        </Table.Row>
    );
}
