import { Stack, Text, Tooltip } from '@vg-react/components';
import { InfoCircleSolid } from '@vg-react/icons/solid';
import { LaptopMobileRegular, QuestionRegular, RouterRegular } from '@vg-react/icons/v6/regular';
import { XmarkSolid } from '@vg-react/icons/v6/solid';
import { motion, useCycle } from 'framer-motion';
import { useRef } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import styles from '../../index/Topology.module.scss';
import { useDimensions } from '../utils';

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: 'spring',
            stiffness: 10,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(25px at 27px 243px)',
        transition: {
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
    },
};

export const LegendPanel = () => {
    const { t } = useTranslation();
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef<HTMLDivElement>(null);
    const { height } = useDimensions(containerRef);
    return (
        <motion.div
            initial={false}
            animate={isOpen ? 'open' : 'closed'}
            custom={height}
            ref={containerRef}
            onClick={() => {
                if (!isOpen) toggleOpen();
            }}
            style={{ cursor: isOpen ? 'default' : 'pointer' }}
        >
            <motion.div className={styles.background} variants={sidebar}>
                <Stack direction="column">
                    <Stack direction="row" align="center" justify="space-between">
                        <Stack
                            direction="column"
                            align="center"
                            justify="center"
                            spacing="small"
                            style={{ minWidth: 80 }}
                        >
                            <div style={{ width: 54, height: 24 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 400">
                                    <path
                                        d="M55.156951904296875,250.22421264648438C91.47982279459634,235.5754826863607,199.85052998860675,155.90432739257812,273.09417724609375,162.3318328857422C346.33782450358075,168.75933837890625,424.5142008463542,281.0164489746094,494.61883544921875,288.78924560546875C564.7234700520834,296.5620422363281,660.5381266276041,222.2720514933268,693.7219848632812,208.96861267089844"
                                        fill="none"
                                        strokeWidth="34"
                                        stroke="var(--mesh-controller)"
                                        strokeLinecap="round"
                                        strokeDasharray="49 0"
                                    ></path>
                                </svg>
                            </div>
                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-50)">
                                <Text weight="regular" color="var(--lightness-200)">
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.controller')}
                                </Text>
                                <Tooltip placement="bottom">
                                    <Tooltip.Trigger>
                                        <InfoCircleSolid color="var(--lightness-600)" size="12" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        <Text size="xs" weight="bold" color="var(--foreground-color)">
                                            {t(
                                                'DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.controllerDescription',
                                            )}
                                        </Text>
                                    </Tooltip.Content>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="column"
                            align="center"
                            justify="center"
                            spacing="small"
                            style={{ minWidth: 80 }}
                        >
                            <div style={{ width: 54, height: 24 }}>
                                <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 400">
                                    <path
                                        d="M55.156951904296875,250.22421264648438C91.47982279459634,235.5754826863607,199.85052998860675,155.90432739257812,273.09417724609375,162.3318328857422C346.33782450358075,168.75933837890625,424.5142008463542,281.0164489746094,494.61883544921875,288.78924560546875C564.7234700520834,296.5620422363281,660.5381266276041,222.2720514933268,693.7219848632812,208.96861267089844"
                                        fill="none"
                                        strokeWidth="34"
                                        stroke="var(--mesh-agent)"
                                        strokeLinecap="round"
                                        strokeDasharray="49 0"
                                    ></path>
                                </svg>
                            </div>
                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-50)">
                                <Text weight="regular" color="var(--lightness-200)">
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.agent')}
                                </Text>
                                <Tooltip placement="bottom">
                                    <Tooltip.Trigger>
                                        <InfoCircleSolid color="var(--lightness-600)" size="12" />
                                    </Tooltip.Trigger>
                                    {createPortal(
                                        <Tooltip.Content>
                                            <Text size="xs" weight="bold" color="var(--foreground-color)">
                                                {t(
                                                    'DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.agentDescription',
                                                )}
                                            </Text>
                                        </Tooltip.Content>,
                                        document.getElementById('workspace') ?? document.body,
                                    )}
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack direction="row" align="center" justify="space-between">
                        <Stack
                            direction="column"
                            align="center"
                            justify="space-between"
                            spacing="var(--spacing-50)"
                            style={{ minWidth: 80 }}
                        >
                            <Stack justify="center" align="center" className={styles.legendIcon}>
                                <RouterRegular color="var(--lightness-200)" size="18" />
                            </Stack>
                            <Stack direction="row" align="center" justify="center" spacing="small">
                                <Text weight="regular" color="var(--lightness-200)">
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.cpe')}
                                </Text>
                                <Tooltip placement="bottom">
                                    <Tooltip.Trigger>
                                        <InfoCircleSolid color="var(--lightness-600)" size="12" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        <Text size="xs" weight="bold" color="var(--foreground-color)">
                                            {t(
                                                'DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.cpeDescription',
                                            )}
                                        </Text>
                                    </Tooltip.Content>
                                </Tooltip>
                            </Stack>
                        </Stack>
                        <Stack
                            direction="column"
                            align="center"
                            justify="space-between"
                            spacing="var(--spacing-50)"
                            style={{ minWidth: 80 }}
                        >
                            <Stack justify="center" align="center" className={styles.legendIcon}>
                                <LaptopMobileRegular color="var(--lightness-200)" size="18" />
                            </Stack>
                            <Stack direction="row" align="center" justify="center" spacing="small">
                                <Text weight="regular" color="var(--lightness-200)">
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.host')}
                                </Text>
                                <Tooltip placement="top">
                                    <Tooltip.Trigger>
                                        <InfoCircleSolid color="var(--lightness-600)" size="12" />
                                    </Tooltip.Trigger>
                                    {createPortal(
                                        <Tooltip.Content>
                                            <Text size="xs" weight="bold" color="var(--foreground-color)">
                                                {t(
                                                    'DevicesScreen.DeviceScreen.Connectivity.Topology.LegendPanel.hostDescription',
                                                )}
                                            </Text>
                                        </Tooltip.Content>,
                                        document.getElementById('workspace') ?? document.body,
                                    )}
                                </Tooltip>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </motion.div>

            <div
                style={{ paddingBottom: 5, position: 'relative', left: '3px', top: '0px', cursor: 'pointer' }}
                onClick={() => {
                    if (isOpen) toggleOpen();
                }}
            >
                {isOpen ? <XmarkSolid color="var(--gray-900)" /> : <QuestionRegular color="var(--gray-900)" />}
            </div>
        </motion.div>
    );
};
