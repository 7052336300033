import usePermissions from '@/hooks/usePermissions';
import { Stack, Text } from '@vg-react/components';
import { CSSProperties, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import { BreadcrumbContext } from '../../contexts/Breadcrumb/BreadcrumbContext';
import style from './Breadcrumb.module.scss';

const Breadcrumb = ({ styles }: { styles?: CSSProperties }) => {
    const { t } = useTranslation();
    const routes = [
        { path: '/', name: t('Breadcrumb.home') },
        { path: 'logs', name: t('Breadcrumb.logs') },
        { path: 'roles', name: t('Breadcrumb.roles') },
        { path: 'five_ghz', name: t('Breadcrumb.five_ghz') },
        { path: 'summary', name: t('Breadcrumb.summary') },
        { path: 'system', name: t('Breadcrumb.system') },
        { path: 'users', name: t('Breadcrumb.users') },
        { path: 'files', name: t('Breadcrumb.files') },
        { path: 'two_ghz', name: t('Breadcrumb.two_ghz') },
        { path: 'server', name: t('Breadcrumb.server') },
        { path: 'backups', name: t('Breadcrumb.backups') },
        { path: 'chart', name: t('Breadcrumb.chart') },
        { path: 'latency', name: t('Breadcrumb.latency') },
        { path: 'ip', name: t('Breadcrumb.ip') },
        { path: 'routines', name: t('Breadcrumb.routines') },
        { path: 'history', name: t('Breadcrumb.history') },
        { path: 'firmware', name: t('Breadcrumb.firmware') },
        { path: 'result', name: t('Breadcrumb.result') },
        { path: 'tools', name: t('Breadcrumb.tools') },
        { path: 'ppp', name: t('Breadcrumb.ppp') },
        { path: 'security', name: t('Breadcrumb.security') },
        { path: 'hosts', name: t('Breadcrumb.hosts') },
        { path: 'results', name: t('Breadcrumb.results') },
        { path: 'update', name: t('Breadcrumb.update') },
        { path: 'sercurity', name: t('Breadcrumb.security') },
        { path: 'devices', name: t('Breadcrumb.devices') },
        { path: 'wifi', name: t('Breadcrumb.wifi') },
        { path: 'pppoe', name: t('Breadcrumb.pppoe') },
        { path: 'settings', name: t('Breadcrumb.settings') },
        { path: 'properties', name: t('Breadcrumb.properties') },
        { path: 'api_clients', name: t('Breadcrumb.api_clients') },
        { path: 'diagnostics', name: t('Breadcrumb.diagnostics') },
        { path: 'integrations', name: t('Breadcrumb.integrations') },
        { path: 'connectivity', name: t('Breadcrumb.connectivity') },
        { path: 'new_update', name: t('Breadcrumb.new_update') },
        { path: 'authentication', name: t('Breadcrumb.authentication') },
        { path: 'neighborTest', name: t('Breadcrumb.neighborTest') },
        { path: 'speedTest', name: t('Breadcrumb.speedTest') },
        { path: 'self', name: t('Breadcrumb.self') },
        { path: 'natRedirection', name: t('Breadcrumb.natRedirection') },
        { path: 'mass_change', name: t('Breadcrumb.mass_change') },
        { path: 'permissions', name: t('Breadcrumb.permissions') },
        { path: 'statistics', name: t('Breadcrumb.statistics') },
        { path: 'autoprovision', name: t('Breadcrumb.autoprovision') },
        { path: 'autoconfiguration', name: t('Breadcrumb.autoconfiguration') },
        { path: 'webManagement', name: t('Breadcrumb.webManagement') },
        { path: 'topology', name: t('Breadcrumb.topology') },
        { path: 'network', name: t('Breadcrumb.network') },
    ];

    function getName(name: string) {
        return (
            routes.find((route) => route.path === name)?.name ||
            (breadcrumbName.path === name && breadcrumbName.name) ||
            name
        );
    }

    const { pathname } = useLocation();
    const pathnames = pathname.split('/').filter((x) => x);
    const { validateDevicePermissions, validateDashboardPermissions } = usePermissions();
    const a = validateDashboardPermissions().hasAllPermissions
        ? 'home'
        : validateDevicePermissions().allDevices
        ? 'devices'
        : 'system';
    const { breadcrumbName } = useContext(BreadcrumbContext);

    if (pathnames.length >= 1) {
        document.title = `${
            routes.find((route) => route.path === pathnames[0])?.name ||
            (breadcrumbName.path === pathnames[0] && breadcrumbName.name) ||
            pathnames[0]
        } | IXC ACS`;
        if (pathnames[0] === 'devices' && pathnames[1]) document.title = `${pathnames[1]} | IXC ACS`;
    } else {
        document.title = 'IXC ACS';
    }

    return (
        <div className={style.breadcrumbArea} style={styles}>
            <Stack direction="row" spacing="var(--spacing-75)" align="center">
                <Stack direction="row" spacing="small" align="center" style={{ gap: 'var(--spacing-200)' }}>
                    <NavLink className={style.item} to={`/${a}`}>
                        <Text weight="bold" color="var(--color-400)" className={style.item} size="xs">
                            {t('Breadcrumb.home')}
                        </Text>
                    </NavLink>
                </Stack>
                {pathnames.length > 0 && (
                    <Text color="var(--gray-500)" weight="bolder" size="sm" style={{ marginBottom: 3 }}>
                        ›
                    </Text>
                )}
                {pathnames.map((name, index) => {
                    const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`;
                    const isLast = index === pathnames.length - 1;

                    return isLast ? (
                        <Text size="xs" weight="bold" color="var(--gray-700)" key="activeItem" className={style.item}>
                            {getName(name)}
                        </Text>
                    ) : (
                        <Stack key={index} direction="row" style={{ gap: 'var(--spacing-75)' }} align="center">
                            <NavLink className={style.item} key={`${name}-${index}`} to={routeTo}>
                                <Text
                                    weight="bold"
                                    color="unset"
                                    className={style.item}
                                    style={{ textTransform: 'capitalize' }}
                                    size="xs"
                                >
                                    {getName(name)}
                                </Text>
                            </NavLink>
                            <span
                                style={{
                                    color: 'var(--gray-700)',
                                    marginBottom: 3,
                                    WebkitUserSelect: 'none',
                                    userSelect: 'none',
                                }}
                                key={'span'}
                            >
                                ›
                            </span>
                        </Stack>
                    );
                })}
            </Stack>
        </div>
    );
};

export default Breadcrumb;
