import { t } from 'i18next';
import { z } from 'zod';

const webAccessSchema = z.object({
    configured: z.boolean(),
    enableRemoteAccess: z.boolean().optional(),
    username: z.string().trim().optional(),
    password: z
        .string()
        .optional()
        .superRefine((value, ctx) => {
            if (value && value.trim().length < 8) {
                ctx.addIssue({
                    code: 'custom',
                    message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.password'),
                });
            }
            if (value && value.trim().length > 18) {
                ctx.addIssue({
                    code: 'custom',
                    message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.passwordMax'),
                });
            }
        }),
    webPort: z
        .string()
        .trim()
        .optional()
        .refine(
            (val) => {
                if (!val) return true;
                const portNumber = Number(val);
                return !isNaN(portNumber) && portNumber > 0 && portNumber <= 65535;
            },
            {
                message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebPortModal.invalid'),
            },
        ),
});

const dnsWanSchema = z
    .object({
        configured: z.boolean(),
        dnsManual: z.boolean().optional(),
        primaryDns: z
            .string()
            .trim()
            .optional()
            .refine((val) => !val || z.string().trim().ip().safeParse(val).success, {
                message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.dnsInvalid'),
            }),
        secondaryDns: z
            .string()
            .trim()
            .optional()
            .refine((val) => !val || z.string().trim().ip().safeParse(val).success, {
                message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.dnsInvalid'),
            }),
    })
    .superRefine((schema, ctx) => {
        if (schema.dnsManual && !schema.primaryDns) {
            ctx.addIssue({
                code: 'custom',
                path: ['primaryDns'],
                message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.dnsRequired'),
            });
        }
    });

const dnsLanSchema = z.object({
    configured: z.boolean(),
    primaryDns: z
        .string()
        .trim()
        .optional()
        .refine((val) => !val || z.string().trim().ip().safeParse(val).success, {
            message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.dnsInvalid'),
        }),
    secondaryDns: z
        .string()
        .trim()
        .optional()
        .refine((val) => !val || z.string().trim().ip().safeParse(val).success, {
            message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.dnsInvalid'),
        }),
});

const ntpSchema = z.object({
    configured: z.boolean(),
    primaryNtp: z
        .string()
        .trim()
        .optional()
        .refine(
            (val) => {
                if (!val) return true;
                const ipValid = z
                    .string()
                    .trim()
                    .regex(/^(?:([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})|((?:\d{1,3}\.){3}\d{1,3}))$/)
                    .safeParse(val).success;
                return ipValid;
            },
            {
                message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.ntpInvalid'),
            },
        ),
    secondaryNtp: z
        .string()
        .trim()
        .optional()
        .refine(
            (val) => {
                if (!val) return true;
                const ipValid = z
                    .string()
                    .trim()
                    .regex(/^(?:([a-zA-Z0-9.-]+)\.([a-zA-Z]{2,})|((?:\d{1,3}\.){3}\d{1,3}))$/)
                    .safeParse(val).success;
                return ipValid;
            },
            {
                message: t('ToolsScreen.AutoConfigurationDeviceScreen.Validations.ntpInvalid'),
            },
        ),
});

const regulatoryDomainSchema = z.object({
    configured: z.boolean(),
    value: z.string().optional(),
});

const bindLayerSchema = z.object({
    configured: z.boolean(),
    lan1: z.boolean().optional(),
    lan2: z.boolean().optional(),
    lan3: z.boolean().optional(),
    lan4: z.boolean().optional(),
});

export const autoConfigurationDeviceSchema = z.object({
    webAccess: webAccessSchema,
    dnsWan: dnsWanSchema,
    dnsLan: dnsLanSchema,
    ntp: ntpSchema,
    regulatoryDomain: regulatoryDomainSchema,
    bindLayer: bindLayerSchema,
});

export type AutoConfigurationDeviceTypeZod = z.infer<typeof autoConfigurationDeviceSchema>;
