import { Badge, Stack, Text, Tooltip } from '@vg-react/components';
import { NetworkWiredRegular, RouterRegular } from '@vg-react/icons/regular';
import {
    SignalDuotone,
    SignalFairDuotone,
    SignalGoodDuotone,
    SignalSlashDuotone,
    SignalStrongDuotone,
    SignalWeakDuotone,
} from '@vg-react/icons/v6/duotone';
import { LaptopMobileRegular, WifiRegular } from '@vg-react/icons/v6/regular';
import { useTranslation } from 'react-i18next';
import { Node } from 'reactflow';
import styles from '../../index/Topology.module.scss';

export const RightPanel = ({ nodeData }: { nodeData: Node | undefined }) => (
    <div
        data-parent={nodeData?.type !== 'host' ? nodeData?.type : nodeData?.data.parentType}
        className={styles.rightPanel}
    >
        <div style={{ position: 'absolute', top: 0, left: 0, height: '100%', width: '100%' }}>
            <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 800 800" opacity="0.34">
                <g strokeWidth="1" stroke="var(--lightness-100)" fill="none" strokeLinecap="round">
                    <line x1="0" y1="0" x2="100" y2="100" opacity="1.00"></line>
                    <line x1="100" y1="0" x2="200" y2="100" opacity="1.00"></line>
                    <line x1="300" y1="0" x2="200" y2="100" opacity="1.00"></line>
                    <line x1="300" y1="0" x2="400" y2="100" opacity="1.00"></line>
                    <line x1="400" y1="0" x2="500" y2="100" opacity="1.00"></line>
                    <line x1="500" y1="0" x2="600" y2="100" opacity="1.00"></line>
                    <line x1="600" y1="0" x2="700" y2="100" opacity="1.00"></line>
                    <line x1="700" y1="0" x2="800" y2="100" opacity="1.00"></line>
                    <line x1="800" y1="0" x2="900" y2="100" opacity="1.00"></line>
                    <line x1="0" y1="100" x2="100" y2="200" opacity="0.88"></line>
                    <line x1="200" y1="100" x2="100" y2="200" opacity="0.88"></line>
                    <line x1="200" y1="100" x2="300" y2="200" opacity="0.88"></line>
                    <line x1="400" y1="100" x2="300" y2="200" opacity="0.88"></line>
                    <line x1="400" y1="100" x2="500" y2="200" opacity="0.88"></line>
                    <line x1="500" y1="100" x2="600" y2="200" opacity="0.88"></line>
                    <line x1="600" y1="100" x2="700" y2="200" opacity="0.88"></line>
                    <line x1="700" y1="100" x2="800" y2="200" opacity="0.88"></line>
                    <line x1="800" y1="100" x2="900" y2="200" opacity="0.88"></line>
                    <line x1="100" y1="200" x2="0" y2="300" opacity="0.76"></line>
                    <line x1="100" y1="200" x2="200" y2="300" opacity="0.76"></line>
                    <line x1="200" y1="200" x2="300" y2="300" opacity="0.76"></line>
                    <line x1="400" y1="200" x2="300" y2="300" opacity="0.76"></line>
                    <line x1="400" y1="200" x2="500" y2="300" opacity="0.76"></line>
                    <line x1="500" y1="200" x2="600" y2="300" opacity="0.76"></line>
                    <line x1="700" y1="200" x2="600" y2="300" opacity="0.76"></line>
                    <line x1="700" y1="200" x2="800" y2="300" opacity="0.76"></line>
                    <line x1="800" y1="200" x2="900" y2="300" opacity="0.76"></line>
                    <line x1="0" y1="300" x2="100" y2="400" opacity="0.64"></line>
                    <line x1="100" y1="300" x2="200" y2="400" opacity="0.64"></line>
                    <line x1="300" y1="300" x2="200" y2="400" opacity="0.64"></line>
                    <line x1="300" y1="300" x2="400" y2="400" opacity="0.64"></line>
                    <line x1="400" y1="300" x2="500" y2="400" opacity="0.64"></line>
                    <line x1="600" y1="300" x2="500" y2="400" opacity="0.64"></line>
                    <line x1="700" y1="300" x2="600" y2="400" opacity="0.64"></line>
                    <line x1="800" y1="300" x2="700" y2="400" opacity="0.64"></line>
                    <line x1="900" y1="300" x2="800" y2="400" opacity="0.64"></line>
                    <line x1="100" y1="400" x2="0" y2="500" opacity="0.53"></line>
                    <line x1="200" y1="400" x2="100" y2="500" opacity="0.53"></line>
                    <line x1="200" y1="400" x2="300" y2="500" opacity="0.53"></line>
                    <line x1="300" y1="400" x2="400" y2="500" opacity="0.53"></line>
                    <line x1="400" y1="400" x2="500" y2="500" opacity="0.53"></line>
                    <line x1="500" y1="400" x2="600" y2="500" opacity="0.53"></line>
                    <line x1="700" y1="400" x2="600" y2="500" opacity="0.53"></line>
                    <line x1="800" y1="400" x2="700" y2="500" opacity="0.53"></line>
                    <line x1="800" y1="400" x2="900" y2="500" opacity="0.53"></line>
                    <line x1="100" y1="500" x2="0" y2="600" opacity="0.41"></line>
                    <line x1="200" y1="500" x2="100" y2="600" opacity="0.41"></line>
                    <line x1="300" y1="500" x2="200" y2="600" opacity="0.41"></line>
                    <line x1="400" y1="500" x2="300" y2="600" opacity="0.41"></line>
                    <line x1="400" y1="500" x2="500" y2="600" opacity="0.41"></line>
                    <line x1="500" y1="500" x2="600" y2="600" opacity="0.41"></line>
                    <line x1="700" y1="500" x2="600" y2="600" opacity="0.41"></line>
                    <line x1="700" y1="500" x2="800" y2="600" opacity="0.41"></line>
                    <line x1="900" y1="500" x2="800" y2="600" opacity="0.41"></line>
                    <line x1="0" y1="600" x2="100" y2="700" opacity="0.29"></line>
                    <line x1="100" y1="600" x2="200" y2="700" opacity="0.29"></line>
                    <line x1="200" y1="600" x2="300" y2="700" opacity="0.29"></line>
                    <line x1="400" y1="600" x2="300" y2="700" opacity="0.29"></line>
                    <line x1="400" y1="600" x2="500" y2="700" opacity="0.29"></line>
                    <line x1="500" y1="600" x2="600" y2="700" opacity="0.29"></line>
                    <line x1="700" y1="600" x2="600" y2="700" opacity="0.29"></line>
                    <line x1="700" y1="600" x2="800" y2="700" opacity="0.29"></line>
                    <line x1="800" y1="600" x2="900" y2="700" opacity="0.29"></line>
                    <line x1="0" y1="700" x2="100" y2="800" opacity="0.17"></line>
                    <line x1="200" y1="700" x2="100" y2="800" opacity="0.17"></line>
                    <line x1="300" y1="700" x2="200" y2="800" opacity="0.17"></line>
                    <line x1="300" y1="700" x2="400" y2="800" opacity="0.17"></line>
                    <line x1="400" y1="700" x2="500" y2="800" opacity="0.17"></line>
                    <line x1="500" y1="700" x2="600" y2="800" opacity="0.17"></line>
                    <line x1="600" y1="700" x2="700" y2="800" opacity="0.17"></line>
                    <line x1="700" y1="700" x2="800" y2="800" opacity="0.17"></line>
                    <line x1="800" y1="700" x2="900" y2="800" opacity="0.17"></line>
                    <line x1="0" y1="800" x2="100" y2="900" opacity="0.05"></line>
                    <line x1="100" y1="800" x2="200" y2="900" opacity="0.05"></line>
                    <line x1="300" y1="800" x2="200" y2="900" opacity="0.05"></line>
                    <line x1="300" y1="800" x2="400" y2="900" opacity="0.05"></line>
                    <line x1="500" y1="800" x2="400" y2="900" opacity="0.05"></line>
                    <line x1="600" y1="800" x2="500" y2="900" opacity="0.05"></line>
                    <line x1="700" y1="800" x2="600" y2="900" opacity="0.05"></line>
                    <line x1="800" y1="800" x2="700" y2="900" opacity="0.05"></line>
                    <line x1="800" y1="800" x2="900" y2="900" opacity="0.05"></line>
                </g>
            </svg>
        </div>
        <Stack
            direction="column"
            justify="space-between"
            style={{ height: '100%', position: 'relative', paddingBlock: 12 }}
        >
            {nodeData?.type === 'host' && <HostDetails nodeData={nodeData} />}
            {nodeData?.type === 'agent' && <AgentDetails nodeData={nodeData} />}
            {nodeData?.type === 'controller' && <ControllerDetails nodeData={nodeData} />}
        </Stack>
    </div>
);

const ControllerDetails = ({ nodeData }: { nodeData: Node | undefined }) => {
    const { t } = useTranslation();

    return (
        <>
            <Stack direction="column" align="center" justify="center">
                <Stack
                    direction="row"
                    align="center"
                    spacing="var(--spacing-75)"
                    justify="center"
                    style={{
                        borderRadius: '100%',
                        width: '6rem',
                        height: '6rem',
                        backgroundColor: 'var(--mesh-controller)',
                        boxShadow: '0px 0px 41px -2px var(--mesh-controller)',
                    }}
                >
                    <RouterRegular color="white" size="28" />
                </Stack>
                <Stack direction="column" spacing="var(--spacing-75)" align="center" justify="center">
                    <Text color="var(--gray-700)" weight="bold" size="lg">
                        {nodeData?.data.label}
                    </Text>
                    <Stack direction="column" spacing="var(--spacing-50)" align="center" justify="center">
                        <Text color="var(--gray-500)" weight="bold" size="xs">
                            {nodeData?.data.ip}
                        </Text>
                        <Text color="var(--gray-500)" weight="bold" size="xs">
                            {nodeData?.data?.macAddress?.toUpperCase()}
                        </Text>
                    </Stack>
                </Stack>

                <Stack direction="row" align="center" justify="center">
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Badge>
                                {nodeData?.data.isMeshEnabled === '1'
                                    ? t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.mesh', {
                                          type: nodeData?.type,
                                      })
                                    : t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.cpe')}
                            </Badge>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.controllerDescription')}
                        </Tooltip.Content>
                    </Tooltip>
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Badge>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.connected', {
                                    count: nodeData?.data.children.length,
                                })}
                            </Badge>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.connectedTooltip')}
                        </Tooltip.Content>
                    </Tooltip>
                </Stack>

                <Stack direction="row" align="center" justify="space-evenly">
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '3.5rem',
                            width: '3.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: 22, height: 22 }}>
                                <NetworkWiredRegular color="var(--lightness-100)" size="22" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.wiredFirst')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                </Stack>
            </Stack>
        </>
    );
};

const AgentDetails = ({ nodeData }: { nodeData: Node | undefined }) => {
    const { t } = useTranslation();
    const bandRegex = /(\(\S+)\)/.exec(nodeData?.data?.meshAgentBackhaulMediaType);
    const band = bandRegex ? bandRegex[0] : '';
    return (
        <>
            <Stack direction="column" align="center" justify="center">
                <Stack
                    direction="row"
                    align="center"
                    spacing="var(--spacing-75)"
                    justify="center"
                    style={{
                        borderRadius: '100%',
                        width: '6rem',
                        height: '6rem',
                        backgroundColor: 'var(--mesh-agent)',
                        boxShadow: '0px 0px 41px -2px var(--mesh-agent)',
                    }}
                >
                    <RouterRegular color="white" size="28" />
                </Stack>
                <Stack direction="column" spacing="var(--spacing-75)" align="center" justify="center">
                    <Text color="var(--gray-700)" weight="bold" size="lg">
                        {nodeData?.data.label}
                    </Text>
                    <Stack direction="column" spacing="var(--spacing-50)" align="center" justify="center">
                        <Text color="var(--gray-500)" weight="bold" size="xs">
                            {nodeData?.data.hostIp}
                        </Text>
                        <Text color="var(--gray-500)" weight="bold" size="xs">
                            {nodeData?.data?.meshAgentMAC?.toUpperCase()}
                        </Text>
                    </Stack>
                </Stack>
                <Stack direction="row" align="center" justify="center">
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Badge>{`Mesh ${nodeData?.type}`}</Badge>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.meshDescription')}
                        </Tooltip.Content>
                    </Tooltip>
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Badge>{nodeData?.data.meshAgentManufacturer}</Badge>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.manufacturer')}
                        </Tooltip.Content>
                    </Tooltip>

                    <Tooltip>
                        <Tooltip.Trigger>
                            <Badge>{nodeData?.data.meshAgentSoftwareVersion}</Badge>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.firmware')}
                        </Tooltip.Content>
                    </Tooltip>
                </Stack>
            </Stack>

            {nodeData?.data.meshAgentBackhaulMediaType.includes('GHz') ? (
                <Stack direction="row" align="center" justify="space-evenly">
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '2.5rem',
                            width: '2.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: 18, height: 18 }}>
                                <WifiRegular color="var(--lightness-400)" size="18" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.wifi')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '3.5rem',
                            width: '3.5rem',
                        }}
                    >
                        <SignalIndicator rssi={nodeData?.data.meshAgentBackhaulSignalStrength} />
                    </Stack>
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '2.5rem',
                            width: '2.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Text color="var(--gray-500)" weight="bold" size="exs">
                                    {band.replace('(', '').replace(')', '')}
                                </Text>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.band')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                </Stack>
            ) : (
                <Stack direction="row" align="center" justify="space-evenly">
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '3.5rem',
                            width: '3.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: 22, height: 22 }}>
                                <NetworkWiredRegular color="var(--lightness-100)" size="22" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.wired')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

const HostDetails = ({ nodeData }: { nodeData: Node | undefined }) => {
    const { t } = useTranslation();
    return (
        <>
            <Stack direction="column" align="center" justify="center">
                <Stack
                    direction="row"
                    align="center"
                    spacing="small"
                    justify="center"
                    style={{
                        borderRadius: '100%',
                        width: '6rem',
                        height: '6rem',
                        backgroundColor:
                            nodeData?.data.parentType === 'agent' ? 'var(--mesh-agent)' : 'var(--mesh-controller)',
                        boxShadow: `0px 0px 41px -2px ${
                            nodeData?.data.parentType === 'agent' ? 'var(--mesh-agent)' : 'var(--mesh-controller)'
                        }`,
                    }}
                >
                    <LaptopMobileRegular color="white" size="28" />
                </Stack>
                <Stack direction="column" spacing="var(--spacing-75)" align="center" justify="center">
                    <Text color="var(--gray-700)" weight="bold" size="lg">
                        {nodeData?.data.label}
                    </Text>
                    <Stack direction="column" spacing="var(--spacing-50)" align="center" justify="center">
                        <Text color="var(--gray-500)" weight="bold" size="xs">
                            {nodeData?.data.meshSTAIPv4Address || nodeData?.data.hostIp}
                        </Text>
                        {nodeData?.data.label !== (nodeData?.data.meshSTAMAC || nodeData?.data.hostMAC) && (
                            <Text color="var(--gray-500)" weight="bold" size="xs">
                                {nodeData?.data?.meshSTAMAC?.toUpperCase() || nodeData?.data.hostMAC.toUpperCase()}
                            </Text>
                        )}
                    </Stack>
                </Stack>
            </Stack>

            {nodeData?.data.meshSTAAccessType === '2.4G' ||
            nodeData?.data.meshSTAAccessType === '5G' ||
            nodeData?.data.hostInterfaceType === '802.11' ? (
                <Stack direction="row" align="center" justify="space-evenly">
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '2.5rem',
                            width: '2.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: 18, height: 18 }}>
                                <WifiRegular color="var(--lightness-400)" size="18" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.wifi')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: nodeData.data.meshSTAAccessType ? '3.5rem' : '2.5rem',
                            width: nodeData.data.meshSTAAccessType ? '3.5rem' : '2.5rem',
                        }}
                    >
                        <SignalIndicator
                            rssi={nodeData?.data.meshSTASignalStrength || nodeData?.data.hostAssociatedDeviceRssi}
                        />
                    </Stack>
                    {nodeData.data.meshSTAAccessType && (
                        <Stack
                            direction="row"
                            align="center"
                            spacing="small"
                            justify="center"
                            style={{
                                borderRadius: '100%',
                                border: '2px solid var(--lightness-700)',
                                height: '2.5rem',
                                width: '2.5rem',
                            }}
                        >
                            <Tooltip>
                                <Tooltip.Trigger>
                                    <Text color="var(--gray-500)" weight="bold" size="exs">
                                        {nodeData?.data.meshSTAAccessType}
                                    </Text>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.band')}
                                </Tooltip.Content>
                            </Tooltip>
                        </Stack>
                    )}
                </Stack>
            ) : (
                <Stack direction="row" align="center" justify="space-evenly">
                    <Stack
                        direction="row"
                        align="center"
                        spacing="small"
                        justify="center"
                        style={{
                            borderRadius: '100%',
                            border: '2px solid var(--lightness-700)',
                            height: '3.5rem',
                            width: '3.5rem',
                        }}
                    >
                        <Tooltip>
                            <Tooltip.Trigger style={{ width: 22, height: 22 }}>
                                <NetworkWiredRegular color="var(--lightness-100)" size="22" />
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.wired')}
                            </Tooltip.Content>
                        </Tooltip>
                    </Stack>
                </Stack>
            )}
        </>
    );
};

const SignalIndicator = ({ rssi }: { rssi: number }) => {
    const { t } = useTranslation();
    const getLevel = (rssi: number) => {
        if (rssi > -40) {
            return 'excellent';
        } else if (rssi > -50) {
            return 'good';
        } else if (rssi > -60) {
            return 'fair';
        } else if (rssi > -70) {
            return 'bad';
        } else if (rssi > -80) {
            return 'horrible';
        } else {
            return 'offline';
        }
    };

    const display = {
        excellent: {
            icon: <SignalDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalExcellent', { rssi }),
        },
        good: {
            icon: <SignalStrongDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalGood', { rssi }),
        },
        fair: {
            icon: <SignalGoodDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalMedium', { rssi }),
        },
        bad: {
            icon: <SignalFairDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalWeak', { rssi }),
        },
        horrible: {
            icon: <SignalWeakDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalVeryWeak', { rssi }),
        },
        offline: {
            icon: <SignalSlashDuotone color={['var(--lightness-200)', 'var(--lightness-700)']} size="22" />,
            text: t('DevicesScreen.DeviceScreen.Connectivity.Topology.RightPanel.signalOffline'),
        },
    }[getLevel(rssi)];

    return (
        <Tooltip>
            <Tooltip.Trigger style={{ width: 22, height: 22 }}>{display.icon}</Tooltip.Trigger>
            <Tooltip.Content>{display.text}</Tooltip.Content>
        </Tooltip>
    );
};
