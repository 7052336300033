import { useContext } from 'react';
import { ThemeContext } from '../contexts/Theme/ThemeContext';

const darkColors: Record<string, string> = {
    zte: 'hsl(158, 45%, 38%)',
    fhth: 'hsl(199, 47%, 46%)',
    dlink: 'hsl(234, 47%, 56%)',
    tenda: 'hsl(278, 34%, 40%)',
    parks: 'hsl(270, 50%, 57%)',
    nokia: 'hsl(90, 62%, 28%)',
    alcl: 'hsl(90, 62%, 28%)',
    nbel: 'hsl(90, 62%, 28%)',
    sumec: 'hsl(53, 47%, 49%)',
    huawei: 'hsl(21, 66%, 46%)',
    tplink: 'hsl(337, 52%, 38%)',
    'tp-link': 'hsl(337, 52%, 38%)',
    mktech: 'hsl(331, 35%, 49%)',
    datacom: 'hsl(158, 45%, 38%)',
    greatek: 'hsl(199, 47%, 46%)',
    baudtec: 'hsl(234, 47%, 56%)',
    realtek: 'hsl(278, 34%, 40%)',
    mercusys: 'hsl(270, 50%, 57%)',
    raisecom: 'hsl(90, 62%, 28%)',
    intelbras: 'hsl(337, 52%, 39%)',
    fiberhome: 'hsl(21, 66%, 46%)',
    mitrastar: 'hsl(53, 47%, 49%)',
    other: 'hsl(90, 62%, 28%)',
};

const lightColors: Record<string, string> = {
    zte: 'hsl(170, 80%, 60%)',
    fhth: 'hsl(199, 100%, 76%)',
    dlink: 'hsl(234, 100%, 77%)',
    tenda: 'hsl(278, 100%, 70%)',
    parks: 'hsl(259, 69%, 73%)',
    nokia: 'hsl(90, 70%, 65%)',
    alcl: 'hsl(90, 70%, 65%)',
    nbel: 'hsl(90, 70%, 65%)',
    sumec: 'hsl(53, 80%, 70%)',
    huawei: 'hsl(21, 100%, 61%)',
    tplink: 'hsl(338, 100%, 69%)',
    'tp-link': 'hsl(338, 100%, 69%)',
    mktech: 'hsl(330, 100%, 80%)',
    datacom: 'hsl(170, 100%, 82%)',
    greatek: 'hsl(199, 100%, 76%)',
    baudtec: 'hsl(234, 100%, 77%)',
    realtek: 'hsl(278, 100%, 70%)',
    mercusys: 'hsl(259, 69%, 73%)',
    raisecom: 'hsl(90, 70%, 65%)',
    intelbras: 'hsl(338, 100%, 69%)',
    fiberhome: 'hsl(21, 100%, 61%)',
    mitrastar: 'hsl(53, 80%, 70%)',
    other: 'hsl(90, 70%, 65%)',
};

export default function useTheme() {
    const { storageTheme, theme, setTheme } = useContext(ThemeContext);

    const colors = theme === 'light' ? lightColors : darkColors;

    function getManufacturerColor(manufacturer: string) {
        let color = colors[manufacturer];

        if (!color) {
            Object.entries(colors).some(([manufacturerName, manufacturerColor]) => {
                const regex = new RegExp(manufacturerName, 'i');
                if (regex.test(manufacturer)) {
                    color = manufacturerColor;
                    return true;
                }
            });
        }

        if (!color) color = colors.other;

        return color;
    }

    return {
        storageTheme,
        theme,
        setTheme,
        getManufacturerColor,
    };
}
