import { Filter, Operator } from '@vg-react/components';
import React, { CSSProperties, ReactElement, useEffect, useRef, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css';
import debounce from '../../util/debounce';
import Stack from '../Stack/Stack';
import Table from '../Table/Table';
import DataTableBody from './DataTableBody';
import { DataTableFooter, ResultantRowsLabelProps, SelectedRowsLabelProps } from './DataTableFooter';
import DataTableHead from './DataTableHead';
import { DataTableHeader } from './DataTableHeader';

export type PaginationVariant = 'default' | 'only-arrows' | 'dots' | 'carousel';

export interface DataTableSortBy<T> {
    column: DataTableColumn<T>['accessor'] | null;
    direction: 'asc' | 'desc';
}

export interface DataTableColumn<T> {
    /**
     * The name of the column.
     */
    accessor: string;
    /**
     * Defines the column header text
     */
    label: string | JSX.Element;
    /**
     * Defines the width of the column.
     * If not specified, and table width is `auto`, the column will have 100px width.
     * If not specified, and table width is `full`, the column will have auto width.
     * If specified, the column will have the specified width.
     */
    width?: CSSProperties['width'];
    /**
     * Defines the value to be rendered in the cell.
     */
    render: (row: T) => React.ReactNode | string;
    /**
     * Defines if the table should be sorted by this column.
     * It's necessary to define the sortFunction to make it work
     */
    sortable?: boolean;
    /**
     * Defines the function to sort the column.
     * It's necessary to define the sortable to make it work
     */
    sortFunction?: (a: T, b: T) => number;
    /**
     * Defines the alignment of the column.
     */
    align?: 'left' | 'center' | 'right';
    /**
     * Defines the operators to be used in the filter.
     */
    operators?: Operator[];
    /**
     * Defines if the table can be used to create filters.
     */
    filterable?: boolean;
    /**
     * Defines if the column will be visible.
     */
    visible?: boolean;
    /**
     * Defines the type od the data in the column.
     * this is used to filter the data. If not specified, the filter will be a string.
     */
    type?: 'string' | 'number' | 'date';
    /**
     * Defines the enum values to be used in the filter.
     */
    enum?: { label: string; value: string | number | boolean }[];
    /**
     * Defines if the column can be resizeble with a ResizeTrigger.
     */
    resizable?: boolean;
    /**
     * Defines if the column can be searchable using search function.
     * Ff searchable is true and datatable isn't external controlled than searchFunction is required.
     */
    searchable?: boolean;
    /**
     * Defines function that receive query to check if the column in the row match querys value.
     */
    searchFunction?: (query: string, row: T) => boolean;
}

type DataTableColumnAccessor<T> = DataTableColumn<T>['accessor'];

interface DataTableProps<T> {
    /**
     * Define the columns to be displayed in the table.
     * It's necessary to define the accessor, label and render properties.
     * If column is sortable, it's necessary to define the sortFunction property.
     */
    columns: DataTableColumn<T>[];
    /**
     * Define the rows to be displayed in the table.
     * Each row is an object that will be accessed by the accessor property of each column.
     */
    rows: T[];
    /**
     * Define if the rows should have a hover effect.
     */
    rowsHover?: boolean;
    /**
     * Define the number of rows to be displayed per page.
     */
    rowsPerPage?: number;
    /**
     * Define the options for the number of rows to be displayed per page.
     * If not defined, the RowsPerPage component will not be displayed.
     * If defined, the rowsPerPage prop will be used as the default value.
     */
    rowsPerPageOptions?: number[];
    /**
     * Define the width of the DataTable. If not defined, the DataTable will take the width of its container.
     */
    width?: CSSProperties['width'];
    /**
     * Defines the width of Table.
     * If `auto`, the width of the table will be the sum of the width of each column.
     * If `full`, the width of the table will be 100% of its container.
     */
    tableWidth?: 'auto' | 'full';
    /**
     * Define if the table should have a checkbox in the first column to select rows.
     * If true, each row will have a checkbox to select it, and a head checkbox to select all rows.
     * If false, the checkbox will not be displayed.
     */
    selectable?: boolean;
    /**
     * Define if the table should have a checkbox in the first column to select rows.
     * If true, each row will have a checkbox to select it, and a head checkbox to select all rows.
     * If false, the checkbox will not be displayed.
     */
    selectableHasCheckbox?: boolean;
    /**
     * Define if a row should be selectable.
     * If defined, the row will be selectable only if this function returns true.
     * If not defined, all rows will be selectable.
     * This prop will be used only if the selectable prop is true.
     */
    isRowSelectable?: (row: T) => boolean;
    /**
     * Define the function to be called when the selected rows change.
     * This prop will be used only if the selectable prop is true.
     */
    onRowsSelectedChange?: (rows: T[]) => void;
    /**
     * Define the selected rows.
     * This prop will be used only if the selectable prop is true.
     */
    selectedRows?: T[];
    /**
     * Define the height of the table.
     * If not defined, the table will take the height of its container.
     * If defined, the table will have a fixed height and will have a vertical scrollbar(if needed).
     */
    height?: CSSProperties['height'];
    /**
     * Define if the table head should be sticky.
     * If true, when table needs to be scrolled, the head will be sticky and just the body will scroll.
     * If false, the head will not be sticky. The table will scroll normally.
     */
    stickyHead?: boolean;
    /**
     * Defines the table density.
     * If `compact`, the table will have a smaller height for each row.
     * If `comfortable`, the table will have a bigger height for each row.
     */
    density?: 'comfortable' | 'compact';
    /**
     * Defines the Pagination Variant
     */
    paginationVariant?: PaginationVariant;
    /**
     * Defines if the table should have a loading state.
     * If true, the table will have a loading state.
     * If false, the table will not have a loading state.
     * If loading state is true, the table will have a loading state and the rows will not be displayed.
     * If loading state is false, the table will not have a loading state and the rows will be displayed.
     */
    loading?: boolean;
    /**
     * Defines if the columns should be reordered.
     */
    allowColumnReorder?: boolean;
    /**
     * Defines a function to be executed on a row is double-clicked.
     */
    onRowDoubleClick?: (row: T) => void;
    /**
     * Defines an overlay message to be displayed when the table is empty.
     * If not defined, the table will not have an overlay message.
     * If defined, the table will have an overlay message.
     */
    overlayMessage?: string;
    /**
     * Defines if the table will be controlled externally.
     * This prop should be used when the table is on server-side.
     * When this prop is true, the table will change:
     * - All rows provided by the rows prop will be displayed.
     * - When inside the component, the pagination changes, the onPaginationChange prop will be called.
     * - The current page will be controlled by the currentPage prop.
     * - When inside the component, the rows per page changes, the onRowsPerPageChange prop will be called.
     * - The number of rows per page will be controlled by the rowsPerPage prop.
     * - When inside the component, the sort changes, the onSortByChange prop will be called.
     * - The sort will be controlled by the sortBy prop.
     * - The number of rows will be controlled by the rowsCount prop.
     */
    externalControlled?: boolean;
    /**
     * Defines the current page.
     * This prop will be used only if the externalControlled prop is true.
     */
    currentPage?: number;
    /**
     * Defines the function to be called when the current page changes.
     * This prop will be used only if the externalControlled prop is true.
     */
    onCurrentPageChange?: (currentPage: number) => void;
    /**
     * Defines the function to be called when the number of rows per page changes.
     * This prop will be used only if the externalControlled prop is true.
     */
    onRowsPerPageChange?: (rowsPerPage: number) => void;
    /**
     * Defines the sort.
     * This prop will be used only if the externalControlled prop is true.
     */
    sortBy?: DataTableSortBy<T>;
    /**
     * Defines the function to be called when the sort changes.
     * This prop will be used only if the externalControlled prop is true.
     */
    onSortByChange?: (sortBy: DataTableSortBy<T>) => void;
    /**
     * Defines the number of rows.
     * This prop will be used only if the externalControlled prop is true.
     */
    rowsCount?: number;
    /**
     * Defines the filters to be applied to the table.
     * This prop will be used only if the externalControlled prop is true.
     */
    filters?: Filter[];
    /**
     * Defines the function to be called when the filters change.
     * This prop will be used only if the externalControlled prop is true.
     */
    onFiltersChange?: (filters: Filter[]) => void;
    /**
     * Defines the columns to be displayed in the table.
     */
    columnsVisibility?: Record<DataTableColumnAccessor<T>, boolean> | undefined;
    /**
     * Defines the function to be called when the columns visibility changes.
     */
    onColumnsVisibilityChange?: (columnsOrder: Record<DataTableColumnAccessor<T>, boolean>) => void;
    /**
     * Defines if table pagination should be a loop.
     * If true, when the user clicks on the next page button and is on the last page, the table will go to the first page.
     */
    paginationLoop?: boolean;
    /**
     * Defines search query.
     */
    search?: string | undefined;
    /**
     * Defines the function to be called when on search value changes.
     */
    onSearchChange?: (query: string) => void;
    /**
     * Defines data table is searchable or not.
     * @default false
     */
    isSearchable?: boolean;
    /**
     * Defines the text for the rows per page description
     */
    rowsPerPageLabel?: string;
    /**
     * Callback which returns the formatted string to be used to indicate the selected rows count
     */
    selectedRowsLabel?: (rowInfo: SelectedRowsLabelProps) => string;
    /**
     * Callback which returns the formatted string to be used to indicate the total rows and the displayed ones
     */
    resultantRowsLabel?: (rowInfo: ResultantRowsLabelProps) => string;
    /**
     * Defines the columns order to be displayed in the table.
     */
    columnsOrder?: DataTableColumnAccessor<T>[];
    /**
     * Defines the function to be called when the columns order changes.
     */
    onColumnsOrderChange?: (columnOrder: DataTableColumnAccessor<T>[]) => void;
    /**
     * Defines if the datatable footer is going to be showed or not.
     * @default true
     */
    showDataTableFooter?: boolean;
    /**
     * Auto fit rows in the page.
     * When ```autoRowsPerPage``` is set, ```rowsPerPage``` will be ignored
     */
    autoRowsPerPage?: boolean;
    showHeader?: boolean;
    headerContainer?: ReactElement;
    customFilter?: ReactElement;
}

/**
 * The DataTable component is used to display data in a table.
 * It has a lot of features like pagination, sorting, filtering, exporting as CSV, etc.
 * It is very customizable and can be used in a lot of different ways.
 * @example
 * <DataTable<{name: string, age: number}>
 *    columns={[
 *      {
 *          accessor: 'name',
 *          label: 'Name',
 *          render: (row) => row.name,
 *      },
 *      {
 *          accessor: 'age',
 *          label: 'Age',
 *          render: (row) => row.age,
 *      },
 *    ]}
 *    rows={[
 *      { name: 'John', age: 20 },
 *      { name: 'Jane', age: 21 },
 *      { name: 'Jack', age: 22 },
 *    ]}
 *    rowsPerPage={2}
 *    rowsPerPageOptions={[2, 5, 10]}
 *    allowExportAsCSV
 *    allowFilters
 *    selectable
 *    isRowSelectable={(row) => row.age > 20}
 *    onRowsSelectedChange={(rows) => console.log(rows)}
 *    selectedRows={[{ name: 'Jane', age: 21 }]}
 *    height={300}
 *    stickyHead
 *    />
 */
export default function DataTable<T>({
    rows,
    width,
    sortBy,
    columns,
    rowsCount,
    showHeader,
    search = undefined,
    customFilter,
    filters = [],
    columnsOrder,
    overlayMessage,
    paginationLoop,
    headerContainer,
    height = 'auto',
    currentPage = 1,
    loading = false,
    rowsPerPage = 10,
    autoRowsPerPage = false,
    rowsPerPageLabel,
    selectedRows = [],
    columnsVisibility,
    rowsHover = false,
    stickyHead = false,
    selectable = false,
    tableWidth = 'full',
    isSearchable = false,
    density = 'comfortable',
    rowsPerPageOptions = [],
    allowColumnReorder = false,
    externalControlled = false,
    showDataTableFooter = true,
    paginationVariant = 'default',
    selectableHasCheckbox = false,
    onSearchChange,
    onSortByChange,
    onFiltersChange,
    onRowDoubleClick,
    selectedRowsLabel,
    resultantRowsLabel,
    onCurrentPageChange,
    onRowsPerPageChange,
    onColumnsOrderChange,
    onRowsSelectedChange,
    onColumnsVisibilityChange,
    isRowSelectable = () => true,
}: DataTableProps<T>): JSX.Element {
    const firstRender = useRef(true);

    const [internalCurrentPage, setInternalCurrentPage] = useState<number>(1);

    const [internalCurrentRowsPerPage, setInternalCurrentRowsPerPage] = useState<number>(rowsPerPage);

    const [internalFilters, setInternalFilters] = useState<Filter[]>([]);

    const [internalSearch, setInternalSearch] = useState<string>('');

    const tableRef = useRef<HTMLDivElement>(null);
    const tableBodyRef = useRef<HTMLTableSectionElement>(null);
    const tableHeadRef = useRef<HTMLTableSectionElement>(null);

    const [internalColumnsVisibility, setInternalColumnsVisibility] = useState<
        Record<DataTableColumnAccessor<T>, boolean>
    >({
        ...columns.reduce((acc, column) => ({ ...acc, [column.accessor]: true }), {}),
    });

    const [internalSortBy, setInternalSortBy] = useState<DataTableSortBy<T>>({
        column: null,
        direction: 'asc',
    });

    const [internalColumnOrder, setInternalColumnOrder] = useState<DataTableColumnAccessor<T>[]>(
        columns.map((column) => column.accessor),
    );

    const handleResize = () => {
        if (!tableRef.current || !tableBodyRef.current || loading) return;
        const rowCount = externalControlled ? rowsPerPage : internalCurrentRowsPerPage;
        const headSize = tableHeadRef.current?.getBoundingClientRect().height ?? 0;
        const childNode = tableBodyRef.current.children[0];
        const rowSize = childNode
            ? childNode.getBoundingClientRect().height
            : tableBodyRef.current.getBoundingClientRect().height / rowCount;
        const newRowsPerPage = Math.floor((tableRef.current.getBoundingClientRect().height - headSize) / rowSize);
        if (internalCurrentRowsPerPage !== newRowsPerPage) {
            setInternalCurrentPage(1);
            setInternalCurrentRowsPerPage(newRowsPerPage > 1 ? newRowsPerPage : 1);
            if (externalControlled) {
                onRowsPerPageChange?.(newRowsPerPage > 1 ? newRowsPerPage : 1);
                onCurrentPageChange?.(1);
            }
        }
    };

    useEffect(() => {
        if (!autoRowsPerPage) return;
        handleResize();
    }, [rows]);

    useEffect(() => {
        if (!autoRowsPerPage) return;
        const debouncedHandleResize = debounce(handleResize, 100);
        window.addEventListener('resize', debouncedHandleResize);
        return () => window.removeEventListener('resize', debouncedHandleResize);
    }, [tableRef, tableBodyRef, rowsPerPage, internalCurrentRowsPerPage]);

    useEffect(() => {
        if (autoRowsPerPage) handleResize();
        if (externalControlled && columnsOrder?.length === 0) {
            onColumnsOrderChange?.(columns.map((column) => column.accessor));
        }
    }, []);

    const handleColumnsOrderChange = (columnOrder: DataTableColumnAccessor<T>[]) => {
        if (externalControlled) {
            onColumnsOrderChange?.(columnOrder);
        } else {
            setInternalColumnOrder(columnOrder);
        }
    };

    const handleRowsPerPageChange = (rowsPerPage: number) => {
        if (externalControlled) {
            onRowsPerPageChange?.(rowsPerPage);
            onCurrentPageChange?.(1);
        } else {
            setInternalCurrentRowsPerPage(rowsPerPage);
            setInternalCurrentPage(1);
        }
    };

    const handleSelectAll = (checked: boolean) => {
        if (checked) {
            onRowsSelectedChange?.([...rows.filter(isRowSelectable)]);
        } else {
            onRowsSelectedChange?.([]);
        }
    };

    const handleSelectRow = (row: T, checked: boolean) => {
        if (!isRowSelectable(row)) return;
        if (checked) {
            onRowsSelectedChange?.([...selectedRows, row]);
        } else {
            onRowsSelectedChange?.([...selectedRows.filter((selectedRow) => selectedRow !== row)]);
        }
    };

    const handleColumnsVisibilityChange = (columns: DataTableColumn<T>[]) => {
        const columnsVisibility = columns.reduce((acc, column) => ({ ...acc, [column.accessor]: column.visible }), {});

        if (externalControlled) {
            onColumnsVisibilityChange?.(columnsVisibility);
        } else {
            setInternalColumnsVisibility(columnsVisibility);
        }
    };

    const rowsFiltered = rows;

    const rowsSearched = !externalControlled
        ? internalSearch.length === 0
            ? rows
            : rowsFiltered.filter((row) => {
                  const columnsSearchable = columns.filter((column) => column.searchable && column.searchFunction);
                  return columnsSearchable.some((column) => column.searchFunction?.(internalSearch, row)) || false;
              })
        : rowsFiltered;

    const rowsSorted = !externalControlled
        ? rowsSearched.sort((a, b) => {
              const column = columns.find((column) => column.accessor === internalSortBy.column);
              if (column?.sortable && column.sortFunction) {
                  return internalSortBy.direction === 'asc' ? column.sortFunction(a, b) : column.sortFunction(b, a);
              }
              return 0;
          })
        : rowsSearched;

    const firstRow = (internalCurrentPage - 1) * internalCurrentRowsPerPage;
    const lastRow = firstRow + internalCurrentRowsPerPage;
    const rowsPaginated = externalControlled ? rows : rowsSorted.slice(firstRow, lastRow);

    const columnsReordered = columns.sort((a, b) => {
        if (externalControlled) {
            const indexA = columnsOrder?.indexOf(a.accessor);
            const indexB = columnsOrder?.indexOf(b.accessor);

            return (indexA as number) - (indexB as number);
        }

        const indexA = internalColumnOrder.indexOf(a.accessor);
        const indexB = internalColumnOrder.indexOf(b.accessor);

        return indexA - indexB;
    });

    const columnsVisible = columnsReordered.filter((column) => {
        if (externalControlled) {
            return columnsVisibility?.[column.accessor] !== undefined ? columnsVisibility[column.accessor] : true;
        }
        return internalColumnsVisibility[column.accessor];
    });

    const columnsWithVisibility = columns.map((column) => ({
        ...column,
        visible: columnsVisible.includes(column),
    }));

    useEffect(() => {
        if (firstRender.current) {
            firstRender.current = false;
            return;
        }
    }, [internalCurrentPage, internalCurrentRowsPerPage, internalSortBy]);

    useEffect(() => {
        if (!externalControlled) {
            setInternalCurrentPage(1);
        }
    }, [rowsCount]);
    return (
        <Stack width={width} spacing="small" height={height} direction="column" style={{ minHeight: 0 }}>
            {(showHeader || columns.find((column) => column.filterable)) && (
                <DataTableHeader
                    customFilter={customFilter}
                    headerContainer={headerContainer}
                    filters={externalControlled ? filters : internalFilters}
                    onColumnsVisibilityChange={handleColumnsVisibilityChange}
                    onFiltersChange={onFiltersChange ?? setInternalFilters}
                    onSearchChange={onSearchChange || setInternalSearch}
                    onColumnsOrderChange={handleColumnsOrderChange}
                    search={externalControlled || search ? search : internalSearch}
                    allowColumnReorder={allowColumnReorder}
                    columnsOrder={externalControlled ? (columnsOrder as string[]) : internalColumnOrder}
                    columns={columnsWithVisibility}
                    isSearchable={isSearchable}
                />
            )}
            <Table
                ref={tableRef}
                height="100%"
                density={density}
                width={tableWidth}
                stickyHead={stickyHead}
                style={{
                    position: 'relative',
                    userSelect: loading || overlayMessage ? 'none' : 'auto',
                    ...(overlayMessage && { overflow: 'hidden' }),
                }}
            >
                <DataTableHead<T>
                    ref={tableHeadRef}
                    selectable={selectable}
                    rows={rows}
                    columns={columnsVisible}
                    selectedRows={selectedRows}
                    rowsPaginated={rowsPaginated}
                    isRowSelectable={isRowSelectable}
                    onSelectAllRows={handleSelectAll}
                    selectableHasCheckbox={selectableHasCheckbox}
                    onSortByChange={onSortByChange || setInternalSortBy}
                    sortBy={externalControlled ? sortBy || internalSortBy : internalSortBy}
                />
                <DataTableBody
                    ref={tableBodyRef}
                    loading={loading}
                    rows={[...rowsPaginated]}
                    rowsHover={rowsHover}
                    selectable={selectable}
                    columns={[...columnsVisible]}
                    selectedRows={[...selectedRows]}
                    onRowSelect={handleSelectRow}
                    overlayMessage={overlayMessage}
                    isRowSelectable={isRowSelectable}
                    onRowDoubleClick={onRowDoubleClick}
                    selectableHasCheckbox={selectableHasCheckbox}
                    rowsPerPage={rowsPerPage || internalCurrentRowsPerPage}
                />
            </Table>
            {showDataTableFooter && rowsPaginated.length >= 1 && (
                <DataTableFooter
                    autoRowsPerPage={autoRowsPerPage}
                    rowsPerPageLabel={rowsPerPageLabel}
                    paginationVariant={paginationVariant}
                    rowsPerPageOptions={rowsPerPageOptions}
                    paginationLoop={paginationLoop || false}
                    numberOfSelectedRows={selectedRows.length}
                    onRowsPerPageChange={handleRowsPerPageChange}
                    currentPage={externalControlled ? currentPage : internalCurrentPage}
                    rowsPerPage={externalControlled ? rowsPerPage : internalCurrentRowsPerPage}
                    onPaginationChange={onCurrentPageChange || setInternalCurrentPage}
                    numberOfRows={externalControlled ? rowsCount || rows.length : rows.length}
                    selectedRowsLabel={selectedRowsLabel}
                    resultantRowsLabel={resultantRowsLabel}
                />
            )}
        </Stack>
    );
}
