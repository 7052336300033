import Form from '@/components/Form/Form';
import NumberInput from '@/components/NumberInput/NumberInput';
import Text from '@/components/Text/Text';
import useSocket from '@/hooks/useSocket';
import request from '@/util/request';
import { ActionButton, Modal, Stack } from '@vg-react/components';
import { SaveLight } from '@vg-react/icons/light';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { WebSocketMessage } from '../Types';

interface EditWebPortModalProps {
    onClose: () => void;
    webPort: string;
    setIsSentWebPort: (value: boolean) => void;
    setDescriptionDevice: (...args: any) => void;
    refresh: () => void;
    needsReboot?: boolean;
}

type FormData = {
    webPort: string;
};

export default function EditWebPortModal({
    onClose,
    webPort,
    setIsSentWebPort,
    setDescriptionDevice,
    refresh,
    needsReboot,
}: EditWebPortModalProps) {
    const { id } = useParams();
    const { socket } = useSocket();
    const { t } = useTranslation();

    const schemaWebPort = z.object({
        webPort: z
            .string({
                required_error: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebPortModal.required'),
            })
            .refine(
                (val) => {
                    const portNumber = Number(val);
                    return !isNaN(portNumber) && portNumber > 0 && portNumber <= 65535;
                },
                {
                    message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebPortModal.invalid'),
                },
            ),
    });

    const initialErrors = {
        webPort: '',
    };

    const [disable, setDisabled] = useState(true);
    const [alreadySubmit, setAlreadySubmit] = useState(false);
    const [errors, setErrors] = useState(initialErrors);

    const verifyErrors = useCallback(
        (fields: Partial<FormData>) => {
            type Key = keyof FormData;
            for (const key in fields) {
                if (fields[key as Key] === '') fields[key as Key] = undefined;
            }

            const result = schemaWebPort.safeParse(fields);
            if (result.success) {
                setErrors(initialErrors);
                return false;
            }

            setErrors((previous) => {
                const zodErrors = result.error.flatten().fieldErrors;
                const newErrors = { ...initialErrors };
                Object.keys(previous).forEach((key) => {
                    newErrors[key as Key] = zodErrors[key as Key]?.[0] ?? '';
                });

                return newErrors;
            });

            return true;
        },
        [errors, setErrors],
    );

    function onChange(fields: FormData) {
        const notContainsError = verifyErrors(fields);
        if (!fields.webPort) {
            setDisabled(true);
            return;
        }
        setDisabled(notContainsError);
        if (alreadySubmit) verifyErrors(fields);
    }

    function onSubmit(fields: FormData) {
        if (!id) return;
        const notContainsError = verifyErrors(fields);
        if (notContainsError) return;
        setAlreadySubmit(true);
        setIsSentWebPort(true);

        const url = `/app/devices/${id}/security/remoteAccess/`;

        request
            .patch(url, { webPort: fields.webPort })
            .then(() => {
                socket?.on(`resources:security:${id}`, (response: WebSocketMessage) => {
                    if (response.status === 'in progress') {
                        toast.info(response.message);
                        setDescriptionDevice({ message: response.message, status: 'in progress' });
                        return;
                    }
                    if (response.status === 'success') {
                        toast.success(response.message);
                        setDescriptionDevice(response);
                    } else {
                        toast.error(response.message);
                        setDescriptionDevice(response);
                    }
                    setTimeout(() => {
                        setDescriptionDevice(null);
                        refresh();
                    }, 5000);
                    setIsSentWebPort(false);
                    socket.off(`resources:security:${id}`);
                });
                onClose();
            })
            .catch((error) => {
                toast.error(error.response.data);
                setDescriptionDevice({
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.error',
                    ),
                    status: 'error',
                });
                setTimeout(() => {
                    setDescriptionDevice(null);
                    setIsSentWebPort(false);
                    refresh();
                }, 5000);
            });
    }

    return (
        <Modal
            size="small"
            active
            onClose={onClose}
            style={{
                overflow: 'visible',
            }}
        >
            <Form<FormData> initialValues={{ webPort }} onChange={onChange} onSubmit={onSubmit}>
                <Modal.Header generateCloseButton>
                    <Modal.Title>
                        {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebPortModal.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack>
                        {needsReboot && (
                            <Text weight="bold" variant="p" size="xs">
                                {t(
                                    'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmRemoteAccessEnable.needsReboot',
                                )}
                            </Text>
                        )}
                        <Stack spacing="small">
                            <NumberInput
                                required
                                autoComplete="false"
                                width="100%"
                                helpText={errors.webPort}
                                invalid={Boolean(errors.webPort)}
                                name="webPort"
                                label={t(
                                    'DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebPortModal.label',
                                )}
                                placeholder="8080"
                            />
                        </Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" spacing="small" align="center" justify="end">
                        <ActionButton quiet onClick={onClose}>
                            {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" disabled={disable}>
                            <SaveLight color="currentColor" size="16" />
                            {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.save')}
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
