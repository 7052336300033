import React from 'react';
import { useNavigate } from 'react-router-dom';

interface NavigateTempProps {
    to: string;
}

export const NavigateTemp = ({ to }: NavigateTempProps) => {
    const navigate = useNavigate();

    React.useEffect(() => {
        setTimeout(() => {
            navigate(to);
        }, 50);
    }, [to]);

    return null;
};
