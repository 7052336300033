import { Stack, Text, Tooltip } from '@vg-react/components';
import { LaptopMobileDuotone } from '@vg-react/icons/v6/duotone';
import { PipeLight } from '@vg-react/icons/v6/light';
import { CircleInfoSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { IConsumptionPerHost } from '../index/SpeedTest.screen';
import styles from '../index/SpeedTestResult.module.scss';

interface ConsumptionPerHostProps {
    hosts: IConsumptionPerHost[];
    isRunning: boolean;
}

export const ConsumptionPerHost = ({ hosts, isRunning }: ConsumptionPerHostProps) => {
    const { t } = useTranslation();
    return (
        <Stack className={styles.consumptionPerHostContainer} spacing="var(--spacing-100)">
            <Stack direction="row" spacing="var(--spacing-75)">
                <Text size="sm" weight="bold" color="var(--lightness-300)">
                    {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ConsumptionPerHost.title')}
                </Text>
                <Tooltip>
                    <Tooltip.Trigger>
                        <CircleInfoSolid size="14" color="var(--lightness-400)" />
                    </Tooltip.Trigger>

                    <Tooltip.Content>
                        <Text size="xs" weight="regular" color="currentColor">
                            {t(
                                'DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ConsumptionPerHost.consumptionPerHost',
                            )}
                        </Text>
                    </Tooltip.Content>
                </Tooltip>
            </Stack>
            <Stack style={{ overflowY: 'auto' }} spacing="var(--spacing-100)">
                {hosts
                    .sort((a, b) => {
                        const aConsumption = a.bytesSentFinish - a.bytesSentInit;
                        const bConsumption = b.bytesSentFinish - b.bytesSentInit;

                        if (aConsumption > bConsumption) {
                            return -1;
                        }
                        if (aConsumption < bConsumption) {
                            return 1;
                        }
                        return 0;
                    })
                    .map((host, index) => (
                        <Stack key={index} direction="row" className={styles.consumptionPerHostCard}>
                            <Stack style={{ width: '100%' }} direction="row" align="center" justify="space-between">
                                <Stack
                                    direction="row"
                                    align="center"
                                    justify="center"
                                    spacing="var(--spacing-200)"
                                    style={{ overflowX: 'hidden' }}
                                >
                                    {isRunning ? (
                                        <Skeleton
                                            width={300}
                                            height={28}
                                            borderRadius={'var(--radius-medium)'}
                                            baseColor="var(--background-color)"
                                            style={{ opacity: 0.7 }}
                                        />
                                    ) : (
                                        <>
                                            <LaptopMobileDuotone color="var(--lightness-400)" size="26" />
                                            <Text weight="regular" size="sm" color="var(--lightness-400)">
                                                {host.name || host.mac}
                                            </Text>
                                        </>
                                    )}
                                </Stack>
                                <Stack
                                    direction="row"
                                    align="center"
                                    justify="space-between"
                                    spacing="var(--spacing-100)"
                                    style={{ width: '40%' }}
                                >
                                    <Stack direction="column" align="center" justify="center" spacing="0">
                                        <Text color="var(--lightness-400)">
                                            {t(
                                                'DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ConsumptionPerHost.download',
                                            )}
                                        </Text>
                                        {isRunning ? (
                                            <Skeleton
                                                width={30}
                                                height={14}
                                                borderRadius={'var(--radius-medium)'}
                                                baseColor="var(--background-color)"
                                                style={{ opacity: 0.7 }}
                                            />
                                        ) : (
                                            <>
                                                {calculateDataConsumption(
                                                    host.bytesSentInit,
                                                    host.bytesSentFinish,
                                                    'var(--download-color)',
                                                )}
                                            </>
                                        )}
                                    </Stack>
                                    <PipeLight size="24" color="var(--lightness-900)" />
                                    <Stack direction="column" align="center" justify="center" spacing="0">
                                        <Text color="var(--lightness-400)">
                                            {t(
                                                'DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ConsumptionPerHost.upload',
                                            )}
                                        </Text>
                                        {isRunning ? (
                                            <Skeleton width={30} height={14} />
                                        ) : (
                                            <>
                                                {calculateDataConsumption(
                                                    host.bytesReceivedInit,
                                                    host.bytesReceivedFinish,
                                                    'var(--upload-color)',
                                                )}
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
            </Stack>
        </Stack>
    );
};

function calculateDataConsumption(initialBytes: number, finalBytes: number, color: string) {
    if (initialBytes < 0 || finalBytes < 0) {
        throw new Error('Byte values must be non-negative.');
    }

    if (finalBytes < initialBytes) {
        return (
            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-100)">
                <Text size="sm" weight="bold" color={color}>
                    0
                </Text>
                <Text size="xs" weight="regular" color={color}>
                    B
                </Text>
            </Stack>
        );
    }

    const bytesConsumed = finalBytes - initialBytes;

    function convertBytes(bytes: number) {
        const units = ['B', 'KB', 'MB', 'GB', 'TB'];

        let size = bytes;
        let unitIndex = 0;

        while (size >= 1024 && unitIndex < units.length - 1) {
            size /= 1024;
            unitIndex++;
        }

        size = Math.round(size * 100) / 100;

        return (
            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-50)">
                <Text size="sm" weight="bold" color={color}>
                    {size}
                </Text>
                <Text size="xs" weight="regular" color={color}>
                    {units[unitIndex]}
                </Text>
            </Stack>
        );
    }

    const formattedConsumption = convertBytes(bytesConsumed);

    return formattedConsumption;
}
