import useCapability from '@/atoms/capabilityAtom';
import ActionButton from '@/components/ActionButton/ActionButton';
import Form from '@/components/Form/Form';
import Input from '@/components/Input/Input';
import Modal from '@/components/Modal/Modal';
import Select from '@/components/Select/Select';
import Stack from '@/components/Stack/Stack';
import { QuestionCircleLight, SaveLight } from '@vg-react/icons/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import useSocket from '../../../../../../../../hooks/useSocket';
import request from '../../../../../../../../util/request';
import { ModalEditPasswordProps, UpdateDataSocket } from './types/Types';

type FormData = {
    password: string;
    beaconType: 'None' | '11i' | 'WPA' | 'WPAand11i';
};
const openBeaconTypes = ['None', 'Open',];
export default function ModalEditPassword({
    id,
    active,
    previousPassword,
    instance,
    refresh,
    onClose,
    setButtonPasswordDisable,
    setDeviceOperation,
    currentBeaconType,
    beaconTypeSupported,
}: ModalEditPasswordProps) {
    const { t } = useTranslation();
    const { socket } = useSocket();
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const [hasNotCapability] = useCapability();

    const validBeaconTypes = beaconTypeSupported || ['None', '11i', 'WPA', 'WPAand11i'];

    const schema = z
        .object({
            beaconType: z.string().superRefine((val, ctx) => {
                if (!validBeaconTypes.includes(val)) {
                    ctx.addIssue({
                        code: z.ZodIssueCode.custom,
                        message: t(
                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.invalidBeaconType',
                        ),
                        path: ['beaconType'],
                    });
                }
            }),
            password: z
                .string()
                .min(8, {
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.passwordMin',
                    ),
                })
                .max(63, {
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.passwordMax',
                    ),
                })
                .optional(),
        })
        .refine((schema) => !(!openBeaconTypes.includes(schema.beaconType) && schema.password === ''), {
            message: t(
                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.passwordRequired',
            ),
            path: ['password'],
        });

    const [fields, setFields] = useState<FormData>({
        password: previousPassword,
        beaconType: currentBeaconType ?? 'None',
    });
    const initialErrors = { password: '', beaconType: '' };
    const [errors, setErrors] = useState(initialErrors);

    function renderOptionsBeaconTypes() {
        return validBeaconTypes.map((beaconType) => {
            switch (beaconType) {
                case 'None':
                    return (
                        <Select.Option key={beaconType} value={beaconType}>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.none')}
                        </Select.Option>
                    );
                case 'WPAand11i':
                    return (
                        <Select.Option key={beaconType} value={beaconType}>
                            {t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.wpaWpa2',
                            )}
                        </Select.Option>
                    );
                case '11i':
                    return (
                        <Select.Option key={beaconType} value={beaconType}>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.wpa2')}
                        </Select.Option>
                    );
                default:
                    return (
                        <Select.Option key={beaconType} value={beaconType}>
                            {beaconType}
                        </Select.Option>
                    );
            }
        });
    }

    function verifyErrors(fields: Partial<FormData>) {
        type Key = keyof FormData;

        for (const key in fields) {
            if (fields[key as Key] === '') fields[key as Key] = undefined;
        }

        const result = schema.safeParse(fields);

        if (result.success) {
            setErrors(initialErrors);
            return true;
        }

        setErrors((previous) => {
            const zodErrors = result.error.flatten().fieldErrors;
            Object.keys(previous).forEach((key) => {
                initialErrors[key as Key] = zodErrors[key as Key]?.[0] ?? '';
            });
            return initialErrors;
        });
    }

    function handleOnChange(fields: FormData) {
        setFields(fields);
        const notContainsError = verifyErrors(fields);
        if (!openBeaconTypes.includes(fields.beaconType) && fields.password === '') {
            setDisabled(true);
            setErrors({
                beaconType: '',
                password: t(
                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.passwordRequired',
                ),
            });
        } else setDisabled(!notContainsError);
    }

    function handleOnSubmit(fields: FormData) {
        const notContainsError = verifyErrors(fields);
        if (!notContainsError) return;
        setLoading(true);
        request
            .patch(`/app/devices/${id}/wifi/${instance}`, {
                password: fields.beaconType !== 'None' ? fields.password : undefined,
                beaconType: fields.beaconType !== currentBeaconType ? fields.beaconType : undefined,
            })
            .then(() => {
                toast.info(
                    t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.tostSending'),
                );
                setDeviceOperation({
                    status: 'in progress',
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.sending',
                    ),
                });
                socket?.on('updateWifiParameter', (data: UpdateDataSocket) => {
                    setDeviceOperation({ status: data.status, message: data.message });

                    if (data.status === 'in progress') return;

                    if (data.status === 'success') {
                        toast.success(data.message);
                        refresh?.();
                    }

                    setButtonPasswordDisable(false);
                    socket.off('updateWifiParameter');

                    setTimeout(() => {
                        setDeviceOperation({
                            message: t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.message',
                            ),
                            status: 'default',
                        });
                    }, 5000);
                });
                setButtonPasswordDisable(true);
                onClose();
            })
            .catch((error) => {
                setButtonPasswordDisable(false);
                toast.error(error.response.data);
            });
    }
    return (
        <Modal size="small" active={active} onClose={onClose} closeOnClickOutside={disabled && !invalid}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.title')}
                </Modal.Title>
            </Modal.Header>
            <Form<FormData>
                onSubmit={handleOnSubmit}
                onChange={handleOnChange}
                initialValues={{
                    password: previousPassword,
                    beaconType: currentBeaconType ?? 'None',
                }}
            >
                <Modal.Body>
                    <Stack>
                        <Select
                            size="medium"
                            width="100%"
                            name="beaconType"
                            label={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.encryptionMode',
                            )}
                            initialValue={fields.beaconType}
                            textProps={{ variant: 'p', weight: 'regular', color: 'var(--gray-700)', size: 'xs' }}
                            helpText={errors.beaconType}
                            disabled={hasNotCapability.WiFi?.setBeaconType}
                            iconInfo={
                                hasNotCapability.WiFi?.setBeaconType
                                    ? {
                                        icon: <QuestionCircleLight size="14" color="var(--yellow-800)" />,
                                        text: t(
                                            'DevicesScreen.DeviceScreen.Connectivity.NatRedirection.ModalEditNatRedirection.parameterNotAcceptedByDevice',
                                        ),
                                    }
                                    : undefined
                            }
                        >
                            {renderOptionsBeaconTypes()}
                        </Select>
                        {!openBeaconTypes.includes(fields.beaconType) &&
                            <Input
                                type="password"
                                required={true}
                                width="100%"
                                label={t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.password',
                                )}
                                name="password"
                                invalid={Boolean(errors.password)}
                                disabled={loading || openBeaconTypes.includes(fields.beaconType)}
                                value={fields.password}
                                helpText={errors.password}
                            />
                        }
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={onClose} quiet>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" loading={loading} disabled={disabled}>
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                <SaveLight size="16" color="currentColor" />
                                {t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditPassword.save',
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
