import ActionButton from '@/components/ActionButton/ActionButton';
import Form from '@/components/Form/Form';
import Input from '@/components/Input/Input';
import Modal from '@/components/Modal/Modal';
import SelectGroup from '@/components/Select/SelectGroup';
import SelectOption from '@/components/Select/SelectOption';
import SelectRoot from '@/components/Select/SelectRoot';
import SingleFileDropzone from '@/components/SingleFileDropzone/SingleFileDropzone';
import Stack from '@/components/Stack/Stack';
import TagInput from '@/components/TagInput/TagInput';
import Text from '@/components/Text/Text';
import useFetch from '@/hooks/useFetch';
import { Fields } from '@vg-react/components/dist/components/Form/Form';
import { PlusLight } from '@vg-react/icons/light';
import { PenToSquareLight } from '@vg-react/icons/v6/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import request from '../../../../util/request';

interface CreateFileFields extends Fields {
    file_type: number;
    version: string;
    file: FileItem;
    tags: { id: number; name: string; selected: boolean }[];
}

type FileItem = {
    name: string;
    type: string;
    file: {
        path: string;
        name: string;
        size: number;
        type: string;
        lastModified: number;
        lastModifiedDate: Date;
        webkitRelativePath: string;
    };
};

interface ModalCreateFileProps {
    active: boolean;
    onClose: () => void;
    onRoleCreated?: () => void;
    file?: {
        fileType: number;
        version: string;
        tags: string[];
        size: string;
        name: string;
        id: string;
    };
}

interface InvalidFields {
    version: {
        state: boolean;
        message: string;
    };
    file_type: {
        state: boolean;
        message: string;
    };
    file: {
        state: boolean;
        message: string;
    };
}

interface TagInputHookProps {
    id: number;
    name: string;
}

export default function ModalCreateFile({ active, onClose, onRoleCreated, file }: ModalCreateFileProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const { data: suggestions } = useFetch<string[]>('/app/files/tags');
    const [firstSubmit, setFirstSubmit] = useState<boolean>(false);
    const [tags, setTags] = useState<string[]>(file?.tags ?? []);

    const [invalid, setInvalid] = useState<InvalidFields>({
        version: {
            state: false,
            message: '',
        },
        file_type: {
            state: false,
            message: '',
        },
        file: {
            state: false,
            message: '',
        },
    });

    const FileSchema = z.object({
        version: z.string().min(1, t('FilesScreen.ModalCreateFile.version')),
        file_type: z.string().min(1, t('FilesScreen.ModalCreateFile.fileType')),
        file: z.object({
            name: z
                .string()
                .refine(
                    (name: string) =>
                        ['npk', 'bin', 'cfg', 'zip', 'tar', 'conf', 'img', 'aes', 'xml', 'xls', 'xlsx', 'w'].includes(
                            name.split('.').pop() as string,
                        ),
                    t('FilesScreen.fileExtension'),
                ),
            type: z.string({ required_error: t('FilesScreen.ModalCreateFile.fileRequired') }),
        }),
    });

    const validate = (values: CreateFileFields) => {
        const parsing = FileSchema.safeParse(values);

        const newInvalid: InvalidFields = {
            version: {
                state: false,
                message: '',
            },
            file_type: {
                state: false,
                message: '',
            },
            file: {
                state: false,
                message: '',
            },
        };

        if (parsing.success === false) {
            parsing.error.errors.forEach((error) => {
                newInvalid[error.path[0] as keyof InvalidFields].state = true;
                newInvalid[error.path[0] as keyof InvalidFields].message = error.message;
            });

            setInvalid(newInvalid);
            return false;
        }

        setInvalid(newInvalid);
        return true;
    };

    const handleOnChange = (fields: CreateFileFields) => {
        if (firstSubmit) {
            validate(fields);
        }
    };
    const handleOnSubmit = (fields: CreateFileFields) => {
        if (!validate(fields)) {
            setFirstSubmit(true);
            return;
        }

        const formData = new FormData();
        formData.append('fileType', fields.file_type.toString());
        formData.append('version', fields.version);
        formData.append('tags', JSON.stringify(tags));
        formData.append('file', fields.file.file as unknown as Blob);
        setLoading(true);
        if (file) {
            request
                .patch(`/app/files/${file.id}`, formData)
                .then(() => {
                    toast.success('Arquivo editado com sucesso!');
                    setLoading(false);
                    onClose();
                })
                .catch((err) => {
                    toast.error(err.response.data);
                    setLoading(false);
                });
            return;
        }
        request
            .post('/app/files', formData)
            .then(() => {
                toast.success(t('FilesScreen.ModalCreateFile.fileCreated'));
                setLoading(false);
                onRoleCreated?.();
                onClose();
            })
            .catch((err) => {
                toast.error(err.response.data);
                setLoading(false);
            });
    };
    return (
        <Modal size="medium" active={active} onClose={onClose} closeOnClickOutside={false}>
            <Modal.Header generateCloseButton>
                <Text weight="bold" size="lg">
                    {file ? t('FilesScreen.ModalCreateFile.editFile') : t('FilesScreen.ModalCreateFile.newFile')}
                </Text>
            </Modal.Header>
            <Form<CreateFileFields>
                onSubmit={handleOnSubmit}
                onChange={handleOnChange}
                initialValues={{
                    version: file?.version,
                    file_type: file?.fileType.toString(),
                    file: { name: file?.name ?? '', type: file?.fileType.toString() ?? '', file },
                }}
            >
                <Modal.Body>
                    <Stack spacing="small">
                        <Input
                            required
                            width="100%"
                            label={t('FilesScreen.ModalCreateFile.version')}
                            name="version"
                            inputSize="medium"
                            invalid={invalid.version.state}
                            helpText={invalid.version.message}
                        />
                        <SelectRoot
                            required
                            width="100%"
                            size="medium"
                            name="file_type"
                            label={t('FilesScreen.ModalCreateFile.fileType')}
                            value={file?.fileType}
                            invalid={invalid.file_type.state}
                            helpText={invalid.file_type.message}
                            textProps={{
                                size: 'xs',
                                variant: 'p',
                                weight: 'regular',
                                color: 'var(--gray-700)',
                            }}
                        >
                            <SelectGroup groupName={t('FilesScreen.ModalCreateFile.selectGroup')}>
                                <SelectOption key={1} value={'1'}>
                                    {t('FilesScreen.firmwareUpgradeImage')}
                                </SelectOption>
                                <SelectOption key={3} value={'3'}>
                                    {t('FilesScreen.vendorConfigurationFile')}
                                </SelectOption>
                            </SelectGroup>
                        </SelectRoot>
                        <TagInput
                            label={t('FilesScreen.ModalCreateFile.tags')}
                            tags={tags}
                            setTags={setTags}
                            suggestions={suggestions ?? []}
                        />
                        <SingleFileDropzone
                            required
                            editFile
                            name="file"
                            width="100%"
                            label={t('FilesScreen.ModalCreateFile.file')}
                            disabled={loading}
                            invalid={invalid.file.state}
                            helpText={t('FilesScreen.fileExtension')}
                        />
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton
                            quiet
                            width="small"
                            onClick={onClose}
                            style={{
                                fontSize: 'var(--font-size-xs)',
                                background: 'transparent',
                                color: 'var(--gray-700)',
                                border: 'transparent',
                            }}
                        >
                            {t('FilesScreen.ModalCreateFile.cancel')}
                        </ActionButton>
                        <ActionButton
                            type="submit"
                            loading={loading}
                            style={{
                                fontSize: 'var(--font-size-xs)',
                            }}
                        >
                            {file ? (
                                <>
                                    <PenToSquareLight size="14" color="currentColor" />
                                    {t('FilesScreen.ModalCreateFile.editFile')}
                                </>
                            ) : (
                                <>
                                    <PlusLight size="16" color="currentColor" />
                                    {t('FilesScreen.ModalCreateFile.createFile')}
                                </>
                            )}
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
