import useSocket from '@/hooks/useSocket';
import { formatDate } from '@/util/format';
import { ProgressBar, Spinner, Stack, Text, Tooltip } from '@vg-react/components';
import { TimesRegular } from '@vg-react/icons/regular';
import { ExclamationTriangleSolid } from '@vg-react/icons/solid';
import { LocationPinLight, PipeLight } from '@vg-react/icons/v6/light';
import { TimerRegular } from '@vg-react/icons/v6/regular';
import { CubeSolid } from '@vg-react/icons/v6/solid';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { useParams } from 'react-router-dom';
import styles from '../index/Latency.module.scss';

export type PingResult = {
    id: number;
    name: string;
    diagnosticState:
        | 'pending'
        | 'failed'
        | 'Complete'
        | 'Error_CannotResolveHostName'
        | 'Error_Internal'
        | 'Error_Other';
    averageResponseTime: string;
    failureCount: string;
    successCount: string;
    host: string;
    lossPercentage: string;
    maximumResponseTime: string;
    minimumResponseTime: string;
};

const Ping = ({
    state,
    isFetching,
    timestamp,
    isPingRunning,
}: {
    state: {
        data: {
            destinations: PingResult[];
            repetitions: number;
            timeout: number;
            timestamp: Date;
        };
        state: 'not_started_yet' | 'pending' | 'failed' | 'success';
    } | null;
    isFetching: boolean;
    timestamp?: Date;
    isPingRunning?: (value: boolean) => void;
    pingRefresh?: () => void;
}) => {
    const { t } = useTranslation();
    const { socket } = useSocket();
    const { id } = useParams();
    let testExecutedAt = '';

    const [defaultMessage, setDefaultMessage] = useState<string>(
        t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.resultTest', { testExecutedAt }),
    );
    const [results, setResults] = useState<PingResult[]>(state?.data?.destinations || []);
    const [isRunning, setIsRunning] = useState<boolean>(
        state?.state === 'pending' || state?.state === 'not_started_yet',
    );

    useEffect(() => {
        if (state?.data?.destinations) {
            setResults(state.data.destinations);
        }

        if (state?.state === 'pending' || state?.state === 'not_started_yet') {
            setIsRunning(true);
        }

        if (timestamp) {
            const timeAgo = (new Date().getTime() - new Date(timestamp).getTime()) / 1000;
            testExecutedAt =
                ' - ' +
                t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.executedAt', {
                    time: formatDate(timeAgo),
                });

            setDefaultMessage(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.resultTest', { testExecutedAt }));
        }
    }, [state]);

    useEffect(() => {
        isPingRunning?.(isRunning);
        if (isRunning) {
            setDefaultMessage(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.inProgress'));
        }
    }, [isRunning]);

    useEffect(() => {
        socket?.on(`${id}_diagnostics:ping:values`, (data: PingResult) => {
            setResults((prev) => {
                const newResults = [...prev];
                const index = newResults.findIndex((result) => result.host === data.host);
                if (index !== -1) {
                    newResults[index] = data;
                }
                return newResults;
            });
        });
        socket?.on(`${id}_diagnostics:ping:ended`, (data: PingResult) => {
            setDefaultMessage(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.resultTest', { testExecutedAt }));
            setIsRunning(false);
        });

        socket?.on(`${id}_diagnostics:ping:detailed_info`, (data: { serialNumber: string; message: string }) => {
            setDefaultMessage(data.message);
        });

        return () => {
            socket?.off(`${id}_diagnostics:ping:values`);
            socket?.off(`${id}_diagnostics:ping:detailed_info`);
            socket?.off(`${id}_diagnostics:ping:ended`);
        };
    }, [socket]);

    return (
        <Stack className={styles.resultContainer} justify="start" align="center" spacing="var(--spacing-100)">
            <Stack
                style={{ width: '100%' }}
                direction="row"
                align="center"
                justify="space-between"
                spacing="var(--spacing-100)"
            >
                <Stack direction="row" align="center" justify="space-between" spacing="var(--spacing-50)">
                    {isRunning ? (
                        <Spinner size="large" color="var(--accent-900)" />
                    ) : (
                        <LocationPinLight size="30" color="var(--accent-900)" />
                    )}

                    <Stack spacing="0">
                        <Text size="md" weight="bold" color="var(--lightness-300)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.title')}
                        </Text>
                        <Text size="xs" weight="regular" color="var(--lightness-600)">
                            {defaultMessage}
                        </Text>
                    </Stack>
                </Stack>

                <Stack
                    direction="row"
                    align="center"
                    justify="space-between"
                    spacing="var(--spacing-300)"
                    style={{
                        borderRadius: 'var(--radius-small)',
                        border: '1px solid var(--lightness-900)',
                        padding: 'var(--spacing-100)',
                        paddingInline: 'var(--spacing-300)',
                    }}
                >
                    {isFetching ? (
                        <Skeleton width={200} height={16} />
                    ) : (
                        <>
                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-75)">
                                <CubeSolid size="12" color="var(--lightness-300)" />
                                <Text size="xxs" weight="bold" color="var(--lightness-400)">
                                    {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.packetsSent', {
                                        count: state?.data?.repetitions,
                                    })}
                                </Text>
                            </Stack>

                            <Stack direction="row" align="center" justify="center" spacing="var(--spacing-75)">
                                <TimerRegular size="12" color="var(--lightness-300)" />
                                <Text size="xxs" weight="bold" color="var(--lightness-400)">
                                    {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.timeout', {
                                        time: state?.data?.timeout,
                                    })}
                                </Text>
                            </Stack>
                        </>
                    )}
                </Stack>
            </Stack>

            <Stack direction="column" className={styles.internalContainer} spacing="var(--spacing-200)">
                {results.map((result, index) => (
                    <IndividualPingResult
                        key={index}
                        host={result.host}
                        state={result.diagnosticState || ''}
                        loss={Number(result.lossPercentage)}
                        latency={{
                            min: result.minimumResponseTime,
                            med: result.averageResponseTime,
                            max: result.maximumResponseTime,
                        }}
                    />
                ))}
            </Stack>
        </Stack>
    );
};

export const IndividualPingResult = ({
    host,
    loss,
    latency,
    state,
}: {
    host: string;
    loss: number;
    state: 'pending' | 'failed' | 'Complete' | 'Error_CannotResolveHostName' | 'Error_Internal' | 'Error_Other';
    latency: {
        min: string;
        med: string;
        max: string;
    };
}) => {
    const { t } = useTranslation();
    const src = `https://www.google.com/s2/favicons?domain=${host}&sz=20`;
    let parsedMin: number | string = Math.round(Number(latency.min)) + ' ms';
    let parsedMed: number | string = Math.round(Number(latency.med)) + ' ms';
    let parsedMax: number | string = Math.round(Number(latency.max)) + ' ms';

    if (isNaN(Number(latency.min)) || state.startsWith('Error') || state === 'failed') parsedMin = '--';
    if (isNaN(Number(latency.med)) || state.startsWith('Error') || state === 'failed') parsedMed = '--';
    if (isNaN(Number(latency.max)) || state.startsWith('Error') || state === 'failed') parsedMax = '--';

    return (
        <div className={styles.pingResult}>
            <div className={styles.pingResultContainer}>
                <Tooltip>
                    <Tooltip.Trigger>
                        <Stack
                            direction="column"
                            align="center"
                            justify="center"
                            spacing="var(--spacing-100)"
                            style={{ gridArea: 'progressBar' }}
                        >
                            <Text weight="bold" color="var(--lightness-600)">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.connectionQuality')}
                            </Text>
                            {state.startsWith('Complete') || state === 'failed' || state.startsWith('Error') ? (
                                <Stack direction="row" style={{ position: 'relative', width: '100%' }}>
                                    <ProgressBar
                                        height="5px"
                                        progress={invertPercentage(loss)}
                                        indicatorColor={
                                            state.startsWith('Error') || state === 'failed'
                                                ? 'var(--red-600)'
                                                : getBarColor(invertPercentage(loss))
                                        }
                                        trackColor="var(--lightness-900)"
                                        completeColor={
                                            state.startsWith('Error') || state === 'failed'
                                                ? 'var(--red-600)'
                                                : '#83b877'
                                        }
                                    />
                                    {loss > 99 && (
                                        <ExclamationTriangleSolid
                                            style={{ position: 'absolute', top: -20, right: -32 }}
                                            size="16"
                                            color="var(--red-700)"
                                        />
                                    )}
                                    {(state === 'failed' || state.startsWith('Error')) && (
                                        <TimesRegular
                                            style={{ position: 'absolute', top: -20, right: -32 }}
                                            size="16"
                                            color="var(--red-700)"
                                        />
                                    )}
                                </Stack>
                            ) : (
                                <Skeleton width={142} height={8} containerClassName={styles.spanClassnameBar} inline />
                            )}
                        </Stack>
                    </Tooltip.Trigger>
                    <Tooltip.Content>
                        {state.startsWith('Complete')
                            ? t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.connectionQualityTooltip', {
                                  loss: loss + '%',
                              })
                            : state === 'failed' || state.startsWith('Error')
                            ? t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.connectionQualityTooltipRejected')
                            : t(
                                  'DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.connectionQualityTooltipInProgress',
                              )}
                    </Tooltip.Content>
                </Tooltip>

                <Stack
                    direction="row"
                    align="center"
                    justify="center"
                    spacing="var(--spacing-50)"
                    style={{ position: 'relative', gridArea: 'host' }}
                >
                    <Stack direction="row" align="center" justify="center">
                        <img src={src} style={{ width: '20px', height: '20px' }} />
                    </Stack>
                    <div>
                        <Text weight="regular" size="md">
                            {host}
                        </Text>
                    </div>
                </Stack>
                <Stack
                    direction="row"
                    align="center"
                    justify="center"
                    spacing="var(--spacing-100)"
                    style={{ gridArea: 'latency' }}
                >
                    <Stack direction="column" align="center" justify="center" spacing="0">
                        <Text color="var(--lightness-400)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.min')}
                        </Text>
                        {state.startsWith('Complete') || state.startsWith('Error') || state === 'failed' ? (
                            <Text weight="regular" color="var(--lightness-100)">
                                {parsedMin}
                            </Text>
                        ) : (
                            <Skeleton width={30} containerClassName={styles.spanClassnameLatency} height={14} />
                        )}
                    </Stack>
                    <PipeLight size="24" color="var(--lightness-900)" />
                    <Stack direction="column" align="center" justify="center" spacing="0">
                        <Text color="var(--lightness-400)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.avg')}
                        </Text>
                        {state.startsWith('Complete') || state.startsWith('Error') || state === 'failed' ? (
                            <Text weight="bold">{parsedMed}</Text>
                        ) : (
                            <Skeleton width={30} containerClassName={styles.spanClassnameLatency} height={14} />
                        )}
                    </Stack>
                    <PipeLight size="24" color="var(--lightness-900)" />
                    <Stack direction="column" align="center" justify="center" spacing="0">
                        <Text color="var(--lightness-400)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Ping.max')}
                        </Text>
                        {state.startsWith('Complete') || state.startsWith('Error') || state === 'failed' ? (
                            <Text weight="regular" color="var(--lightness-100)">
                                {parsedMax}
                            </Text>
                        ) : (
                            <Skeleton width={30} containerClassName={styles.spanClassnameLatency} height={14} />
                        )}
                    </Stack>
                </Stack>
            </div>
        </div>
    );
};

const invertPercentage = (percentage: number) => {
    return 100 - percentage;
};

function getBarColor(value: number | undefined) {
    if (value === undefined) return 'var(--green-700)';
    if (value > 99) return 'var(--green-700)';
    if (value > 94) return 'var(--yellow-700)';
    return 'var(--red-700)';
}

export default Ping;
