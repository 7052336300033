/**
 *
 * @param fn function to be debounced
 * @param ms time of debounce in ms
 * @returns debounced instance of function
 */
export default function debounce<T extends (...args: any[]) => any>(fn: T, ms = 300) {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
}
