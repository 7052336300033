import { lazy, ReactNode, Suspense, useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Outlet, Routes as ReactRoutes, Route, useLocation } from 'react-router-dom';
import getCookies from '../util/getCookies';
import AnimatedRoutes from './components/AnimatedRoutes';

const IndexScreen = lazy(() => import('../screens/Index/Index.screen'));
const HomeScreen = lazy(() => import('../screens/Home/index/Home.screen'));
const UsersScreen = lazy(() => import('../screens/Users/index/Users.screen'));
const RolesScreen = lazy(() => import('../screens/Roles/index/Roles.screen'));
const ToolsScreen = lazy(() => import('../screens/Tools/index/Tools.screen'));
const RoleScreen = lazy(() => import('../screens/Roles/[id]/index/Role.screen'));
const UserScreen = lazy(() => import('../screens/Users/[id]/index/User.screen'));
const SystemScreen = lazy(() => import('../screens/System/index/System.screen'));
const LogsScreen = lazy(() => import('../screens/System/Logs/index/Logs.screen'));
const DevicesScreen = lazy(() => import('../screens/Devices/index/Devices.screen'));
const DeviceScreen = lazy(() => import('../screens/Devices/[id]/index/Device.screen'));
const UpdateScreen = lazy(() => import('../screens/System/Update/index/Update.screen'));
const FilesScreen = lazy(() => import('../screens/Devices/[id]/Files/index/Files.screen'));
const BackupsScreen = lazy(() => import('../screens/System/Backups/index/Backups.screen'));
const SystemFilesScreen = lazy(() => import('../screens/System/Files/index/Files.screen'));
const ConfigScreen = lazy(() => import('../screens/System/Settings/index/Settings.screen'));
const SystemFileScreen = lazy(() => import('../screens/System/Files/[id]/index/File.screen'));
const DeviceLogsScreen = lazy(() => import('../screens/Devices/[id]/Logs/index/Logs.screen'));
const IPScreen = lazy(() => import('@/screens/Devices/[id]/Connectivity/Ethernet/IP/IP.screen'));
const SummaryScreen = lazy(() => import('../screens/Devices/[id]/Summary/index/Summary.screen'));
const ApiClientsScreen = lazy(() => import('../screens/Tools/ApiClients/index/ApiClients.screen'));
const TreeViewScreen = lazy(() => import('../screens/Devices/[id]/TreeView/index/TreeView.screen'));
const PingScreen = lazy(() => import('../screens/Devices/[id]/Diagnostics/Ping/index/Ping.screen'));
const WifiScreen = lazy(() => import('../screens/Devices/[id]/Connectivity/Wifi/index/Wifi.screen'));
const LanScreen = lazy(() => import('../screens/Devices/[id]/Connectivity/Lan/index/Lan.screen'));
const ApiClientScreen = lazy(() => import('../screens/Tools/ApiClients/[id]/index/ApiClient.screen'));
const HostsScreen = lazy(() => import('../screens/Devices/[id]/Connectivity/Hosts/index/Hosts.screen'));
const PPPoEScreen = lazy(() => import('@/screens/Devices/[id]/Connectivity/Ethernet/PPPoE/PPPoE.screen'));
const SystemStatisticsScreen = lazy(() => import('../screens/System/Statistics/index/Statistics.screen'));
const AutoProvisionScreen = lazy(() => import('../screens/Tools/AutoProvision/index/AutoProvision.screen'));
const ConfigServer = lazy(() => import('../screens/System/Settings/index/components/Server/Server.screen'));
const ConfigClient = lazy(() => import('../screens/System/Settings/index/components/Client/Client.screen'));
const DiagnosticsScreen = lazy(() => import('../screens/Devices/[id]/Diagnostics/index/Diagnostics.screen'));
const BackupRoutineScreen = lazy(() => import('../screens/System/Backups/Routines/[id]/index/Routine.screen'));
const RolePermissionsScreen = lazy(() => import('../screens/Roles/[id]/index/Permissions/Permissions.screen'));
const ConnectivityScreen = lazy(() => import('../screens/Devices/[id]/Connectivity/index/Connectivity.screen'));
const FirmwareMassUpdate = lazy(() => import('../screens/Tools/MassChange/Firmware/FirmwareMassUpdate.screen'));
const ConfigSecurity = lazy(() => import('../screens/System/Settings/index/components/Security/Security.screen'));
const ConfigNetwork = lazy(() => import('../screens/System/Settings/index/components/Network/Network.screen'));
const ResultScreen = lazy(() => import('../screens/Devices/[id]/Diagnostics/NeighborTest/Scanned/Result.screen'));
const SpeedTestScreen = lazy(() => import('../screens/Devices/[id]/Diagnostics/SpeedTest/index/SpeedTest.screen'));
const TracerouteScreen = lazy(() => import('../screens/Devices/[id]/Diagnostics/Traceroute/index/Traceroute.screen'));
const AuthenticationScreen = lazy(() => import('../screens/Devices/[id]/Connectivity/Ethernet/index/Ethernet.screen'));
const BackupIntegrationFilesScreen = lazy(() => import('../screens/System/Backups/Integrations/[id]/List/List.screen'));
const AutoConfigurationDevice = lazy(
    () => import('../screens/Tools/AutoConfigurationDevice/AutoConfigurationDevice.screen'),
);
const ApiClientPermissions = lazy(
    () => import('../screens/Tools/ApiClients/[id]/index/Permissions/Permissions.screen'),
);
const BackupIntegrationScreen = lazy(
    () => import('../screens/System/Backups/Integrations/[id]/index/Integration.screen'),
);
const NeighbouringScreen = lazy(
    () => import('../screens/Devices/[id]/Diagnostics/NeighborTest/index/NeighborTest.screen'),
);
const NatRedirection = lazy(
    () => import('../screens/Devices/[id]/Connectivity/NatRedirection/index/NatRedirection.screen'),
);

import useCapability from '@/atoms/capabilityAtom';
import { AutoConfigurationDeviceProvider } from '@/contexts/AutoConfigurationDevice/AutoConfigurationDeviceContext';
import usePermissions from '@/hooks/usePermissions';
import TopologyScreen from '@/screens/Devices/[id]/Connectivity/Topology/index/Topology.screen';
import WebManagementScreen from '@/screens/Devices/[id]/Connectivity/WebManagement/index/WebManagement.screen';
import HistoryScreen from '@/screens/Devices/[id]/Diagnostics/Latency/index/History.screen';
import LatencyScreen from '@/screens/Devices/[id]/Diagnostics/Latency/index/Latency.screen';
import ResultsScreen from '@/screens/Devices/[id]/Diagnostics/Latency/index/Results.screen';
import { SpeedTestResult } from '@/screens/Devices/[id]/Diagnostics/SpeedTest/index/SpeedTestResult';
import IntegrationsScreen from '@/screens/Tools/Integrations/Integrations.screen';
import NewFirmwareUpdate from '@/screens/Tools/MassChange/Firmware/NewFirmwareUpdate/NewFirmwareUpdate.screen';
import ErrorIndicator from '../components/ErrorHandling/ErrorIndicator';
import { SocketProvider } from '../contexts/Socket/SocketContext';
import { FrequencyScreen } from '../screens/Devices/[id]/Connectivity/Wifi/components/[frequency]/index/Frequency.screen';
import SpeedTestHistoryScreen from '../screens/Devices/[id]/Diagnostics/SpeedTest/SpeedTestHistory/index/SpeedTestHistory.screen';
import { NavigateTemp } from './NavigateTemp';

function HandleError({ children, sugestion }: { children: ReactNode; sugestion?: string }) {
    function onError() {
        //TODO: Implement back-end telemetry sending
        // eslint-disable-next-line prefer-rest-params
        const [{ message }, { componentStack }] = arguments;
        console.error('Error', message);
    }

    return (
        <ErrorBoundary onError={onError} fallback={<ErrorIndicator sugestion={sugestion} />}>
            {children}
        </ErrorBoundary>
    );
}

function SlidingPages() {
    const tryUpdateDevice = 'Tente sincronizar os dados do dispositivo e aguardar alguns instantes.';

    const {
        validateDevicePermissions,
        validateDashboardPermissions,
        validateSystemPermissions,
        validatePermission,
        validateToolsPermissions,
    } = usePermissions();
    const userPermissions = validatePermission('/app/users/', 'get');
    const rolesPermissions = validatePermission('/app/roles/', 'get');
    const devicePermissions = validateDevicePermissions();
    const systemPermissions = validateSystemPermissions();
    const toolsPermissions = validateToolsPermissions();
    const [hasNotCapability] = useCapability();

    return (
        <AnimatedRoutes>
            <Route index element={<Navigate to="/home" />} />
            <Route path="dashboard" element={<Navigate to="/home" />} />
            <Route
                path="home"
                element={
                    validateDashboardPermissions().hasAllPermissions ? (
                        <Suspense>
                            <HomeScreen />
                        </Suspense>
                    ) : (
                        <Navigate to="/devices" />
                    )
                }
            />
            <Route path="devices/*" element={<Outlet />}>
                <Route
                    path="*"
                    element={
                        <AnimatedRoutes>
                            <Route
                                index
                                element={
                                    devicePermissions.allDevices ? (
                                        <Suspense>
                                            <DevicesScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/system" />
                                    )
                                }
                            />
                            <Route
                                path=":id/*"
                                element={
                                    <Suspense>
                                        <DeviceScreen />
                                    </Suspense>
                                }
                            >
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    devicePermissions.resources ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <SummaryScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="connectivity/*"
                                                element={
                                                    devicePermissions.resources ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <ConnectivityScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            >
                                                <Route
                                                    path="*"
                                                    element={
                                                        <AnimatedRoutes>
                                                            <Route
                                                                path="internet"
                                                                element={
                                                                    <Suspense>
                                                                        <AuthenticationScreen />
                                                                    </Suspense>
                                                                }
                                                            >
                                                                <Route
                                                                    path="*"
                                                                    element={
                                                                        <AnimatedRoutes>
                                                                            <Route
                                                                                index
                                                                                path="ppp"
                                                                                element={
                                                                                    devicePermissions.resources ? (
                                                                                        <PPPoEScreen />
                                                                                    ) : (
                                                                                        <Navigate
                                                                                            to={
                                                                                                devicePermissions.navigate
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="ip"
                                                                                element={
                                                                                    devicePermissions.resources ? (
                                                                                        <IPScreen />
                                                                                    ) : (
                                                                                        <Navigate
                                                                                            to={
                                                                                                devicePermissions.navigate
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                        </AnimatedRoutes>
                                                                    }
                                                                />
                                                            </Route>
                                                            <Route
                                                                path="/"
                                                                element={
                                                                    <Navigate to="internet/ppp" /> /* this route was put below `internet` because of animation errors */
                                                                }
                                                            />
                                                            <Route
                                                                path="lan"
                                                                element={
                                                                    devicePermissions.resources ? (
                                                                        <Suspense>
                                                                            <LanScreen />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="wifi"
                                                                element={
                                                                    <Suspense>
                                                                        <WifiScreen />
                                                                    </Suspense>
                                                                }
                                                            >
                                                                <Route
                                                                    path="*"
                                                                    element={
                                                                        <AnimatedRoutes>
                                                                            <Route
                                                                                index
                                                                                path="two_ghz"
                                                                                element={
                                                                                    devicePermissions.resources ? (
                                                                                        <FrequencyScreen frequency="two_ghz" />
                                                                                    ) : (
                                                                                        <Navigate
                                                                                            to={
                                                                                                devicePermissions.navigate
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="five_ghz"
                                                                                element={
                                                                                    devicePermissions.resources ? (
                                                                                        <FrequencyScreen frequency="five_ghz" />
                                                                                    ) : (
                                                                                        <Navigate
                                                                                            to={
                                                                                                devicePermissions.navigate
                                                                                            }
                                                                                        />
                                                                                    )
                                                                                }
                                                                            />
                                                                        </AnimatedRoutes>
                                                                    }
                                                                />
                                                            </Route>
                                                            <Route
                                                                path="hosts/:mac?"
                                                                element={
                                                                    devicePermissions.resources ? (
                                                                        <Suspense>
                                                                            <HostsScreen />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="natRedirection"
                                                                element={
                                                                    devicePermissions.resources ? (
                                                                        <Suspense>
                                                                            <NatRedirection />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="topology"
                                                                element={
                                                                    devicePermissions.resources ? (
                                                                        <Suspense>
                                                                            <TopologyScreen />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="webManagement"
                                                                element={
                                                                    devicePermissions.resources ? (
                                                                        <Suspense>
                                                                            <WebManagementScreen />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                        </AnimatedRoutes>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path="diagnostics/*"
                                                element={
                                                    devicePermissions.diagnostics ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <DiagnosticsScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            >
                                                <Route
                                                    path="*"
                                                    element={
                                                        <AnimatedRoutes>
                                                            <Route index element={<></>} />
                                                            <Route
                                                                path="latency/*"
                                                                element={
                                                                    devicePermissions.diagnostics ? (
                                                                        <AnimatedRoutes>
                                                                            <Route
                                                                                index
                                                                                element={
                                                                                    <Suspense>
                                                                                        <LatencyScreen />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="results"
                                                                                element={
                                                                                    <Suspense>
                                                                                        <ResultsScreen />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="history"
                                                                                element={
                                                                                    <Suspense>
                                                                                        <HistoryScreen />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                        </AnimatedRoutes>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="ping/*"
                                                                element={
                                                                    devicePermissions.diagnostics ? (
                                                                        <Suspense>
                                                                            <PingScreen />
                                                                        </Suspense>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                            <Route
                                                                path="traceroute/*"
                                                                element={
                                                                    <Suspense>
                                                                        <TracerouteScreen />
                                                                    </Suspense>
                                                                }
                                                            />
                                                            <Route
                                                                path="neighborTest/*"
                                                                element={
                                                                    <AnimatedRoutes>
                                                                        <Route
                                                                            index
                                                                            element={
                                                                                <Suspense>
                                                                                    <NeighbouringScreen />
                                                                                </Suspense>
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="chart"
                                                                            element={
                                                                                <Suspense>
                                                                                    <ResultScreen />
                                                                                </Suspense>
                                                                            }
                                                                        />
                                                                    </AnimatedRoutes>
                                                                }
                                                            />
                                                            <Route
                                                                path="speedTest/*"
                                                                element={
                                                                    devicePermissions.diagnostics ? (
                                                                        <AnimatedRoutes>
                                                                            <Route
                                                                                index
                                                                                element={
                                                                                    <Suspense>
                                                                                        <SpeedTestScreen />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="result"
                                                                                element={
                                                                                    <Suspense>
                                                                                        <SpeedTestResult />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                            <Route
                                                                                path="history"
                                                                                element={
                                                                                    <Suspense>
                                                                                        <SpeedTestHistoryScreen />
                                                                                    </Suspense>
                                                                                }
                                                                            />
                                                                        </AnimatedRoutes>
                                                                    ) : (
                                                                        <Navigate to={devicePermissions.navigate} />
                                                                    )
                                                                }
                                                            />
                                                        </AnimatedRoutes>
                                                    }
                                                />
                                            </Route>
                                            <Route
                                                path="logs"
                                                element={
                                                    devicePermissions.allDevices ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <DeviceLogsScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="files"
                                                element={
                                                    devicePermissions.files &&
                                                    hasNotCapability.FirmwareUpdate !== true ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <FilesScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="properties"
                                                element={
                                                    devicePermissions.resources ? (
                                                        <Suspense>
                                                            <HandleError sugestion={tryUpdateDevice}>
                                                                <TreeViewScreen />
                                                            </HandleError>
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to={devicePermissions.navigate} />
                                                    )
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>
                        </AnimatedRoutes>
                    }
                />
            </Route>
            <Route path="tools/*" element={<Outlet />}>
                <Route
                    path="*"
                    element={
                        <AnimatedRoutes>
                            <Route
                                index
                                element={
                                    <Suspense>
                                        <ToolsScreen />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="autoconfiguration"
                                element={
                                    toolsPermissions.autoconfiguration ? (
                                        <Suspense>
                                            <AutoConfigurationDeviceProvider>
                                                <AutoConfigurationDevice />
                                            </AutoConfigurationDeviceProvider>
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/tools" />
                                    )
                                }
                            />
                            <Route
                                path="mass_change/"
                                element={
                                    <Suspense>
                                        <Navigate to="firmware" />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="mass_change/firmware/*"
                                element={
                                    <AnimatedRoutes>
                                        <Route
                                            index
                                            element={
                                                toolsPermissions.massChange ? (
                                                    <Suspense>
                                                        <FirmwareMassUpdate />
                                                    </Suspense>
                                                ) : (
                                                    <Navigate to="/tools" />
                                                )
                                            }
                                        />
                                        <Route
                                            path=":firmwareId?"
                                            element={
                                                toolsPermissions.massChange ? (
                                                    <Suspense>
                                                        <FirmwareMassUpdate />
                                                    </Suspense>
                                                ) : (
                                                    <Navigate to="/tools" />
                                                )
                                            }
                                        />
                                        <Route
                                            path="new_update"
                                            element={
                                                toolsPermissions.massChange ? (
                                                    <Suspense>
                                                        <NewFirmwareUpdate />
                                                    </Suspense>
                                                ) : (
                                                    <Navigate to="/tools" />
                                                )
                                            }
                                        />
                                    </AnimatedRoutes>
                                }
                            />
                            <Route
                                path="autoprovision/"
                                element={
                                    toolsPermissions.autoprovision ? (
                                        <Suspense>
                                            <AutoProvisionScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/system" />
                                    )
                                }
                            />
                            <Route path="api_clients/*" element={<Outlet />}>
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    toolsPermissions.apiClient ? (
                                                        <Suspense>
                                                            <ApiClientsScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    toolsPermissions.apiClient ? (
                                                        <Suspense>
                                                            <ApiClientScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="/:id/permissions"
                                                element={
                                                    toolsPermissions.apiClient ? (
                                                        <Suspense>
                                                            <ApiClientPermissions />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>
                            <Route path="integrations/*" element={<Outlet />}>
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    toolsPermissions.integrations ? (
                                                        <Suspense>
                                                            <IntegrationsScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    toolsPermissions.integrations ? (
                                                        <Suspense>
                                                            <IntegrationsScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>
                        </AnimatedRoutes>
                    }
                />
            </Route>

            <Route path="users/*" element={<Outlet />}>
                <Route
                    path="*"
                    element={
                        <AnimatedRoutes>
                            <Route
                                index
                                element={
                                    userPermissions ? (
                                        <Suspense>
                                            <UsersScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/home" />
                                    )
                                }
                            />
                            <Route
                                path=":id"
                                element={
                                    userPermissions ? (
                                        <Suspense>
                                            <UserScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/home" />
                                    )
                                }
                            />
                        </AnimatedRoutes>
                    }
                />
            </Route>
            <Route
                path="/self"
                element={
                    <Suspense>
                        <UserScreen />
                    </Suspense>
                }
            />
            <Route path="roles/*" element={<Outlet />}>
                <Route
                    path="*"
                    element={
                        <AnimatedRoutes>
                            <Route
                                index
                                element={
                                    rolesPermissions ? (
                                        <Suspense>
                                            <RolesScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/home" />
                                    )
                                }
                            />
                            <Route
                                path="/:id"
                                element={
                                    rolesPermissions ? (
                                        <Suspense>
                                            <RoleScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/home" />
                                    )
                                }
                            />
                            <Route
                                path="/:id/permissions"
                                element={
                                    rolesPermissions ? (
                                        <Suspense>
                                            <RolePermissionsScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/home" />
                                    )
                                }
                            />
                        </AnimatedRoutes>
                    }
                />
            </Route>
            <Route path="system/*" element={<Outlet />}>
                <Route
                    path="*"
                    element={
                        <AnimatedRoutes>
                            <Route
                                index
                                element={
                                    <Suspense>
                                        <SystemScreen />
                                    </Suspense>
                                }
                            />

                            <Route path="backups/*" element={<Outlet />}>
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    systemPermissions.backup ? (
                                                        <Suspense>
                                                            <BackupsScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="integrations"
                                                element={
                                                    systemPermissions.backup ? (
                                                        <NavigateTemp to="/system/backups" />
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="integrations/:id"
                                                element={
                                                    systemPermissions.backup ? (
                                                        <Suspense>
                                                            <BackupIntegrationScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="integrations/:id/files"
                                                element={
                                                    systemPermissions.backup ? (
                                                        <Suspense>
                                                            <BackupIntegrationFilesScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="routines"
                                                element={
                                                    systemPermissions.backup ? (
                                                        <NavigateTemp to="/system/backups" />
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path="routines/:id"
                                                element={
                                                    systemPermissions.backup ? (
                                                        <Suspense>
                                                            <BackupRoutineScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <NavigateTemp to="/system" />
                                                    )
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>
                            <Route path="files/*" element={<Outlet />}>
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    systemPermissions.files ? (
                                                        <Suspense>
                                                            <SystemFilesScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                            <Route
                                                path=":id"
                                                element={
                                                    systemPermissions.files ? (
                                                        <Suspense>
                                                            <SystemFileScreen />
                                                        </Suspense>
                                                    ) : (
                                                        <Navigate to="/system" />
                                                    )
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>

                            <Route
                                path="logs"
                                element={
                                    <Suspense>
                                        <LogsScreen />
                                    </Suspense>
                                }
                            />
                            <Route
                                path="settings/*"
                                element={
                                    <Suspense>
                                        <ConfigScreen />
                                    </Suspense>
                                }
                            >
                                <Route
                                    path="*"
                                    element={
                                        <AnimatedRoutes>
                                            <Route
                                                index
                                                element={
                                                    <Suspense>
                                                        <ConfigClient />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="Server"
                                                element={
                                                    <Suspense>
                                                        <ConfigServer />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="Security"
                                                element={
                                                    <Suspense>
                                                        <ConfigSecurity />
                                                    </Suspense>
                                                }
                                            />
                                            <Route
                                                path="Network"
                                                element={
                                                    <Suspense>
                                                        <ConfigNetwork />
                                                    </Suspense>
                                                }
                                            />
                                        </AnimatedRoutes>
                                    }
                                />
                            </Route>
                            <Route
                                path="update"
                                element={
                                    systemPermissions.update ? (
                                        <Suspense>
                                            <UpdateScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/system" />
                                    )
                                }
                            />
                            <Route
                                path="statistics"
                                element={
                                    systemPermissions.serverStats ? (
                                        <Suspense>
                                            <SystemStatisticsScreen />
                                        </Suspense>
                                    ) : (
                                        <Navigate to="/system" />
                                    )
                                }
                            />
                        </AnimatedRoutes>
                    }
                />
            </Route>
        </AnimatedRoutes>
    );
}

export default function ProtectedRoute() {
    const cookies = getCookies();
    const previousLocation = useLocation();
    // const [tracker] = useAtom(trackerAtom);
    // tracker?.setUserID(JSON.parse(cookies.user).email);

    const socketMemo = useMemo(
        () => (
            <SocketProvider>
                <Suspense>
                    <IndexScreen />
                </Suspense>
            </SocketProvider>
        ),
        [],
    );
    return !cookies.user ? (
        <Navigate to="login" state={{ from: previousLocation.pathname }} />
    ) : (
        <ReactRoutes>
            <Route path="*" element={socketMemo}>
                <Route path="*" element={<SlidingPages />} />
            </Route>
            <Route path="*" element={<h1>404</h1>} />
        </ReactRoutes>
    );
}
