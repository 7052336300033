import Modal from '@/components/Modal/Modal';
import request from '@/util/request';
import { ActionButton, Form, Input, Stack, Text, ToggleButton } from '@vg-react/components';
import { SaveLight } from '@vg-react/icons/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { IxcIntegration } from '../Integrations.screen';

type FormData = {
    enabled: boolean;
    clientId: string;
    clientSecret: string;
    url: string;
};

const initialErrors = {
    clientId: '',
    clientSecret: '',
    url: '',
};

interface ModalEditIntegrationProps {
    active: boolean;
    onClose: () => void;
    integration: IxcIntegration;
    refreshIntegrations: () => void;
}
export default function ModalEditIntegration({
    active,
    onClose,
    integration,
    refreshIntegrations,
}: ModalEditIntegrationProps) {
    const { t } = useTranslation();
    const initialValues: FormData = {
        enabled: integration.active,
        url: integration.value.url,
        clientId: integration.value.clientId,
        clientSecret: integration.value.clientSecret,
    };

    const [loading, setLoading] = useState<boolean>(false);
    const [disable, setDisable] = useState(true);
    const [fieldss, setFields] = useState<FormData>(initialValues);
    const [errors, setErrors] = useState<{
        clientId: string;
        clientSecret: string;
        url: string;
    }>(initialErrors);

    const schema = z.object({
        enabled: z.boolean(),
        url: z
            .string({
                required_error: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.urlRequired'),
            })
            .url({ message: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.urlInvalid') })
            .trim(),
        clientId: z
            .string({
                required_error: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientIdRequired'),
            })
            .trim()
            .min(10, { message: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientIdInvalid') }),
        clientSecret: z
            .string({
                required_error: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientSecretRequired'),
            })
            .trim()
            .min(10, {
                message: t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientSecretInvalid'),
            }),
    });

    function verifyErrors(fields: Partial<FormData>) {
        type Key = keyof FormData;

        for (const key in fields) {
            if (fields[key as keyof FormData] === '') fields[key as keyof FormData] = undefined;
        }

        const result = schema.safeParse(fields);

        if (result.success) {
            setErrors({
                url: '',
                clientId: '',
                clientSecret: '',
            });
            return false;
        }

        setErrors((previous) => {
            const zodErrors = result.error.flatten().fieldErrors;
            const newErrors: any = initialErrors;
            Object.keys(previous).forEach((key) => {
                newErrors[key as Key] = zodErrors[key as Key]?.[0] ?? '';
            });
            return newErrors;
        });
        return true;
    }

    function onChange(fields: FormData) {
        setFields(fields);
        const existsErros = verifyErrors(fields);
        setDisable(true);
        if (
            (fields.enabled !== initialValues.enabled ||
                fields.url !== initialValues.url ||
                fields.clientId !== initialValues.clientId ||
                fields.clientSecret !== initialValues.clientSecret) &&
            !existsErros
        ) {
            setDisable(false);
        }
    }

    async function onSubmit() {
        const erros = verifyErrors(fieldss);
        if (erros) return;

        const integrationData: IxcIntegration = {
            name: integration.name,
            value: {
                clientId: fieldss.clientId,
                clientSecret: fieldss.clientSecret,
                url: fieldss.url,
            },
            createdAt: integration.createdAt,
            active: fieldss.enabled,
            lastSync: integration.lastSync,
        };

        setLoading(true);
        request
            .patch('/app/integrations/system/update', integrationData)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(() => {
                    setLoading(false);
                    refreshIntegrations();
                }, 1000);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLoading(false);
            });
    }

    return (
        <Modal size="medium" active={active} onClose={onClose}>
            <Form<FormData> onChange={onChange} initialValues={initialValues} onSubmit={onSubmit}>
                <Modal.Header generateCloseButton>
                    <Text weight="bold" size="lg">
                        {t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.editIntegration')}
                    </Text>
                </Modal.Header>

                <Modal.Body>
                    <Stack spacing="var(--spacing-300)">
                        <Text weight="bold" size="md">
                            {t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.integrationData')}
                        </Text>
                        <Stack direction="row" spacing="var(--spacing-200)" align="center" justify="center">
                            <ToggleButton
                                name="enabled"
                                label={t(
                                    'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.enableIntegration',
                                )}
                            />
                            <div
                                style={{
                                    flex: '1',
                                    height: 1,
                                    backgroundColor: 'var(--lightness-900)',
                                }}
                            />
                        </Stack>
                        <Input
                            required
                            width="100%"
                            label="URL"
                            name="url"
                            inputSize="medium"
                            description={t(
                                'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.urlDescription',
                            )}
                            placeholder={t(
                                'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.urlPlaceholder',
                            )}
                            invalid={errors.url !== ''}
                            helpText={errors.url}
                            disabled={!fieldss.enabled}
                        />

                        <Stack direction="row" spacing="var(--spacing-200)" align="center" justify="center">
                            <Text weight="bold" size="xs">
                                {t(
                                    'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.authenticationCredentials',
                                )}
                            </Text>
                            <div
                                style={{
                                    flex: '1',
                                    height: 1,
                                    backgroundColor: 'var(--lightness-900)',
                                }}
                            />
                        </Stack>

                        <Input
                            type="password"
                            required
                            width="100%"
                            label="Client ID"
                            name="clientId"
                            inputSize="medium"
                            description={t(
                                'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientIdDescription',
                            )}
                            placeholder=""
                            invalid={errors.clientId !== ''}
                            helpText={errors.clientId}
                            disabled={!fieldss.enabled}
                        />

                        <Input
                            type="password"
                            required
                            width="100%"
                            label="Client secret"
                            name="clientSecret"
                            inputSize="medium"
                            description={t(
                                'ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.clientSecretDescription',
                            )}
                            placeholder=""
                            invalid={errors.clientSecret !== ''}
                            helpText={errors.clientSecret !== '' ? errors.clientSecret : ''}
                            disabled={!fieldss.enabled}
                        />
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton
                            quiet
                            width="small"
                            onClick={onClose}
                            style={{
                                fontSize: 'var(--font-size-xs)',
                                background: 'transparent',
                                color: 'var(--gray-700)',
                                border: 'transparent',
                            }}
                        >
                            {t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.cancel')}
                        </ActionButton>
                        <ActionButton
                            type="submit"
                            loading={loading}
                            disabled={disable}
                            style={{
                                fontSize: 'var(--font-size-xs)',
                            }}
                        >
                            <SaveLight color="currentColor" size="16" />
                            {t('ToolsScreen.IntegrationsScreen.ModalEditIntegrationComponent.save')}
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
