import Breadcrumb from '@/components/Breadcrumb/Breadcrumb';
import ConfirmPasswordModal from '@/components/ConfirmPassword/ConfirmPasswordModal';
import { Step } from '@/components/Stepper';
import {
    DataFilters,
    DeviceProps,
    FirmwareProps,
    MassUpdateContext,
} from '@/contexts/MassChange/MassChangeFirmwareContext';
import useAuth from '@/hooks/useAuth';
import useFetch from '@/hooks/useFetch';
import { ActionButton, Stack } from '@vg-react/components';
import { ArrowLeftSolid, ArrowRightSolid, CheckSolid } from '@vg-react/icons/v6/solid';
import { AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import PageTitle from '../../../../../components/PageTitle/PageTitle';
import Screen from '../../../../../components/Screen/Screen';
import request from '../../../../../util/request';
import Devices from './Device/Devices.screen';
import Firmware from './Firmware/Firmware.screen';
import Resume from './Resume/Resume.screen';

export default function NewFirmwareUpdate() {
    enum screens {
        devices = 0,
        firmware = 1,
        resume = 2,
        finish = 3,
    }
    const navigate = useNavigate();

    const { t } = useTranslation();
    const {
        data: dataFilters,
        forceRefresh,
        isFetching,
    } = useFetch<DataFilters>('/app/massChange/firmware/manufacturers');
    const [validData, setValidData] = useState<boolean>(true);
    const [isFetchingDevicesList, setIsFetchingDevicesList] = useState<boolean>(false);
    const [openConfirmPasswordModal, setOpenConfirmPasswordModal] = useState<boolean>(false);
    const [selectedDevices, setSelectedDevices] = useState<DeviceProps[]>([]);
    const [deviceList, setDevicesList] = useState<DeviceProps[]>([]);
    const [selectedFirmware, setSelectedFirmware] = useState<FirmwareProps>({} as FirmwareProps);
    const [blockNextScreen, setBlockNextScreen] = useState<boolean>(true);
    const [scheduleDate, setScheduleDate] = useState<Date | null>(null);
    const [selectedFilters, setSelectedFilters] = useState<{
        manufacturer: string;
        model: string;
        hardware: string;
        firmwares: string[];
    }>({
        manufacturer: '',
        model: '',
        hardware: '',
        firmwares: [],
    });

    const [activeScreen, setActiveScreen] = useState(screens.devices);
    const [direction, setDirection] = useState<'forward' | 'backward'>('forward');

    const navigateTo = (nav: { backward?: boolean; forward?: boolean }) => {
        if (nav.forward) {
            setActiveScreen((prev) => prev + 1);
            setDirection('forward');
        } else if (nav.backward) {
            setActiveScreen((prev) => prev - 1);
            setDirection('backward');
        }
    };

    const executeUpdate = async () => {
        const body = {
            devices: selectedDevices.map((device) => device.serialNumber),
            firmwareId: selectedFirmware.id,
            manufacturer: selectedDevices?.[0].manufacturer,
            scheduleDate: scheduleDate?.toString() || null,
        };
        await request
            .post('/app/massChange/firmware/start', body)
            .then(() => {
                navigate('/tools/mass_change/firmware');
            })
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            .catch((err: any) => {
                toast.error(err.response.data.message);
            });
    };

    const provider = {
        refresh: forceRefresh,
        direction,
        selectedDevices,
        setSelectedDevices,
        deviceList,
        setDevicesList,
        selectedFirmware,
        setSelectedFirmware,
        blockNextScreen,
        setBlockNextScreen,
        selectedFilters,
        setSelectedFilters,
        dataFilters,
        setScheduleDate,
        scheduleDate,
        isFetchingDataFilters: isFetching,
        isFetchingDevicesList,
        setIsFetchingDevicesList,
        setValidData,
        validData,
    };
    const { user } = useAuth();
    const [disabled, setDisabled] = useState<boolean>(false);
    return (
        <MassUpdateContext.Provider value={provider}>
            <Screen>
                <Breadcrumb />
                <Stack height="100%" spacing={window.innerHeight < 800 ? 'var(--spacing-100)' : 'var(--spacing-300)'}>
                    <Stack direction="row" justify="space-between" align="center">
                        <PageTitle
                            title={t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.title')}
                            description={t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.description')}
                        />
                    </Stack>
                    <Stack>
                        <Step
                            currentStep={activeScreen}
                            itemText={[
                                t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.devices'),
                                t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.firmware'),
                                t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.resume'),
                            ]}
                            onClick={(currentStep) => {
                                setActiveScreen(currentStep);
                            }}
                        />
                    </Stack>
                    <Stack height="100%" style={{ overflow: 'hidden', transition: 'transform 0.5s ease' }}>
                        <AnimatePresence mode="wait">
                            {activeScreen === screens.devices && <Devices />}
                            {activeScreen === screens.firmware && <Firmware />}
                            {activeScreen === screens.resume && <Resume />}
                        </AnimatePresence>
                    </Stack>
                    <Stack
                        justify="end"
                        direction="row"
                        spacing="var(--spacing-100)"
                        align="center"
                        style={{
                            margin: 'var(--spacing-50) 0 var(--spacing-50) 0',
                        }}
                    >
                        {activeScreen !== screens.devices && (
                            <ActionButton
                                variant="terciary"
                                style={{ width: '98px' }}
                                onClick={() => {
                                    navigateTo({ backward: true });
                                }}
                            >
                                <ArrowLeftSolid color="var(--gray-600)" />
                                {t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.back')}
                            </ActionButton>
                        )}
                        {(activeScreen === screens.devices || activeScreen === screens.firmware) && (
                            <ActionButton
                                disabled={blockNextScreen}
                                style={{ width: '98px' }}
                                onClick={() => {
                                    navigateTo({ forward: true });
                                    setBlockNextScreen(true);
                                }}
                            >
                                {t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.next')}
                                <ArrowRightSolid
                                    color={blockNextScreen ? 'var(--gray-400)' : 'var(--background-color)'}
                                />
                            </ActionButton>
                        )}

                        {activeScreen === screens.resume && (
                            <ActionButton
                                style={{ width: scheduleDate ? '100px' : '155px' }}
                                onClick={() => {
                                    if (scheduleDate && scheduleDate <= new Date()) {
                                        toast.error(t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.invalidData'));
                                        setValidData(false);
                                        return;
                                    }
                                    setDisabled(true);
                                    if (!user?.sso) {
                                        setOpenConfirmPasswordModal(true);
                                    } else {
                                        executeUpdate();
                                    }
                                }}
                                disabled={disabled || user?.sso || !validData}
                            >
                                <CheckSolid color="var(--background-color)" />
                                {scheduleDate
                                    ? t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.confirm')
                                    : t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.confirmAndStart')}
                            </ActionButton>
                        )}
                    </Stack>
                </Stack>
            </Screen>
            {openConfirmPasswordModal && (
                <ConfirmPasswordModal
                    onClose={() => {
                        setOpenConfirmPasswordModal(false);
                        setDisabled(false);
                    }}
                    onConfirm={async () => {
                        setOpenConfirmPasswordModal(false);
                        await executeUpdate();
                        setDisabled(false);
                    }}
                    textLabel={t('ToolsScreen.MassChangeScreen.NewFirmwareUpdateScreen.confirmPassword')}
                />
            )}
        </MassUpdateContext.Provider>
    );
}
