import { Dispatch, SetStateAction, useContext, useEffect } from 'react';
import { FormContext } from './Form';

export function useForm<Type>(
    name: string,
    defaultValue: Type,
): {
    value: Type;
    onChange: Dispatch<SetStateAction<Type>>;
} {
    const { fields, setFields } = useContext(FormContext);

    useEffect(() => {
        if (!setFields) return;
        setFields((oldFields: { [key: string]: unknown }) => {
            if (oldFields[name] === undefined) {
                return { ...oldFields, [name]: defaultValue };
            }
            return oldFields;
        });
    }, []);

    if (!fields || !setFields) return { value: defaultValue, onChange: () => {} };

    const onChange = (value: Type | ((value: Type) => Type)): void => {
        setFields((oldFields: { [key: string]: unknown }) => {
            if (value instanceof Function) {
                return { ...oldFields, [name]: value(oldFields[name] as Type) };
            } else {
                return { ...oldFields, [name]: value };
            }
        });
    };

    return {
        value: (fields[name] !== undefined ? fields[name] : defaultValue) as Type,
        onChange,
    };
}
