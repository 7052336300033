import { atom, useAtom } from 'jotai';

type CapabilityResourceType = {
    autoBandWidth?: boolean;
    channel?: boolean;
    channelBandWidthAuto?: boolean;
    connectionTrigger?: boolean;
    create?: boolean;
    delete?: boolean;
    dhcp?: boolean;
    dns?: boolean;
    dnsAddress?: boolean;
    dnsEnable?: boolean;
    editChannel?: boolean;
    editPassword?: boolean;
    editUser?: boolean;
    enable?: boolean;
    enableRemoteAccess?: boolean;
    ipv6?: boolean;
    pppoe?: boolean;
    setAlias?: boolean;
    setExternalAddress?: boolean;
    setExternalPort?: boolean;
    setInternalAddress?: boolean;
    setInternalPort?: boolean;
    staticIp?: boolean;
    update?: boolean;
    updateInternalIp?: boolean;
    userEnable?: boolean;
    setBeaconType?: boolean;
    editWebPort?: boolean;
    editGateway?: boolean;
    editPool?: boolean;
    editSubnetMask?: boolean;
    bindLayer?: boolean;
    regulatoryDomain?: boolean;
};
export type CapabilityType = {
    [key in
        | 'Ethernet'
        | 'Hosts'
        | 'WiFi'
        | 'Reset'
        | 'PortForward'
        | 'Lan'
        | 'Security'
        | 'Topology'
        | 'FirmwareUpdate'
        | 'TimeProtocol']: CapabilityResourceType;
};

export type BooleanCapabilityType = {
    TraceRoute?: boolean;
    Ping?: boolean;
    Security?: boolean;
    NeighborScan?: boolean;
    SpeedTest?: {
        upload?: boolean;
        download?: boolean;
    };
    Lan?: boolean;
    Hosts?: {
        getHosts?: boolean;
        banHosts?: boolean;
    };
    PortForward?:
        | {
              create?: boolean;
              updateInternalIp?: boolean;
          }
        | boolean;
    WiFi?:
        | {
              channel?: boolean;
              autoBandWidth?: boolean;
              regulatoryDomain?: boolean;
          }
        | boolean;
    FirmwareUpdate?: boolean;
    TimeProtocol?: boolean;
};

export const capabilityAtom = atom({});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useCapability(): [Partial<CapabilityType & BooleanCapabilityType>, (...args: any[]) => void] {
    return useAtom<Partial<CapabilityType & BooleanCapabilityType>>(capabilityAtom);
}
