import i18n from '@/i18n';
import getChartConfig from '@/screens/Home/index/charts/getChartConfig';
import { Stack, Text, Tooltip } from '@vg-react/components';
import { ChevronLeftLight } from '@vg-react/icons/v6/light';
import { useEffect, useState } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Screen from '../../../../../../../components/Screen/Screen';
import useFetch from '../../../../../../../hooks/useFetch';
import calcRate, { convertToGbps } from '../../utils/calcRate';
import styles from './SpeedTestHistory.module.scss';

export interface SpeedTestHistory {
    date: string;
    download: {
        bytes: number;
        milliseconds: number;
    };
    upload: {
        bytes: number;
        milliseconds: number;
    };
}

interface DataSet {
    name: string;
    data: (number | null)[];
}

export default function SpeedTestHistoryScreen() {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data, isFetching } = useFetch<{ speedTestHistory: SpeedTestHistory[] }>(
        `/app/devices/diagnostics/speedTest/history/${id}`,
    );
    const [dataSet, setDataSet] = useState<DataSet[]>([]);
    const [categories, setCategories] = useState<string[]>([]);

    useEffect(() => {
        if (data?.speedTestHistory && data?.speedTestHistory?.length > 0) {
            const download: (number | null)[] = [];
            const upload: (number | null)[] = [];
            const categories: string[] = [];

            data.speedTestHistory.forEach((speedTest) => {
                categories.push(
                    new Date(speedTest.date)
                        .toLocaleString(i18n.language, {
                            year: '2-digit',
                            month: '2-digit',
                            day: '2-digit',
                            hour: '2-digit',
                            minute: '2-digit',
                        })
                        .replace(',', ''),
                );

                download.push(
                    speedTest?.download?.milliseconds && speedTest?.download?.bytes
                        ? calcRate(speedTest.download)
                        : null,
                );
                upload.push(
                    speedTest?.upload?.milliseconds && speedTest?.upload?.bytes ? calcRate(speedTest.upload) : null,
                );
            });

            setDataSet([
                {
                    name: 'Download',
                    data: download,
                },
                {
                    name: 'Upload',
                    data: upload,
                },
            ]);

            setCategories(categories);
        }
    }, [data]);

    const chartOptions = getChartConfig({
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            stacked: false,
        },
        grid: {
            show: false,
        },
        plotOptions: {
            bar: {
                borderRadius: 0,
                horizontal: false,
                dataLabels: {
                    position: 'top',
                    total: {
                        enabled: false,
                    },
                },
            },
        },
        colors: ['var(--download-color)', 'var(--upload-color)'],
        dataLabels: {
            enabled: true,
            textAnchor: 'middle',
            offsetY: 5,
            formatter: (val) => (val ? (Number(val) > 1000 ? convertToGbps(Number(val)) + 'G' : `${val}M`) : ''),
            style: {
                colors: ['white'],
                fontWeight: 'var(--text-weight-bold)',
                fontFamily: 'var(--font-family)',
                fontSize: 'var(--font-size-xs)',
            },
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
        },
        xaxis: {
            categories,
            labels: {
                show: true,
                style: {
                    colors: 'var(--gray-800)',
                    fontWeight: 'var(--text-weight-regular)',
                    fontFamily: 'var(--font-family)',
                    fontSize: 'var(--font-size-xs)',
                },
            },
            axisTicks: {
                show: false,
            },
        },
        legend: {
            fontWeight: 'var(--text-weight-bold)',
            fontFamily: 'var(--font-family)',
            fontSize: 'var(--font-size-xs)',
            labels: {
                colors: 'var(--gray-800)',
            },
            markers: {
                width: 14,
                height: 14,
                radius: 14,
            },
        },
        yaxis: {
            show: false,
            axisTicks: {
                show: false,
            },
        },
        fill: {
            opacity: 1,
        },
    });

    return (
        <Screen
            style={{
                height: '100%',
                backgroundColor: 'var(--foreground-color)',
                padding: 20,
                borderRadius: 'var(--radius-medium)',
            }}
        >
            <Stack spacing="medium" height="100%" width="100%">
                <Stack direction="row" spacing="var(--spacing-75)" align="center">
                    <Tooltip>
                        <Tooltip.Trigger>
                            <Link to={`/devices/${id}/diagnostics/speedTest/`} className={styles.returnButton}>
                                <ChevronLeftLight size="16" color="var(--gray-900)" />
                            </Link>
                        </Tooltip.Trigger>
                        <Tooltip.Content>
                            {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.History.back')}
                        </Tooltip.Content>
                    </Tooltip>
                    <Text size="md" weight="bold" color="var(--gray-900)">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.History.title')}
                    </Text>
                </Stack>
                <Stack height="100%">
                    {isFetching ? null : data?.speedTestHistory && data?.speedTestHistory?.length > 0 ? (
                        <ReactApexChart type="bar" width="100%" height="100%" series={dataSet} options={chartOptions} />
                    ) : (
                        <div
                            style={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                        >
                            <Text weight="regular" size="xxl" color="var(--lightness-500)">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.History.noData')}
                            </Text>
                        </div>
                    )}
                </Stack>
            </Stack>
        </Screen>
    );
}
