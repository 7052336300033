import { ChevronDownLight, ChevronUpLight, ClockLight } from '@vg-react/icons/light';
import FocusTrap from 'focus-trap-react';
import React, { KeyboardEvent, useId, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../util/classnames';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { useForm } from '../Form/useForm';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import Stack from '../Stack/Stack';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import useDimensions from '../hooks/useDimensions';
import css from './TimeInput.module.scss';

interface TimeInputValue {
    hours: string;
    minutes: string;
}

export interface TimeInputProps extends FieldWrapperProps {
    /** name of the field for form control */
    name: string;
    /**
     * size of the step clicking on the minutes controller
     * @default 1
     */
    step?: number;
    /**
     * defines if the field is disabled
     * @default false
     */
    disabled?: boolean;
    /**
     * defines if the field is readonly
     * @default false
     */
    readonly?: boolean;
    /**
     * defines if the field is in invalid state
     * @default false
     */
    invalid?: boolean;
    /**
     * height of the field
     * @default 'medium'
     */
    size?: 'small' | 'medium' | 'large' | 'xlarge' | 'auto';
    /**
     * default value of the field
     * @default { hours: '00', minutes: '00' }
     */
    defaultValue?: TimeInputValue;
}

/**
 * TimeInput component
 *
 * If inside a Form, value will be automatically captured.
 *
 * @example
 * //Inside a form (the value will be output on form callbacks)
 * <Form>
 *     <TimeInput name='my-time-input' label='My Input'/>
 * </Form>
 */
export default function TimeInput({
    name,
    step = 1,
    size = 'medium',
    disabled = false,
    readonly = false,
    invalid = false,
    helpText,
    defaultValue = { hours: '00', minutes: '00' },
    ...props
}: TimeInputProps) {
    const { t } = useTranslation();
    const { onChange, value: time } = useForm<TimeInputValue>(name, defaultValue);
    const hoursRef = useRef<HTMLInputElement>(null);
    const minutesRef = useRef<HTMLInputElement>(null);
    const [open, setOpen] = useState<boolean>(false);
    const id = useId();

    function handleHoursChangeByButton(value: number) {
        const newValue = parseInt(time.hours, 10) + value;

        let hours: string;

        if (newValue > 23) hours = '00';
        else if (newValue < 0) hours = '23';
        else hours = newValue.toString().padStart(2, '0');

        onChange({ hours, minutes: time.minutes });
    }

    function handleMinutesChangeByButton(value: number) {
        const newValue = parseInt(time.minutes, 10) + value;

        let minutes: string;

        if (newValue > 59) minutes = '00';
        else if (newValue < 0) minutes = '59';
        else minutes = newValue.toString().padStart(2, '0');

        onChange({ hours: time.hours, minutes });
    }

    function handleHoursChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        if (value === '') {
            onChange({ hours: '', minutes: time.minutes });
            return;
        }

        if (/[a-zA-Z]/.test(value)) {
            onChange({ hours: time.hours, minutes: time.minutes });
            return;
        }

        const intValue = parseInt(value, 10);

        if (value.length === 1 && intValue > 2 && minutesRef.current) {
            minutesRef.current.focus();
            onChange({ hours: `${intValue || 0}`.padStart(2, '0'), minutes: time.minutes });
            return;
        }

        let filteredValue = value.length < 2 ? value : `${intValue}`.padStart(2, '0');
        filteredValue = filteredValue.length > 2 ? `${filteredValue[0]}${filteredValue[1]}` : filteredValue;

        let parsedValue = parseInt(filteredValue, 10) > 23 ? '23' : filteredValue;
        parsedValue = parseInt(parsedValue, 10) < 0 ? '0' : parsedValue;

        onChange({ hours: parsedValue, minutes: time.minutes });

        parsedValue.length === 2 && minutesRef.current && minutesRef.current.focus();
    }

    function handleMinutesChange(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.target;

        if (/[a-zA-Z]/.test(value)) {
            onChange({ hours: time.hours, minutes: time.minutes });
            return;
        }

        if (value === '') {
            onChange({ hours: time.hours, minutes: '' });
            return;
        }

        const intValue = parseInt(value, 10);

        if (value.length === 1 && intValue > 5 && minutesRef.current) {
            if (!invalid) {
                minutesRef.current.blur();
                setOpen(false);
            }

            onChange({ hours: time.hours, minutes: `${intValue || 0}`.padStart(2, '0') });
            return;
        }

        let filteredValue = value.length < 2 ? value : `${intValue}`.padStart(2, '0');
        filteredValue = filteredValue.length > 2 ? `${filteredValue[0]}${filteredValue[1]}` : filteredValue;

        let parsedValue = parseInt(filteredValue, 10) > 59 ? '59' : filteredValue;
        parsedValue = parseInt(parsedValue, 10) < 0 ? '0' : parsedValue;

        if (/^[0-5]?[0-9]?$/.test(parsedValue)) {
            onChange({ hours: time.hours, minutes: parsedValue });
            if (minutesRef.current && parsedValue.length === 2 && !invalid) {
                minutesRef.current.blur();
                setOpen(false);
            }
        }
    }

    function handleBlur() {
        onChange((previous: TimeInputValue) => {
            return {
                hours: `${parseInt(previous.hours, 10) || 0}`.padStart(2, '0'),
                minutes: `${parseInt(previous.minutes, 10) || 0}`.padStart(2, '0'),
            };
        });
    }

    function handleOpen() {
        if (!disabled && !readonly) {
            setOpen(!open);
        }
    }

    function handleEnter(event: KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') handleBlur();
    }

    const { ref: resizeRef, dimensions: resizeDimensions } = useDimensions<HTMLButtonElement>();

    const clockSize = size === 'small' ? '14' : size === 'auto' ? '16' : '18';
    const fontSize =
        size === 'small' ? 'xxs' : size === 'medium' ? 'xs' : size === 'large' ? 'sm' : size === 'xlarge' ? 'md' : 'xs';

    return (
        <FieldWrapper invalid={invalid} helpText={helpText} id={id} width="auto" {...props}>
            <button
                ref={resizeRef}
                onClick={handleOpen}
                type="button"
                className={classnames(
                    css.rootButton,
                    open && css.open,
                    readonly && css.readonly,
                    disabled && css.disabled,
                    invalid && css.invalid,
                    css[size],
                )}
            >
                <Stack direction="row" spacing="var(--spacing-75)" align="center">
                    <ClockLight color="var(--gray-600)" size={clockSize} />
                    <Text size={fontSize}>
                        {time.hours}:{time.minutes}
                    </Text>
                    <input
                        tabIndex={-1}
                        className={css.hidden}
                        id={id}
                        type="text"
                        defaultValue={`${time.hours}:${time.minutes}`}
                    />
                </Stack>
            </button>
            {open && (
                <FocusTrap focusTrapOptions={{ clickOutsideDeactivates: true }}>
                    <OutsideClickHandler onClickOutside={() => setOpen(false)}>
                        <div
                            style={{ width: resizeDimensions.width }}
                            className={classnames(css.dropdown, open && css.open)}
                        >
                            <Stack direction="row" spacing="var(--spacing-75)" align="center" justify="center">
                                <Stack spacing="var(--spacing-50)" justify="center" align="center">
                                    <Tooltip placement="top">
                                        <Tooltip.Trigger>
                                            <button
                                                aria-label="aumentar horas"
                                                className={css.button}
                                                type="button"
                                                tabIndex={-1}
                                                onClick={() => handleHoursChangeByButton(+1)}
                                            >
                                                <ChevronUpLight color="var(--gray-800)" size="14" />
                                            </button>
                                        </Tooltip.Trigger>
                                        <Tooltip.Content>{t('TimeInputlComponent.increaseHours')}</Tooltip.Content>
                                    </Tooltip>

                                    <input
                                        onKeyDown={handleEnter}
                                        ref={hoursRef}
                                        className={classnames(css.timeField, invalid && css.invalid)}
                                        onBlur={handleBlur}
                                        value={time.hours}
                                        onFocus={() => {
                                            if (hoursRef.current) hoursRef.current.select();
                                        }}
                                        onChange={handleHoursChange}
                                    />

                                    <Tooltip placement="bottom">
                                        <Tooltip.Trigger>
                                            <button
                                                aria-label="diminuir horas"
                                                className={css.button}
                                                type="button"
                                                tabIndex={-1}
                                                onClick={() => handleHoursChangeByButton(-1)}
                                            >
                                                <ChevronDownLight color="var(--gray-800)" size="14" />
                                            </button>
                                        </Tooltip.Trigger>
                                        <Tooltip.Content>{t('TimeInputlComponent.decreaseHours')}</Tooltip.Content>
                                    </Tooltip>
                                </Stack>
                                :
                                <Stack spacing="var(--spacing-50)" justify="center" align="center">
                                    <Tooltip placement="top">
                                        <Tooltip.Trigger>
                                            <button
                                                aria-label="aumentar minutos"
                                                className={css.button}
                                                type="button"
                                                tabIndex={-1}
                                                onClick={() => handleMinutesChangeByButton(+step)}
                                            >
                                                <ChevronUpLight color="var(--gray-800)" size="14" />
                                            </button>
                                        </Tooltip.Trigger>
                                        <Tooltip.Content>{t('TimeInputlComponent.increaseMinutes')}</Tooltip.Content>
                                    </Tooltip>

                                    <input
                                        onKeyDown={handleEnter}
                                        ref={minutesRef}
                                        className={classnames(css.timeField, invalid && css.invalid)}
                                        onBlur={handleBlur}
                                        value={time.minutes}
                                        onFocus={() => {
                                            if (minutesRef.current) minutesRef.current.select();
                                        }}
                                        onChange={handleMinutesChange}
                                    />

                                    <Tooltip placement="bottom">
                                        <Tooltip.Trigger>
                                            <button
                                                aria-label="diminuir minutos"
                                                className={css.button}
                                                type="button"
                                                tabIndex={-1}
                                                onClick={() => handleMinutesChangeByButton(-step)}
                                            >
                                                <ChevronDownLight color="var(--gray-800)" size="14" />
                                            </button>
                                        </Tooltip.Trigger>
                                        <Tooltip.Content>{t('TimeInputlComponent.decreaseMinutes')}</Tooltip.Content>
                                    </Tooltip>
                                </Stack>
                            </Stack>
                        </div>
                    </OutsideClickHandler>
                </FocusTrap>
            )}
        </FieldWrapper>
    );
}
