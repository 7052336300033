import { ChevronLeftRegular, ChevronRightRegular } from '@vg-react/icons/v6/regular';
import React, { CSSProperties, useRef } from 'react';
import style from './DatePicker.module.scss';

import i18n from '@/i18n';
import { classnames } from '@/util/classnames';
import mergeRefs from '@/util/mergeRefs';
import { offset, useFloating } from '@floating-ui/react';
import { OutsideClickHandler, Stack, Text } from '@vg-react/components';
import { CalendarLight } from '@vg-react/icons/light';
import { createPortal } from 'react-dom';

export interface DatePickerProps {
    /**
     * locale to be used in the datepicker
     * @default 'default'
     */
    locale?: string | 'default';
    /**
     * if true, the calendar will close after selecting a date
     * @default false
     */
    closeOnSelect?: boolean;
    /**
     * callback function to control from outside */
    onValueChange?: (date: Date) => void | undefined;
    /**
     * initial value of the datepicker
     *
     */
    initialValue?: Date;
    /**
     * if true, the datepicker will be disabled
     * @default false
     */
    disabled?: boolean;
    parentRef?: React.RefObject<HTMLDivElement>;
}

/**
 *
 * DatePicker component
 *
 * @example
 *
 * <DatePicker initialValue={new Date()} closeOnSelect />
 */

export interface DatePickerProps {
    style?: CSSProperties;
    locale?: string | 'default';
    closeOnSelect?: boolean;
    onValueChange?: (date: Date) => void | undefined;
    initialValue?: Date;
    disabled?: boolean;
    parent?: Element | DocumentFragment;
    min?: Date;
    max?: Date;
    invalid?: boolean;
}

export default function DatePicker({
    initialValue,
    locale,
    closeOnSelect,
    onValueChange,
    disabled,
    parent = document.getElementById('workspace') ?? document.body,
    min,
    max,
    invalid,
    ...props
}: DatePickerProps) {
    if (!locale) locale = i18n.language;
    const [openCalendar, setOpenCalendar] = React.useState<boolean>(false);
    const [date, setDate] = React.useState<Date>(initialValue || new Date());
    const ref = useRef(null);
    const { refs, floatingStyles } = useFloating({
        transform: true,
        middleware: [offset({ mainAxis: 5 })],
    });

    return (
        <div
            className={style.root}
            style={props.style}
            key="root"
            ref={mergeRefs<HTMLDivElement>(refs.setReference, ref)}
        >
            <DataPickerInput
                open={openCalendar}
                setOpen={setOpenCalendar}
                value={date.toLocaleDateString(locale)}
                disabled={disabled}
                invalid={invalid}
            />
            {openCalendar &&
                createPortal(
                    <div
                        ref={refs.setFloating}
                        style={{
                            ...floatingStyles,
                            width: '16rem',
                            paddingTop: '2px',
                            zIndex: 9999,
                        }}
                    >
                        <OutsideClickHandler onClickOutside={() => setOpenCalendar(false)}>
                            <DatePickerContent
                                min={min}
                                max={max}
                                selectedDate={date}
                                setSelectedDate={setDate}
                                locale={locale}
                                closeOnSelect={closeOnSelect}
                                setOpenCalendar={setOpenCalendar}
                                onValueChange={onValueChange}
                                initialValue={initialValue}
                            />
                        </OutsideClickHandler>
                    </div>,
                    parent,
                )}
        </div>
    );
}

function DataPickerInput({
    open,
    setOpen,
    value,
    disabled,
    invalid,
}: {
    open: boolean;
    setOpen: (open: boolean) => void;
    value?: string;
    disabled?: boolean;
    invalid?: boolean;
}) {
    return (
        <Stack key="input" direction="row" spacing="var(--spacing-100)" height="32px">
            <button disabled={disabled} onClick={() => setOpen(!open)} className={classnames(style.button, invalid && style.invalidValue)} >
                <Stack direction="row" spacing="var(--spacing-75)" align="center">
                    <CalendarLight size="16" color="var(--gray-600)" />
                    <Text>{value}</Text>
                    <input className={style.hidden} tabIndex={-1} type="text" defaultValue={value} />
                </Stack>
            </button>
        </Stack>
    );
}

export function DatePickerContent({
    selectedDate: selectedDate,
    setSelectedDate,
    locale: locale = 'default',
    closeOnSelect,
    setOpenCalendar,
    onValueChange,
    min,
    max,
}: DatePickerProps & {
    selectedDate: Date;
    setSelectedDate: (date: Date) => void;
    setOpenCalendar: (open: boolean) => void;
    min?: Date;
    max?: Date;
}) {
    const [currentDateCalendar, setCurrentDateCalendar] = React.useState<Date>(selectedDate);
    const navigateBetweenMonths = (direction: 'next' | 'previous') => {
        const newDate = new Date(currentDateCalendar);
        if (direction === 'next') {
            newDate.setMonth(newDate.getMonth() + 1);
        } else {
            newDate.setMonth(newDate.getMonth() - 1);
        }
        setCurrentDateCalendar(newDate);
    };

    function getWeekdayOrder() {
        const randomDate = new Date(2006, 0, 1);

        const weekdays = [];
        for (let i = 0; i < 7; i++) {
            randomDate.setDate(i + 1);
            const weekday = randomDate.toLocaleString(i18n.language, { weekday: 'short' });
            weekdays.push(weekday.charAt(0).toUpperCase() + weekday.slice(1).replaceAll('.', ''));
        }

        return weekdays;
    }

    function renderDays() {
        const maxWeeksInMonth = 6;
        const maxDaysInWeek = 7;
        const days: React.ReactNode[] = [];
        const firstDayOfMonth = new Date(currentDateCalendar.getFullYear(), currentDateCalendar.getMonth(), 1);
        const lastDayOfMonth = new Date(currentDateCalendar.getFullYear(), currentDateCalendar.getMonth() + 1, 0);

        const startWithDayWeek = firstDayOfMonth.getDay();
        const numDaysMonth = lastDayOfMonth.getDate();

        for (let i = startWithDayWeek - 1; i >= 0; i--) {
            let disabled = false;
            const prevMonthDay = new Date(currentDateCalendar.getFullYear(), currentDateCalendar.getMonth(), -i);
            if (
                (min && prevMonthDay.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (max && prevMonthDay.setHours(0, 0, 0, 0) > max.setHours(0, 0, 0, 0))
            ) {
                disabled = true;
            }
            days.push(
                <div
                    key={`prev-${i}`}
                    className={classnames(style.day, style.dayOtherMonth, disabled && style.disabled)}
                    onClick={() => {
                        if (disabled) return;
                        setSelectedDate(prevMonthDay);
                        navigateBetweenMonths('previous');
                        closeOnSelect && setOpenCalendar(false);
                        onValueChange && onValueChange(prevMonthDay);
                    }}
                >
                    {prevMonthDay.getDate()}
                </div>,
            );
        }

        for (let i = 1; i <= numDaysMonth; i++) {
            const currDay = new Date(currentDateCalendar.getFullYear(), currentDateCalendar.getMonth(), i);
            let disabled = false;
            if (
                (min && currDay.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (max && currDay.setHours(0, 0, 0, 0) > max.setHours(0, 0, 0, 0))
            ) {
                disabled = true;
            }
            days.push(
                <div
                    key={i}
                    className={classnames(
                        style.day,
                        disabled && style.disabled,
                        currDay.getDate() === new Date().getDate() &&
                            currDay.getMonth() === new Date().getMonth() &&
                            style.today,
                        selectedDate.getDate() === currDay.getDate() &&
                            selectedDate.getMonth() === currDay.getMonth() &&
                            style.selectedDate,
                    )}
                    onClick={() => {
                        if (disabled) return;
                        setSelectedDate(currDay);
                        closeOnSelect && setOpenCalendar(false);
                        onValueChange && onValueChange(currDay);
                    }}
                >
                    {i}
                </div>,
            );
        }

        let remainingDays = maxDaysInWeek * maxWeeksInMonth - days.length;
        remainingDays = remainingDays >= 7 ? remainingDays - 7 : remainingDays;
        remainingDays = remainingDays === 7 ? 0 : remainingDays;

        for (let i = 1; i <= remainingDays; i++) {
            const nextMonthDay = new Date(currentDateCalendar.getFullYear(), currentDateCalendar.getMonth() + 1, i);
            let disabled = false;
            if (
                (min && nextMonthDay.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0)) ||
                (max && nextMonthDay.setHours(0, 0, 0, 0) > max.setHours(0, 0, 0, 0))
            ) {
                disabled = true;
            }
            days.push(
                <div
                    key={`next-${i}`}
                    className={classnames(style.day, style.dayOtherMonth, disabled && style.disabled)}
                    onClick={() => {
                        if (disabled) return;
                        setSelectedDate(nextMonthDay);
                        navigateBetweenMonths('next');
                        closeOnSelect && setOpenCalendar(false);
                        onValueChange && onValueChange(nextMonthDay);
                    }}
                >
                    {nextMonthDay.getDate()}
                </div>,
            );
        }
        return days;
    }

    return (
        <Stack
            key="content"
            spacing="var(--spacing-50)"
            style={{
                backgroundColor: 'var(--background-color)',
                padding: '10px',
                borderRadius: 'var(--radius-small)',
            }}
        >
            <Stack key="triggers" direction="row" justify="space-between">
                <Text weight="bold">
                    {currentDateCalendar.toLocaleString(i18n.language, { month: 'long', year: 'numeric' })}
                </Text>
                <Stack key="triggerButtons" direction="row" spacing="var(--spacing-50)">
                    <div onClick={() => navigateBetweenMonths('previous')}>
                        <ChevronLeftRegular color="var(--gray-800)" style={{ cursor: 'pointer' }} size="14" />
                    </div>
                    <div onClick={() => navigateBetweenMonths('next')}>
                        <ChevronRightRegular color="var(--gray-800)" style={{ cursor: 'pointer' }} size="14" />
                    </div>
                </Stack>
            </Stack>
            <Stack spacing="var(--spacing-30)" justify="center">
                <div className={style.days} key="daysTitle">
                    {getWeekdayOrder().map((day, index) => (
                        <div key={index} className={style.headerDay} style={{ paddingBottom: '4px' }}>
                            {day}
                        </div>
                    ))}
                    {renderDays()}
                </div>
            </Stack>
        </Stack>
    );
}
