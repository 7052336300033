import * as Progress from '@radix-ui/react-progress';
import { CSSProperties } from 'react';
import css from './ProgressBar.module.scss';

interface ProgressBarProps {
    /**
     * Height of the ProgressBar
     * @default 'medium'
     */
    height?: 'small' | 'medium' | 'large' | string;
    /**
     * Progress level of the ProgressBar
     * @default: 0
     */
    progress?: number;
    /**
     * Wether the ProgressBar will change it's color when complete or not
     * @default true
     */
    changeColorOnComplete?: boolean;
    /**
     * Color that will be set on the ProgressBar when it hits 100%
     * @default var(--green-800);
     */
    completeColor?: string;
    /**
     * Color of the ProgressBar track
     * @default var(--gray-400);
     */
    trackColor?: string;
    /**
     * Color of the ProgressBar indicator
     * @default var(--blue-800);
     */
    indicatorColor?: string;
}

/**
 * ProgressBar Component
 *
 * Shows a bar that changes it's color when complete
 *
 * @example
 * <ProgressBar progress={25}>
 */
export default function ProgressBar({
    height = 'medium',
    progress = 0,
    changeColorOnComplete = true,
    completeColor,
    trackColor,
    indicatorColor,
}: ProgressBarProps) {
    const styles: CSSProperties = {
        backgroundColor: trackColor ?? 'var(--gray-400)',
    };

    if (height !== 'small' && height !== 'medium' && height !== 'larger') {
        styles.height = height;
    }

    let classes = `${css.track}`;
    if (height === 'small' || height === 'large') {
        classes = `${classes} ${css[height]}`;
    }

    const indicatorStyles: CSSProperties = {
        backgroundColor: indicatorColor ?? 'var(--blue-800)',
    };

    if (progress === 100 && changeColorOnComplete) {
        indicatorStyles.backgroundColor = completeColor ?? 'var(--green-800)';
    }

    return (
        <div style={{ width: '100%' }}>
            <Progress.Root className={classes} style={styles}>
                <Progress.Indicator
                    className={css.indicator}
                    style={{
                        transform: `translateX(-${100 - progress}%)`,
                        ...indicatorStyles,
                    }}
                />
            </Progress.Root>
        </div>
    );
}
