import { createContext, ReactNode, useEffect, useState } from 'react';

interface ThemeContextProps {
    children: ReactNode | ReactNode[];
}

export interface Colors extends Record<string, string | number> {
    pointer: number;
}

export type Theme = 'light' | 'dark' | 'system';
interface ThemeProps {
    setTheme: (theme: Theme) => void;
    theme: Theme;
    storageTheme: Theme;
}

interface ThemeData {
    storageTheme: Theme;
    theme: 'dark' | 'light';
}

export const ThemeContext = createContext({} as ThemeProps);

function themeSetter(newTheme: Theme): ThemeData {
    localStorage.setItem('theme', newTheme);

    if (newTheme === 'dark' || newTheme === 'light') {
        document.documentElement.dataset.theme = newTheme;
        return { theme: newTheme, storageTheme: newTheme };
    }

    let realTheme: 'dark' | 'light' = 'light';

    const darkThemeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    if (darkThemeMediaQuery.matches) {
        realTheme = 'dark';
    }

    document.documentElement.dataset.theme = realTheme;
    return { theme: realTheme, storageTheme: newTheme };
}

export function ThemeProvider({ children }: ThemeContextProps) {
    const storageTheme = localStorage.getItem('theme') as Theme;

    const [theme, setTheme] = useState<ThemeData>(themeSetter(storageTheme));

    useEffect(() => {
        window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (e) => {
            if (storageTheme === 'system') {
                document.body.className = `${theme.theme}`;
                setTheme(themeSetter('system'));
            }
        });
    }, []);

    return (
        <ThemeContext.Provider
            value={{
                storageTheme: theme.storageTheme,
                theme: theme.theme,
                setTheme: (newTheme) => {
                    document.body.className = `${newTheme}`;
                    setTheme(themeSetter(newTheme));
                },
            }}
        >
            <div id="theme-div" className={`${theme.theme}-mode local_${theme.theme}`}>
                {children}
            </div>
        </ThemeContext.Provider>
    );
}
