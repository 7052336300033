import styles from './Modal.module.scss';
import { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

export default function ModalBody(props: Props): JSX.Element {
    return (
        <main className={styles.body}>
            {props.children}
        </main>
    );
}
