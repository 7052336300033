import { Listbox } from '@headlessui/react';
import { CheckRegular } from '@vg-react/icons/regular';
import { ReactNode, useContext, useEffect } from 'react';
import css from './Select.module.scss';
import { SelectContext } from './SelectRoot';

interface ItemProps {
    size?: string;
    disabled?: boolean;
    children: ReactNode;
    value: string | number | boolean;
}

export default function SelectOption({ value, children, disabled }: ItemProps) {
    const context = useContext(SelectContext);

    useEffect(() => {
        context.register({ [value as string | number]: children });
        return () => context.unregister(value as string);
    }, []);

    const optionClasses = [css.option, ...[disabled && css.disabled]];

    return (
        <Listbox.Option value={value} disabled={disabled}>
            {({ active, selected }: { active: boolean; selected: boolean }) => (
                <div className={`${optionClasses.join(' ')} ${active && css.active}`}>
                    {selected ? (
                        <CheckRegular color="var(--accent-900)" size="14" />
                    ) : (
                        <div className={css.iconPlaceholder} />
                    )}
                    {children}
                </div>
            )}
        </Listbox.Option>
    );
}
