import { getUniqueKey } from '../../util/getUniqueKey';
import Checkbox from '../Checkbox/Checkbox';
import Table from '../Table/Table';
import { DataTableColumn } from './DataTable';

interface DataTableBodyContentProps<T> {
    rows: T[];
    selectedRows: T[];
    rowsHover: boolean;
    selectable: boolean;
    columns: DataTableColumn<T>[];
    selectableHasCheckbox: boolean;
    isRowSelectable: (row: T) => boolean;
    onRowDoubleClick?: (row: T) => void;
    onRowSelect: (row: T, selected: boolean) => void;
}

export function DataTableBodyContent<T>({
    rows,
    columns,
    selectedRows,
    rowsHover = false,
    selectable = false,
    selectableHasCheckbox = false,
    onRowSelect,
    onRowDoubleClick,
    isRowSelectable = () => true,
}: DataTableBodyContentProps<T>): JSX.Element {
    return (
        <>
            {rows.map((row) => {
                const rowKey = getUniqueKey('row');
                const isSelectable = isRowSelectable(row) && selectable;
                const isSelected =
                    selectedRows.find((selectedRow) => JSON.stringify(selectedRow) === JSON.stringify(row)) &&
                    isSelectable;

                return (
                    <Table.Row
                        key={rowKey}
                        hover={rowsHover}
                        selected={isSelected}
                        onClick={() => {
                            if (isSelectable) {
                                onRowSelect?.(row, !selectedRows.includes(row));
                            }
                        }}
                        onDoubleClick={() => onRowDoubleClick?.(row)}
                        style={{
                            cursor: isSelectable ? 'pointer' : selectable ? 'not-allowed' : 'default',
                            opacity: isSelectable || !selectable ? 1 : 0.5,
                        }}
                    >
                        {selectable && selectableHasCheckbox && (
                            <Table.Cell minWidth="auto">
                                <Checkbox
                                    name="select-row"
                                    value={isSelected}
                                    disabled={!isRowSelectable(row)}
                                    onChange={(selected) => onRowSelect?.(row, selected)}
                                />
                            </Table.Cell>
                        )}
                        {columns.map((column) => (
                            <Table.Cell
                                align={column.align}
                                key={getUniqueKey(`${rowKey}${column.accessor}`)}
                                minWidth={column.width}
                            >
                                {column.render(row)}
                            </Table.Cell>
                        ))}
                    </Table.Row>
                );
            })}
        </>
    );
}
