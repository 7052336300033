import { Response } from '@/contexts/Auth/AuthContext';
import { classnames } from '@/util/classnames';
import { ActionButton, Form, Input, PinInput, Stack, Text, Tooltip } from '@vg-react/components';
import { Fields } from '@vg-react/components/dist/components/Form/Form';
import { ArrowToLeftLight, ExclamationTriangleLight } from '@vg-react/icons/light';
import { InfoCircleRegular } from '@vg-react/icons/regular';
import { ArrowRightRegular } from '@vg-react/icons/v6/regular';
import { SparklesSolid } from '@vg-react/icons/v6/solid';
import { memo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import ACSLogo from '../../assets/images/ACSLogo';
import { ExperimentalFeatureLabel } from '../../components/Miscelaneous/SSOUser';
import useAuth from '../../hooks/useAuth';
import useFetch from '../../hooks/useFetch';
import useTheme from '../../hooks/useTheme';
import isValidEmail from '../../util/isEmailValid';
import request from '../../util/request';
import { ContainerToast } from './components/ContainerToast';
import { BackgroundGradientAnimation } from './components/GradientAnimation';
import { ThemeButton } from './components/ThemeButton';
import { TypewriterEffectSmooth } from './components/Typewriter';
import styles from './Login.module.scss';

interface LoginFields extends Fields {
    email: string;
    password: string;
    token?: string;
}

interface PasswordRecoverFields extends Fields {
    email: string;
}

type Screens = 'login' | 'passwordRecover' | 'forceLogin' | '2fa';

const phrases = {
    en: [
        'Connecting you to the future.',
        'Innovation that transforms your connection.',
        'Smarter connections.',
        'Technology that connects.',
        'Your new IXC ACS',
    ],
    es: [
        'Conectando você al futuro.',
        'Innovación que transforma su conexión.',
        'Conexiones más inteligentes.',
        'Tecnología que conecta.',
        'Su nuevo IXC ACS',
    ],
    pt: [
        'Conectando você ao futuro.',
        'A inovação que transforma sua conexão.',
        'Conexões mais inteligentes.',
        'Tecnologia que conecta.',
        'Seu novo IXC ACS',
    ],
};

export default function LoginScreen() {
    const { login, sessionExpired } = useAuth();
    const [loading, setLoading] = useState(false);
    const { theme, setTheme } = useTheme();
    const [invalid, setInvalid] = useState(false);
    const [formData, setFormData] = useState<Fields>({});
    const [screen, setScreen] = useState<Screens>('login');
    const location = useLocation();
    let redirectTo = location?.state?.from;
    if (window.location.port === '5174') redirectTo = `${window.location.origin}/home`;
    if (redirectTo === '/login' || redirectTo === '/heimdall/auth') redirectTo = '/home';

    const { data: ssoData } = useFetch<{ ssoUrl: string; ip: string }>('/app/login');
    const { t, i18n } = useTranslation();

    document.title = t('LoginScreen.pageTitle');

    function handleSubmit(data: LoginFields, force?: boolean) {
        const onError = (response: Response) => {
            setLoading(false);

            switch (response.status) {
                case 400:
                    setInvalid(false);
                    setScreen('2fa');
                    break;
                case 401:
                    if (screen !== '2fa') toast.error(t('LoginScreen.invalidCredentials'));
                    setInvalid(true);
                    break;
                case 403:
                    toast.error(t('LoginScreen.ipNotAllowed'));
                    setInvalid(true);
                    break;
                case 409:
                    setScreen('forceLogin');
                    break;
                default:
                    toast.error(t('LoginScreen.serverError'));
                    break;
            }
        };

        const onSuccess = () => {
            toast.success(t('LoginScreen.success'));
        };

        if (data.email && data.password) {
            setLoading(true);
            login({
                email: data.email,
                password: data.password,
                onSuccess,
                onError,
                redirectTo: location?.state?.from,
                force,
                ...(data.token && { token: data.token }),
            });
        } else {
            toast.error(t('LoginScreen.fillAllFields'));
        }
    }

    return (
        <Stack
            direction="row"
            width="100vw"
            height="100vh"
            spacing="0"
            className={classnames(styles.background, theme === 'dark' && styles.dark)}
        >
            <BackgroundGradientAnimation />
            <div className="absolute w-full h-full bg-[radial-gradient(ellipse_at_center,transparent_50%,rgb(0,0,0,30%))]"></div>
            <div className="absolute h-full w-full bg-transparent bg-dot-[rgb(0,0,0,20%)] flex items-center justify-center"></div>

            <div className={classnames(styles.backgroundContainer, theme === 'dark' && styles.dark)}>
                <ACSLogo mode={'dark'} style={{ height: 110 }} />
                <TypewriterEffectSmooth phrases={phrases[i18n.language as 'en' | 'es' | 'pt']} className="font-sm" />
            </div>
            <div className={classnames(styles.loginContainer, theme === 'dark' && styles.dark)}>
                <ThemeButton theme={theme} setTheme={setTheme} />
                <LanguageSelector />
                <div className={styles.loginBox}>
                    <div className={styles.loginHeader}>
                        <ACSLogo
                            mode={theme === 'dark' ? 'dark' : 'light'}
                            style={{
                                position: 'absolute',
                                top: 25,
                                left: 25,
                                height: '40px',
                                MozUserSelect: 'none',
                                userSelect: 'none',
                            }}
                        />
                    </div>
                    <div className={styles.transitionContainer}>
                        {screen === 'passwordRecover' && <RecoverPassword setScreen={setScreen} />}
                        {screen === 'forceLogin' && (
                            <Stack direction="column" className={styles.loginInternal}>
                                <button
                                    type="button"
                                    title={t('LoginScreen.back')}
                                    className={styles.backButton}
                                    onClick={() => setScreen('login')}
                                >
                                    <ArrowToLeftLight size="22" color="var(--gray-900)" style={{ cursor: 'pointer' }} />
                                </button>
                                <Stack spacing="var(--spacing-75)">
                                    <Text weight="regular" color="var(--gray-700)" align="justify" variant="p">
                                        {t('LoginScreen.activeSession')}
                                    </Text>
                                    <Text weight="regular" color="var(--gray-700)" align="justify" variant="p">
                                        {t('LoginScreen.closeSession')}
                                    </Text>
                                </Stack>
                                <ActionButton
                                    loading={loading}
                                    variant="secondary"
                                    style={{ alignSelf: 'end', paddingInline: 'var(--spacing-100)' }}
                                    onClick={() => handleSubmit(formData as LoginFields, true)}
                                >
                                    {t('LoginScreen.continue')}
                                    <ArrowRightRegular size="14" color="var(--accent-900)" />
                                </ActionButton>
                            </Stack>
                        )}
                        {screen === 'login' && (
                            <div className={styles.loginInternal}>
                                <Stack direction="row" align="center" spacing="var(--spacing-75)">
                                    <Text size="xl" weight="bold" color="var(--gray-900)">
                                        {t('LoginScreen.welcome')}
                                    </Text>
                                    <SparklesSolid size="20" color="var(--gray-900)" />
                                </Stack>
                                <Text
                                    className={styles.text}
                                    weight="regular"
                                    color="var(--gray-700)"
                                    align="justify"
                                    variant="p"
                                >
                                    {t('LoginScreen.instructions')}
                                    <a
                                        className={styles.link}
                                        target="_blank"
                                        href={t('LoginScreen.linkSupport')}
                                        rel="noopener noreferrer"
                                    >
                                        {' '}
                                        {t('LoginScreen.support')}
                                    </a>
                                    .
                                </Text>
                                <Form<LoginFields>
                                    onSubmit={handleSubmit}
                                    outsideState={formData}
                                    setOutsideState={setFormData}
                                >
                                    <div className={styles.form}>
                                        <div className={styles.fields}>
                                            <Input
                                                className={styles.input}
                                                style={{ paddingInline: 'var(--spacing-50)' }}
                                                invalid={invalid}
                                                inputSize="medium"
                                                name="email"
                                                width="auto"
                                                placeholder={t('LoginScreen.emailPlaceholder')}
                                                label={t('LoginScreen.emailLabel')}
                                            />
                                            <Input
                                                className={styles.input}
                                                style={{ paddingInline: 'var(--spacing-50)' }}
                                                invalid={invalid}
                                                inputSize="medium"
                                                name="password"
                                                width="auto"
                                                type="password"
                                                placeholder={t('LoginScreen.passwordPlaceholder')}
                                                label={t('LoginScreen.passwordLabel')}
                                            />
                                        </div>
                                        <Stack direction="column" width="100%">
                                            <ActionButton
                                                style={{ height: '2.25rem', fontSize: '.85rem', borderRadius: 10 }}
                                                loading={loading}
                                                variant="secondary"
                                                width="100%"
                                                type="submit"
                                            >
                                                {t('LoginScreen.enter')}
                                                <ArrowRightRegular size="14" color="currentColor" />
                                            </ActionButton>
                                            <Text
                                                weight="light"
                                                align="right"
                                                size="exs"
                                                color="var(--lightness-700)"
                                                style={{ marginTop: -20, fontStyle: 'italic' }}
                                                variant="span"
                                            >
                                                {t('LoginScreen.yourIp')}: {ssoData?.ip}
                                            </Text>
                                            <Stack
                                                direction="row"
                                                justify={ssoData?.ssoUrl ? 'space-between' : 'center'}
                                                align="center"
                                            >
                                                {ssoData?.ssoUrl && (
                                                    <Text weight="regular" variant="p">
                                                        <a
                                                            className={styles.link}
                                                            href={`${ssoData.ssoUrl}?redirect=${redirectTo || '/home'}`}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                align="center"
                                                                justify="center"
                                                                spacing="var(--spacing-75)"
                                                            >
                                                                <ExperimentalFeatureLabel size="16" />
                                                                {t('LoginScreen.ssoLogin')}
                                                            </Stack>
                                                        </a>
                                                    </Text>
                                                )}
                                                <Text weight="regular" variant="p">
                                                    <a
                                                        className={styles.link}
                                                        href="#"
                                                        onClick={() => setScreen('passwordRecover')}
                                                    >
                                                        {t('LoginScreen.forgotPassword')}
                                                    </a>
                                                </Text>
                                            </Stack>
                                        </Stack>
                                        {sessionExpired && (
                                            <Stack
                                                direction="row"
                                                spacing="var(--spacing-75)"
                                                align="center"
                                                width="fit-content"
                                                justify="center"
                                                style={{
                                                    alignSelf: 'center',
                                                    marginTop: 'var(--spacing-100)',
                                                    paddingBlock: 'var(--spacing-75)',
                                                    paddingInline: 'var(--spacing-100)',
                                                    borderRadius: 'var(--radius-medium)',
                                                    backgroundColor: 'var(--lightness-950)',
                                                }}
                                            >
                                                <InfoCircleRegular size="18" color="var(--lightness-400)" />
                                                <Text color="var(--lightness-400)" size="xs" weight="regular">
                                                    {t('LoginScreen.sessionExpired')}
                                                </Text>
                                            </Stack>
                                        )}
                                    </div>
                                </Form>
                            </div>
                        )}
                        {screen === '2fa' && (
                            <Stack
                                style={{ margin: 'var(--spacing-100)' }}
                                spacing="large"
                                align="center"
                                justify="center"
                            >
                                <button
                                    type="button"
                                    title="Voltar"
                                    className={styles.backButton}
                                    onClick={() => setScreen('login')}
                                >
                                    <ArrowToLeftLight size="22" color="var(--gray-900)" style={{ cursor: 'pointer' }} />
                                </button>
                                <Text size="xs" weight="regular">
                                    {t('LoginScreen.2fa.instruction')}
                                </Text>
                                <Stack spacing="var(--spacing-50)" align="start" justify="center">
                                    <Text size="xxs" weight="bold" color="var(--gray-600)">
                                        {t('LoginScreen.2fa.codeLabel')}
                                    </Text>
                                    <PinInput
                                        invalid={invalid}
                                        onChange={() => setInvalid(false)}
                                        placeholder="*"
                                        onComplete={(token) => {
                                            handleSubmit({ ...formData, token } as LoginFields, true);
                                        }}
                                    />
                                </Stack>
                                <Stack
                                    direction="row"
                                    spacing="var(--spacing-75)"
                                    align="center"
                                    width="fit-content"
                                    justify="center"
                                    style={{
                                        paddingBlock: 'var(--spacing-75)',
                                        paddingInline: 'var(--spacing-100)',
                                        borderRadius: 'var(--radius-medium)',
                                        backgroundColor: 'var(--yellow-300-a30)',
                                    }}
                                >
                                    <ExclamationTriangleLight size="18" color="var(--yellow-700)" />
                                    <Text color="var(--yellow-700)" size="xs" weight="regular">
                                        {t('LoginScreen.2fa.warning')}
                                        <br />
                                        {t('LoginScreen.2fa.warning2')}
                                    </Text>
                                </Stack>
                            </Stack>
                        )}
                    </div>
                </div>
            </div>
            <ContainerToast />
        </Stack>
    );
}

interface RecoverPasswordProps {
    setScreen: (screen: Screens) => void;
}

function RecoverPassword({ setScreen }: RecoverPasswordProps) {
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [firstSubmit, setFirstSubmit] = useState<boolean>(false);

    const { t } = useTranslation();
    const handlePasswordRecover = (data: PasswordRecoverFields) => {
        if (!isValidEmail(data.email)) {
            setFirstSubmit(true);
            return;
        }

        if (data.email) {
            setLoading(true);
            request
                .post('/app/login/forgotPassword/', {
                    email: data.email.trim(),
                })
                .then((response) => {
                    setLoading(false);
                    toast.success(response.data.message);
                    setScreen('login');
                });
        } else {
            toast.error(t('LoginScreen.recoverPassword.fillAllFields'));
        }
    };

    const handleOnChange = (data: PasswordRecoverFields) => {
        setDisabled(!isValidEmail(data.email));
        if (firstSubmit) setInvalid(!isValidEmail(data.email));
    };

    return (
        <Stack direction="column" className={styles.loginInternal} justify="center">
            <button type="button" title="Voltar" className={styles.backButton} onClick={() => setScreen('login')}>
                <ArrowToLeftLight size="22" color="var(--gray-900)" style={{ cursor: 'pointer' }} />
            </button>
            <Text variant="h6" align="justify" weight="regular" color="var(--gray-700)">
                {t('LoginScreen.recoverPassword.instruction')}
            </Text>
            <Form<PasswordRecoverFields> onSubmit={handlePasswordRecover} onChange={handleOnChange}>
                <Stack>
                    <Input
                        name="email"
                        width="auto"
                        label={t('LoginScreen.recoverPassword.emailLabel')}
                        invalid={invalid}
                        inputSize="medium"
                        placeholder={t('LoginScreen.recoverPassword.emailPlaceholder')}
                        helpText={invalid ? t('LoginScreen.recoverPassword.invalidEmail') : ''}
                    />
                    <Stack direction="column" width="auto">
                        <ActionButton
                            width="100%"
                            type="submit"
                            loading={loading}
                            variant="secondary"
                            disabled={disabled}
                        >
                            {t('LoginScreen.recoverPassword.recover')}
                        </ActionButton>
                    </Stack>
                </Stack>
            </Form>
        </Stack>
    );
}

const LanguageSelector = memo(() => {
    const { i18n } = useTranslation();

    function setLanguage(lang: 'en' | 'es' | 'pt') {
        i18n.changeLanguage(lang);
        localStorage.setItem('tr069_lang', lang);
        document.cookie = `tr069_lang=${lang};path=/;`;
    }

    return (
        <div className={styles.languageSelector}>
            <Tooltip>
                <Tooltip.Trigger>
                    <button
                        onClick={() => setLanguage('en')}
                        className={classnames(styles.changeButton, i18n.language === 'en' && styles.selected)}
                    >
                        EN
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Content>English</Tooltip.Content>
            </Tooltip>
            <Tooltip>
                <Tooltip.Trigger>
                    <button
                        onClick={() => setLanguage('es')}
                        className={classnames(styles.changeButton, i18n.language === 'es' && styles.selected)}
                    >
                        ES
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Content>Español</Tooltip.Content>
            </Tooltip>
            <Tooltip>
                <Tooltip.Trigger>
                    <button
                        onClick={() => setLanguage('pt')}
                        className={classnames(styles.changeButton, i18n.language === 'pt' && styles.selected)}
                    >
                        PT
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Content>Português</Tooltip.Content>
            </Tooltip>
        </div>
    );
});

LanguageSelector.displayName = 'LanguageSelector';
