import useCapability from '@/atoms/capabilityAtom';
import ActionButton from '@/components/ActionButton/ActionButton';
import Form from '@/components/Form/Form';
import Modal from '@/components/Modal/Modal';
import Select from '@/components/Select/Select';
import SelectOption from '@/components/Select/SelectOption';
import Stack from '@/components/Stack/Stack';
import ToggleButton from '@/components/ToggleButton/ToggleButton';
import Tooltip from '@/components/Tooltip/Tooltip';
import { QuestionCircleLight, SaveLight } from '@vg-react/icons/light';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSocket from '../../../../../../../../hooks/useSocket';
import request from '../../../../../../../../util/request';
import { UpdateDataSocket } from './types/Types';

export type ModalEditBandWidthProps = {
    id: string;
    active: boolean;
    bandWidth: '2.4' | '5.8';
    value: 'Auto' | '20' | '40' | '80' | '160';
    instance: string;
    refresh?: () => void;
    possibleChannelBandWidths?: string;
    onClose: () => void;
    setEditButtonDisable: (value: boolean) => void;
    setDeviceOperation: (value: { message: string; status: 'default' | 'in progress' | 'success' | 'error' }) => void;
};

type EditNameFields = {
    channelBandWidth: string;
    autoBandWidth: boolean;
    bandWidth: string;
};

export default function ModalEditBandWidth({
    id,
    active,
    value,
    instance,
    bandWidth,
    refresh,
    onClose,
    setEditButtonDisable,
    setDeviceOperation,
    possibleChannelBandWidths,
}: ModalEditBandWidthProps) {
    const { t } = useTranslation();
    const { socket } = useSocket();
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const [autoBandWidth, setAutoBandWidth] = useState<boolean>(value === 'Auto');
    const [initialValues, setInitialValues] = useState({
        bandWidth: value.split('MHz')[0],
        autoBandWidth: value === 'Auto',
    });
    const [capability] = useCapability();
    const wifiCapability = capability.WiFi;

    function handleOnChange(fields: EditNameFields) {
        setAutoBandWidth(fields.autoBandWidth);

        setDisabled(JSON.stringify(fields) === JSON.stringify(initialValues));
    }

    function handleOnSubmit(fields: EditNameFields) {
        setLoading(true);
        request
            .patch(`/app/devices/${id}/wifi/${instance}`, {
                channelBandWidth: autoBandWidth ? 'Auto' : fields.bandWidth || 'Auto',
            })
            .then(() => {
                toast.info(
                    t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.tostSending'),
                );
                setDeviceOperation({
                    status: 'in progress',
                    message: t(
                        'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.sending',
                    ),
                });
                socket?.on('updateWifiParameter', (data: UpdateDataSocket) => {
                    setDeviceOperation({ status: data.status, message: data.message });

                    if (data.status === 'in progress') return;

                    if (data.status === 'success') {
                        toast.success(data.message);
                        setDeviceOperation({ status: data.status, message: data.message });
                    } else {
                        setDeviceOperation({ status: data.status, message: data.message });
                    }
                    socket.off('updateWifiParameter');
                    setEditButtonDisable(false);
                    setTimeout(() => {
                        refresh?.();
                        setDeviceOperation({
                            message: t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.message',
                            ),
                            status: 'default',
                        });
                    }, 5000);
                });
                setEditButtonDisable(true);
                onClose();
            })
            .catch((error) => {
                setEditButtonDisable(true);
                toast.error(error.response.data);
            });
    }

    const possibleBandwidths = useMemo(() => {
        if (!possibleChannelBandWidths) {
            if (bandWidth.includes('2')) return ['20', '40'];
            return ['40', '80'];
        }

        const filteredBandwidths: string[] = [];
        possibleChannelBandWidths.split(',').forEach((bw) => {
            const [match] = /(\d+)/.exec(bw) ?? [];
            if (match) filteredBandwidths.push(match);
        });

        return filteredBandwidths;
    }, [possibleChannelBandWidths]);
    return (
        <Modal size="small" active={active} onClose={onClose}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.title')}
                </Modal.Title>
            </Modal.Header>
            <Form<EditNameFields> initialValues={initialValues} onSubmit={handleOnSubmit} onChange={handleOnChange}>
                <Modal.Body>
                    <Stack spacing="var(--spacing-300)">
                        <Stack direction="row" spacing="var(--spacing-100)">
                            <ToggleButton
                                disabled={wifiCapability?.autoBandWidth}
                                name="autoBandWidth"
                                label={t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.labelAutoToogle',
                                )}
                                labelSide="right"
                                key="name"
                            ></ToggleButton>
                            {wifiCapability?.autoBandWidth && (
                                <Tooltip placement="right">
                                    <Tooltip.Trigger style={{ height: '20px' }}>
                                        <QuestionCircleLight size="20" color="var(--yellow-800)" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.notAvailable',
                                        )}
                                    </Tooltip.Content>
                                </Tooltip>
                            )}
                        </Stack>
                        <Select
                            disabled={autoBandWidth}
                            width="auto"
                            initialValue={initialValues.bandWidth}
                            label={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.labelBandWidth',
                            )}
                            name="bandWidth"
                            placeholder={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.placeholderBandWidth',
                            )}
                            description={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.descriptionBandWidth',
                            )}
                        >
                            {possibleBandwidths.map((channelBandWidth) => (
                                <SelectOption key={channelBandWidth} value={channelBandWidth}>
                                    {channelBandWidth} MHz
                                </SelectOption>
                            ))}
                        </Select>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={onClose} quiet>
                            {t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.cancel',
                            )}
                        </ActionButton>
                        <ActionButton type="submit" loading={loading} disabled={disabled}>
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                <SaveLight size="16" color={'currentColor'} />
                                {t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditBandWidth.save',
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
