/* eslint-disable @typescript-eslint/no-explicit-any */
import { BooleanCapabilityType, CapabilityType } from '@/atoms/capabilityAtom';
import useAuth from '@/hooks/useAuth';
import usePermissions from '@/hooks/usePermissions';
import request from '@/util/request';
import { useQuery } from '@tanstack/react-query';
import { t } from 'i18next';
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { autoConfigurationDeviceSchema, AutoConfigurationDeviceTypeZod } from './ValidationsZod';

export interface WebAccess {
    enableRemoteAccess?: boolean;
    username?: string;
    password?: string;
    webPort?: string;
}

export interface DnsWan {
    dnsManual: boolean;
    primaryDns?: string;
    secondaryDns?: string;
}

export interface DnsLan {
    primaryDns?: string;
    secondaryDns?: string;
}

export interface Ntp {
    primaryNtp?: string;
    secondaryNtp?: string;
}

export interface RegulatoryDomain {
    value?: string;
}

export interface BindLayer {
    lan1: boolean;
    lan2: boolean;
    lan3: boolean;
    lan4: boolean;
}

export interface DevicesWithCapability {
    count: number;
    models: {
        count: number;
        hardwareVersion: string;
        hasNotCapability: Partial<CapabilityType & BooleanCapabilityType>;
        manufacturer: string;
        modelName: string;
    }[];
}

export interface AutoConfigurationDevice {
    _id: string;
    configurations: {
        webAccess?: WebAccess;
        dnsWan?: DnsWan;
        dnsLan?: DnsLan;
        ntp?: Ntp;
        regulatoryDomain?: RegulatoryDomain;
        bindLayer?: BindLayer;
    };
    userId: string;
    name: string;
    userEmail: string;
    devicesSetUp: { sn: string; date: Date }[];
    enabled: boolean;
    createdAt: Date;
    updatedAt: Date;
}

type AutoConfigurationDeviceContextType = {
    configs: AutoConfigurationDeviceTypeZod;
    errors: Partial<AutoConfigurationDeviceTypeZod>;
    configurations: AutoConfigurationDevice[];
    modelCapabilities?: DevicesWithCapability;
    hasAnyConfigured: boolean;
    configIsDifferentToConfigurations: boolean;
    existsErrors: boolean;
    setConfig: (
        field: keyof AutoConfigurationDeviceTypeZod,
        value: Partial<AutoConfigurationDeviceTypeZod[keyof AutoConfigurationDeviceTypeZod]>,
    ) => void;
    currentFieldSelected: keyof AutoConfigurationDeviceTypeZod | null;
    setCurrentFieldSelected: (field: keyof AutoConfigurationDeviceTypeZod) => void;
    validateConfigs: () => boolean;
    saveConfigs: () => Promise<void>;
    deleteConfiguration: () => Promise<void>;
    refreshAutoConfiguration: () => void;
    setConfigured: (field: keyof AutoConfigurationDeviceTypeZod, value: { configured: boolean }) => void;
    changeEnabled: () => Promise<void>;
    clearConfigs: () => void;
    populateConfigs: () => void;
};

const AutoConfigurationDeviceContext = createContext<AutoConfigurationDeviceContextType | undefined>(undefined);

export const AutoConfigurationDeviceProvider = ({ children }: { children: ReactNode }) => {
    const { user } = useAuth();
    const { validatePermission } = usePermissions();

    const defaultConfig = {
        webAccess: { configured: false, enableRemoteAccess: undefined, username: '', password: '', webPort: '' },
        dnsWan: { configured: false, dnsManual: undefined, primaryDns: '', secondaryDns: '' },
        dnsLan: { configured: false, primaryDns: '', secondaryDns: '' },
        ntp: { configured: false, primaryNtp: '', secondaryNtp: '' },
        regulatoryDomain: { configured: false, value: '' },
        bindLayer: { configured: false, lan1: undefined, lan2: undefined, lan3: undefined, lan4: undefined },
    };

    const { data: configurations, refetch: refresh } = useQuery<AutoConfigurationDevice[]>({
        queryKey: ['AutoConfigurationDevice'],
        queryFn: async () => {
            const response = await fetch('/app/autoconfiguration');
            return response.json();
        },
    });

    const { data: modelCapabilities, refetch: refreshModelCapabilities } = useQuery<DevicesWithCapability>({
        queryKey: ['DevicesWithCapability'],
        queryFn: async () => {
            const response = await fetch('/app/autoconfiguration/model-capabilities');
            return response.json();
        },
    });

    const [configs, setConfigs] = useState<AutoConfigurationDeviceTypeZod>(defaultConfig);
    const [configIsDifferentToConfigurations, setConfigIsDifferentToConfigurations] = useState(true);

    const populateConfigs = () => {
        if (configurations && configurations.length > 0) {
            const currentConfig = configurations[0];
            setConfigs({
                webAccess: {
                    configured: Object.values(currentConfig.configurations?.webAccess || {}).some(
                        (value) => value !== undefined && value !== '',
                    ),
                    ...currentConfig.configurations?.webAccess,
                },
                dnsWan: {
                    configured: Object.values(currentConfig.configurations?.dnsWan || {}).some(
                        (value) => value !== undefined && value !== '',
                    ),
                    ...currentConfig.configurations?.dnsWan,
                },
                dnsLan: {
                    configured: Object.values(currentConfig.configurations?.dnsLan || {}).some(
                        (value) => value !== undefined && value !== '',
                    ),
                    ...currentConfig.configurations?.dnsLan,
                },
                ntp: {
                    configured: Object.values(currentConfig.configurations?.ntp || {}).some(
                        (value) => value !== undefined && value !== '',
                    ),
                    ...currentConfig.configurations?.ntp,
                },
                regulatoryDomain: {
                    configured: Object.values(currentConfig.configurations?.regulatoryDomain || {}).some(
                        (value) => value !== undefined && value !== '',
                    ),
                    ...currentConfig.configurations?.regulatoryDomain,
                },
                bindLayer: {
                    configured: Object.values(currentConfig.configurations?.bindLayer || {}).some(
                        (value) => value !== undefined,
                    ),
                    ...currentConfig.configurations?.bindLayer,
                },
            });
        }
    };

    const verifyConfigIsDifferentToConfigurations = () => {
        if (!configurations || configurations.length === 0) {
            setConfigIsDifferentToConfigurations(true);
            return;
        }

        const currentConfig = configurations[0].configurations;
        let isDifferent = false;

        // Verificar apenas as seções que estão configuradas no formulário atual
        for (const key in configs) {
            const configSection = configs[key as keyof AutoConfigurationDeviceTypeZod];
            const currentConfigSection = currentConfig?.[key as keyof typeof currentConfig];

            // Se a seção está configurada no formulário atual
            if (configSection.configured) {
                // Verificar se a seção está configurada diferente no banco
                const isSectionConfiguredInDB = currentConfigSection &&
                    Object.entries(currentConfigSection)
                        .some(([k, v]) => k !== 'configured' && v !== undefined && v !== '');

                // Se o status de configuração é diferente
                if (configSection.configured !== isSectionConfiguredInDB) {
                    isDifferent = true;
                    break;
                }

                // Comparar os valores dos campos
                for (const prop in configSection) {
                    if (prop !== 'configured') {
                        const configValue = configSection[prop as keyof typeof configSection];
                        const currentValue = currentConfigSection?.[prop as keyof typeof currentConfigSection];

                        if (configValue !== currentValue) {
                            isDifferent = true;
                            break;
                        }
                    }
                }
            } else if (currentConfigSection) {
                // Se a seção não está configurada no formulário, mas está no banco
                const isSectionConfiguredInDB = Object.entries(currentConfigSection)
                    .some(([k, v]) => k !== 'configured' && v !== undefined && v !== '');

                if (isSectionConfiguredInDB) {
                    isDifferent = true;
                    break;
                }
            }

            if (isDifferent) break;
        }

        setConfigIsDifferentToConfigurations(isDifferent);
    };

    useEffect(() => {
        populateConfigs();
    }, [configurations]);

    const [currentFieldSelected, setCurrentFieldSelected] = useState<keyof AutoConfigurationDeviceTypeZod>('webAccess');

    const [errors, setErrors] = useState<Partial<AutoConfigurationDeviceTypeZod>>({});
    const [existsErrors, setExistsErrors] = useState(false);

    const clearConfigs = () => {
        setConfigs(defaultConfig);
        setCurrentFieldSelected('webAccess');
        setExistsErrors(false);
    };

    const setConfig = (
        field: keyof AutoConfigurationDeviceTypeZod,
        value: Partial<AutoConfigurationDeviceTypeZod[keyof AutoConfigurationDeviceTypeZod]>,
    ) => {
        setConfigs((prev: AutoConfigurationDeviceTypeZod) => {
            const updatedField = { ...prev[field], ...value };

            const isConfigured = Object.keys(updatedField).some((key) => {
                if (key === 'configured') return false;

                const fieldValue = updatedField[key as keyof typeof updatedField];

                if (typeof fieldValue === 'boolean') return true;
                if (typeof fieldValue === 'string') return String(fieldValue).trim() !== '';
                return fieldValue !== undefined;
            });
            return { ...prev, [field]: { ...updatedField, configured: isConfigured } };
        });
    };

    const setConfigured = (field: keyof AutoConfigurationDeviceTypeZod, value: { configured: boolean }) => {
        setConfigs((prev) => ({
            ...prev,
            [field]: {
                ...prev[field],
                configured: value.configured,
            },
        }));
    };

    const verifyHasAnyConfigured = (configs: AutoConfigurationDeviceTypeZod): boolean => {
        return Object.values(configs).some((config) => config.configured === true);
    };
    const [hasAnyConfigured, setHasAnyConfigured] = useState(verifyHasAnyConfigured(configs));

    const validateConfigs = (): boolean => {
        try {
            autoConfigurationDeviceSchema.parse(configs);
            setErrors({});
            setExistsErrors(false);
            return true;
        } catch (error) {
            if (error instanceof z.ZodError) {
                const newErrors: Partial<AutoConfigurationDeviceTypeZod> = {};

                error.errors.forEach((err) => {
                    const path = err.path.join('.');
                    const message = err.message;

                    const [field, key] = path.split('.');
                    if (field && key) {
                        if (!newErrors[field as keyof AutoConfigurationDeviceTypeZod]) {
                            newErrors[field as keyof AutoConfigurationDeviceTypeZod] = {} as any;
                        }
                        (newErrors[field as keyof AutoConfigurationDeviceTypeZod] as any)[key] = message;
                    }
                });
                setErrors(newErrors);
            }
            setExistsErrors(true);
            return false;
        }
    };

    function filterConfigsForBackend(configs: AutoConfigurationDeviceTypeZod) {
        const filteredConfigs = { ...configs };

        Object.keys(filteredConfigs).forEach((key) => {
            const value = (filteredConfigs as any)[key];
            if (value?.configured === false) {
                delete (filteredConfigs as any)[key];
            }
        });

        Object.keys(filteredConfigs).forEach((key) => {
            const value = (filteredConfigs as any)[key];
            if (value && typeof value === 'object') {
                delete value.configured;
            }
        });

        if (configs.bindLayer.configured || configs.bindLayer.lan1 || configs.bindLayer.lan2 || configs.bindLayer.lan3 || configs.bindLayer.lan4) {
            filteredConfigs.bindLayer = {
                configured: configs.bindLayer.configured || true,
                lan1: configs.bindLayer.lan1 || false,
                lan2: configs.bindLayer.lan2 || false,
                lan3: configs.bindLayer.lan3 || false,
                lan4: configs.bindLayer.lan4 || false,
            };
        }

        return filteredConfigs;
    }

    const saveConfigs = async () => {
        if (!validateConfigs()) return;
        if (!verifyHasAnyConfigured(configs)) return;
        if (configurations && configurations.length > 0) {
            if (!validatePermission('/app/autoconfiguration/:id', 'edit')) return;
            try {
                await request.put(`/app/autoconfiguration/${configurations[0]._id}`, {
                    configurations: filterConfigsForBackend(configs),
                    userEmail: user?.email,
                });
                await refresh();
                toast.success(t('ToolsScreen.AutoConfigurationDeviceScreen.successSave'));
            } catch (error) {
                toast.error(t('ToolsScreen.AutoConfigurationDeviceScreen.errorSave'));
            }
        } else {
            if (!validatePermission('/app/autoconfiguration', 'post')) return;
            try {
                await request.post('/app/autoconfiguration', {
                    configurations: filterConfigsForBackend(configs),
                    userId: user?.id,
                    userEmail: user?.email,
                });
                await refresh();
                toast.success(t('ToolsScreen.AutoConfigurationDeviceScreen.successSave'));
            } catch (error) {
                toast.error(t('ToolsScreen.AutoConfigurationDeviceScreen.errorSave'));
            }
        }
    };

    const changeEnabled = async () => {
        if (configurations && configurations.length > 0) {
            if (!validatePermission('/app/autoconfiguration/:id', 'edit')) return;
            try {
                await request.put(`/app/autoconfiguration/${configurations[0]._id}`, {
                    enabled: !configurations[0].enabled,
                });
                await refresh();
                toast.success(t('ToolsScreen.AutoConfigurationDeviceScreen.successSave'));
            } catch (error) {
                toast.error(t('ToolsScreen.AutoConfigurationDeviceScreen.errorSave'));
            }
        }
    };

    const deleteConfiguration = async () => {
        if (configurations && configurations.length > 0) {
            await request.delete(`/app/autoconfiguration/${configurations[0]._id}`);
            await refresh();
            setConfigs({
                webAccess: {
                    configured: false,
                    enableRemoteAccess: undefined,
                    username: '',
                    password: '',
                    webPort: '',
                },
                dnsWan: { configured: false, dnsManual: undefined, primaryDns: '', secondaryDns: '' },
                dnsLan: { configured: false, primaryDns: '', secondaryDns: '' },
                ntp: { configured: false, primaryNtp: '', secondaryNtp: '' },
                regulatoryDomain: { configured: false, value: '' },
                bindLayer: { configured: false, lan1: undefined, lan2: undefined, lan3: undefined, lan4: undefined },
            });
            toast.success(t('ToolsScreen.AutoConfigurationDeviceScreen.successDelete'));
        }
    };

    useEffect(() => {
        validateConfigs();
        setHasAnyConfigured(verifyHasAnyConfigured(configs));
    }, [configs]);

    useEffect(() => {
        verifyConfigIsDifferentToConfigurations();
    }, [configs, configurations]);

    return (
        <AutoConfigurationDeviceContext.Provider
            value={{
                changeEnabled,
                configs,
                errors,
                existsErrors,
                configurations: configurations || [],
                modelCapabilities: modelCapabilities || {
                    count: 0,
                    models: [],
                },
                setConfig,
                validateConfigs,
                currentFieldSelected,
                setCurrentFieldSelected,
                saveConfigs,
                hasAnyConfigured,
                setConfigured,
                deleteConfiguration,
                refreshAutoConfiguration: refresh,
                clearConfigs,
                populateConfigs,
                configIsDifferentToConfigurations,
            }}
        >
            {children}
        </AutoConfigurationDeviceContext.Provider>
    );
};

export const useAutoConfigurationDevice = () => {
    const context = useContext(AutoConfigurationDeviceContext);
    if (!context) throw new Error('useAutoConfigurationDevice must be used within an AutoConfigurationDeviceProvider');
    return context;
};
