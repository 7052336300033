import { CheckLight } from '@vg-react/icons/light';
import React, { CSSProperties } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { classnames } from '../../util/classnames';
import { useForm } from '../Form/useForm';
import css from './Checkbox.module.scss';

export interface CheckboxProps {
    /**
     * set checkbox size. If using a custom size, set a checkSize
     * @default 'medium'
     */
    size?: 'small' | 'medium' | 'large' | string;
    /** if using a custom size, set a checkSize */
    checkSize?: string;
    /** name of the field for form control */
    name: string;
    /** label to be shown on the right of the checkbox */
    label?: string | JSX.Element;
    /**
     * side of the label
     * @default 'right'
     */
    labelSide?: 'left' | 'right';
    /** callback function to control from outside */
    onChange?: (value: boolean) => void;
    /** value that will be set when controlling from outside */
    value?: boolean;
    /**
     * Wheter the Checkbox is disabled or not
     * @default false
     */
    disabled?: boolean;
    /**
     * if true, the checkbox will be in a semi-selected state
     */
    thirdState?: boolean;
}

/**
 * Checkbox component
 *
 * If inside a Form, value will be automatically captured.
 *
 * Outside of a Form, must be used as a Controlled Component.
 *
 * @example
 * //Inside a form (the value will be output on form callbacks)
 * <Form>
 *     <Checkbox
 *          name='my-checkbox'
 *          label='My Checkbox'
 *          size='large'
 *      />
 * </Form>
 *
 * // Outside a form
 * const [state, setState] = useState(false);
 *
 * <Checkbox
 *     name='my-checkbox'
 *     label='My Checkbox'
 *     value={state}
 *     onChange={setState}
 * />
 */
export default function Checkbox({
    size = 'medium',
    checkSize,
    name,
    label,
    value,
    onChange,
    disabled = false,
    labelSide = 'right',
    thirdState,
    ...props
}: CheckboxProps) {
    const { value: valueForm, onChange: onChangeForm } = useForm<boolean>(name, false);

    function handleCheck() {
        if (onChange) onChange(!value);
        else if (onChangeForm) onChangeForm(!valueForm);
    }

    const id = uuidv4();

    const styles: CSSProperties = {};

    if (size !== 'small' && size !== 'medium' && size !== 'large') {
        styles.width = size;
        styles.height = size;
    }

    const iconSize = {
        small: '10px',
        medium: '14px',
        large: '18px',
    }[size];

    function handleKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.key === ' ' || event.key === 'Enter') {
            handleCheck();
        }
    }

    const Label = (
        <label style={disabled ? { pointerEvents: 'none' } : {}} className={css.label} htmlFor={id}>
            {label}
        </label>
    );

    return (
        <div className={css.container}>
            {label && labelSide === 'left' && Label}
            <div style={{ cursor: disabled ? 'not-allowed' : 'default' }}>
                <div
                    tabIndex={disabled ? -1 : 0}
                    style={styles}
                    className={classnames(
                        css.checkbox,
                        css[size],
                        disabled && css.disabled,
                        (value || valueForm) && css.checked,
                        thirdState && css.thirdState,
                    )}
                    onClick={handleCheck}
                    onKeyDown={handleKeyDown}
                    {...props}
                >
                    <input
                        tabIndex={-1}
                        id={id}
                        className={css.hidden}
                        type="checkbox"
                        defaultChecked={typeof value !== 'undefined' ? value : valueForm}
                    />
                    {(value || valueForm) && !thirdState && (
                        <CheckLight className={css.icon} color="var(--gray-50)" size={checkSize ?? iconSize} />
                    )}
                </div>
            </div>
            {label && labelSide === 'right' && Label}
        </div>
    );
}
