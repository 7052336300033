import { ToastContainer } from 'react-toastify';

export function ContainerToast() {
    return (
        <ToastContainer
            position='bottom-right'
            hideProgressBar={false}
            closeOnClick={true}
            autoClose={5000}
            theme='colored'
            pauseOnHover
            draggable
        />
    );
}