import { CSSProperties, ReactNode, useContext } from 'react';
import { classnames } from '../../util/classnames';
import ResizeTrigger from '../ResizeTrigger/ResizeTrigger';
import styles from './table.module.scss';
import { TableContext } from './TableRoot';

interface TableCellProps {
    /**
     * The content of the table cell. A React node or a string.
     */
    children: ReactNode;
    /**
     * The alignment of the content of the cell.
     */
    align?: 'left' | 'center' | 'right';
    /**
     * Defines the cell width.
     */
    minWidth?: undefined | CSSProperties['minWidth'];
    /**
     * Defines the maximum cell width.
     */
    maxWidth?: CSSProperties['maxWidth'];
    /**
     * Defines if the cell doesn't have padding.
     */
    noPadding?: boolean;
    /**
     * Defines a custom class name for the cell.
     */
    className?: string;
    /**
     * Defines a custom style for the cell.
     */
    style?: CSSProperties;
    /**
     * Column accessor.
     */
    accessor?: string;
    /**
     * Defines if the column is resizable.
     */
    resizable?: boolean;
    /**
     * Defines a function to be executed when the column is resized.
     */
    onResize?: (movement: number) => void;
}

/**
 * A cell of the table. It renders a `td` element with table cell styles.
 * @example
 * <TableCell>
 *     ...
 * </TableCell>
 * <TableCell align="right">
 *     ...
 * </TableCell>
 * <TableCell fitContent>
 *     ...
 * </TableCell>
 */
export default function TableCell({
    children,
    align = 'left',
    minWidth = '100px',
    noPadding = false,
    className,
    style,
    accessor,
    resizable = false,
    onResize,
}: TableCellProps) {
    const tableWidth = useContext(TableContext).props?.width;

    const cellWidth = tableWidth === 'full' && minWidth === 'auto' ? 0 : minWidth;

    return (
        <td
            id={accessor}
            className={classnames(styles.cell, className)}
            style={{
                textAlign: align,
                padding: noPadding ? 0 : undefined,
                width: cellWidth,
                minWidth: cellWidth,
                maxWidth: cellWidth,
                ...style,
            }}
        >
            {children}
            {resizable && <ResizeTrigger onResize={onResize} />}
        </td>
    );
}
