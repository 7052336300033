import { arrow, flip, inline, offset, shift } from '@floating-ui/dom';
import { ReferenceType, Strategy, useFloating } from '@floating-ui/react-dom-interactions';
import { Popover } from '@headlessui/react';
import React, { Context, useRef, useState } from 'react';

export interface PopoverRootProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /**
     * Define distance between popover modal element and popover button
     * @default 9
     */
    offsetValue?: number;

    /**
     * Define distance between popover modal element and screen sides
     * @default 7
     */
    shiftPadding?: number;

    /**
     * @default false
     * Define if popover has flip or not
     */
    hasFlip?: boolean;

    /**
     * @default false
     * Define if popover has arrow or not
     */
    hasArrow?: boolean;

    /**
     * Children elements that can be PopoverButton and PopoverPanel
     */
    children: any;
    /**
     * Popover placement in relation of it's Trigger
     */
    placement?:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end';
    /**
     * external open state
     */
    open?: boolean;
    /**
     * external open state change
     */
    onOpenChange?: (open: boolean) => void;
}

export interface PanelContextProps {
    x?: number | null;
    y?: number | null;
    floating?: (node: HTMLElement | null) => void;
    strategy?: Strategy;
    arrowRef?: React.MutableRefObject<null>;
    xArrow?: number | undefined;
    yArrow?: number | undefined;
    hasArrow?: boolean;
}

export interface ButtonContextProps {
    reference?: (node: ReferenceType | null) => void;
}

export const PanelContext: Context<PanelContextProps> = React.createContext({});
export const ButtonContext: Context<ButtonContextProps> = React.createContext({});

/**
 * Popover component
 *
 * @example
 * <Popover>
 *   <Popover.Button name='Popover Button'></Popover.Button>
 *   <Popover.Panel>
 *      <div>
 *          <span>Popover Item</span>
 *      </div>
 *   </Popover.Panel>
 * </Popover>
 */
const PopoverRoot: React.FC<PopoverRootProps> = ({
    offsetValue,
    shiftPadding,
    hasFlip = false,
    hasArrow = false,
    children,
    placement = 'bottom',
    open,
    onOpenChange,
    ...rest
}: PopoverRootProps) => {
    const [internalOpen, setInternalOpen] = useState(true);

    const arrowRef = useRef(null);
    const middleware = [offset(offsetValue ? offsetValue : 9), inline()];
    if (hasFlip) middleware.push(flip());
    if (hasArrow && arrowRef) middleware.push(arrow({ element: arrowRef.current as unknown as HTMLElement }));
    middleware.push(shift({ padding: shiftPadding ? shiftPadding : 7 }));

    const { x, y, reference, floating, strategy, middlewareData } = useFloating({
        open: open !== undefined ? open : internalOpen,
        placement,
        onOpenChange: onOpenChange !== undefined ? onOpenChange : setInternalOpen,
        middleware: middleware,
    });

    const xArrow = middlewareData.arrow?.x;
    const yArrow = middlewareData.arrow?.y;

    const buttonContext = { reference };

    const panelContext = {
        x,
        y,
        floating,
        strategy,
        arrowRef,
        xArrow,
        yArrow,
        hasArrow,
    };

    return (
        <PanelContext.Provider value={panelContext}>
            <ButtonContext.Provider value={buttonContext}>
                <Popover {...rest}>{children}</Popover>
            </ButtonContext.Provider>
        </PanelContext.Provider>
    );
};

export default PopoverRoot;
