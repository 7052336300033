export default function getCookies() {
    const cookies = document.cookie.split(';');

    const parsedCookies: { [key: string]: string } = {};

    cookies.forEach((cookie) => {
        const splitCookie = cookie.split('=');

        parsedCookies[splitCookie[0].trim()] = decodeURIComponent(splitCookie[1]);
    });

    return parsedCookies;
}