import FocusTrap from 'focus-trap-react';
import { createContext, CSSProperties, ReactNode, RefObject, useEffect, useRef } from 'react';
import { classnames } from '../../util/classnames';
import Backdrop from '../Backdrop/Backdrop';
import OutsideClickHandler from '../OutsideClickHandler/OutsideClickHandler';
import styles from './Modal.module.scss';

interface Props {
    /** Children to be rendered inside the modal */
    children: ReactNode;
    /** Function to be called to close the modal */
    onClose?: () => void;
    /**
     * Whether the modal should close when the user clicks outside it
     * @default true
     */
    closeOnClickOutside?: boolean;
    /**
     * Whether the modal should close when the user presses the escape key
     * @default true
     */
    closeOnPressEscape?: boolean;
    /**
     * Animation to be used when the modal is opened or closed
     * @default 'fade'
     */
    animation?: 'fade' | 'shake' | 'slide' | 'none';
    /**
     * Whether the modal should take up the entire screen
     * @default false
     */
    fullScreen?: boolean;
    /**
     * Whether the modal backdrop should be blurred
     * @default false
     */
    backdropBlur?: boolean;
    /**
     * Size of the modal
     * @default 'medium'
     */
    size?: 'small' | 'medium' | 'large';
    /**
     * Determines the position of the modal
     * @default 'center'
     */
    position?: 'center' | 'top-left' | 'top-center' | 'top-right' | 'bottom-left' | 'bottom-center' | 'bottom-right';
    /**
     * Determines whether the modal will be visible or not
     * @default false
     */
    active?: boolean;
    /**
     * Styles to be applied to the modal
     * @default {}
     */
    style?: CSSProperties;
    /**
     * Class to be applied to the modal
     * @default ''
     */
    className?: string;
    /**
     * Styles to be applied to the modal backdrop
     * @default ''
     */
    backdropStyle?: CSSProperties;
    /**
     * Class to be applied to the modal backdrop
     * @default ''
     */
    backdropClass?: string;
}

type ModalContext = Props & { boxRef: RefObject<HTMLDivElement> };

export const ModalContext = createContext<ModalContext>({} as ModalContext);

/**
 * Modal component
 * This component is used to display content on top view of the page
 * @param {Props} props
 * @returns {JSX.Element}
 */
export default function Modal({
    children,
    onClose,
    active = false,
    position = 'center',
    size = 'medium',
    backdropBlur = false,
    fullScreen = false,
    closeOnClickOutside = true,
    closeOnPressEscape = true,
    animation = 'fade',
    className,
    backdropStyle,
    backdropClass,
    ...props
}: Props): JSX.Element {
    const boxRef = useRef<HTMLDivElement>(null);

    const handleKeyDown = (event: KeyboardEvent) => {
        if (event.key === 'Escape' && closeOnPressEscape) onClose?.();
    };

    useEffect(() => {
        document.addEventListener('keydown', handleKeyDown);
        return () => document.removeEventListener('keydown', handleKeyDown);
    }, []);

    useEffect(() => {
        if (active) document.body.style.overflow = 'hidden';
        else document.body.style.overflow = 'unset';
    }, [active]);

    return (
        <ModalContext.Provider value={{ ...{ boxRef }, ...{ ...arguments[0] } }}>
            <Backdrop
                active={active}
                position={position}
                blur={backdropBlur}
                noPadding={fullScreen}
                style={backdropStyle}
                className={backdropClass}
            >
                <OutsideClickHandler
                    onClickOutside={() => {
                        if (closeOnClickOutside) onClose?.();
                    }}
                >
                    <FocusTrap
                        focusTrapOptions={{
                            clickOutsideDeactivates: true,
                            initialFocus: false,
                        }}
                    >
                        <div
                            className={classnames(
                                styles.box,
                                styles[size],
                                styles[position],
                                fullScreen && styles.fullScreen,
                                active && styles.active,
                                className && className,
                            )}
                            ref={boxRef}
                            {...props}
                        >
                            {children}
                        </div>
                    </FocusTrap>
                </OutsideClickHandler>
            </Backdrop>
        </ModalContext.Provider>
    );
}
