import { classnames } from '@/util/classnames';
import { ActionButton, Badge, Card, Stack, Text } from '@vg-react/components';
import { RouterSolid } from '@vg-react/icons/solid';
import { PenToSquareLight } from '@vg-react/icons/v6/light';
import { CalendarDayRegular, RotateRegular, TrashCanRegular } from '@vg-react/icons/v6/regular';
import { useTranslation } from 'react-i18next';
import styles from '../Integrations.module.scss';

export const CardWithoutIntegration = () => {
    const { t } = useTranslation();

    return (
        <Card className={classnames(styles.integrationCardWithoutIntegration)}>
            <Stack direction="row" spacing="var(--spacing-300)" align="center" justify="space-between">
                <Stack direction="row" spacing="var(--spacing-300)" align="center">
                    <Stack width="100px">
                        <div
                            style={{
                                width: 80,
                                height: 50,
                                background: 'var(--lightness-900)',
                                borderRadius: 'var(--spacing-75)',
                            }}
                        />
                    </Stack>
                    <div
                        style={{
                            width: 200,
                            height: 50,
                            background: 'var(--lightness-900)',
                            borderRadius: 'var(--spacing-75)',
                        }}
                    />
                </Stack>

                <Stack direction="row" spacing="var(--spacing-100)" align="center">
                    <ActionButton disabled>
                        <PenToSquareLight size="16" color="currentColor" />
                        {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.edit')}
                    </ActionButton>

                    <ActionButton disabled>
                        <RotateRegular size="16" color="currentColor" />
                        {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.sync')}
                    </ActionButton>

                    <ActionButton variant="danger" disabled>
                        <TrashCanRegular size="16" color="currentColor" />
                    </ActionButton>
                </Stack>
            </Stack>

            <Stack direction="row" spacing="var(--spacing-100)" align="center" justify="space-between">
                <Stack direction="row" spacing="var(--spacing-300)" align="center">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <Badge color="secondary" withDot outlined background={false}>
                            {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.notCreated')}
                        </Badge>
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <CalendarDayRegular size="16" color="var(--lightness-300)" />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.notCreated')}
                        </Text>
                    </Stack>
                </Stack>

                <Stack direction="row" spacing="var(--spacing-500)">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <RouterSolid size="18" color="var(--lightness-300)" />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.syncedDevices')}
                        </Text>
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <RotateRegular size="18" color={'var(--lightness-300)'} />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            {t('ToolsScreen.IntegrationsScreen.CardWithoutIntegrationComponent.noSync')}
                        </Text>
                    </Stack>
                </Stack>
            </Stack>
        </Card>
    );
};
