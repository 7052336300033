import {
    FileAltLight,
    FileArchiveLight,
    FileCodeLight,
    FileImageLight,
    FileMusicLight,
    FileVideoLight,
    PlusCircleLight,
    TimesLight,
} from '@vg-react/icons/light';
import React, { useCallback, useId, useRef, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../util/classnames';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { useForm } from '../Form/useForm';
import ProgressBar from '../ProgressBar/ProgressBar';
import css from './SingleFileDropzone.module.scss';

interface SingleFileDropzoneProps extends FieldWrapperProps {
    name: string;
    disabled?: boolean;
    invalid?: boolean;
    readonly?: boolean;
    editFile?: boolean;
}

interface FileData {
    type: string;
    name: string;
    file: unknown;
}

export default function SingleFileDropzone({
    name,
    label,
    invalid = false,
    readonly = false,
    required = false,
    disabled = false,
    editFile = false,
    helpText,
    description,
}: SingleFileDropzoneProps) {
    const { t } = useTranslation();
    const { value, onChange } = useForm<FileData>(name, {} as FileData);
    const [progress, setProgress] = useState<number>(0);
    const ref = useRef<HTMLDivElement>(null);

    function MimeIcon({ type }: { type: string }) {
        const props = {
            className: css.icon,
            color: 'var(--gray-800)',
            size: '60px',
        };

        if (/css|html|javascript|json/.test(type)) return <FileCodeLight {...props} />;
        if (/x-xz|x-bzip|x-bzip2|gzip|zip|x-7z-compressed/.test(type)) return <FileArchiveLight {...props} />;
        if (type?.startsWith('image')) return <FileImageLight {...props} />;
        if (type?.startsWith('video')) return <FileVideoLight {...props} />;
        if (type?.startsWith('audio')) return <FileMusicLight {...props} />;
        return <FileAltLight {...props} />;
    }

    const onDrop = useCallback(
        (files: unknown) => {
            if (Array.isArray(files)) {
                if (onChange)
                    onChange({
                        type: files[0].type,
                        name: files[0].name,
                        file: files[0],
                    });

                setProgress(100);
            }
        },
        [value],
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        disabled: disabled || readonly,
        onDrop,
        maxFiles: 1,
        multiple: false,
    });

    function removeFile(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        event.stopPropagation();
        if (onChange) onChange({} as FileData);
        setProgress(0);
    }

    let dropzone = classnames(
        css.dropzone,
        disabled ? css.disabled : '',
        invalid ? css.invalid : '',
        readonly ? css.readonly : '',
    );

    if (isDragActive) {
        dropzone = `${dropzone} ${css.dropzoneHover}`;
    }

    const id = useId();

    const charCount = Math.floor((ref.current?.clientWidth ?? 0) / 10);

    return (
        <FieldWrapper
            width="100%"
            id={id}
            description={description}
            label={label}
            required={required}
            invalid={invalid}
            helpText={helpText}
        >
            <div {...getRootProps({ ref, className: `${dropzone}` })}>
                <input id={id} {...getInputProps()} />
                <div className={css.contentContainer}>
                    {value.name ? (
                        <>
                            <div>
                                {!disabled && (
                                    <div className={css.removeButton} onClick={removeFile}>
                                        <TimesLight color="var(--gray-800)" size="14px" />
                                    </div>
                                )}
                                <MimeIcon type={value.type} />
                            </div>
                            <label htmlFor={id} className={css.text}>
                                {value.name.length > (charCount || 25) ? (
                                    <>{`${value.name.substring(0, charCount || 25)}...`}</>
                                ) : (
                                    <>{value.name}</>
                                )}
                            </label>
                            {!editFile && (
                                <div className={css.progressBarContainer}>
                                    <ProgressBar height="small" progress={progress} />
                                </div>
                            )}
                        </>
                    ) : (
                        <>
                            <PlusCircleLight className={css.icon} color="var(--gray-600)" size="60px" />
                            <label htmlFor={id} className={css.text}>
                                {t('SingleFileDropzoneComponent.dropFile')}
                            </label>
                            <div className={css.progressBarContainer}>
                                <div className={css.progressBarPlaceholder}></div>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </FieldWrapper>
    );
}
