import { ApexOptions } from 'apexcharts';
import { t } from 'i18next';
import useTheme from '../../../../hooks/useTheme';
import deepMerge from '../../../../util/deepMerge';

export default function getChartConfig(config: ApexOptions): ApexOptions {
    const { theme } = useTheme();

    return deepMerge(
        {
            chart: {
                locales: [
                    {
                        name: 'pt',
                        options: {
                            months: [
                                t('DashboardScreen.getChartConfig.january'),
                                t('DashboardScreen.getChartConfig.february'),
                                t('DashboardScreen.getChartConfig.march'),
                                t('DashboardScreen.getChartConfig.april'),
                                t('DashboardScreen.getChartConfig.may'),
                                t('DashboardScreen.getChartConfig.june'),
                                t('DashboardScreen.getChartConfig.july'),
                                t('DashboardScreen.getChartConfig.august'),
                                t('DashboardScreen.getChartConfig.september'),
                                t('DashboardScreen.getChartConfig.october'),
                                t('DashboardScreen.getChartConfig.november'),
                                t('DashboardScreen.getChartConfig.december'),
                            ],
                            shortMonths: [
                                t('DashboardScreen.getChartConfig.januaryShort'),
                                t('DashboardScreen.getChartConfig.februaryShort'),
                                t('DashboardScreen.getChartConfig.marchShort'),
                                t('DashboardScreen.getChartConfig.aprilShort'),
                                t('DashboardScreen.getChartConfig.mayShort'),
                                t('DashboardScreen.getChartConfig.juneShort'),
                                t('DashboardScreen.getChartConfig.julyShort'),
                                t('DashboardScreen.getChartConfig.augustShort'),
                                t('DashboardScreen.getChartConfig.septemberShort'),
                                t('DashboardScreen.getChartConfig.octoberShort'),
                                t('DashboardScreen.getChartConfig.novemberShort'),
                                t('DashboardScreen.getChartConfig.decemberShort'),
                            ],
                            days: [
                                t('DashboardScreen.getChartConfig.sunday'),
                                t('DashboardScreen.getChartConfig.monday'),
                                t('DashboardScreen.getChartConfig.tuesday'),
                                t('DashboardScreen.getChartConfig.wednesday'),
                                t('DashboardScreen.getChartConfig.thursday'),
                                t('DashboardScreen.getChartConfig.friday'),
                                t('DashboardScreen.getChartConfig.saturday'),
                            ],
                            shortDays: [
                                t('DashboardScreen.getChartConfig.sundayShort'),
                                t('DashboardScreen.getChartConfig.mondayShort'),
                                t('DashboardScreen.getChartConfig.tuesdayShort'),
                                t('DashboardScreen.getChartConfig.wednesdayShort'),
                                t('DashboardScreen.getChartConfig.thursdayShort'),
                                t('DashboardScreen.getChartConfig.fridayShort'),
                                t('DashboardScreen.getChartConfig.saturdayShort'),
                            ],
                            toolbar: {
                                exportToSVG: t('DashboardScreen.getChartConfig.exportToSVG'),
                                exportToPNG: t('DashboardScreen.getChartConfig.exportToPNG'),
                                menu: t('DashboardScreen.getChartConfig.menu'),
                                selection: t('DashboardScreen.getChartConfig.selection'),
                                selectionZoom: t('DashboardScreen.getChartConfig.selectionZoom'),
                                zoomIn: t('DashboardScreen.getChartConfig.zoomIn'),
                                zoomOut: t('DashboardScreen.getChartConfig.zoomOut'),
                                pan: t('DashboardScreen.getChartConfig.pan'),
                                reset: t('DashboardScreen.getChartConfig.reset'),
                            },
                        },
                    },
                ],
                defaultLocale: 'pt',
            },
            tooltip: {
                theme,
            },
        },
        config,
    );
}
