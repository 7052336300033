import { Stack } from '@vg-react/components';
import { createContext, CSSProperties, Dispatch, ReactElement, SetStateAction, useContext, useState } from 'react';
import { ContentProps } from './Content';
import { HeaderProps } from './Header';

/** context definition ------------------------------------------------------- */
interface AccordionContext {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
}

const AccordionContext = createContext<AccordionContext>({} as AccordionContext);
export const useAccordion = () => useContext(AccordionContext);

/** component definition ----------------------------------------------------- */
interface Accordion {
    /** the initial state when internally controlled */
    open?: boolean;
    /** accordion state */
    state?: boolean;
    /** accordion state control */
    setState?: Dispatch<SetStateAction<boolean>>;
    /** Accordion header and content */
    children: readonly [ReactElement<HeaderProps>, ReactElement<ContentProps>];
    style?: CSSProperties;
}

export default function Accordion({ open = false, state, children, setState, style }: Accordion) {
    const [internalOpen, setInternalOpenOpen] = useState(open);

    return (
        <AccordionContext.Provider
            value={{
                open: state ?? internalOpen,
                setOpen: setState ?? setInternalOpenOpen,
            }}
        >
            <Stack spacing="0" style={style}>{children}</Stack>
        </AccordionContext.Provider>
    );
}
