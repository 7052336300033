import Stack from '@/components/Stack/Stack';
import Text from '@/components/Text/Text';
import Tooltip from '@/components/Tooltip/Tooltip';
import { DevicesCount, useMassUpdateContext } from '@/contexts/MassChange/MassChangeFirmwareContext';
import { FileLinesSolid, MicrochipSolid, TagSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import style from './Resume.module.scss';

export default function Carousel({ item }: { item: DevicesCount }) {
    const { selectedDevices } = useMassUpdateContext();
    const { t } = useTranslation();

    return (
        <Stack direction="row" justify="space-evenly" spacing="var(--spacing-150)">
            <Stack className={style.manufacturerIcon} justify="center" align="center" spacing="var(--spacing-50)">
                <Tooltip>
                    <Tooltip.Trigger>
                        <Stack direction="row" spacing="var(--spacing-50)" align="center" justify="center">
                            <FileLinesSolid color="var(--gray-900)" size="20" />
                            <Text
                                variant="h6"
                                size="sm"
                                style={{
                                    maxWidth: '92px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap',
                                }}
                            >
                                {item.software}
                            </Text>
                        </Stack>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{item.software}</Tooltip.Content>
                </Tooltip>
                <Text>{t('ToolsScreen.MassChangeScreen.ResumeScreen.Carousel.software')}</Text>
            </Stack>
            <Stack justify="center" spacing="var(--spacing-50)" align="center">
                <Tooltip>
                    <Tooltip.Trigger>
                        <Stack direction="row" spacing="var(--spacing-50)" align="center" justify="center">
                            <TagSolid color="var(--gray-900)" size="20" />
                            <Text
                                variant="h6"
                                size="sm"
                                style={{
                                    maxWidth: '92px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap',
                                }}
                            >
                                {item.model}
                            </Text>
                        </Stack>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{item.model}</Tooltip.Content>
                </Tooltip>
                <Text>{t('ToolsScreen.MassChangeScreen.ResumeScreen.Carousel.model')}</Text>
            </Stack>
            <Stack justify="center" spacing="var(--spacing-50)" align="center">
                <Tooltip>
                    <Tooltip.Trigger>
                        <Stack direction="row" spacing="var(--spacing-50)" align="center" justify="center">
                            <MicrochipSolid color="var(--gray-900)" size="20" />
                            <Text
                                variant="h6"
                                size="sm"
                                style={{
                                    maxWidth: '92px',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    textWrap: 'nowrap',
                                }}
                            >
                                {item.hardware}
                            </Text>
                        </Stack>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{item.hardware}</Tooltip.Content>
                </Tooltip>
                <Text>{t('ToolsScreen.MassChangeScreen.ResumeScreen.Carousel.hardware')}</Text>
            </Stack>
        </Stack>
    );
}
