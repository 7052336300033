import useSocket from '@/hooks/useSocket';
import { WebSocketData } from '@/screens/Devices/[id]/Connectivity/NatRedirection/Types';
import { ReactNode, createContext, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';

interface RefreshContextProps {
    refreshList: (serialNumber: string) => void;
    addRefreshIntoRefreshList: (refresh: { name: string; refresh: () => void }) => void;
    removeRefreshIntoRefreshList: (name: string) => void;
}

interface RefreshProviderProps {
    children: ReactNode;
}

export const RefreshContext = createContext({} as RefreshContextProps);

export function RefreshProvider({ children }: RefreshProviderProps) {
    const { socket } = useSocket();
    const params = useParams();
    const list = useRef<{ name: string; refresh: () => void }[]>([]);

    const addRefreshIntoRefreshList = (refresh: { name: string; refresh: () => void }) => {
        if (!list.current.find((item) => item.name === refresh.name)) {
            list.current.push(refresh);
        }
    };

    const removeRefreshIntoRefreshList = (name: string) => {
        list.current = list.current.filter((refresh) => refresh.name !== name);
    };

    const refreshList = (serialNumber: string) => {
        list.current.forEach((item) => {
            if (item.name.endsWith(serialNumber)) {
                item.refresh();
            }
        });
        list.current = [];
    };

    useEffect(() => {
        socket?.on(`acs:device:refresh:${params.id}`, (data: WebSocketData) => {
            refreshList(data.serialNumber);
        });

        return () => {
            socket?.off(`acs:device:refresh:${params.id}`);
        };
    }, [socket]);

    return (
        <RefreshContext.Provider
            value={{
                refreshList,
                addRefreshIntoRefreshList,
                removeRefreshIntoRefreshList,
            }}
        >
            {children}
        </RefreshContext.Provider>
    );
}
