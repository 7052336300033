import { classnames } from '@/util/classnames';
import { Tooltip } from '@vg-react/components';
import { ChevronDownRegular } from '@vg-react/icons/v6/regular';
import { motion } from 'framer-motion';
import { CSSProperties, ReactNode } from 'react';
import { useAccordion } from './Accordion';
import styles from './Accordion.module.scss';

export interface HeaderProps {
    children: ReactNode;
    customStyle?: CSSProperties;
}

export default function Header({ children, customStyle }: HeaderProps) {
    const { open, setOpen } = useAccordion();

    return (
        <motion.section
            className={classnames(styles.header, open && styles.open)}
            onClick={() => setOpen((previous) => !previous)}
            style={customStyle}
        >
            {children}
            <div className={classnames(styles.icon, open && styles.open)}>
                <Tooltip>
                    <Tooltip.Trigger>
                        <ChevronDownRegular size="18" color="var(--lightness-500)" />
                    </Tooltip.Trigger>
                    <Tooltip.Content>{open ? 'Recolher' : 'Expandir'}</Tooltip.Content>
                </Tooltip>
            </div>
        </motion.section>
    );
}
