import { CSSProperties, useEffect, useRef, useState } from 'react';
import styles from './ProfilePicture.module.scss';

const defaultColors = [
    '#D67E96',
    '#7AC2BE',
    '#EDA37D',
    '#76BBDF',
    '#B587E3',
    '#77AAAA',
    '#D67E96',
    '#0F766E',
];

function getColor(name?: string) {
    let color = localStorage.getItem(`UserColor-${name}`);

    if (!color) {
        color = defaultColors[Math.floor(Math.random() * (8))];
        localStorage.setItem(`UserColor-${name}`, color);
    }

    return color;
}

interface ProfilePictureProps {
    name?: string;
    size?: string | number;
    outlined?: boolean;
    online?: boolean;
}

export default function ProfilePicture({ name, size, outlined, online }: ProfilePictureProps) {
    const containerRef = useRef<HTMLDivElement>(null);
    const [containerDimension, setContainerDimension] = useState<DOMRect>();

    useEffect(() => {
        const eventListenerFunction = () => {
            setContainerDimension(containerRef.current?.getBoundingClientRect());
        };

        if (containerRef) {
            setContainerDimension(containerRef.current?.getBoundingClientRect());
            containerRef.current?.addEventListener('resize', eventListenerFunction);
        }

        return () => {
            if (containerRef) {
                containerRef.current?.removeEventListener('resize', eventListenerFunction);
            }
        };
    }, []);

    const fontSize = containerDimension ? (containerDimension.height * 0.54) : 0;

    const letter = name?.charAt(0).toUpperCase();

    return (
        <div
            ref={containerRef}
            className={styles.picture}
            style={{
                backgroundColor: getColor(name),
                fontSize,
                ...(size && { width: size }),
                ...((outlined && size) && { border: `${(Number(size) * 0.05) || 2}px solid var(--lightness-700)` }),
            }}
        >
            {letter}
            {online && <div
                style={{ '--size': typeof size === 'number' ? `${size}px` : size } as CSSProperties }
                className={styles.online}
            />
            }
        </div>
    );
}