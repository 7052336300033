import { EyeSlashSolid, EyeSolid } from '@vg-react/icons/solid';
import React, { ReactNode, RefObject, useId, useState } from 'react';
import FieldWrapper, { FieldWrapperProps } from '../FieldWrapper/FieldWrapper';
import { useForm } from '../Form/useForm';
import css from './Input.module.scss';

export interface InputProps extends React.InputHTMLAttributes<HTMLInputElement>, FieldWrapperProps {
    /** name of the field for form control */
    name: string;
    /**
     * width of the input. Can receive a string with any CSS width rules
     * @default 'medium'
     */
    width?: 'small' | 'medium' | 'large' | string;
    style?: React.CSSProperties;
    /**
     * set the input size
     * @default 'medium'
     */
    inputSize?: 'small' | 'medium' | 'large' | 'xlarge';
    /** defines if the field is readonly */
    readonly?: boolean;
    /** defines if the field is disabled */
    disabled?: boolean;
    /** defines if the field is in invalid state */
    invalid?: boolean;
    /** defines if the field is quiet - can be used with any of the other attributes */
    quiet?: boolean;
    /** defines the type of the input */
    pattern?: string;
    /** the id for the input field */
    id?: string;
    /** defines the default value of the field when it is not inside a Form */
    value?: string;
    /**
     * Wether the autocomplete from browser will be on or off
     * @default 'off'
     */
    autoComplete?: 'on' | 'off' | string;
    /**
     * set onChange function for input
     * @param event
     * @returns
     */
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    /**
     * set left icon in input component
     */
    leftIcon?: ReactNode;

    /**
     * set left icon in input component
     */
    rightIcon?: ReactNode;

    inputRef?: RefObject<HTMLInputElement>;

    generatePassword?: ReactNode;

    /**
     * set showPasswordWhenFocused to true to show the password when the input is focused
     * @default false
     */
    showPasswordWhenFocused?: boolean;
}

/**
 * Input component
 *
 * If inside a Form, value will be automatically captured.
 *
 * Outside of a Form, must be used as a Controlled Component.
 *
 * @example
 * //Inside a form (the value will be output on form callbacks)
 * <Form>
 *     <Input name='my-input' label='My Input'/>
 * </Form>
 *
 * // Outside a form
 * const [state, setState] = useState('');
 *
 * <Input
 *     name='my-input'
 *     label='My Input'
 *     value={state}
 *     onChange={(event) => setState(event.target.value)}
 * />
 */
const Input: React.FC<InputProps> = ({
    name,
    type,
    quiet,
    label,
    width,
    invalid,
    style,
    inputRef,
    disabled,
    helpText,
    leftIcon,
    required,
    readonly,
    rightIcon,
    className,
    pattern,
    value = '',
    description,
    inputSize = 'medium',
    autoComplete = 'off',
    onChange,
    iconInfo,
    generatePassword,
    showPasswordWhenFocused,
    ...props
}: InputProps) => {
    const id = useId();

    const inputClasses = [
        css.inputOutline,
        ...[readonly && css.readonly],
        ...[disabled && css.disabled],
        ...[invalid && css.invalid],
        ...[quiet && css.quiet],
        className ?? '',
    ];

    const { value: valueForm, onChange: onChangeForm } = useForm<string>(name, value);

    const [showPassword, setShowPassword] = useState(false);

    const handleShowPassword = (e: React.FocusEvent<HTMLInputElement>) => {
        if (!showPasswordWhenFocused) return;
        if (e.type === 'focus' && type === 'password') {
            setShowPassword(true);
        }
        if (e.type === 'blur' && type === 'password') {
            setShowPassword(false);
        }
    };

    return (
        <FieldWrapper
            id={id}
            label={label}
            width={width}
            invalid={invalid}
            required={required}
            helpText={helpText}
            description={description}
            iconInfo={iconInfo}
        >
            <div
                className={`
                        ${inputClasses.join(' ')}
                        ${css.inputElementContainer}
                        ${css['input-size-' + inputSize]}
                    `}
                style={style}
            >
                {leftIcon && <div className={css.icon}>{leftIcon}</div>}
                <input
                    id={id}
                    onFocus={(e) => handleShowPassword(e)}
                    onBlur={(e) => handleShowPassword(e)}
                    ref={inputRef}
                    {...props}
                    value={valueForm}
                    name={name ?? ''}
                    autoComplete={autoComplete}
                    readOnly={readonly || disabled}
                    tabIndex={readonly || disabled ? -1 : 0}
                    onKeyPress={(e) => {
                        if (pattern) {
                            const regex = new RegExp(pattern);
                            const inputChar = String.fromCharCode(e.charCode);
                            if (!regex.test(inputChar)) e.preventDefault();
                        }
                    }}
                    className={`
                            ${css.inputElement}
                            ${css['font-size-' + inputSize]}
                            `}
                    type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
                    onChange={(input) => {
                        onChange ? onChange(input) : onChangeForm(input.target.value);
                    }}
                />
                {rightIcon && (
                    <div className={css.icon} style={{ width: '14px' }}>
                        {rightIcon}
                    </div>
                )}
                {type === 'password' && (
                    <button className={css.icon} type="button" onClick={() => setShowPassword(!showPassword)}>
                        {showPassword ? (
                            <EyeSlashSolid color="var(--accent-900)" size="18" />
                        ) : (
                            <EyeSolid color="var(--accent-900)" size="18" />
                        )}
                    </button>
                )}
                {generatePassword && (
                    <div className={css.icon} onClick={() => setShowPassword(true)}>
                        {generatePassword}
                    </div>
                )}
            </div>
        </FieldWrapper>
    );
};

export default Input;
