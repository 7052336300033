import { forceLink, forceManyBody, forceSimulation, forceX, forceY, SimulationNodeDatum } from 'd3';
import { useEffect, useRef } from 'react';
import { Node, Position } from 'reactflow';
import { RootNode } from './core/Flow';
import FloatingEdge from './edges/FloatingEdge';
import { Agent } from './nodes/Agent';
import { Controller } from './nodes/Controller';
import { Host } from './nodes/Host';
export interface D3Node extends Node<{ children: string[] }> {
    x?: number;
    y?: number;
}

type ParentData = {
    position: {
        x: number;
        y: number;
    };
    childrenLength: number;
    selfIndexInChildren: number;
    type: string;
    online?: boolean;
};

function getNodeIntersection(intersectionNode: Node, targetNode: Node) {
    const {
        width: intersectionNodeWidth,
        height: intersectionNodeHeight,
        positionAbsolute: intersectionNodePosition,
    } = intersectionNode;
    const targetPosition = targetNode.positionAbsolute;

    const w = (intersectionNodeWidth || 0) / 2;
    const h = (intersectionNodeHeight || 0) / 2;

    const x2 = (intersectionNodePosition?.x || 0) + w;
    const y2 = (intersectionNodePosition?.y || 0) + h;
    const x1 = (targetPosition?.x || 0) + w;
    const y1 = (targetPosition?.y || 0) + h;

    const xx1 = (x1 - x2) / (2 * w) - (y1 - y2) / (2 * h);
    const yy1 = (x1 - x2) / (2 * w) + (y1 - y2) / (2 * h);
    const a = 1 / (Math.abs(xx1) + Math.abs(yy1));
    const xx3 = a * xx1;
    const yy3 = a * yy1;
    const x = w * (xx3 + yy3) + x2;
    const y = h * (-xx3 + yy3) + y2;

    return { x, y };
}

// returns the position (top,right,bottom or right) passed node compared to the intersection point
function getEdgePosition(
    node: Node,
    intersectionPoint: {
        x: any;
        y: any;
    },
) {
    const n = { ...node.positionAbsolute, ...node };
    const nx = Math.round(n.positionAbsolute?.x || 1);
    const ny = Math.round(n.positionAbsolute?.y || 1);
    const px = Math.round(intersectionPoint.x);
    const py = Math.round(intersectionPoint.y);

    if (px <= nx + 1) {
        return Position.Left;
    }
    if (px >= nx + (n.width || 1) - 1) {
        return Position.Right;
    }
    if (py <= ny + 1) {
        return Position.Top;
    }
    if (py >= (n.positionAbsolute?.y || 1) + (n.height || 1) - 1) {
        return Position.Bottom;
    }

    return Position.Top;
}

// returns the parameters (sx, sy, tx, ty, sourcePos, targetPos) you need to create an edge
export function getEdgeParams(
    source: Node,
    target:
        | Node
        | {
              id: string;
              width: number;
              height: number;
              positionAbsolute: {
                  x: number;
                  y: number;
              };
          },
) {
    const sourceIntersectionPoint = getNodeIntersection(source, target as Node);
    const targetIntersectionPoint = getNodeIntersection(target as Node, source);

    const sourcePos = getEdgePosition(source, sourceIntersectionPoint);
    const targetPos = getEdgePosition(target as Node, targetIntersectionPoint);

    return {
        sx: sourceIntersectionPoint.x,
        sy: sourceIntersectionPoint.y,
        tx: targetIntersectionPoint.x,
        ty: targetIntersectionPoint.y,
        sourcePos,
        targetPos,
    };
}

export const EDGE_TYPES = {
    floating: FloatingEdge,
};

export const NODE_TYPES = {
    controller: Controller,
    agent: Agent,
    host: Host,
};

export function getNodesAndEdges(initialNodes: D3Node[]) {
    const links: {
        source: (typeof initialNodes)[0];
        target: (typeof initialNodes)[1];
        data: any;
    }[] = [];
    initialNodes.forEach((node) => {
        node.data.children.forEach((childId) => {
            const sourceNode = initialNodes.find((n) => n.id === node.id);
            const targetNode = initialNodes.find((n) => n.id === childId);
            const data = initialNodes.find((n) => n.id === childId)?.data;
            links.push({
                source: sourceNode as (typeof initialNodes)[0],
                target: targetNode as (typeof initialNodes)[1],
                data,
            });
        });
    });

    const edges = links.map((link) => ({
        id: `${link.source.id}-${link.target.id}`,
        source: link.source.id,
        target: link.target.id,
        type: 'floating',
        data: link.data,
    }));

    initialNodes = initialNodes.map((node) => ({
        ...node,
        x: node.position.x,
        y: node.position.y,
    }));
    const linkEdges = { ...edges.map((edge) => edge) };

    const simulation = forceSimulation(initialNodes as SimulationNodeDatum[])
        .force('link', forceLink(linkEdges))
        .force('charge', forceManyBody().strength(-800))
        .force('x', forceX())
        .force('y', forceY())
        .stop();

    simulation.tick(300);

    const nodes = initialNodes.map((node) => ({
        ...node,
        position: {
            x: node.x,
            y: node.y,
        },
    }));
    return { nodes, edges };
}

const polarToCartesian = (centerX: number, centerY: number, radius: number, angleInDegrees: number) => {
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;

    return {
        x: centerX + radius * Math.cos(angleInRadians),
        y: centerY + radius * Math.sin(angleInRadians),
    };
};

export const prepareNodes = (nodes: RootNode[]) => {
    nodes = nodes.filter((node) => node.id);
    nodes.forEach((node) => {
        if (!node.position) {
            const getParentData = (node: RootNode): ParentData => {
                const parent = nodes.find((n) => n.data.children.includes(node.id));
                if (!parent) {
                    return {
                        position: {
                            x: 0,
                            y: 0,
                        },
                        childrenLength: 0,
                        selfIndexInChildren: 0,
                        type: 'controller',
                        online: true,
                    };
                }
                if (parent.position) {
                    return {
                        position: parent.position,
                        childrenLength: parent.data.children.length,
                        selfIndexInChildren: parent.data.children.indexOf(node.id),
                        type: parent.type,
                        online: parent.data.online,
                    };
                }
                return getParentData(parent);
            };

            const {
                position: { x, y },
                childrenLength,
                selfIndexInChildren,
                type,
                online,
            } = getParentData(node);
            const angle = (360 / childrenLength) * selfIndexInChildren;
            const baseDistance = node.type === 'host' ? 150 : 400;
            node.data.parentType = type;
            if (!online) {
                node.data.online = false;
            }
            node.position = polarToCartesian(x, y, baseDistance, angle);
        }
        node.data.children = node.data.children.filter((child: string) => child);
        node.draggable = false;
    });
    return nodes;
};

export const useDimensions = (ref: React.MutableRefObject<HTMLDivElement | null>) => {
    const dimensions = useRef({ width: 0, height: 0 });

    useEffect(() => {
        if (ref.current) {
            dimensions.current.width = ref.current.offsetWidth;
            dimensions.current.height = ref.current.offsetHeight;
        }
    }, []);

    return dimensions.current;
};
