import { Popover } from '@headlessui/react';
import React, { CSSProperties, ReactNode } from 'react';
import { ButtonContext, ButtonContextProps } from './PopoverRoot';

export interface PopoverButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
    /**
     * Define button name
     */
    name?: string;

    /**
     * Define button styles
     */
    style?: CSSProperties;

    /**
     * Css class to style the button
     */
    className?: string | undefined;

    children?: ReactNode;
}

/**
 * PopoverButton component
 */
const PopoverButton: React.FC<PopoverButtonProps> = ({
    name,
    style,
    children,
    className,
    ...props
}: PopoverButtonProps) => {
    const { reference }: ButtonContextProps = React.useContext(ButtonContext);

    return (
        <Popover.Button className={className} ref={reference} style={style} {...props}>
            {name ? name : children}
        </Popover.Button>
    );
};

export default PopoverButton;
