import { useCallback, useRef } from 'react';
import { Edge, getBezierPath, useStore } from 'reactflow';
import styles from '../../index/Topology.module.scss';

import { classnames } from '@/util/classnames';
import { getEdgeParams } from '../utils.js';

function FloatingEdge({
    id,
    source,
    target,
    markerEnd,
    style,
    animated,
    ...props
}: Edge) {
    const pathRef = useRef<SVGPathElement | null>(null);
    const sourceNode = useStore(
        useCallback((store) => store.nodeInternals.get(source), [source]),
    );
    const targetNode = useStore(
        useCallback((store) => store.nodeInternals.get(target), [target]),
    );

    if (!sourceNode || !targetNode) {
        return null;
    }

    const { sx, sy, tx, ty, sourcePos, targetPos } = getEdgeParams(
        sourceNode,
        targetNode,
    );

    const [edgePath] = getBezierPath({
        sourceX: sx,
        sourceY: sy,
        sourcePosition: sourcePos,
        targetPosition: targetPos,
        targetX: tx,
        targetY: ty,
    });
    const begin = Math.random();
    let offset = 3;
    return (
        <>
            <path
                ref={pathRef}
                id={id}
                className={classnames(styles.floatingEdge, props.data.online && styles.online, props.data.parentType && styles[props.data.parentType + "Parent"])}
                d={edgePath}
                markerEnd={markerEnd as string}
            />
            {props.data.online && (
               Array.from({ length: 6 }).map((_, i) => (
                <>
                <CircleRenderer
                    key={i}
                    offset={offset+= 0.020}
                    begin={begin}
                    path={edgePath}
                />
                </>
               ))
            )}
        </>
    );
}

const CircleRenderer = ({
    offset,
    begin,
    path,
}: {
    offset: number;
    begin: number;
    path: string;
}) => {
    return (
        <circle
            r="1"
            fill="rgb(255, 255, 255, 0.9)"
            style={{ filter: 'drop-shadow( 0px 0px 3px rgb(255, 255, 255, 0.9)'  }}
            className={styles.floatingEdgeCircle}
        >
            <animateMotion
                dur="1s"
                repeatCount="indefinite"
                path={path}
                begin={`${begin * offset}s`}
                keyPoints="0;1"
                keyTimes="0;1"
                calcMode="linear"
                
            />
        </circle>
    );
};

export default FloatingEdge;
