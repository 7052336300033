import { atom } from 'jotai';

interface SpeedTestAtom {
  statusTest: 'init' | 'running' | 'finished';
  download: {
    selected: boolean;
    init: number;
    secundary: number;
    speedContracted?: number;
  };
  upload: {
    selected: boolean;
    init: number;
    secundary: number;
    speedContracted?: number;
  };
  refresh: () => void;
}

export const speedTestAtom = atom<SpeedTestAtom | null>(null)