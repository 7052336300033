import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import { Stack, Text, Tooltip } from '@vg-react/components';
import { ChevronLeftLight } from '@vg-react/icons/v6/light';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import Ping, { PingResult } from '../components/Ping';
import Traceroute, { TestState } from '../components/Traceroute';
import styles from './Latency.module.scss';

export default function HistoryScreen() {
    const { t } = useTranslation();
    const { id } = useParams();

    const {
        data: pingState,
        isFetching: isPingFetching,
        refresh: pingRefresh,
    } = useFetch<{
        data: {
            destinations: PingResult[];
            repetitions: number;
            timeout: number;
            timestamp: Date;
        };
        state: 'not_started_yet' | 'pending' | 'failed' | 'success';
    }>(`/app/devices/diagnostics/ping/history/${id}`);

    const { data: tracerouteState, isFetching: isTracerouteFetching } = useFetch<TestState>(
        `/app/devices/${id}/diagnostics/traceroute/history`,
    );

    return (
        <Screen
            style={{
                height: '100%',
                backgroundColor: 'var(--foreground-color)',
                padding: 20,
                borderRadius: 'var(--radius-medium)',
            }}
        >
            <Stack spacing="medium" height="100%" width="100%">
                <Stack spacing="var(--spacing-75)">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Link to={`/devices/${id}/diagnostics/latency/`} className={styles.returnButton}>
                                    <ChevronLeftLight size="16" color="var(--gray-900)" />
                                </Link>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.History.back')}
                            </Tooltip.Content>
                        </Tooltip>
                        <Text size="md" weight="bold" color="var(--gray-900)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.History.title')}
                        </Text>
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-50)" justify="start" align="start">
                        <Text size="xs" weight="regular" color={'var(--gray-600)'} style={{ fontStyle: 'normal' }}>
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.History.description')}
                        </Text>
                    </Stack>
                </Stack>
                <div
                    className={styles.resultsContainer}
                    style={{ gridTemplateColumns: pingState?.state && tracerouteState?.state ? '' : '1fr' }}
                >
                    {pingState?.state && (
                        <Ping state={pingState} timestamp={pingState?.data?.timestamp} isFetching={isPingFetching} />
                    )}
                    {tracerouteState?.state && (
                        <Traceroute
                            state={tracerouteState}
                            timestamp={tracerouteState?.timestamp}
                            isFetching={isTracerouteFetching}
                        />
                    )}
                </div>
            </Stack>
        </Screen>
    );
}
