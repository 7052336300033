import { AsteriskRegular } from '@vg-react/icons/regular';
import { InfoCircleSolid } from '@vg-react/icons/solid';
import { ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import css from './FieldWrapper.module.scss';

export interface FieldWrapperProps {
    /** label to be shown on the top of the field */
    label?: string;
    /** wheter the 'required' icon should be displayed or not */
    required?: boolean;
    /** Optional className to be added to parent div */
    className?: string;
    /**
     * Width of the field. May receive any valid CSS value
     * @default 'medium'
     */
    width?: 'small' | 'medium' | 'large' | string;
    /** description to be show on the right of the field inside a tooltip */
    description?: string;
    /** iconInfo to be show on the right of the field inside a tooltip */
    iconInfo?: {
        icon: JSX.Element;
        text: string;
    };
    /** text (or elements) to be shown on below the field */
    helpText?: ReactNode;
    /** defines the color of the help text */
    invalid?: boolean;
}

interface InternalFieldWrapperProps extends FieldWrapperProps {
    /** The form element to be wrapped */
    children: ReactNode;
    /** ID to bind the field to the label */
    id: string;
}

/**
 * Field Wrapper
 *
 * *Internal use only*
 *
 * Wraps a Form field into common used components
 *
 * Adds a label and a required Icon
 *
 * @example
 * <FieldWrapper label='test' required>
 *      <input />
 * </FieldWrapper>
 */
export default function FieldWrapper({
    label,
    required,
    children,
    id,
    className,
    width = 'medium',
    description,
    helpText,
    invalid,
    iconInfo,
}: InternalFieldWrapperProps) {
    const styles = {
        ...(width !== 'small' && width !== 'medium' && width !== 'large' && { width }),
    };

    return (
        <div style={styles} className={classnames(css.container, className, css[width])}>
            {label && (
                <label htmlFor={id} className={css.label}>
                    {label}
                    {required && <AsteriskRegular size="8px" color="red" style={{ marginLeft: 5 }} />}
                    {description ? (
                        <Tooltip variant="secondary" placement="top">
                            <Tooltip.Trigger>
                                <InfoCircleSolid size="12px" color="var(--gray-500)" style={{ marginLeft: 5 }} />
                            </Tooltip.Trigger>
                            <Tooltip.Content style={{ maxWidth: 300 }}>
                                <Text weight="regular" color="white">
                                    {description}
                                </Text>
                            </Tooltip.Content>
                        </Tooltip>
                    ) : null}
                    {iconInfo ? (
                        <Tooltip>
                            <Tooltip.Trigger style={{ marginLeft: '3px' }}>{iconInfo.icon}</Tooltip.Trigger>
                            <Tooltip.Content>
                                <Text color="var(--gray-100)">{iconInfo.text}</Text>
                            </Tooltip.Content>
                        </Tooltip>
                    ) : null}
                </label>
            )}
            {children}
            {helpText && (
                <div className={css.helpTextContainer}>
                    <Text weight="regular" color={invalid ? 'var(--scarlet-900)' : 'var(--gray-600)'}>
                        {helpText}
                    </Text>
                </div>
            )}
        </div>
    );
}
