import {
    autoUpdate,
    flip as floatinFlip,
    arrow as floatingArrow,
    offset as floatingOffset,
    shift as floatingShift,
    safePolygon,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole,
} from '@floating-ui/react-dom-interactions';
import { ReactNode, useEffect, useRef } from 'react';
import TooltipContext, { Content, useTooltip } from './TooltipContext';

export interface TooltipRootProps {
    /**
     * Defines the background and text color of the tooltip
     * @default 'primary'
     */
    variant?: 'primary' | 'secondary';
    /**
     * Tooltip.Trigger and Tooltip.Content Elements
     */
    children: ReactNode;
    /**
     * Defines wether the tooltip should appear on focus or not
     * @default true
     */
    showOnFocus?: boolean;
    /**
     * Defines the distance between the tooltip and it's Trigger (in px)
     * @default 5
     */
    offset?: number;
    /**
     * defines whether the tooltip should flip or not when on the edge of the screen
     * @default true
     */
    flip?: boolean;
    /**
     * defines if the tooltip should shift to avoid being rendered off screen
     * @default true
     */
    shift?: boolean;
    /**
     * defines if an arrow will be rendered
     * @default true
     */
    arrow?: boolean;
    /**
     * Arrow className to edit the arrow styles
     */
    arrowClassName?: string;
    /**
     * Tooltip className to edit the tooltip styles
     */
    tooltipClassName?: string;
    /**
     * Tooltip placement in relation of it's Trigger
     */
    placement?:
        | 'top'
        | 'top-start'
        | 'top-end'
        | 'bottom'
        | 'bottom-start'
        | 'bottom-end'
        | 'left'
        | 'left-start'
        | 'left-end'
        | 'right'
        | 'right-start'
        | 'right-end';

    /**
     * Tooltip and arrow color (any css string)
     */
    color?: string;
    /**
     * defines wether the tooltip should be hoverable or not
     */
    hoverable?: boolean;
}

/**
 * Tooltip component
 *
 * Must have a Tooltip.Trigger and a Tooltip.Content element as children
 *
 * @example
 *  <Tooltip>
 *      <Tooltip.Trigger>
 *          <button>Hover me!</button>
 *      </Tooltip.Trigger>
 *      <Tooltip.Content>
 *          I'm a tooltip!
 *      </Tooltip.Content>
 *  </Tooltip>
 */
function TooltipRoot({
    children,
    showOnFocus = true,
    offset = 5,
    flip = true,
    shift = true,
    arrow = true,
    arrowClassName = '',
    tooltipClassName = '',
    placement = 'top',
    variant = 'primary',
    color,
    hoverable,
}: TooltipRootProps) {
    const { setContent, setTrigger, open, setOpen, setConfig } = useTooltip();

    const arrowRef = useRef<HTMLDivElement>(null);

    const {
        x,
        y,
        reference,
        floating,
        strategy,
        context,
        middlewareData,
        placement: floatingPlacement,
    } = useFloating({
        open,
        onOpenChange: setOpen,
        placement,
        middleware: [
            flip && floatinFlip(),
            shift && floatingShift(),
            floatingOffset(arrow ? offset + 4 : offset),
            arrow && floatingArrow({ element: arrowRef, padding: 5 }),
        ],
        whileElementsMounted: autoUpdate,
    });

    const hover = useHover(context, {
        delay: {
            open: 150,
            close: 150,
        },
        ...(hoverable && { handleClose: safePolygon() }),
    });

    const dismiss = useDismiss(context);
    const role = useRole(context, { role: 'tooltip' });

    const { getReferenceProps, getFloatingProps } = useInteractions([hover, role, dismiss]);

    useEffect(() => {
        setConfig({
            showOnFocus,
            arrowClassName,
            tooltipClassName,
            floatingPlacement,
            variant,
        });
    }, [showOnFocus, arrowClassName, tooltipClassName]);

    useEffect(() => {
        setTrigger({
            ref: reference,
            props: { ...getFloatingProps() },
        });
    }, [children]);

    useEffect(() => {
        const staticSide = {
            top: 'bottom',
            right: 'left',
            bottom: 'top',
            left: 'right',
        }[floatingPlacement.split('-')[0]];

        const content: Content = {
            ref: floating,
            props: {
                ...getReferenceProps({
                    style: {
                        left: x ?? 0,
                        top: y ?? 0,
                        position: strategy,
                        ...(color && { backgroundColor: color }),
                    },
                }),
            },
        };

        if (arrow) {
            content.arrow = {
                ref: arrowRef,
                props: {
                    style: {
                        top: middlewareData?.arrow?.y != null ? middlewareData.arrow.y : '',
                        left: middlewareData?.arrow?.x != null ? middlewareData.arrow.x : '',
                        right: '',
                        bottom: '',
                        [staticSide || '']: -4,
                        ...(color && { backgroundColor: color }),
                    },
                },
            };
        }
        setContent(content);
    }, [x, y, strategy, middlewareData]);

    return <>{children}</>;
}

export default function Provider(props: TooltipRootProps) {
    return (
        <TooltipContext>
            <TooltipRoot {...props} />
        </TooltipContext>
    );
}
