import { Stack, Text } from '@vg-react/components';
import { ExclamationTriangleLight, InfoCircleLight } from '@vg-react/icons/light';
import { CheckRegular } from '@vg-react/icons/regular';
import styles from './ErrorIndicator.module.scss';

interface ErrorIndicatorProps {
    sugestion?: string;
}

export default function ErrorIndicator({ sugestion }: ErrorIndicatorProps) {

    return (
        <div className={styles.center}>
            <Stack spacing='small' align='center' justify='center'>
                <Stack direction='row' spacing='var(--spacing-100)' align='end'>
                    <ExclamationTriangleLight size='42' color='var(--gray-900)' />
                    <Text weight='light' size='xxxl' lineHeight='none' color='var(--gray-900)'>
                        Algo deu errado!
                    </Text>
                </Stack>
                <Text weight='regular' lineHeight='lg' size='sm' color='var(--gray-700)'>
                    Ocorreu um erro ao processar esse recurso. Um relatório de erros anônimo foi enviado.
                </Text>
            </Stack>

            {
                sugestion &&
                <div className={styles.badge}>
                    <InfoCircleLight size='18' color='var(--gray-600)' />
                    <Text color='var(--gray-600)' size='sm' weight='regular'>
                        {sugestion}
                    </Text>
                </div>
            }

        </div>
    );
}