import { Stack, Text } from '@vg-react/components';

interface QualityBarSpeedTestProps {
    color: string;
    percentageTest: number;
}

export const QualityBarSpeedTest = ({ color, percentageTest }: QualityBarSpeedTestProps) => {
    function filledWidth(percentageTest: number) {
        if (isNaN(percentageTest) || percentageTest < 10) return '16%';
        else if (percentageTest < 20) return '20%';
        else if (percentageTest > 100) return '100%';
        else return `${percentageTest}%`;
    }

    return (
        <Stack
            width="100%"
            height="25px"
            style={{
                backgroundColor: 'var(--background-color)',
                borderRadius: '25px',
            }}
        >
            <Stack
                width={filledWidth(percentageTest)}
                height="100%"
                align="end"
                justify="center"
                style={{
                    backgroundColor: color,
                    borderRadius: '25px',
                    transition: 'width 0.5s ease-in-out',
                    paddingInline: 'var(--spacing-75)',
                }}
            >
                <Text size="sm" weight="bold" color="var(--background-color)">
                    {isNaN(percentageTest) ? '0%' : `${percentageTest}%`}
                </Text>
            </Stack>
        </Stack>
    );
};
