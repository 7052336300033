import useCapability from '@/atoms/capabilityAtom';
import ActionButton from '@/components/ActionButton/ActionButton';
import Form from '@/components/Form/Form';
import Modal from '@/components/Modal/Modal';
import SelectOption from '@/components/Select/SelectOption';
import Select from '@/components/Select/SelectRoot';
import Stack from '@/components/Stack/Stack';
import ToggleButton from '@/components/ToggleButton/ToggleButton';
import convertBoolean from '@/util/convertBoolean';
import { SaveLight } from '@vg-react/icons/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSocket from '../../../../../../../../hooks/useSocket';
import request from '../../../../../../../../util/request';
import { ModalEditChannelProps, UpdateDataSocket } from './types/Types';

type EditNameFields = {
    channel: string;
    autoChannelEnable: boolean;
};

export default function ModalEditChannel({
    id,
    active,
    values,
    instance,
    refresh,
    onClose,
    setButtonChannelDisable,
    setDeviceOperation,
}: ModalEditChannelProps) {
    const { t } = useTranslation();
    const autoChannel =
        typeof values.autoChannelEnable === 'string'
            ? convertBoolean(values.autoChannelEnable)
            : values.autoChannelEnable;

    const { socket } = useSocket();
    const [loading, setLoading] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(!autoChannel);
    const [autoChannelEnable, setAutoChannelEnable] = useState<boolean>(autoChannel);
    const [hasNotCapability] = useCapability();

    function handleOnChange(fields: EditNameFields) {
        if (fields.autoChannelEnable === values.autoChannelEnable && Number(fields.channel) === values.channel)
            setDisabled(true);
        else setDisabled(false);
    }
    function handleOnSubmit(fields: EditNameFields) {
        setLoading(true);
        request
            .patch(`/app/devices/${id}/wifi/${instance}`, {
                ...(autoChannelEnable !== values.autoChannelEnable && { autoChannelEnable }),
                channel: !autoChannelEnable ? fields.channel : undefined,
            })
            .then(() => {
                toast.info(
                    t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.tostSending'),
                );
                setDeviceOperation({
                    status: 'in progress',
                    message: t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.sending'),
                });
                socket?.on('updateWifiParameter', (data: UpdateDataSocket) => {
                    setDeviceOperation({ status: data.status, message: data.message });

                    if (data.status === 'in progress') return;

                    if (data.status === 'success') {
                        toast.success(data.message);
                        refresh?.();
                    }

                    setButtonChannelDisable(false);
                    socket.off('updateWifiParameter');

                    setTimeout(() => {
                        setDeviceOperation({
                            message: t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.message',
                            ),
                            status: 'default',
                        });
                    }, 5000);
                });
                setButtonChannelDisable(true);
                onClose();
            })
            .catch((error) => {
                setButtonChannelDisable(true);
                toast.error(error.response.data);
            });
    }

    return (
        <Modal size="small" active={active} onClose={onClose}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.title')}
                </Modal.Title>
            </Modal.Header>
            <Form<EditNameFields>
                initialValues={{ autoChannelEnable: values.autoChannelEnable, channel: values.channel }}
                onSubmit={handleOnSubmit}
                onChange={handleOnChange}
            >
                <Modal.Body>
                    <Stack spacing="var(--spacing-300)">
                        <ToggleButton
                            name="autoChannelEnable"
                            label={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.autoChannel',
                            )}
                            value={autoChannelEnable}
                            onChange={(value) => {
                                setAutoChannelEnable(value);
                                setDisabled(false);
                            }}
                        />
                        <Select
                            width="auto"
                            label={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.channel',
                            )}
                            name="channel"
                            disabled={autoChannelEnable}
                            placeholder={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.placeholderChannel',
                            )}
                            initialValue={values.channel.toString()}
                            description={t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.description',
                            )}
                        >
                            {values.possibleChannels.map((channel) => (
                                <SelectOption key={channel.id} value={channel.id}>
                                    {channel.name}
                                </SelectOption>
                            ))}
                        </Select>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={onClose} quiet>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" loading={loading} disabled={disabled}>
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                <SaveLight size="16" color="currentColor" />
                                {t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditChannel.save',
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
