import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import { getUniqueKey } from '../../util/getUniqueKey';
import Table from '../Table/Table';
import { DataTableColumn } from './DataTable';

interface DataTableBodyContentOnMockProps<T> {
    numberOfRows: number;
    columns: DataTableColumn<T>[];
    loading: boolean;
    selectable: boolean;
}

export function DataTableBodyMock<T>({
    numberOfRows,
    columns,
    loading,
    selectable = false,
}: DataTableBodyContentOnMockProps<T>): JSX.Element {
    const rowsList: unknown[] = Array.from({ length: 10 });

    return (
        <SkeletonTheme baseColor="var(--background-color)" highlightColor="var(--lightness-950)">
            {rowsList.map((_, index) => (
                <Table.Row key={getUniqueKey(`loading-row-${index}`)}>
                    {selectable && (
                        <>
                            <Table.Cell minWidth={'fit-content'}>
                                {loading ? <Skeleton width="100%" height="1rem" style={{ zIndex: 0 }} /> : '-'}
                            </Table.Cell>
                        </>
                    )}
                    {columns.map((column) => (
                        <Table.Cell
                            key={getUniqueKey(`loading-cell-${index}-${column.accessor}`)}
                            align={column.align}
                            minWidth={column.width}
                        >
                            {loading ? <Skeleton width="100%" height="1rem" style={{ zIndex: 0 }} /> : '-'}
                        </Table.Cell>
                    ))}
                </Table.Row>
            ))}
        </SkeletonTheme>
    );
}
