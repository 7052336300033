import { BooleanCapabilityType, CapabilityType } from '@/atoms/capabilityAtom';
import useSocket from '@/hooks/useSocket';
import request from '@/util/request';
import { ActionButton, Form, Input, Modal, Stack } from '@vg-react/components';
import { QuestionCircleLight, SaveLight } from '@vg-react/icons/light';
import { QuestionCircleRegular } from '@vg-react/icons/regular';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { z } from 'zod';
import { WebSocketData } from '../../NatRedirection/Types';
import { WebUserData } from '../Types';

interface EditWebUserModalProps {
    onClose: () => void;
    user: WebUserData;
    setIsSentUser: (value: boolean) => void;
    setDescriptionDevice: (...args: any) => void;
    refresh: () => void;
    defaultDescriptionDevice: { message: string; status: string };
    hasNotCapability: Partial<CapabilityType & BooleanCapabilityType>;
}

type FormData = {
    username: string;
    password: string;
};

export default function EditWebUserModal({
    onClose,
    user,
    setIsSentUser,
    setDescriptionDevice,
    refresh,
    defaultDescriptionDevice,
    hasNotCapability,
}: EditWebUserModalProps) {
    const { id } = useParams();
    const { socket } = useSocket();
    const { t } = useTranslation();

    const schemaWithOptionalUsername = z.object({
        username: z.string().trim().optional(),
        password: z
            .string({
                required_error: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.password'),
            })
            .trim()
            .min(8, {
                message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.passwordMin'),
            })
            .max(18, {
                message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.passwordMax'),
            })
            .optional(),
    });

    const schemaWithoutOptionalUsername = z.object({
        username: z
            .string({
                required_error: t(
                    'DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.usernameRequired',
                ),
            })
            .trim()
            .min(1, t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.usernameRequired')),
        password: z
            .string({
                required_error: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.password'),
            })
            .trim()
            .min(8, {
                message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.passwordMin'),
            })
            .max(18, {
                message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.passwordMax'),
            })
            .optional(),
    });

    const defaultData = {
        username: user?.username ?? '',
        password: user?.password ?? '',
    };

    const initialErrors = {
        username: '',
        password: '',
    };

    const [disable, setDisabled] = useState(true);
    const [alreadySubmit, setAlreadySubmit] = useState(false);
    const [errors, setErrors] = useState(initialErrors);

    const verifyErrors = useCallback(
        (fields: Partial<FormData>) => {
            type Key = keyof FormData;
            for (const key in fields) {
                if (fields[key as Key] === '') fields[key as Key] = undefined;
            }

            const result = !hasNotCapability.Security?.editUser
                ? schemaWithoutOptionalUsername.safeParse(fields)
                : schemaWithOptionalUsername.safeParse(fields);
            if (result.success) {
                setErrors(initialErrors);
                return false;
            }

            setErrors((previous) => {
                const zodErrors = result.error.flatten().fieldErrors;
                const newErrors = { ...initialErrors };
                Object.keys(previous).forEach((key) => {
                    newErrors[key as Key] = zodErrors[key as Key]?.[0] ?? '';
                });

                return newErrors;
            });

            return true;
        },
        [errors, setErrors],
    );

    function onChange(fields: FormData) {
        const notContainsError = verifyErrors(fields);
        if (!fields.password) {
            setDisabled(true);
            return;
        }
        setDisabled(notContainsError);
        if (!user?.username && user.password === fields.password) setDisabled(true);
        if (alreadySubmit) verifyErrors(fields);
    }

    function onSubmit(fields: FormData) {
        if (!id) return;
        const notContainsError = verifyErrors(fields);
        if (notContainsError) return;
        setAlreadySubmit(true);

        const url = `/app/devices/${id}/security/webUser`;

        const bodyRequest = {
            username: hasNotCapability.Security?.editUser ? undefined : fields.username,
            password: fields.password !== '' ? fields.password : undefined,
            path: user?.path,
        };

        request
            .patch(url, bodyRequest)
            .then(() => {
                setDisabled(true);
                setIsSentUser(true);
                onClose();
                socket?.on(`acs:device:webUser:${id}`, (data: WebSocketData) => {
                    if (data.status === 'in progress') {
                        toast.info(data.message);
                        setDescriptionDevice({ message: data.message, status: 'in progress' });
                        return;
                    }

                    setTimeout(() => {
                        setDescriptionDevice(defaultDescriptionDevice);
                        typeof refresh === 'function' && refresh();
                    }, 5000);

                    if (data.status === 'success') {
                        toast.success(data.message);
                    } else toast.error(data.message);

                    setDescriptionDevice({ message: data.message, status: data.status });
                    setIsSentUser(false);
                    socket?.off(`acs:device:webUser:${id}`);
                });
            })
            .catch(() => {
                setDescriptionDevice({
                    message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.error'),
                    status: 'error',
                });
                setIsSentUser(false);
            });
    }

    return (
        <Modal
            size="small"
            active
            onClose={onClose}
            style={{
                overflow: 'visible',
            }}
        >
            <Form<FormData> initialValues={defaultData} onChange={onChange} onSubmit={onSubmit}>
                <Modal.Header generateCloseButton>
                    <Modal.Title>
                        {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.title')}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Stack spacing="small">
                        <Input
                            required={!hasNotCapability?.Security?.editUser}
                            width="100%"
                            iconInfo={
                                hasNotCapability.Security?.editUser
                                    ? {
                                          icon: <QuestionCircleLight size="16" color="var(--yellow-800)" />,
                                          text: t(
                                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.notAllowChange',
                                          ),
                                      }
                                    : undefined
                            }
                            helpText={errors.username}
                            disabled={hasNotCapability?.Security?.editUser}
                            invalid={Boolean(errors.username)}
                            name="username"
                            label={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.username')}
                        />
                        <Input
                            required={!hasNotCapability.Security?.editPassword}
                            type="password"
                            width="100%"
                            name="password"
                            label={t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.password')}
                            disabled={hasNotCapability?.Security?.editPassword}
                            helpText={errors.password}
                            invalid={Boolean(errors.password)}
                            iconInfo={
                                hasNotCapability.Security?.editPassword
                                    ? {
                                          icon: (
                                              <QuestionCircleRegular
                                                  size="14"
                                                  color="var(--yellow-800)"
                                                  style={{ marginRight: '5px' }}
                                              />
                                          ),
                                          text: t(
                                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.notAllowChangePassword',
                                          ),
                                      }
                                    : undefined
                            }
                        />
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" spacing="small" align="center" justify="end">
                        <ActionButton quiet onClick={onClose}>
                            {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" disabled={disable}>
                            <SaveLight color="currentColor" size="16" />
                            {t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.EditWebUserModal.save')}
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
