import { Stack, Text } from '@vg-react/components';
import { CircleDownRegular, CircleUpRegular } from '@vg-react/icons/v6/regular';
import { TriangleExclamationSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import { ChartLastResults } from '../index/ChartLastResults';
import { CountUpAnimation, Result, TestHistoryData } from '../index/SpeedTestResult';
import calcRate, { convertToGbps } from '../utils/calcRate';
import { QualityBarSpeedTest } from './QualityBarSpeedTest';

interface ResultCardProps {
    result: Result | null;
    type: 'Download' | 'Upload';
    testHistory: TestHistoryData[];
    speedContracted?: number;
}

export const ResultAdvancedCard = ({ result, type, testHistory, speedContracted }: ResultCardProps) => {
    const { t } = useTranslation();
    const widthScreen = window.innerWidth;

    const resultRate =
        type === 'Download'
            ? calcRate({
                  bytes: result?.data?.download.bytes ?? 0,
                  milliseconds: result?.data?.download.milliseconds ?? 0,
              })
            : calcRate({
                  bytes: result?.data?.upload.bytes ?? 0,
                  milliseconds: result?.data?.upload.milliseconds ?? 0,
              });

    const resultDecimalPlaces = resultRate?.toString().split('.')[1]?.length || 0;

    const errorTest =
        type === 'Download' ? result?.data.download.status === 'failed' : result?.data.upload.status === 'failed';

    const isRunning =
        type === 'Download'
            ? Boolean(result && result.data.download.status === 'awaiting')
            : Boolean(result && result.data.upload.status === 'awaiting');

    const verifyLastResults = () => {
        if (testHistory.length === 0) return false;

        if (type === 'Download') {
            const resultMajorZero = testHistory.find((result) => result.download > 0);
            return resultMajorZero ? true : false;
        }

        const resultMajorZero = testHistory.find((result) => result.upload > 0);
        return resultMajorZero ? true : false;
    };

    return (
        <Stack direction="row" align="center" justify="center" width="calc(50% - var(--spacing-100))" height={'100%'}>
            {isRunning ? (
                <Stack direction="column" align="center" justify="center" style={{ padding: 'var(--spacing-300)' }}>
                    <Skeleton
                        width={150}
                        height={150}
                        borderRadius={'var(--radius-medium)'}
                        baseColor="var(--background-color)"
                        style={{ opacity: 0.7 }}
                    />

                    <Skeleton
                        width={widthScreen > 1000 ? 280 : 200}
                        height={40}
                        borderRadius={'var(--radius-medium)'}
                        baseColor="var(--background-color)"
                        style={{ opacity: 0.7 }}
                    />
                </Stack>
            ) : (
                <Stack direction="column" align="center" justify="center" spacing="var(--spacing-50)">
                    <Stack direction="column" align="center" justify="center" spacing="var(--spacing-75)">
                        <Stack direction="row" align="center" justify="center" spacing="var(--spacing-50)">
                            {type === 'Download' ? (
                                <CircleDownRegular color="var(--download-color)" />
                            ) : (
                                <CircleUpRegular color="var(--upload-color)" />
                            )}
                            <Text
                                size="sm"
                                weight="regular"
                                color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                            >
                                {type}
                            </Text>
                        </Stack>
                        <Stack
                            direction="row"
                            align="center"
                            justify="center"
                            spacing="var(--spacing-50)"
                            style={{
                                padding: '30px',
                            }}
                        >
                            {!errorTest && !isNaN(resultRate) ? (
                                <>
                                    <Text
                                        size={widthScreen < 1400 ? 'xxxl' : 'exl'}
                                        weight="bold"
                                        color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                                    >
                                        <CountUpAnimation duration={1500} decimalPlaces={resultDecimalPlaces}>
                                            {resultRate > 1000 ? convertToGbps(resultRate) : resultRate}
                                        </CountUpAnimation>
                                    </Text>
                                    <Text
                                        size="lg"
                                        weight="regular"
                                        color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                                    >
                                        {resultRate > 1000 ? 'Gbps' : 'Mbps'}
                                    </Text>
                                </>
                            ) : (
                                <Stack
                                    align="center"
                                    justify="center"
                                    spacing="var(--spacing-100)"
                                    style={{
                                        paddingBlock: '15px',
                                    }}
                                >
                                    <TriangleExclamationSolid size="26" color="var(--lightness-400)" />
                                    <Text size="xs" weight="regular" color="var(--lightness-400)">
                                        {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ResultAdvancedCard.error')}
                                    </Text>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>

                    {speedContracted && speedContracted > 0 && !errorTest && !isNaN(resultRate) && (
                        <Stack
                            direction="column"
                            align="center"
                            spacing="var(--spacing-100)"
                            style={{
                                width: '100%',
                                zIndex: 0,
                            }}
                        >
                            <Stack spacing="var(--spacing-75)">
                                <Stack direction="row" align="center" justify="space-between" width="100%">
                                    <Text size="xs" weight="regular" color="var(--lightness-300)">
                                        0 Mbps
                                    </Text>

                                    <Text size="xs" weight="bold" color="var(--lightness-300)">
                                        {speedContracted} Mbps
                                    </Text>
                                </Stack>

                                <QualityBarSpeedTest
                                    color={type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'}
                                    percentageTest={calculatePercentageTest(resultRate, speedContracted)}
                                />
                                <Text size="xs" weight="regular" color="var(--lightness-300)">
                                    {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ResultAdvancedCard.info', {
                                        percentage: calculatePercentageTest(resultRate, speedContracted),
                                    })}
                                </Text>
                            </Stack>
                        </Stack>
                    )}

                    <Stack width="100%">
                        <Stack
                            direction="row"
                            width="100%"
                            height="60px"
                            align="end"
                            justify="center"
                            style={{
                                paddingLeft: '30%',
                                marginTop: '20px',
                            }}
                        >
                            {verifyLastResults() && (
                                <Stack
                                    direction="row"
                                    width="100%"
                                    align="end"
                                    justify="center"
                                    spacing="var(--spacing-100)"
                                >
                                    <Text size="xxs" weight="regular" color="var(--lightness-300)">
                                        {t(
                                            'DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.ResultAdvancedCard.lastResults',
                                        )}
                                    </Text>
                                    <Stack width="100%">
                                        <ChartLastResults
                                            color={
                                                type === 'Download' ? 'var(--download-color)' : 'var(--upload-color)'
                                            }
                                            values={testHistory}
                                            type={type}
                                        />
                                    </Stack>
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                </Stack>
            )}
        </Stack>
    );
};

function calculatePercentageTest(resultRate: number, speedContracted: number) {
    const percentage = (resultRate / speedContracted) * 100;
    return Math.round(percentage);
}
