import { Stack, Text } from '@vg-react/components';

interface PageTitleProps {
    title: string;
    description?: string;
}

export default function PageTitle({ title, description }: PageTitleProps) {
    return (
        <Stack spacing='var(--spacing-50)'>
            <Text variant='h1' color='var(--gray-900)' lineHeight='none' size='xl'>{title}</Text>
            <Text variant='h2' color='var(--gray-600)' lineHeight='none' weight='regular' size='xs'>{description}</Text>
        </Stack>
    );
}