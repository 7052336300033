import { Text } from '@vg-react/components';
import { FileAltLight } from '@vg-react/icons/light';
import { useDragLayer, XYCoord } from 'react-dnd';
import style from './Firmware.module.scss';

function getItemStyles(
    initialOffset: XYCoord | null,
    currentOffset: XYCoord | null,
    dimensions: { width: number; height: number },
) {
    if (!initialOffset || !currentOffset) {
        return {
            display: 'none',
        };
    }

    const { x, y } = currentOffset;

    const transform = `translate(${x - dimensions.width / 2}px, ${y - dimensions.height / 2}px)`;

    return {
        transform,
        WebkitTransform: transform,
    };
}

export default function DragLayer() {
    const { isDragging, item, initialOffset, currentOffset } = useDragLayer((monitor) => ({
        item: monitor.getItem(),
        itemType: monitor.getItemType(),
        initialOffset: monitor.getInitialSourceClientOffset(),
        currentOffset: monitor.getClientOffset(),
        isDragging: monitor.isDragging(),
    }));

    if (!isDragging) {
        return null;
    }

    const itemDimensions = {
        width: 225,
        height: 175,
    };

    return (
        <div
            style={{
                position: 'fixed',
                pointerEvents: 'none',
                zIndex: 100,
                left: 0,
                top: 0,
                width: '100%',
                height: '100%',
            }}
        >
            <div style={getItemStyles(initialOffset, currentOffset, itemDimensions)}>
                <div
                    className={style.dragOverlay}
                    style={{
                        width: `${itemDimensions.width}px`,
                        height: `${itemDimensions.height}px`,
                    }}
                >
                    <FileAltLight size="100" color="var(--lightness-100)" />
                    <Text color="var(--lightness-100)" weight="bold" size="sm">
                        {item.name?.length > 20 ? `${item.name.substring(0, 20)}...` : item.name}
                    </Text>
                </div>
            </div>
        </div>
    );
}
