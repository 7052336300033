import { AxiosError, AxiosInstance, AxiosRequestConfig } from 'axios';
import { useEffect, useState } from 'react';
import request from '../util/request';

interface Options extends AxiosRequestConfig {
    agent?: AxiosInstance;
}

export default function useFetch<T = unknown, E = unknown>(url: string, options: Options = {}) {
    const [data, setData] = useState<T | null>(null);
    const [error, setError] = useState<AxiosError<E> | null>(null);
    const [isFetching, setIsFetching] = useState<boolean>(true);
    const [refreshState, setRefreshState] = useState<boolean>(false);
    const agent = options.agent ?? request;

    const refresh = () => {
        setRefreshState((refreshState) => !refreshState);
        setIsFetching(true);
    };

    const forceRefresh = () => {
        agent
            .get(url, options)
            .then((response) => setData(response.data))
            .catch((err) => setError(err))
            .finally(() => setIsFetching(false));
    };

    useEffect(() => {
        agent
            .get(url, options)
            .then((response) => setData(response.data))
            .catch((err) => setError(err))
            .finally(() => setIsFetching(false));
    }, [refreshState]);

    return { data, error, isFetching, refresh, forceRefresh };
}
