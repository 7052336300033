import { classnames } from '@/util/classnames';
import { InfoCircleSolid, SparklesSolid } from '@vg-react/icons/solid';
import { t } from 'i18next';
import { ExperimentalFeatureLabel } from '../Miscelaneous/SSOUser';
import Spinner from '../Spinner/Spinner';
import Stack from '../Stack/Stack';
import Text from '../Text/Text';
import Tooltip from '../Tooltip/Tooltip';
import styles from './base/Field.module.scss';
interface FieldLabelProps {
    children: string | React.ReactNode;
    updating: boolean;
    sso: boolean;
    updatingByAI?: boolean;
    iconInfo?: {
        icon: JSX.Element;
        text: string;
    };
    /** @deprecated Use iconInfo instead */
    information?: string;
}

export default function FieldLabel({
    children,
    updating = true,
    sso = false,
    updatingByAI,
    iconInfo,
    information = '',
}: FieldLabelProps) {
    return (
        <Stack direction="row" align="center" spacing="var(--spacing-100)">
            <Stack direction="row" spacing="var(--spacing-75)" align="center">
                <Text size="xs" weight="bold" color="var(--gray-800)">
                    {children}
                </Text>
                {iconInfo && (
                    <Tooltip>
                        <Tooltip.Trigger style={{ display: 'grid', placeItems: 'center', height: '100%' }}>
                            {iconInfo.icon}
                        </Tooltip.Trigger>
                        <Tooltip.Content>{iconInfo.text}</Tooltip.Content>
                    </Tooltip>
                )}
                {information.length > 0 && (
                    <Tooltip>
                        <Tooltip.Trigger style={{ height: 12 }}>
                            <InfoCircleSolid size="12" color="var(--gray-500)" style={{ marginBottom: 5 }} />
                        </Tooltip.Trigger>
                        <Tooltip.Content>{information}</Tooltip.Content>
                    </Tooltip>
                )}
            </Stack>
            {sso && <ExperimentalFeatureLabel size="14" />}
            {updatingByAI !== undefined && <AssistantBadge updatingByAI={updatingByAI} />}
            {updating && updatingByAI === undefined && (
                <Spinner size="small" color="var(--gray-800)" style={{ position: 'relative' }} />
            )}
        </Stack>
    );
}

function AssistantBadge({ updatingByAI }: { updatingByAI: boolean }) {
    return (
        <div className={classnames(styles.assistantBadge, updatingByAI && styles.active)}>
            {!updatingByAI ? (
                <>
                    <SparklesSolid size="14" style={{ transform: 'translate(-1px)' }} color="currentColor" />
                    <p>{t('Assistant.Badge.doneByAssistant')}</p>
                </>
            ) : (
                <div className={styles.content}>
                    <Spinner size="small" color="#d748b3"  style={{ position: 'relative' }} />
                    <p className={styles.textGradient}>{t('Assistant.Badge.updatingByAssistant')}</p>
                </div>
            )}
        </div>
    );
}
