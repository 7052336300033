import ModalCreateFile from '@/screens/System/Files/index/ModalCreateFile';
import { convertBytes } from '@/util/format';
import { Badge, Popover, Stack, Text, Tooltip } from '@vg-react/components';
import { EditLight, EllipsisVLight, FileAltLight } from '@vg-react/icons/light';
import { useEffect, useState } from 'react';
import { useDrag } from 'react-dnd';
import { getEmptyImage } from 'react-dnd-html5-backend';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../../../../../util/classnames';
import style from './Firmware.module.scss';

interface NameValueTextProps {
    name: string;
    value: string;
}

export interface FileType {
    id: string;
    name: string;
    size: string;
    version: string;
    fileType: number;
    tags: string[];
}

function NameValueText({ name, value }: NameValueTextProps) {
    return (
        <Stack direction="row" spacing="var(--spacing-25) var(--spacing-50)" style={{ flexWrap: 'wrap' }}>
            {name.length > 30 ? (
                <Tooltip>
                    <Tooltip.Trigger>
                        <Text weight="bold" size="xs" style={{ userSelect: 'none' }}>
                            {`${name.substring(0, 30)}...`}
                        </Text>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{name}</Tooltip.Content>
                </Tooltip>
            ) : (
                <Text weight="bold" size="xs" style={{ userSelect: 'none' }}>
                    {name}
                </Text>
            )}
            {value.length > 30 ? (
                <Tooltip>
                    <Tooltip.Trigger>
                        <Text weight="regular" size="xs" style={{ userSelect: 'none' }}>
                            {`${value.substring(0, 30)}...`}
                        </Text>
                    </Tooltip.Trigger>
                    <Tooltip.Content>{value}</Tooltip.Content>
                </Tooltip>
            ) : (
                <Text weight="regular" size="xs" style={{ userSelect: 'none' }}>
                    {value}
                </Text>
            )}
        </Stack>
    );
}

interface FileProps {
    file: FileType;
    selected: string;
    blink?: boolean;
    setSelected: (value: string) => void;
    refresh: () => void;
}

interface DropResult {
    name: string;
}

export default function File({ blink, file, selected, setSelected, refresh }: FileProps) {
    const { t } = useTranslation();
    const [_, drag, preview] = useDrag(
        () => ({
            type: 'file',
            item: { name: file.name, id: file.id },
            end: (item, monitor) => {
                const dropResult = monitor.getDropResult<DropResult>();
                if (item && dropResult) {
                    setSelected(item.id);
                }
            },
            canDrag: () => selected === '',
            collect: (monitor) => ({
                isDragging: monitor.isDragging(),
                handlerId: monitor.getHandlerId(),
            }),
        }),
        [selected],
    );

    useEffect(() => {
        preview(getEmptyImage(), { captureDraggingState: true });
    }, []);

    const fileTypeName: Record<number, string> = {
        1: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.firmwareUpgradeImage'),
        2: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.webContent'),
        3: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.vendorConfigurationFile'),
        4: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.toneFile'),
        5: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.ringerFile'),
        6: t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.storedFirmwareImage'),
    };

    const [modalCreateFileIsActive, setModalCreateFileIsActive] = useState<boolean>(false);

    return (
        <div ref={drag} className={blink ? style.blinkingBorder : ''}>
            <Stack
                direction="row"
                align="center"
                justify="space-between"
                spacing="var(--spacing-100)"
                className={classnames(
                    style.file,
                    selected !== '' && style.hasSelection,
                    selected === file.id && style.selected,
                )}
            >
                <Stack direction="row" spacing="var(--spacing-100)" align="center">
                    <FileAltLight color="var(--lightness-500)" size="30" />
                    <NameValueText
                        name={file.name}
                        value={`(${t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.version')} ${file.version})`}
                    />
                    <div className={style.separator} />
                    <NameValueText
                        name={t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.size')}
                        value={convertBytes(Number(file.size))}
                    />
                    <div className={style.separator} />
                    <NameValueText
                        name={t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.type')}
                        value={fileTypeName[file.fileType] ?? ''}
                    />
                </Stack>
                <Stack direction="row" spacing="var(--spacing-50)" align="center">
                    <Stack direction="row" spacing="var(--spacing-50)" style={{ flexWrap: 'wrap' }}>
                        {file.tags.map((tag) => (
                            <Badge outlined key={tag} style={{ userSelect: 'none' }}>
                                {tag}
                            </Badge>
                        ))}
                    </Stack>
                    <Stack direction="row" spacing="var(--spacing-50)" align="center">
                        <Stack direction="row" spacing="var(--spacing-50)" style={{ flexWrap: 'wrap' }}>
                            {file.tags.map((tag) => (
                                <Badge outlined key={tag} style={{ userSelect: 'none' }}>
                                    {tag}
                                </Badge>
                            ))}
                        </Stack>
                        <Popover offsetValue={8} hasArrow>
                            {({ open }: { open: boolean }) => (
                                <>
                                    <Popover.Button className={classnames(style.popoverButton, open && style.open)}>
                                        <EllipsisVLight size="25" color="var(--lightness-400)" />
                                    </Popover.Button>
                                    <Popover.Panel>
                                        <div
                                            className={style.popoverPanel}
                                            onClick={() => {
                                                setModalCreateFileIsActive(true);
                                            }}
                                        >
                                            <Stack
                                                className={style.popoverPanelOption}
                                                direction="row"
                                                spacing="var(--spacing-75)"
                                                align="center"
                                                justify="left"
                                            >
                                                <EditLight color="var(--lightness-600)" size="16" />
                                                <Text size="sm" weight="regular">
                                                    {t('ToolsScreen.MassChangeScreen.FirmwareScreen.File.editFile')}
                                                </Text>
                                            </Stack>
                                        </div>
                                    </Popover.Panel>
                                </>
                            )}
                        </Popover>
                    </Stack>
                </Stack>
            </Stack>
            {modalCreateFileIsActive && (
                <ModalCreateFile
                    active={modalCreateFileIsActive}
                    onClose={() => {
                        setModalCreateFileIsActive(false);
                        refresh();
                    }}
                    file={file}
                />
            )}
        </div>
    );
}
