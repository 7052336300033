import Select from '@/components/Select/Select';
import TagInput from '@/components/TagInput/TagInput';
import { ActionButton, Card, Stack, Text, Tooltip } from '@vg-react/components';
import { MagnifyingGlassSolid, TriangleExclamationSolid } from '@vg-react/icons/v6/solid';
import { motion } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Skeleton from 'react-loading-skeleton';
import {
    useMassUpdateContext,
    variantsBackward,
    variantsForward,
} from '../../../../../../contexts/MassChange/MassChangeFirmwareContext';
import request from '../../../../../../util/request';
import DeviceTable from './DeviceTable';
import style from './Devices.module.scss';

export default function Devices() {
    const { t } = useTranslation();
    const {
        setSelectedDevices,
        setDevicesList,
        setSelectedFilters,
        selectedFilters,
        direction,
        refresh,
        dataFilters,
        isFetchingDataFilters,
        setIsFetchingDevicesList,
    } = useMassUpdateContext();
    const [page, setPage] = useState<number>(1);
    const [manufacturers, setSuggestionManuf] = useState<string[]>([]);
    const [models, setModels] = useState<Array<{ name: string; unsuportedModel: boolean }> | []>([]);
    const [hardwares, setHardwares] = useState<string[]>([]);
    const [firmwares, setFirmwares] = useState<string[]>([]);
    const [disabledButtons, setDisabledButtons] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(isFetchingDataFilters);
    const [description, setDescription] = useState('');

    const searchDevices = async () => {
        const mountFilters = () => {
            return `?manufacturer=${selectedFilters.manufacturer || '[]'}&models[]=${
                selectedFilters.model
            }&hardware[]=${selectedFilters.hardware}&firmware[]=${selectedFilters.firmwares.join(
                '&firmware[]=',
            )}&observation=${description}`;
        };

        if (selectedFilters.manufacturer && selectedFilters.model) setIsFetchingDevicesList(true);
        await request
            .get(`/app/massChange/firmware/devices${mountFilters()}&page=${page}`)
            .then((res) => {
                if (res.data && res.data.length) {
                    setDevicesList([...res.data]);
                }
            })
            .finally(() => {
                setIsFetchingDevicesList(false);
            });
    };

    useEffect(() => {
        searchDevices();
        refresh();
    }, [page]);

    function modelFilters() {
        if (!selectedFilters.model.length) {
            selectedFilters.hardware = '';
            selectedFilters.firmwares = [];
        }
        const manufacturer = dataFilters?.find((a) => a.name === selectedFilters.manufacturer);
        const hardwares: string[] = [];
        const firmwares: string[] = [];
        manufacturer?.models?.forEach((item) => {
            if (selectedFilters.model.includes(item.name)) {
                item?.hardwares?.forEach((hardware) => {
                    if (!hardwares.includes(hardware)) hardwares.push(hardware);
                });
                item?.firmwares?.forEach((firmware) => {
                    if (!firmwares.includes(firmware)) firmwares.push(firmware);
                });
            }
        });

        setSelectedFilters((prev: any) => ({
            ...prev,
            hardware: selectedFilters.hardware,
            firmwares: selectedFilters.firmwares.filter((firmware) => firmwares.includes(firmware)),
        }));

        setModels(
            dataFilters
                ?.find((a) => a.name === manufacturer?.name)
                ?.models.map((a) => {
                    const obj = {
                        name: a.name,
                        unsuportedModel: a.unsuportedModel,
                    };
                    return obj;
                })
                .sort((e) => {
                    if (e.unsuportedModel === true) {
                        return 0;
                    }
                    return -1;
                }) || [],
        );

        setHardwares(hardwares);
        setFirmwares(firmwares);
        setDisabledButtons(!selectedFilters.manufacturer || !selectedFilters.model.length);
    }

    useEffect(() => {
        setSuggestionManuf(dataFilters?.map((a) => a.name) || []);
    }, [dataFilters]);

    useEffect(() => {
        modelFilters();
        setIsLoading(isFetchingDataFilters);
    }, [selectedFilters.manufacturer, manufacturers]);

    useEffect(() => {
        modelFilters();
    }, [selectedFilters.model]);

    return (
        <motion.div
            style={{ x: 0, height: '100%', overflow: 'hidden' }}
            initial="initial"
            animate="animate"
            exit="exit"
            variants={direction === 'forward' ? variantsForward : variantsBackward}
        >
            <Stack
                direction="row"
                justify="space-between"
                align="center"
                style={{
                    height: '100%',
                }}
            >
                <Card className={style.filterCard}>
                    <Stack
                        style={{
                            margin: 'var(--spacing-75)',
                            height: '100%',
                        }}
                    >
                        <Text className={style.title}>{t('ToolsScreen.MassChangeScreen.DeviceScreen.filters')}</Text>
                        <Stack
                            height="100%"
                            style={{ overflow: 'auto', padding: 'var(--spacing-50)' }}
                            spacing={window.innerHeight < 800 ? 'var(--spacing-100)' : 'var(--spacing-300)'}
                        >
                            {isLoading ? (
                                <Skeleton width="100%" height="32px" style={{ marginTop: '22px' }} />
                            ) : (
                                <Select
                                    label={t('ToolsScreen.MassChangeScreen.DeviceScreen.manufacturer')}
                                    placeholder={t('ToolsScreen.MassChangeScreen.DeviceScreen.selectManufacturer')}
                                    name="manufacturer"
                                    width={window.innerHeight < 800 ? '98%' : '100%'}
                                    value={selectedFilters.manufacturer}
                                    required
                                    onChange={(value: string) => {
                                        setSelectedFilters({
                                            manufacturer: value,
                                            model: '',
                                            hardware: '',
                                            firmwares: [],
                                        });
                                        setModels(
                                            dataFilters
                                                ?.find((a) => a.name === value)
                                                ?.models.map((a) => {
                                                    return { name: a.name, unsuportedModel: a.unsuportedModel };
                                                }) || [],
                                        );
                                    }}
                                >
                                    {manufacturers.map((manuf) => (
                                        <Select.Option value={manuf} key={manuf}>
                                            {manuf}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            {isLoading ? (
                                <Skeleton width="100%" height="32px" style={{ marginTop: '22px' }} />
                            ) : (
                                <Select
                                    label={t('ToolsScreen.MassChangeScreen.DeviceScreen.model')}
                                    placeholder={t('ToolsScreen.MassChangeScreen.DeviceScreen.selectModel')}
                                    name="model"
                                    width={window.innerHeight < 800 ? '98%' : '100%'}
                                    value={selectedFilters.model}
                                    description={t('ToolsScreen.MassChangeScreen.DeviceScreen.selectModelInfo')}
                                    required
                                    onChange={(value: string) => {
                                        setSelectedFilters((prev: any) => ({
                                            ...prev,
                                            model: value,
                                        }));
                                    }}
                                >
                                    {models.map((model) => (
                                        <Select.Option
                                            value={model.name}
                                            key={model.name}
                                            disabled={model.unsuportedModel}
                                        >
                                            {model.name}
                                            {model.unsuportedModel === true ? ' (Não suportado)' : ''}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            {isLoading ? (
                                <Skeleton width="100%" height="32px" style={{ marginTop: '22px' }} />
                            ) : (
                                <Select
                                    label={t('ToolsScreen.MassChangeScreen.DeviceScreen.hardware')}
                                    placeholder={t('ToolsScreen.MassChangeScreen.DeviceScreen.selectHardware')}
                                    name="hardware"
                                    width={window.innerHeight < 800 ? '98%' : '100%'}
                                    value={selectedFilters.hardware}
                                    onChange={(value: string) => {
                                        setSelectedFilters((prev: any) => ({
                                            ...prev,
                                            hardware: value,
                                        }));
                                    }}
                                >
                                    {hardwares.map((hw) => (
                                        <Select.Option value={hw} key={hw}>
                                            {hw}
                                        </Select.Option>
                                    ))}
                                </Select>
                            )}
                            {isLoading ? (
                                <Skeleton width="100%" height="41.25px" style={{ marginTop: '22px' }} />
                            ) : (
                                <TagInput
                                    label={t('ToolsScreen.MassChangeScreen.DeviceScreen.firmware')}
                                    enforceWhitelist
                                    placeholder={t('ToolsScreen.MassChangeScreen.DeviceScreen.selectFirmware')}
                                    setTags={(value) => {
                                        setSelectedFilters((prev: any) => ({
                                            ...prev,
                                            firmwares: value.map((a) => a),
                                        }));
                                    }}
                                    suggestions={firmwares}
                                    width={window.innerHeight < 800 ? '98%' : '100%'}
                                    tags={[...selectedFilters.firmwares]}
                                />
                            )}
                        </Stack>
                        <Stack direction="row" justify="end" align="end" style={{ marginBottom: 'var(--spacing-100)' }}>
                            {!disabledButtons && (
                                <Tooltip>
                                    <Tooltip.Trigger>
                                        <TriangleExclamationSolid size="22" color="var(--yellow-800)" />
                                    </Tooltip.Trigger>
                                    <Tooltip.Content>
                                        <Text size="xs" weight="regular" color="currentColor">
                                            {t('ToolsScreen.MassChangeScreen.DeviceScreen.warning')}
                                        </Text>
                                        <Text size="xs" weight="regular" color="currentColor">
                                            {t('ToolsScreen.MassChangeScreen.DeviceScreen.warning2')}
                                        </Text>
                                    </Tooltip.Content>
                                </Tooltip>
                            )}
                            <ActionButton
                                onClick={() => {
                                    searchDevices();
                                    setSelectedDevices([]);
                                }}
                                variant="secondary"
                                disabled={disabledButtons}
                            >
                                <MagnifyingGlassSolid
                                    color={disabledButtons ? 'var(--gray-400)' : 'var(--accent-900)'}
                                />
                                {t('ToolsScreen.MassChangeScreen.DeviceScreen.search')}
                            </ActionButton>
                        </Stack>
                    </Stack>
                </Card>
                <DeviceTable page={page} setPage={setPage} />
            </Stack>
        </motion.div>
    );
}
