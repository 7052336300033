import Modal from '@/components/Modal/Modal';
import request from '@/util/request';
import { ActionButton, Stack, Text } from '@vg-react/components';
import { TrashLight } from '@vg-react/icons/v6/light';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

interface ModalDeleteIntegrationProps {
    onClose: () => void;
    refreshIntegrations: () => void;
    integrationName: string;
}

export default function ModalDeleteIntegration({
    onClose,
    refreshIntegrations,
    integrationName,
}: ModalDeleteIntegrationProps) {
    const { t } = useTranslation();
    function handleDelete() {
        request
            .delete(`/app/integrations/system/${integrationName}`)
            .then((res) => {
                toast.info(t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.deletingIntegration'));
            })
            .catch((err) => {
                toast.error(err.response.data.message);
            })
            .finally(() => {
                toast.success(t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.integrationDeleted'));
                refreshIntegrations();
            });
    }

    return (
        <Modal active size="small" onClose={onClose}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.deleteIntegration')}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Text color="var(--gray-900)" size="xs" weight="regular" align="justify">
                    {t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.deleteIntegrationDescription')}
                </Text>
            </Modal.Body>
            <Modal.Footer>
                <Stack direction="row" align="center" justify="end" spacing="small">
                    <ActionButton onClick={onClose} variant="primary" quiet>
                        {t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.cancel')}
                    </ActionButton>
                    <ActionButton variant="danger" onClick={handleDelete}>
                        <TrashLight size="16" color="currentColor" />
                        {t('ToolsScreen.IntegrationsScreen.ModalDeleteIntegrationComponent.delete')}
                    </ActionButton>
                </Stack>
            </Modal.Footer>
        </Modal>
    );
}
