import { classnames } from '@/util/classnames';
import { memo, useEffect, useState } from 'react';

// initial-value: rgb(192, 38, 211);
// initial-value: rgb(215, 72, 179);
// initial-value: rgb(236, 56, 145);
// initial-value: rgb(245, 17, 119);
// initial-value: rgb(215, 36, 85);
// initial-value: rgb(94, 192, 237)

export const BackgroundGradientAnimation = memo(
    ({
        gradientBackgroundStart = 'rgb(22, 29, 56)',
        gradientBackgroundEnd = 'rgb(7, 62, 94)',
        firstColor = '192, 38, 211',
        secondColor = '94, 192, 237',
        thirdColor = '38, 230, 255',
        fourthColor = '7, 129, 250',
        fifthColor = '215, 36, 197',
        pointerColor = '140, 100, 255',
        animated = true,
        size = '80%',
        children,
        className,
        style,
        containerClassName,
    }: {
        gradientBackgroundStart?: string;
        gradientBackgroundEnd?: string;
        firstColor?: string;
        secondColor?: string;
        thirdColor?: string;
        fourthColor?: string;
        fifthColor?: string;
        pointerColor?: string;
        size?: string;
        animated?: boolean;
        blendingValue?: string;
        children?: React.ReactNode;
        className?: string;
        interactive?: boolean;
        containerClassName?: string;
        style?: React.CSSProperties;
    }) => {
        useEffect(() => {
            document.body.style.setProperty('--gradient-background-start', gradientBackgroundStart);
            document.body.style.setProperty('--gradient-background-end', gradientBackgroundEnd);
            document.body.style.setProperty('--first-color', firstColor);
            document.body.style.setProperty('--second-color', secondColor);
            document.body.style.setProperty('--third-color', thirdColor);
            document.body.style.setProperty('--fourth-color', fourthColor);
            document.body.style.setProperty('--fifth-color', fifthColor);
            document.body.style.setProperty('--pointer-color', pointerColor);
            document.body.style.setProperty('--size', size);
        }, []);

        const [isSafari, setIsSafari] = useState(false);
        useEffect(() => {
            setIsSafari(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));
        }, []);

        return (
            <div
                className={classnames(
                    'h-screen w-screen absolute overflow-hidden top-0 left-0 bg-[linear-gradient(40deg,var(--gradient-background-start),var(--gradient-background-end))] filter-[brightness(80%)]',
                    containerClassName,
                )}
                style={style}
            >
                <svg className="hidden">
                    <defs>
                        <filter id="blurMe">
                            <feGaussianBlur in="SourceGraphic" stdDeviation="10" result="blur" />
                            <feColorMatrix
                                in="blur"
                                mode="matrix"
                                values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 18 -8"
                                result="goo"
                            />
                            <feBlend in="SourceGraphic" in2="goo" />
                        </filter>
                    </defs>
                </svg>
                <div className={classnames('', className)}>{children}</div>
                <div
                    className={classnames(
                        'gradients-container h-full w-full blur-lg',
                        isSafari ? 'blur-2xl' : '[filter:url(#blurMe)_blur(40px)]',
                    )}
                >
                    <div
                        className={classnames(
                            'absolute [background:radial-gradient(circle_at_center,_var(--first-color)_0,_var(--first-color)_50%)_no-repeat]',
                            'w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]',
                            '[transform-origin:center_center]',
                            animated && 'will-change-transform animate-first',
                            'opacity-100',
                            !animated && 'top-[70%] left-[10%] opacity-20',
                        )}
                    ></div>
                    <div
                        className={classnames(
                            'absolute [background:radial-gradient(circle_at_center,_rgba(var(--second-color),_0.8)_0,_rgba(var(--second-color),_0)_50%)_no-repeat]',
                            'w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]',
                            '[transform-origin:calc(50%-400px)]',
                            animated && 'will-change-transform animate-second',
                            'opacity-100',
                            !animated && 'top-[100%] left-[0] opacity-20',
                        )}
                    ></div>
                    <div
                        className={classnames(
                            'absolute [background:radial-gradient(circle_at_center,_rgba(var(--third-color),_0.8)_0,_rgba(var(--third-color),_0)_50%)_no-repeat]',
                            'w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]',
                            '[transform-origin:calc(50%+400px)]',
                            animated && 'will-change-transform animate-third',
                            'opacity-100',
                            !animated && 'top-[calc(10%-var(--size)/2)] left-[calc(10%-var(--size)/2)] opacity-20',
                        )}
                    ></div>
                    <div
                        className={classnames(
                            'absolute [background:radial-gradient(circle_at_center,_rgba(var(--fourth-color),_0.8)_0,_rgba(var(--fourth-color),_0)_50%)_no-repeat]',
                            'w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]',
                            '[transform-origin:calc(50%-200px)]',
                            animated && 'will-change-transform animate-fourth',
                            'opacity-70',
                            !animated && 'top-[calc(var(--size)/2)] left-[calc(50%-var(--size)/2)] opacity-20',
                        )}
                    ></div>
                    <div
                        className={classnames(
                            'absolute [background:radial-gradient(circle_at_center,_rgba(var(--fifth-color),_0.8)_0,_rgba(var(--fifth-color),_0)_50%)_no-repeat]',
                            'w-[var(--size)] h-[var(--size)] top-[calc(50%-var(--size)/2)] left-[calc(50%-var(--size)/2)]',
                            '[transform-origin:calc(50%-800px)_calc(50%+800px)]',
                            animated && 'will-change-transform animate-fifth',
                            'opacity-100',
                            !animated && 'top-[-50%] left-[-10%] opacity-20',
                        )}
                    ></div>
                </div>
            </div>
        );
    },
);

BackgroundGradientAnimation.displayName = 'BackgroundGradientAnimation';
