import styles from './Modal.module.scss';
import { ReactNode } from 'react';

interface Props {
    children: ReactNode;
}

export default function ModalFooter(props: Props): JSX.Element {
    return (
        <footer className={styles.footer}>
            {props.children}
        </footer>
    );
}
