import { classnames } from '@/util/classnames';
import { Badge, Stack, Text } from '@vg-react/components';
import { CogSolid } from '@vg-react/icons/solid';
import { CheckRegular, MinusRegular, PlusRegular } from '@vg-react/icons/v6/regular';
import { motion, useCycle } from 'framer-motion';
import { useRef } from 'react';
import Foco from 'react-foco';
import { useTranslation } from 'react-i18next';
import { useDimensions } from '../../../Connectivity/Topology/components/utils';
import styles from '../index/Latency.module.scss';

interface SettingsProps {
    maxHops: {
        value: string;
        setValue: (value: string) => void;
    };
    timeout: {
        value: string;
        setValue: (value: string) => void;
    };
}

const sidebar = {
    open: (height = 1000) => ({
        clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
        transition: {
            type: 'spring',
            stiffness: 10,
            restDelta: 2,
        },
    }),
    closed: {
        clipPath: 'circle(40px at 0% 17px)',
        transition: {
            type: 'spring',
            stiffness: 400,
            damping: 40,
        },
    },
};

export const SettingsTraceroute = ({ maxHops, timeout }: SettingsProps) => {
    const { t } = useTranslation();
    const [isOpen, toggleOpen] = useCycle(false, true);
    const containerRef = useRef<HTMLDivElement>(null);
    const { height } = useDimensions(containerRef);
    return (
        <Foco onClickOutside={() => toggleOpen(0)}>
            <motion.div initial={false} animate={isOpen ? 'open' : 'closed'} custom={height} ref={containerRef}>
                <motion.div className={styles.background} variants={sidebar}>
                    <Stack direction="column" spacing="var(--spacing-200)">
                        <Stack direction="column" spacing="var(--spacing-50)">
                            <Text size="xs" weight="bold" color="white">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.SettingsTraceroute.maxHops')}
                            </Text>
                            <Stack direction="row" spacing="var(--spacing-100)">
                                <Stack direction="row" spacing="0">
                                    <button
                                        onClick={() => {
                                            const value = parseInt(maxHops.value);
                                            if (value > 5) {
                                                maxHops.setValue((value - 5).toString());
                                            } else {
                                                maxHops.setValue('1');
                                            }
                                        }}
                                        className={classnames(styles.inputButton, styles.buttonMinus)}
                                    >
                                        <MinusRegular size="10" color="white" />
                                    </button>
                                    <input
                                        className={styles.numberInput}
                                        name="maxHops"
                                        type="string"
                                        value={maxHops.value}
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (value > 0 && value <= 64) {
                                                maxHops.setValue(e.target.value);
                                            }
                                        }}
                                        onWheel={(e) => {
                                            const value = Number(maxHops.value);
                                            if (e.deltaY < 0) {
                                                if (value < 64) {
                                                    maxHops.setValue((value + 4).toString());
                                                } else {
                                                    maxHops.setValue('64');
                                                }
                                            } else {
                                                if (value > 1) {
                                                    maxHops.setValue((value - 4).toString());
                                                } else {
                                                    maxHops.setValue('1');
                                                }
                                            }
                                        }}
                                    />
                                    <button
                                        onClick={() => {
                                            const value = parseInt(maxHops.value);
                                            if (value < 64) {
                                                maxHops.setValue((value + 5).toString());
                                            } else {
                                                maxHops.setValue('64');
                                            }
                                        }}
                                        className={classnames(styles.inputButton, styles.buttonPlus)}
                                    >
                                        <PlusRegular size="10" color="white" />
                                    </button>
                                </Stack>
                                <Badge
                                    onClick={() => maxHops.setValue('15')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: maxHops.value === '15' ? '1px solid white' : '',
                                    }}
                                >
                                    15
                                </Badge>
                                <Badge
                                    onClick={() => maxHops.setValue('30')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: maxHops.value === '30' ? '1px solid white' : '',
                                    }}
                                >
                                    30
                                </Badge>
                                <Badge
                                    onClick={() => maxHops.setValue('60')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: maxHops.value === '60' ? '1px solid white' : '',
                                    }}
                                >
                                    60
                                </Badge>
                            </Stack>
                        </Stack>

                        <Stack direction="column" spacing="var(--spacing-50)">
                            <Text size="xs" weight="bold" color="white">
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.SettingsTraceroute.timeout')}
                            </Text>
                            <Stack direction="row" spacing="var(--spacing-75)">
                                <Stack direction="row" spacing="0">
                                    <button
                                        onClick={() => {
                                            const value = parseInt(timeout.value);
                                            if (value > 500) {
                                                timeout.setValue((value - 500).toString());
                                            } else {
                                                timeout.setValue('500');
                                            }
                                        }}
                                        className={classnames(styles.inputButton, styles.buttonMinus)}
                                    >
                                        <MinusRegular size="10" color="white" />
                                    </button>
                                    <input
                                        className={styles.numberInput}
                                        name="timeout"
                                        type="string"
                                        value={timeout.value}
                                        onChange={(e) => {
                                            const value = Number(e.target.value);
                                            if (value > 500 && value <= 20000) {
                                                timeout.setValue(e.target.value);
                                            }
                                        }}
                                        onWheel={(e) => {
                                            const value = Number(timeout.value);
                                            if (e.deltaY < 0) {
                                                if (value < 20000) {
                                                    timeout.setValue((value + 250).toString());
                                                } else {
                                                    timeout.setValue('20000');
                                                }
                                            } else {
                                                if (value > 500) {
                                                    timeout.setValue((value - 250).toString());
                                                } else {
                                                    timeout.setValue('500');
                                                }
                                            }
                                        }}
                                    />
                                    <button
                                        onClick={() => {
                                            const value = parseInt(timeout.value);
                                            if (value < 20000) {
                                                timeout.setValue((value + 500).toString());
                                            } else {
                                                timeout.setValue('20000');
                                            }
                                        }}
                                        className={classnames(styles.inputButton, styles.buttonPlus)}
                                    >
                                        <PlusRegular size="10" color="white" />
                                    </button>
                                </Stack>
                                <Badge
                                    onClick={() => timeout.setValue('2500')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: timeout.value === '2500' ? '1px solid white' : '',
                                    }}
                                >
                                    2.5k
                                </Badge>
                                <Badge
                                    onClick={() => timeout.setValue('5000')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: timeout.value === '5000' ? '1px solid white' : '',
                                    }}
                                >
                                    5k
                                </Badge>
                                <Badge
                                    onClick={() => timeout.setValue('15000')}
                                    color="primary"
                                    style={{
                                        height: '26px',
                                        cursor: 'pointer',
                                        color: 'white',
                                        outline: timeout.value === '15000' ? '1px solid white' : '',
                                    }}
                                >
                                    15k
                                </Badge>
                            </Stack>
                        </Stack>
                    </Stack>
                </motion.div>

                <div
                    style={{ position: 'relative', left: '0px', top: '-26px', cursor: 'pointer', zIndex: 2 }}
                    onClick={() => toggleOpen()}
                >
                    {isOpen ? <CheckRegular size="16" color="white" /> : <CogSolid size="16" color="white" />}
                </div>
            </motion.div>
        </Foco>
    );
};
