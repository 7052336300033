import styles from './Spinner.module.scss';
import { CSSProperties } from 'react';

interface SpinnerProps {
	speed?: number;
	color?: CSSProperties['color'];
	size?: 'small' | 'medium' | 'large' | 'xlarge';
	className?: string;
	style?: CSSProperties;
}

export default function Spinner({
    speed = 1,
    color = 'var(--lightness-400)',
    size = 'medium',
    className,
    style,
}: SpinnerProps): JSX.Element {

    const spinnerSizes = {
        small: 14,
        medium: 22,
        large: 28,
        xlarge: 34
    };

    const speedMultiplier = 1 / speed;

    return (
        <svg className={`
		${styles.spinner}
		${className}
	`} 
        style={{
            width: spinnerSizes[size],
            height: spinnerSizes[size],
            ...style
        }}
        viewBox="0 0 50 50">
            <circle 
                cx="25" cy="25" r="20"
                fill="none"
                strokeWidth="5"
                style={{
                    stroke: color,
                    animation: `${styles.dash} ${speedMultiplier}s ease-in-out infinite`
                }}
            ></circle>
        </svg>
    );
}
