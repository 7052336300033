import { atom } from 'jotai';

export type IntegrationIndicatorStatus = {
    status: 'syncing' | 'error' | 'success' | 'idle';
    percentage: number;
};

export const integrationAtom = atom<IntegrationIndicatorStatus>({
    status: 'idle',
    percentage: 0,
});

export const scheduledIntegrationAtom = atom<{
    percentage: number;
} | null>(null);
