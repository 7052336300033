import { CSSProperties, ReactNode, forwardRef } from 'react';
import { classnames } from '../../util/classnames';
import styles from './table.module.scss';

interface TableBodyProps {
    /**
     * The content of the table body, normally one or more `TableRow`.
     */
    children: ReactNode;
    /**
     * Defines a custom class name for the body.
     */
    className?: string;
    /**
     * Defines a custom style for the body.
     */
    style?: CSSProperties;
}

/**
 * The body of the table. It renders a `tbody` element with table body styles.
 * @example
 * <TableBody>
 *     <TableRow>
 *         ...
 *     </TableRow>
 *     <TableRow>
 *         ...
 *     </TableRow>
 * </TableBody>
 */

const TableBody = forwardRef<HTMLTableSectionElement, TableBodyProps>(({ children, className, style }, ref) => {
    return (
        <tbody ref={ref} className={classnames(styles.body, className)} style={style}>
            {children}
        </tbody>
    );
});

TableBody.displayName = 'TableBody';

export default TableBody;
