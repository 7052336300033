import Modal from '@/components/Modal/Modal';
import { ActionButton, Form, Input, Stack } from '@vg-react/components';
import { SaveLight } from '@vg-react/icons/light';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSocket from '../../../../../../../../hooks/useSocket';
import request from '../../../../../../../../util/request';
import { ModalEditNameProps, UpdateDataSocket } from './types/Types';

type EditNameFields = {
    ssid: string;
};

export default function ModalEditSsid({
    id,
    active,
    previousSsid,
    instance,
    refresh,
    onClose,
    setButtonSsidDisable,
    setDeviceOperation,
}: ModalEditNameProps) {
    const { t } = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [invalid, setInvalid] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(true);
    const { socket, socketConnected } = useSocket();
    useEffect(() => {
        if (!socketConnected) {
            setButtonSsidDisable(false);
        }
    }, [socketConnected]);

    function handleOnChange(fields: EditNameFields) {
        setDisabled(
            fields.ssid === previousSsid || fields.ssid.trim() === '' || /[^a-zA-Z0-9_.\-\s@&]/.test(fields.ssid),
        );
        setInvalid(fields.ssid.trim() === '' || /[^a-zA-Z0-9_.\-\s@&]/.test(fields.ssid));
    }
    function handleOnSubmit(fields: EditNameFields) {
        setLoading(true);
        request
            .patch(`/app/devices/${id}/wifi/${instance}`, {
                ssid: fields.ssid,
            })
            .then(() => {
                toast.info(t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.tostSending'));
                setDeviceOperation({
                    status: 'in progress',
                    message: t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.sending'),
                });
                socket?.on('updateWifiParameter', (data: UpdateDataSocket) => {
                    setDeviceOperation({ status: data.status, message: data.message });

                    if (data.status === 'in progress') return;

                    if (data.status === 'success') {
                        toast.success(data.message);
                        refresh?.();
                    }

                    setButtonSsidDisable(false);
                    socket.off('updateWifiParameter');

                    setTimeout(() => {
                        setDeviceOperation({
                            message: t(
                                'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.message',
                            ),
                            status: 'default',
                        });
                    }, 5000);
                });
                setButtonSsidDisable(true);
                onClose();
            })
            .catch((error) => {
                setButtonSsidDisable(false);
                toast.error(error.response.data);
            });
    }
    return (
        <Modal size="small" active={active} onClose={onClose} closeOnClickOutside={disabled && !invalid}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.title')}
                </Modal.Title>
            </Modal.Header>
            <Form<EditNameFields>
                initialValues={{ ssid: previousSsid }}
                onSubmit={handleOnSubmit}
                onChange={handleOnChange}
            >
                <Modal.Body>
                    <Input
                        required
                        width="100%"
                        name="ssid"
                        label={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.ssid')}
                        invalid={invalid}
                        disabled={loading}
                        helpText={
                            invalid
                                ? t(
                                      'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.invalid',
                                  )
                                : ''
                        }
                    />
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={onClose} quiet>
                            {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" loading={loading} disabled={disabled}>
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                <SaveLight size="16" color="currentColor" />
                                {t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ModalEditSsid.save')}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
