import { Route } from 'react-router-dom';
import SlideRoutes from 'react-slide-routes';
import ProtectedRoute from './Protected.route';
import LoginScreen from '../screens/Login/Login.screen';
import RecoverPasswordScreen from '../screens/Login/RecoverPassword.screen';

export default function Routes() {

    return (
        <SlideRoutes animation="slide" duration={500}>
            <Route path='login' element={<LoginScreen />} />
            <Route path='forgotPassword/:token' element={<RecoverPasswordScreen />} />
            <Route path='*' element={<ProtectedRoute />} />
        </SlideRoutes>
    );
}