import { createContext, useContext } from 'react';

export type Screens = 'selectDevices' | 'selectFirmware' | 'resume' | 'finish';
export type DeviceProps = {
    hardwareVersion: string;
    productName: string;
    pppLogin: string;
    modelName: string;
    periodicInformInterval: number;
    softwareVersion: string;
    status: boolean;
    manufacturer: string;
    serialNumber: string;
};

export type DevicesCount = {
    model: string;
    software: string;
    hardware: string;
    total: number;
};

export const variantsForward = {
    initial: {
        opacity: 0,
        x: '100%',
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeInOut',
        },
    },
};

export const variantsBackward = {
    initial: {
        opacity: 0,
        x: '-100%',
    },
    animate: {
        opacity: 1,
        x: 0,
        transition: {
            duration: 0.4,
            ease: 'easeInOut',
        },
    },
};

export type SelectDevicesProps = {
    dataFilters:
        | {
              name: string;
              models: { name: string; hardwares: string[]; firmwares: string[] }[];
          }[]
        | null;
};

export type DeviceFilters = {
    manufacturer: string;
    model: string;
    hardware: string;
    firmwares: string[];
};

export type FirmwareProps = {
    id: string;
    name: string;
    size: string;
    version: string;
    fileType: number;
    tags: string[];
};

export type DataFilters =
    | {
          name: string;
          models: { name: string; hardwares: string[]; firmwares: string[]; unsuportedModel: boolean }[];
      }[]
    | null;

export type MassChangeFirmwareContextProps = {
    refresh: () => void;
    direction: 'forward' | 'backward';
    selectedDevices: DeviceProps[];
    setSelectedDevices: (devices: DeviceProps[]) => void;
    deviceList: DeviceProps[] | null;
    setDevicesList: (devices: DeviceProps[]) => void;
    selectedFirmware: FirmwareProps;
    setSelectedFirmware: (firmware: FirmwareProps) => void;
    blockNextScreen: boolean;
    setBlockNextScreen: (block: boolean) => void;
    selectedFilters: DeviceFilters;
    setSelectedFilters: (prev: any) => void;
    dataFilters: DataFilters;
    setScheduleDate: (date: Date | null) => void;
    scheduleDate: Date | null;
    isFetchingDataFilters: boolean;
    setIsFetchingDevicesList: (loading: boolean) => void;
    isFetchingDevicesList: boolean;
    validData: boolean;
    setValidData: (valid: boolean) => void;
};

export const MassUpdateContext = createContext({} as MassChangeFirmwareContextProps);

export const useMassUpdateContext = () => useContext(MassUpdateContext);
