import styles from './ResizeTrigger.module.scss';

interface ResizeTriggerProps {
    /**
     * The callback to be called when the user drag the resize trigger.
     * @param movement The horizontal movement of the mouse.
     */
    onResize?: (movement: number) => void;
}

/**
 * A component that renders a resize trigger.
 * The resize trigger is a small element that when dragged it triggers the `onResize` callback.
 * @example
 * <ResizeTrigger onResize={movement => console.log(movement)} />
 */
export default function ResizeTrigger({ onResize }: ResizeTriggerProps) {
    return (
        <div
            className={styles.resizeTrigger}
            onMouseDown={(event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                const startX = event.clientX;
                const onMouseMove = (event: MouseEvent) => {
                    onResize?.(event.clientX - startX);
                };
                const onMouseUp = () => {
                    window.removeEventListener('mousemove', onMouseMove);
                    window.removeEventListener('mouseup', onMouseUp);
                };
                window.addEventListener('mousemove', onMouseMove);
                window.addEventListener('mouseup', onMouseUp);

                event.preventDefault();
            }}
        />
    );
}
