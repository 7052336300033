import Select from '../Select/Select';
import Stack from '../Stack/Stack';
import Text from '../Text/Text';

interface RowsPerPageProps {
    /**
     * The options to display in the select. The value of each option is the number of rows to display.
     */
    options: number[];
    /**
     * The currently selected number of rows to display. This value should be one of the options.
     */
    value: number;
    /**
     * Callback fired when the selected number of rows to display changes. The value of the select is passed as an argument.
     */
    onChange: (value: number) => void;
    /**
     * The label to display above the select.
     */
    label?: string;
}

/**
 * A component that allows the user to select the number of rows to display in a table.
 * This component is typically used in conjunction with the Pagination and Table components.
 * The value of the select is the number of rows to display. The options are the possible values for the select.
 * @example
 * <RowsPerPage
 *    options={[10, 25, 50]}
 *    value={rowsPerPage}
 *    onChange={value => setRowsPerPage(value)}
 * />
 */
export default function RowsPerPage({
    value,
    options,
    onChange,
    label = 'Rows per page',
}: RowsPerPageProps): JSX.Element {
    if (!options.includes(value)) {
        throw new Error(`The value ${value} is not one of the options`);
    }

    const handleChange = (newValue: string) => {
        const newValueAsNumber: number = parseInt(newValue, 10);
        onChange(newValueAsNumber);
    };

    return (
        <Stack direction="row" align="center" spacing="small">
            <label htmlFor="rows-per-page-selector">
                <Text size="xs" color="var(--gray-700)" weight="regular">
                    {`${label}:`}
                </Text>
            </label>
            <Select
                width="small"
                onChange={handleChange}
                value={value.toString()}
                name="rows-per-page-selector"
                initialValue={options.find((option) => option === value)?.toString()}
            >
                {options.map((option) => {
                    return (
                        <Select.Option key={option} value={option.toString()}>
                            {option}
                        </Select.Option>
                    );
                })}
            </Select>
        </Stack>
    );
}
