import { useEffect, useState, useRef } from 'react';

interface Dimensions {
    /** distance from the bottom of the element to the viewport top */
    bottom?: number;
    /** distance from the top of the element to the viewport top */
    top?: number;
    /** distance from the left of the element to the viewport left */
    left?: number;
    /** distance from the right of the element to the viewport left */
    right?: number;
    /** height of the element */
    height?: number;
    /** width of the element */
    width?: number;
}

interface UseDimensionsReturn<T> {
    /** Reference to be used into the target element */
    ref: React.MutableRefObject<T>;
    /** dimensions of the target element */
    dimensions: Dimensions;
}

/**
 * Listen to any update on the target dimensions, and returns it as an object
 * 
 * @example
 * 
 * const { ref, dimensions } = useDimensions<HTMLDivElement>();
 * 
 * // dimensions variable will be updated whenever the dimensions of the div are changed
 * 
 * return(
 *      <div ref={ref} />
 * )
 * 
 */
export default function useDimensions<T extends HTMLElement>(): UseDimensionsReturn<T> {
    const [dimensions, setDimensions] = useState<Dimensions>({});
    const ref = useRef() as React.MutableRefObject<T>;

    useEffect(() => {
        const resizeCallback = () => {
            if (ref && ref.current) {
                const dm = ref.current.getBoundingClientRect();
         
                setDimensions({
                    bottom: dm.bottom,
                    top: dm.top,
                    left: dm.left,
                    right: dm.right,
                    height: dm.height,
                    width: dm.width,
                });
            }
        };

        resizeCallback();
        ref.current?.addEventListener('resize', resizeCallback);

        return () => {
            ref.current?.removeEventListener('resize', resizeCallback);
        };
    }, [ref]);

    return { ref, dimensions };
}