import FieldLabel from './FieldLabel';
import FieldSkeleton from './FieldSkeleton';
import FieldText from './FieldText';
import ButtonField from './variants/ButtonField';
import InfoField from './variants/InfoField';
import SelectField from './variants/SelectField';
import SliderField from './variants/SliderField';
import ToggleField from './variants/ToggleField';

export default {
    Button: ButtonField,
    Select: SelectField,
    Toggle: ToggleField,
    Slider: SliderField,
    Info: InfoField,
};

export { FieldLabel, FieldText, FieldSkeleton };
