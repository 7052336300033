/* eslint-disable react/display-name */
import { ForwardedRef, Ref, forwardRef } from 'react';
import Table from '../Table/Table';
import { DataTableColumn } from './DataTable';
import { DataTableBodyContent } from './DataTableBodyContent';
import { DataTableBodyMock } from './DataTableBodyMock';
import { DataTableOverlay } from './DataTableOverlay';

interface DataTableBodyProps<T> {
    rows: T[];
    rowHeight?: number;
    loading: boolean;
    selectedRows: T[];
    rowsHover: boolean;
    selectable: boolean;
    rowsPerPage: number;
    overlayMessage?: string;
    columns: DataTableColumn<T>[];
    selectableHasCheckbox: boolean;
    onRowDoubleClick?: (row: T) => void;
    isRowSelectable: (row: T) => boolean;
    onRowSelect: (row: T, selected: boolean) => void;
}

const DataTableBody = forwardRef(
    <T,>(
        {
            rows,
            columns,
            loading,
            rowsHover,
            selectable,
            rowsPerPage,
            selectedRows,
            overlayMessage,
            selectableHasCheckbox,
            onRowSelect,
            isRowSelectable,
            onRowDoubleClick,
        }: DataTableBodyProps<T>,
        ref: ForwardedRef<HTMLTableSectionElement>,
    ) => {
        return (
            <Table.Body ref={ref}>
                {!loading && rows.length >= 1 ? (
                    <DataTableBodyContent
                        rows={[...rows]}
                        columns={[...columns]}
                        rowsHover={rowsHover}
                        selectable={selectable}
                        selectedRows={selectedRows}
                        selectableHasCheckbox={selectableHasCheckbox}
                        onRowSelect={onRowSelect}
                        isRowSelectable={isRowSelectable}
                        onRowDoubleClick={onRowDoubleClick}
                    />
                ) : (
                    <DataTableBodyMock
                        columns={[...columns]}
                        selectable={selectable}
                        loading={loading}
                        numberOfRows={rowsPerPage}
                    />
                )}
                {overlayMessage && <DataTableOverlay message={overlayMessage} />}
            </Table.Body>
        );
    },
);

export default DataTableBody as <T>(
    props: DataTableBodyProps<T> & { ref: Ref<HTMLTableSectionElement> },
) => JSX.Element;
