import { CSSProperties, ReactNode, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import { classnames } from '../../util/classnames';
import mergeRefs from '../../util/mergeRefs';
import Text from '../Text/Text';
import styles from './Tooltip.module.scss';
import { useTooltip } from './TooltipContext';

interface TooltipContentProps {
    children: ReactNode;
    style?: CSSProperties;
    className?: string;
}

export default function TooltipContent({ children, style = {}, className }: TooltipContentProps) {
    const { content, open, config } = useTooltip();
    const transitionRef = useRef<HTMLDivElement>(null);

    const renderedChildren =
        typeof children !== 'string' ? (
            children
        ) : (
            <Text size="xs" weight="regular" color="var(--tooltip-text-color, #FFF)" align="center">
                {children}
            </Text>
        );

    return (
        <CSSTransition
            in={open}
            timeout={100}
            mountOnEnter
            unmountOnExit
            nodeRef={transitionRef}
            classNames={{
                enter: styles.enter,
                exit: styles.exit,
            }}
        >
            <div
                style={style}
                ref={mergeRefs<HTMLDivElement>(content?.ref, transitionRef)}
                className={
                    config.tooltipClassName
                        ? classnames(styles[config.floatingPlacement || ''], config.tooltipClassName)
                        : classnames(
                              styles.content,
                              styles[config.variant || ''],
                              styles[config.floatingPlacement || ''],
                              className,
                          )
                }
                {...content?.props}
            >
                {content?.arrow?.ref && (
                    <div
                        ref={content?.arrow.ref}
                        className={
                            config.arrowClassName
                                ? config.arrowClassName
                                : classnames(styles.arrow, styles[config.variant || ''])
                        }
                        {...content?.arrow.props}
                    />
                )}
                {renderedChildren}
            </div>
        </CSSTransition>
    );
}
