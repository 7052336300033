import { classnames } from '@/util/classnames';
import { Stack, Text, Tooltip } from '@vg-react/components';
import { InfoCircleSolid } from '@vg-react/icons/solid';
import { AsteriskRegular } from '@vg-react/icons/v6/regular';
import { BaseTagData } from '@yaireo/tagify';
import Tags from '@yaireo/tagify/dist/react.tagify';
import './TagInput.scss';

interface TagInputProps {
    placeholder?: string;
    tags: string[];
    maxItems?: number;
    editTags?: false | 1 | 2;
    setTags: (tags: string[]) => void;
    onInvalid?: () => void;
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
    className?: string;
    suggestions?: string[];
    validate?: (tag: BaseTagData) => boolean | string;
    pattern?: string | RegExp;
    disabled?: boolean;
    complement?: React.ReactElement<any, any>;
    label?: string;
    required?: boolean;
    width?: string;
    information?: string;
    mode?: 'select' | 'mix';
    enforceWhitelist?: boolean;
    style?: React.CSSProperties;
}

export default function TagInput({
    label,
    placeholder,
    tags,
    editTags,
    maxItems,
    mode,
    enforceWhitelist,
    setTags,
    className,
    suggestions,
    onInvalid,
    validate,
    pattern,
    disabled,
    complement,
    required,
    width,
    information,
    style
}: TagInputProps) {
    return (
        <Stack
            spacing="var(--spacing-50)"
            direction="column"
            align="start"
            justify="center"
            className="tagInputOverrides"
            style={{ width: width ?? '100%' }}
        >
            <Stack direction="row" spacing="var(--spacing-30)" align="center" style={{ width: '100%' }} aria-disabled={true}>
                <Stack justify='space-between' style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%' }}>
                    <div style={{ display: 'flex' }}>
                        <Text color="var(--gray-700)" size="xs" weight="bold">
                            {label}
                        </Text>
                        {required && <AsteriskRegular size="10px" color="red" style={{ marginLeft: 5 }} />}
                        {information && (
                            <Tooltip placement="bottom">
                                <Tooltip.Trigger>
                                    <Stack style={{ padding: 6 }}>
                                        <InfoCircleSolid size="14" color="var(--lightness-600)" style={{ marginTop: '3px' }} />
                                    </Stack>
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    <Stack spacing="var(--spacing-75)" justify="start" style={{ padding: 'var(--spacing-50)' }}>
                                        <Text color="#EEE" weight="regular">
                                            Funcionalidade em fase beta, disponível somente para determinados modelos
                                            específicos no momento.
                                        </Text>
                                    </Stack>
                                </Tooltip.Content>
                            </Tooltip>
                        )}
                    </div>
                    {complement !== undefined && complement}
                </Stack>
            </Stack>
            <div style={{ width: '100%', ...style }}>
                {tags !== undefined && setTags !== undefined && (
                    <Tags
                        className={classnames('input', className)}
                        settings={{
                            ...(placeholder && { placeholder }),
                            dropdown: {
                                classname: 'tagify_dropdown',
                                enabled: 0,
                                maxItems: maxItems || 10,
                                appendTarget: document.getElementById('workspace') ?? document.body,
                            },
                            pattern,
                            mode: mode || undefined,
                            enforceWhitelist: enforceWhitelist || false,
                            editTags,
                            validate,
                        }}
                        readOnly={disabled}
                        whitelist={suggestions}
                        showDropdown={'true'}
                        value={tags ?? []}
                        onInvalid={onInvalid}
                        onChange={(e) => {
                            setTags(e.detail.tagify.value.map((tag) => tag.value));
                        }}
                    />
                )}
            </div>
        </Stack>
    );
}
