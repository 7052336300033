import { useEffect, useRef, useState } from 'react';
import { convertToGbps } from '../utils/calcRate';
import { TestHistoryData } from './SpeedTestResult';

interface Props {
    values: TestHistoryData[];
    color: string;
    type: 'Download' | 'Upload';
}

export const ChartLastResults = ({ values, color, type }: Props) => {
    const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
    const chartRef = useRef<SVGSVGElement | null>(null);

    const handleHover = (index: number) => {
        setHoveredIndex(index);
    };

    const handleLeave = () => {
        setHoveredIndex(null);
    };

    const getMaxValue = (): number => {
        if (type === 'Download') return Math.max(...values.map((item) => item.download));
        return Math.max(...values.map((item) => item.upload));
    };

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            if (chartRef.current && chartRef.current.parentElement) {
                const width = chartRef.current.parentElement.clientWidth;
                chartRef.current.setAttribute('width', String(width));
            }
        });

        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, [chartRef]);

    return (
        <div>
            <svg
                ref={chartRef}
                width="100%"
                height="40px"
                style={{
                    overflow: 'visible',
                }}
            >
                {type === 'Download' &&
                    values.map((item, index) => (
                        <g
                            key={index}
                            transform={`translate(${(index / values.length) * 90}%, 0)`}
                            onMouseEnter={() => handleHover(index)}
                            onMouseLeave={handleLeave}
                        >
                            <rect
                                x={(index / values.length) * 50 + '%'}
                                width={12}
                                rx={5}
                                ry={5}
                                y={40 - (item.download / getMaxValue()) * 40}
                                height={
                                    item.download > 0 ? (item.download / getMaxValue()) * 40 : (4 / getMaxValue()) * 40
                                }
                                fill={color}
                                opacity={hoveredIndex === index ? 1 : 0.5}
                            />
                            {hoveredIndex === index && (
                                <foreignObject
                                    x={(index / values.length) * 90 + ((1 / values.length) * 90) / 2 - 50 + '%'}
                                    y={40}
                                    width="100"
                                    height="100"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: 'var(--lightness-300)',
                                            textAlign: 'center',
                                            background: 'var(--background-color)',
                                            padding: 'var(--spacing-75)',
                                            borderRadius: 'var(--spacing-100)',
                                            gap: 'var(--spacing-50)',
                                        }}
                                    >
                                        <div style={{ background: 'var(--foreground-color)', fontSize: 12 }}>
                                            {item.date}
                                        </div>
                                        <div>
                                            <strong>
                                                {item.download > 1000 ? convertToGbps(item.download) : item.download}
                                            </strong>
                                            <span style={{ fontSize: 13 }}>
                                                {' '}
                                                {item.download > 1000 ? 'Gbps' : 'Mbps'}
                                            </span>
                                        </div>
                                    </div>
                                </foreignObject>
                            )}
                        </g>
                    ))}
                {type === 'Upload' &&
                    values.map((item, index) => (
                        <g
                            key={index}
                            transform={`translate(${(index / values.length) * 90}%, 0)`}
                            onMouseEnter={() => handleHover(index)}
                            onMouseLeave={handleLeave}
                        >
                            <rect
                                x={(index / values.length) * 50 + '%'}
                                width={12}
                                rx={5}
                                ry={5}
                                y={40 - (item.upload / getMaxValue()) * 40}
                                height={item.upload > 0 ? (item.upload / getMaxValue()) * 40 : (4 / getMaxValue()) * 40}
                                fill={color}
                                opacity={hoveredIndex === index ? 1 : 0.5}
                            />
                            {hoveredIndex === index && (
                                <foreignObject
                                    x={(index / values.length) * 90 + ((1 / values.length) * 90) / 2 - 50 + '%'}
                                    y={40}
                                    width="100"
                                    height="100"
                                >
                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                            color: 'var(--lightness-300)',
                                            textAlign: 'center',
                                            background: 'var(--background-color)',
                                            padding: 'var(--spacing-75)',
                                            borderRadius: 'var(--spacing-100)',
                                            gap: 'var(--spacing-50)',
                                        }}
                                    >
                                        <div style={{ background: 'var(--foreground-color)', fontSize: 12 }}>
                                            {item.date}
                                        </div>
                                        <div>
                                            <strong>
                                                {item.upload > 1000 ? convertToGbps(item.upload) : item.upload}
                                            </strong>
                                            <span style={{ fontSize: 13 }}>
                                                {' '}
                                                {item.upload > 1000 ? 'Gbps' : 'Mbps'}
                                            </span>
                                        </div>
                                    </div>
                                </foreignObject>
                            )}
                        </g>
                    ))}
            </svg>
        </div>
    );
};
