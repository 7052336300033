export default function deepCompare(a: any, b: any): boolean {
    if (a && b && a.constructor === Array && b.constructor === Array) {
        if (a.length !== b.length) {
            return false;
        }
        a.forEach((item, index) => {
            if (!deepCompare(item, b[index])) {
                return false;
            }
        });
    }

    else if (a && b && typeof a === 'object' && typeof b === 'object') {
        const aKeys = Object.keys(a);
        if (aKeys.length !== Object.keys(b).length) {
            return false;
        }
        let forEachRes = true;
        aKeys.forEach(key => {
            if (!deepCompare(a[key], b[key])) {
                forEachRes = false;
            }
        });
        return forEachRes;
    }

    return a === b;
}