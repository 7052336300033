import { HTMLAttributes, ReactNode } from 'react';
import { useScreen } from '../../contexts/Screen/ScreenContext';

interface ScreenProps extends HTMLAttributes<HTMLDivElement> {
    children?: ReactNode;
}

export default function Screen({ children, style, ...props }: ScreenProps) {
    const screen = useScreen();

    return (
        <div
            style={{
                height: 'calc(100% - var(--spacing-200))',
                maxHeight: screen.dimensions.height,
                paddingInline: 'var(--spacing-200)',
                display: 'flex',
                flexDirection: 'column',
                scrollbarGutter: 'stable',
                overflow: 'auto',
                minHeight: 0,
                width: '100%',
                ...style,
            }}
            {...props}
        >
            {children}
        </div>
    );
}
