import { createContext, Dispatch, ReactNode, SetStateAction, useContext, useState } from 'react';

interface DataTableContextProps {
    pages: Record<string, number>;
    setPages: Dispatch<SetStateAction<Record<string, number>>>;
}

export const DataTableContext = createContext({} as DataTableContextProps);

interface ProviderProps {
    children: ReactNode | ReactNode[];
}

export function useDataTable() {
    const { pages, setPages } = useContext(DataTableContext);

    const set = (page: string, value: number) => {
        setPages((previousPages) => ({
            ...previousPages,
            [page]: value,
        }));
    };

    return { pages, setPages: set };
}

const DataTableContextProvider = ({ children }: ProviderProps) => {
    const [pages, setPages] = useState<Record<string, number>>({});

    return <DataTableContext.Provider value={{ pages, setPages }}>{children}</DataTableContext.Provider>;
};

export default DataTableContextProvider;
