interface LogoSvg {
  color: string
  width: string
}
export const LogoIxcSvg = ({ color, width }: LogoSvg) => {
  return (
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
      width={width} height="100%" viewBox="0 0 842.000000 344.000000"
      preserveAspectRatio="xMidYMid meet">

      <g transform="translate(0.000000,344.000000) scale(0.100000,-0.100000)"
        fill={color} stroke="none">
        <path d="M0 1725 l0 -1715 1715 0 1715 0 0 1715 0 1715 -1715 0 -1715 0 0
-1715z m2421 436 c118 -45 196 -151 207 -279 l5 -65 -90 5 c-103 5 -129 17
-168 79 -31 47 -57 59 -134 59 -74 0 -117 -27 -151 -95 -21 -42 -25 -64 -25
-140 0 -76 4 -98 25 -140 14 -28 40 -60 60 -73 30 -20 45 -23 102 -20 80 4
109 26 136 100 l17 48 114 0 114 0 -6 -63 c-16 -171 -130 -282 -316 -309 -301
-42 -491 134 -491 457 0 251 111 413 312 455 73 15 226 5 289 -19z m-1381
-436 l0 -445 -120 0 -120 0 0 405 0 404 30 35 c35 39 47 43 143 45 l67 1 0
-445z m395 325 c38 -66 72 -120 75 -120 3 0 30 44 60 97 77 134 83 137 237
141 107 3 125 2 120 -11 -3 -8 -61 -102 -130 -208 -68 -107 -127 -199 -131
-206 -3 -7 52 -108 129 -233 74 -121 135 -223 135 -225 0 -3 -62 -5 -138 -5
l-137 0 -69 115 c-38 64 -71 117 -75 119 -3 2 -37 -50 -75 -115 l-70 -119
-138 0 c-76 0 -138 3 -138 6 0 4 59 104 132 222 72 118 131 220 131 226 0 6
-59 102 -132 214 -72 112 -131 207 -131 213 0 5 58 9 138 9 l138 0 69 -120z"/>
        <path d="M6154 2620 c-66 -10 -139 -46 -172 -85 -14 -16 -37 -55 -51 -85 -21
-47 -25 -69 -25 -150 -1 -110 18 -176 65 -233 110 -133 371 -141 485 -15 50
56 75 117 81 204 19 252 -137 401 -383 364z m163 -99 c81 -37 125 -158 104
-288 -12 -74 -30 -112 -71 -146 -37 -31 -80 -41 -159 -35 -113 8 -163 74 -169
222 -6 139 24 212 102 247 54 24 139 24 193 0z"/>
        <path d="M4265 2597 c-3 -7 -4 -147 -3 -312 l3 -300 53 -3 52 -3 0 104 0 105
118 4 c100 3 126 8 167 28 82 41 117 131 94 241 -14 65 -64 121 -124 138 -58
16 -354 15 -360 -2z m347 -107 c29 -29 30 -34 26 -96 -6 -98 -20 -108 -158
-112 l-110 -4 0 121 0 121 106 0 c104 0 106 0 136 -30z"/>
        <path d="M5085 2598 c-3 -7 -4 -148 -3 -313 l3 -300 49 -3 c32 -2 52 1 57 10
5 7 9 62 9 121 l0 108 77 -3 76 -3 50 -117 50 -118 54 0 c29 0 53 3 53 8 0 4
-23 61 -52 126 -38 87 -49 121 -39 124 30 10 81 66 97 108 34 90 -4 201 -83
240 -32 16 -65 19 -216 22 -140 3 -178 0 -182 -10z m350 -103 c20 -19 25 -34
25 -75 0 -28 -5 -60 -11 -72 -17 -32 -71 -48 -164 -48 l-85 0 0 110 0 110 105
0 c99 0 107 -2 130 -25z"/>
        <path d="M6958 2293 c-13 -3 -18 -14 -18 -38 0 -25 5 -34 23 -39 40 -11 1037
-7 1058 4 11 6 19 21 19 35 0 14 -8 29 -19 35 -19 10 -1025 13 -1063 3z"/>
        <path d="M7058 1490 c-154 -26 -240 -142 -240 -325 0 -188 82 -295 249 -326
167 -31 322 51 368 196 25 81 18 232 -14 295 -47 92 -112 140 -216 159 -65 12
-79 12 -147 1z m159 -91 c38 -14 71 -46 96 -94 18 -33 22 -57 22 -140 0 -83
-4 -107 -22 -140 -42 -79 -88 -105 -184 -105 -122 0 -188 69 -197 205 -2 40
-2 93 2 118 9 61 52 123 101 147 45 22 136 27 182 9z"/>
        <path d="M4240 1474 c0 -10 227 -558 246 -596 13 -24 21 -28 58 -28 l43 0 123
298 c68 163 125 305 128 315 4 16 -2 18 -54 15 l-58 -3 -93 -235 c-92 -235
-92 -235 -105 -202 -7 19 -49 124 -93 235 l-80 202 -58 3 c-31 2 -57 0 -57 -4z"/>
        <path d="M5175 1468 c-3 -7 -4 -148 -3 -313 l3 -300 220 0 220 0 0 35 0 35
-167 3 -168 2 0 95 0 95 118 0 c124 0 142 6 142 45 0 39 -18 45 -142 45 l-118
0 0 90 0 90 148 0 c187 0 194 2 190 49 l-3 36 -218 3 c-169 2 -219 0 -222 -10z"/>
        <path d="M5955 1468 c-3 -7 -4 -148 -3 -313 l3 -300 165 0 c152 0 169 2 218
24 105 48 154 140 154 289 -1 150 -47 233 -157 284 -44 20 -69 23 -212 26
-130 3 -164 1 -168 -10z m358 -111 c62 -47 86 -188 52 -304 -26 -87 -94 -123
-235 -123 l-60 0 0 231 0 231 103 -4 c95 -3 107 -5 140 -31z"/>
        <path d="M7795 1467 c-3 -7 -4 -147 -3 -312 l3 -300 49 -3 c32 -2 52 1 57 10
5 7 9 62 9 121 l0 108 77 -3 77 -3 46 -105 c25 -58 49 -111 53 -117 11 -17
107 -17 107 0 0 6 -23 61 -50 121 -28 61 -50 112 -50 116 0 3 16 14 35 24 47
26 75 74 82 141 10 100 -39 181 -125 205 -56 15 -361 13 -367 -3z m342 -92
c28 -19 44 -79 35 -124 -13 -58 -48 -75 -163 -79 l-99 -4 0 111 0 111 103 0
c76 0 108 -4 124 -15z"/>
      </g>
    </svg>
  )
}