import DatePicker from '@/components/DatePicker/DatePicker';
import Form from '@/components/Form/Form';
import Stack from '@/components/Stack/Stack';
import Text from '@/components/Text/Text';
import TimeInput from '@/components/TimeInput/TimeInput';
import { useMassUpdateContext } from '@/contexts/MassChange/MassChangeFirmwareContext';
import { classnames } from '@/util/classnames';
import { CircleCheckSolid, CircleDashedSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import style from './Resume.module.scss';

export default function Actions({
    startNow = true,
    setStartNow,
}: {
    startNow: boolean;
    setStartNow: (startNow: boolean) => void;
}) {
    const { t } = useTranslation();
    const { setScheduleDate, scheduleDate, setValidData, validData } = useMassUpdateContext();
    function getDefaultValueTime() {
        const minutes = new Date().getMinutes();
        const hours = String(new Date().getHours());
        const minutesRounded = Math.ceil(minutes / 30) * 30;
        if (minutesRounded >= 60) {
            return { hours: (Number(hours) + 1).toString(), minutes: '00' };
        }
        return { hours, minutes: minutesRounded.toString() };
    }

    const defaultValueTime = getDefaultValueTime();

    function onChange({ time }: { time: { hours: string; minutes: string } }) {
        if (time?.hours !== undefined && time?.minutes !== undefined) {
            let date = new Date();
            if (scheduleDate) date = new Date(scheduleDate);

            const currentHours = new Date().getHours();
            const currentMinutes = new Date().getMinutes();
            const scheduledHours = Number(time.hours);
            const scheduledMinutes = Number(time.minutes);

            if (date.getDate() === new Date().getDate() && (scheduledHours < currentHours || (scheduledHours === currentHours && scheduledMinutes <= currentMinutes))) {
                setValidData(false);
                return;
            } else {
                setValidData(true);
            }

            date.setHours(scheduledHours);
            date.setMinutes(scheduledMinutes);
            date.setSeconds(0);
            setScheduleDate(date);
        } else {
            setScheduleDate(null);
        }
    }

    return (
        <Stack spacing="var(--spacing-50)" style={{ flexGrow: 1 }} justify="center">
            <Stack
                id="datepickerRoot"
                onClick={() => setStartNow(false)}
                direction="column"
                align="start"
                style={{
                    userSelect: 'none',
                }}
                spacing={window.innerHeight < 800 ? 'var(--spacing-50)' : 'var(--spacing-100)'}
                className={classnames(style.buttons, !startNow && style.activeButton)}
            >
                <Stack
                    onClick={() => setStartNow(false)}
                    direction="row"
                    align="center"
                    style={{
                        userSelect: 'none',
                    }}
                    spacing="var(--spacing-100)"
                    className={classnames(startNow && style.activeButton)}
                >
                    {startNow ? (
                        <CircleDashedSolid color="var(--gray-600)" size="20" />
                    ) : (
                        <CircleCheckSolid color="var(--accent-900)" size="20" />
                    )}
                    <Text weight="bold" size="xs" color={!startNow ? 'var(--gray-900)' : 'var(--gray-600)'}>
                        {t('ToolsScreen.MassChangeScreen.ResumeScreen.Buttons.scheduleStart')}
                    </Text>
                </Stack>
                {!startNow && (
                    <Stack spacing={window.innerHeight < 800 ? 'var(--spacing-50)' : 'var(--spacing-100)'} width="100%">
                        <Stack spacing="var(--spacing-30)">
                            <Text variant="h6" size="xs">
                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.Buttons.date')}
                            </Text>
                            <DatePicker
                                invalid={!validData}
                                initialValue={new Date()}
                                closeOnSelect
                                min={new Date()}
                                onValueChange={(date) => setScheduleDate(date)}
                            />
                        </Stack>
                        <Stack spacing="var(--spacing-30)">
                            <Text variant="h6" size="xs">
                                {t('ToolsScreen.MassChangeScreen.ResumeScreen.Buttons.time')}
                            </Text>
                            <Form onChange={onChange}>
                                <TimeInput
                                    size="auto"
                                    name="time"
                                    defaultValue={defaultValueTime}
                                    invalid={!validData}
                                />
                            </Form>
                        </Stack>
                    </Stack>
                )}
            </Stack>
            <Stack
                onClick={() => {
                    setStartNow(true);
                    setValidData(true);
                    setScheduleDate(null);
                }}
                direction="row"
                style={{
                    userSelect: 'none',
                }}
                align="center"
                className={classnames(style.buttons, startNow && style.activeButton)}
                spacing="var(--spacing-100)"
            >
                {!startNow ? (
                    <CircleDashedSolid color="var(--gray-600)" size="20" />
                ) : (
                    <CircleCheckSolid color="var(--accent-900)" size="20" />
                )}
                <Text weight="bold" size="xs" color={startNow ? 'var(--gray-900)' : 'var(--gray-600)'}>
                    {t('ToolsScreen.MassChangeScreen.ResumeScreen.Buttons.startNow')}
                </Text>
            </Stack>
        </Stack>
    );
}
