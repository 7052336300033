import { GripVerticalSolid } from '@vg-react/icons/solid';
import { Reorder, useDragControls } from 'framer-motion';
import Checkbox from '../Checkbox/Checkbox';
import Stack from '../Stack/Stack';
import { Column } from './ColumnsControl';

interface ColumnItemProps {
    column: Column;
    disabled?: boolean;
    allowReorder?: boolean;
}

export default function ColumnControlItem({ column, disabled, allowReorder }: ColumnItemProps) {
    const controls = useDragControls();

    if (allowReorder) {
        return (
            <Reorder.Item value={column.accessor} dragControls={controls} as="div">
                <Stack direction="row" spacing="large" align="center" justify="space-between">
                    <Checkbox
                        name={column.accessor}
                        value={column.visible || column.disabled}
                        label={column.label}
                        labelSide="right"
                        disabled={disabled || column.disabled}
                    />
                    <div
                        onPointerDown={(e) => controls.start(e)}
                        style={{
                            cursor: 'grab',
                        }}
                    >
                        <GripVerticalSolid size="14px" color="var(--lightness-600)" />
                    </div>
                </Stack>
            </Reorder.Item>
        );
    } else {
        return (
            <Stack direction="row" spacing="large" align="center" justify="space-between">
                <Checkbox
                    name={column.accessor}
                    value={column.visible || column.disabled}
                    label={column.label}
                    labelSide="right"
                    disabled={disabled || column.disabled}
                />
            </Stack>
        );
    }
}
