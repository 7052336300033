import ActionButton from '@/components/ActionButton/ActionButton';
import Stack from '@/components/Stack/Stack';
import Text from '@/components/Text/Text';
import { DeviceProps, FirmwareProps, useMassUpdateContext } from '@/contexts/MassChange/MassChangeFirmwareContext';
import { FileAltLight, SignInLight } from '@vg-react/icons/light';
import { useEffect, useState } from 'react';
import { useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { classnames } from '../../../../../../util/classnames';
import style from './Firmware.module.scss';

interface SelectedFileContainerProps {
    selected: string;
    setSelected: (value: string) => void;
    files: FirmwareProps[];
    device: DeviceProps;
}

type SelectorStates = 'requested' | 'default';

export default function SelectedFileContainer({ device, files, selected, setSelected }: SelectedFileContainerProps) {
    const { t } = useTranslation();
    const [selectedState, _] = useState<SelectorStates>('default');
    const { setSelectedFirmware, setBlockNextScreen } = useMassUpdateContext();
    const [{ canDrop, isOver }, drop] = useDrop(() => ({
        accept: 'file',
        drop: () => ({ name: 'SelectedFileContainer' }),
        collect: (monitor) => ({
            isOver: monitor.isOver(),
            canDrop: monitor.canDrop(),
        }),
    }));

    const selectedFile = files?.filter((file) => file.id === selected)[0];

    useEffect(() => {
        setBlockNextScreen(true);
        if (selectedFile) setBlockNextScreen(false);
        setSelectedFirmware(selectedFile);
    }, [selectedFile]);

    return (
        <div
            ref={drop}
            className={classnames(
                style.selectedFileContainer,
                canDrop && style.canDrop,
                isOver && style.isOver,
                (selected !== '' || selectedState !== 'default') && style.itemSelected,
            )}
        >
            {selected === '' && selectedState === 'default' ? (
                <Stack
                    width="100%"
                    height="100%"
                    align="center"
                    justify="center"
                    style={{ paddingInline: 'var(--spacing-400)' }}
                >
                    {canDrop ? (
                        <SignInLight size="100" style={{ transform: 'rotate(90deg)' }} color="var(--lightness-500)" />
                    ) : (
                        <FileAltLight size="100" color="var(--lightness-500)" />
                    )}
                    <Text weight="regular" size="sm" color="var(--lightness-500)" align="center">
                        {canDrop
                            ? t('ToolsScreen.MassChangeScreen.FirmwareScreen.SelectedFileContainer.dropFileHere')
                            : t('ToolsScreen.MassChangeScreen.FirmwareScreen.SelectedFileContainer.dropFile')}
                    </Text>
                </Stack>
            ) : selected !== '' && selectedState === 'default' ? (
                <Stack
                    align="center"
                    spacing={window.innerHeight < 800 ? 'var(--spacing-400)' : 'large'}
                    justify="center"
                    width="100%"
                    height="100%"
                >
                    <Text size="md" weight="bold" color="var(--lightness-300)">
                        {t('ToolsScreen.MassChangeScreen.FirmwareScreen.SelectedFileContainer.selectedFile')}:
                    </Text>
                    <Stack align="center" justify="center" style={{ width: '100%' }}>
                        <FileAltLight size="90" color="var(--lightness-300)" />
                        <div style={{ maxWidth: '90%' }}>
                            <span className={classnames(style.text, style.fileName)}>{selectedFile?.name}</span>
                            <span className={style.text}>
                                &nbsp;
                                {t('ToolsScreen.MassChangeScreen.FirmwareScreen.SelectedFileContainer.version', {
                                    version: selectedFile?.version,
                                })}
                            </span>
                        </div>
                    </Stack>
                    <Stack direction="row" spacing="small" align="center" justify="center">
                        <ActionButton variant="secondary" onClick={() => setSelected('')}>
                            {t('ToolsScreen.MassChangeScreen.FirmwareScreen.SelectedFileContainer.cancel')}
                        </ActionButton>
                    </Stack>
                </Stack>
            ) : null}
        </div>
    );
}
