import { ColumnsRegular } from '@vg-react/icons/regular';
import { Reorder, useDragControls } from 'framer-motion';
import { t as translation } from 'i18next';
import { useEffect } from 'react';
import deepCompare from '../../util/deepCompare';
import Form, { Fields } from '../Form/Form';
import Popover from '../Popover/Popover';
import Stack from '../Stack/Stack';
import Text from '../Text/Text';
import styles from './ColumnsControl.module.scss';
import ColumnControlItem from './ColumnsControlItem';

export interface Column {
    accessor: string;
    visible?: boolean;
    disabled?: boolean;
    label: string | JSX.Element;
}

interface ColumnsControlProps {
    label?: string;
    columns: Column[];
    allowReorder?: boolean;
    columnsOrder: string[];
    onColumnsVisibilityChange: (columns: Column[]) => void;
    onColumnsOrderChange: (columnsOrder: string[]) => void;
}

export default function ColumnsControl({
    label = translation('ColumnsControlComponent.columns'),
    columns,
    columnsOrder,
    allowReorder = false,
    onColumnsOrderChange,
    onColumnsVisibilityChange,
}: ColumnsControlProps) {
    useEffect(() => {
        const columnAccessors = columns.map((column) => column.accessor);

        const allOrdersInAccessors = columnsOrder.every((column) => {
            return columnAccessors.includes(column);
        });

        const allAccessorsInOrder = columnAccessors.every((column) => {
            return columnsOrder.includes(column);
        });

        if (!allOrdersInAccessors || !allAccessorsInOrder) {
            onColumnsOrderChange(columnAccessors);
        }
    }, [columns, columnsOrder]);

    const handleFormChange = (newValues: Fields) => {
        if (!deepCompare(initialValues, newValues)) {
            const newColumns = columns.map((column) => ({
                ...column,
                visible: newValues[column.accessor] as boolean,
            }));

            onColumnsVisibilityChange?.(newColumns);
        }
    };

    const initialValues = columns.reduce((acc, column) => {
        acc[column.accessor] = column.visible as boolean;
        return acc;
    }, {} as { [key: string]: boolean });

    const controls = useDragControls();

    return (
        <Popover placement="bottom-end">
            <Popover.Button className={styles.button}>
                <ColumnsRegular size="14px" color="var(--accent-900)" />
                {label}
            </Popover.Button>
            <Popover.Panel>
                <Stack spacing="small" className={styles.panel}>
                    <Text color="var(--gray-500)" transform="uppercase" weight="bold" size="xxs">
                        {label}
                    </Text>
                    <Form onChange={handleFormChange} initialValues={initialValues} style={{ paddingLeft: '.25rem' }}>
                        {allowReorder ? (
                            <Reorder.Group
                                as="div"
                                style={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 8,
                                }}
                                values={columnsOrder}
                                dragControls={controls}
                                onReorder={onColumnsOrderChange}
                            >
                                {columns.map((column) => (
                                    <ColumnControlItem
                                        column={column}
                                        key={column.accessor}
                                        allowReorder={allowReorder}
                                        disabled={columns.filter((c) => c.visible).length === 1 && column.visible}
                                    />
                                ))}
                            </Reorder.Group>
                        ) : (
                            <Stack spacing="small">
                                {columns.map((column) => (
                                    <ColumnControlItem
                                        column={column}
                                        key={column.accessor}
                                        disabled={columns.filter((c) => c.visible).length === 1 && column.visible}
                                    />
                                ))}
                            </Stack>
                        )}
                    </Form>
                </Stack>
            </Popover.Panel>
        </Popover>
    );
}
