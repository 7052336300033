import { scheduledUpdateAtom } from '@/atoms/updateAtom';
import usePermissions from '@/hooks/usePermissions';
import axios from 'axios';
import { useAtom } from 'jotai';
import { createContext, Dispatch, ReactNode, SetStateAction, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import getCookies from '../../util/getCookies';
import request from '../../util/request';

interface User {
    id: string;
    name: string;
    email: string;
    sso?: boolean;
    role: string;
    prompts?: {
        title: string;
        description: string;
        prompt: string;
    }[];
}

export interface Response {
    data: { message: string };
    status: number;
}

export interface Response {
    data: { message: string };
    status: number;
}

interface LoginProps {
    email: string;
    password: string;
    onSuccess?: (user: User) => void;
    onError?: (response: Response) => void;
    redirectTo?: string;
    force?: boolean;
    token?: string;
}
interface AuthContextProps {
    logout: () => void;
    user?: User | null;
    login: (data: LoginProps) => void;
    setUser: Dispatch<SetStateAction<User | null>>;
    keyStatus: { code: number; sso: boolean };
    sessionExpired: boolean;
    register2fa: boolean;
}

interface AuthProviderProps {
    children: ReactNode | ReactNode[];
}

export const AuthContext = createContext<AuthContextProps>({} as AuthContextProps);

export function AuthProvider({ children }: AuthProviderProps) {
    const { getPermissions } = usePermissions();
    const navigate = useNavigate();
    const previousRoute = useLocation();
    const [keyStatus, setKeyStatus] = useState<{
        code: number;
        sso: boolean;
    }>({ code: 0, sso: false });
    const [user, setUser] = useState<User | null>(null);
    const [sessionExpired, setSessionExpired] = useState(false);
    const cookies = getCookies();
    const [_, setScheduledUpdate] = useAtom(scheduledUpdateAtom);
    const [register2fa, setRegister2fa] = useState(false);

    useEffect(() => {
        if (cookies.server_settings) {
            const settings = JSON.parse(cookies.server_settings);

            if (settings.mustUpdate && settings.updateScheduleTime) {
                if (settings.updateScheduleEnabled) {
                    setScheduledUpdate({
                        hours: settings.updateScheduleTime.hours,
                        minutes: settings.updateScheduleTime.minutes,
                    });
                } else {
                    setScheduledUpdate(null);
                }
            }
        }
    }, [cookies.server_settings]);

    useEffect(() => {
        if (cookies.server_version && localStorage.getItem('updating_version') === cookies.server_version) {
            localStorage.removeItem('updating_version');
            return;
        }

        if (cookies.user) {
            request.get('/app/users/self').then(({ data: { data } }) => {
                setUser({
                    id: data.id,
                    name: data.name,
                    email: data.email,
                    sso: data.sso,
                    role: data.role,
                    prompts: data.prompts,
                });
            });
            getPermissions(JSON.parse(cookies.user).id);
            return;
        }

        if (!previousRoute.pathname.startsWith('/forgotPassword/')) {
            navigate('/login', { state: { from: previousRoute.pathname } });
        }
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            const { sessionExpire } = getCookies();
            if (Number(sessionExpire) < Date.now()) {
                setSessionExpired(true);
                logout();
                return;
            }
        }, 60 * 1000);

        return () => clearInterval(interval);
    }, [user]);

    function login({ email, password, onSuccess, onError, redirectTo, force, token }: LoginProps) {
        if (user) {
            navigate(redirectTo && redirectTo !== '/login' ? redirectTo : '/home');
            return;
        }

        axios
            .post('/app/login', { email, password, token, ...(force && { force: true }) })
            .then((response) => {
                if (onSuccess && response.data.user) onSuccess(response.data.user);

                setSessionExpired(false);

                const cookies = getCookies();

                getPermissions(JSON.parse(cookies.user).id);

                request.get('/app/users/self').then(({ data: { data } }) => {
                    setUser({
                        id: data.id,
                        name: data.name,
                        email: data.email,
                        sso: data.sso,
                        role: data.role,
                        prompts: data.prompts,
                    });
                });

                navigate(redirectTo && redirectTo !== '/login' ? redirectTo : '/home');
            })
            .catch((error) => {
                if (onError) onError({ data: error.response.data, status: error.response.status });
            });
    }

    function logout() {
        localStorage.removeItem('alreadyClickedRecoverKey');
        request.get('/app/logout').then((response) => {
            if (response.status === 200) {
                navigate('/login');
                setTimeout(() => setUser(null), 500);
            }
        });
    }

    const alreadySetInterceptor = useRef(false);

    if (!alreadySetInterceptor.current) {
        alreadySetInterceptor.current = true;

        request.interceptors.response.use(
            (data) => data,
            function handleRejection(error) {
                if (error.response.status === 401) {
                    if (error.response?.data?.code) {
                        setKeyStatus({ code: error.response?.data?.code, sso: error.response?.data?.sso });
                    } else if (!previousRoute.pathname.startsWith('/forgotPassword/')) {
                        logout();
                    }
                }
                if (error.response.status === 403 && error.response.data.message === 'register 2fa') {
                    setRegister2fa(true);
                }
                return Promise.reject(error);
            },
        );
    }

    return (
        <AuthContext.Provider value={{ register2fa, sessionExpired, login, logout, user, setUser, keyStatus }}>
            {children}
        </AuthContext.Provider>
    );
}
