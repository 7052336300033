import useCapability from '@/atoms/capabilityAtom';
import Accordion from '@/components/Accordion';
import usePermissions from '@/hooks/usePermissions';
import { classnames } from '@/util/classnames';
import convertBoolean from '@/util/convertBoolean';
import { Popover, Stack, Text, ToggleButton, Tooltip } from '@vg-react/components';
import { QuestionCircleLight } from '@vg-react/icons/light';
import { CloudCheckRegular, CloudQuestionRegular } from '@vg-react/icons/v6/regular';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useOutletContext, useParams } from 'react-router-dom';
import Field from '../../../../../../../../components/Field';
import useRefresh from '../../../../../../../../hooks/useRefresh';
import AutomationPanel from '../../../../index/AutomationPanel';
import connStyles from '../../../../index/Connectivity.module.scss';
import styles from './Frequency.module.scss';
import ModalEditBandWidth from './ModalEditBandWidth';
import ModalEditChannel from './ModalEditChannel';
import ModalEditPassword from './ModalEditPassword';
import ModalEditSsid from './ModalEditSsid';
import ModalEnableInterface from './ModalEnableInterface';
import { InterfaceFeatures, InterfacesResponse, TabToDataProps } from './types/Types';

const tabToData: TabToDataProps = {
    two_ghz: '2.4',
    five_ghz: '5.8',
};

export function FrequencyScreen({ frequency }: { frequency: 'two_ghz' | 'five_ghz' }) {
    const data = useOutletContext<InterfacesResponse>();
    const orderedInterface = (data && data[tabToData[frequency]].sort((item) => (item.enable ? -1 : 1))) || [];
    return (
        <Stack spacing="var(--spacing-100)" style={{ padding: '5px' }}>
            {orderedInterface
                .filter((int) => int.ssid && (!int.isMesh || data.hideMesh))
                .map(({ enable, ...rest }) => {
                    const interfaceEnable = typeof enable === 'boolean' ? enable : convertBoolean(enable);
                    return <InterfaceAccordion key={rest.path} enable={interfaceEnable} {...rest} />;
                })}
        </Stack>
    );
}

function InterfaceAccordion({
    autoChannelEnable,
    channelBandWidth,
    beaconType,
    beaconTypeSupported,
    channel,
    enable,
    possibleChannelBandWidths,
    password,
    path,
    possibleChannels,
    bandWidth,
    refresh,
    setDeviceOperation,
    ssid,
    index,
}: InterfaceFeatures) {
    const [buttonChannelDisable, setButtonChannelDisable] = useState<boolean>(false);
    const [buttonPasswordDisable, setButtonPasswordDisable] = useState<boolean>(false);
    const [buttonSsidDisable, setButtonSsidDisable] = useState<boolean>(false);
    const [modalEditChannelIsActive, setModalEditChannelIsActive] = useState<boolean>(false);
    const [modalEditPasswordIsActive, setModalEditPasswordIsActive] = useState<boolean>(false);
    const [modalEditSsidIsActive, setModalEditSsidIsActive] = useState<boolean>(false);
    const [modalEnableInterface, setModalEnableInterface] = useState<boolean>(false);
    const [toggleInterfaceDisable, setToggleInterfaceDisabled] = useState<boolean>(enable);
    const [buttonBandWidthDisable, setButtonBandWidthDisable] = useState<boolean>(false);
    const [modalEditBandWidthIsActive, setModalEditBandWidthIsActive] = useState<boolean>(false);
    const [hasNotCapability] = useCapability();
    const { t } = useTranslation();

    const autoChannel = typeof autoChannelEnable === 'string' ? convertBoolean(autoChannelEnable) : autoChannelEnable;

    const { id } = useParams();
    if (!id) return null;

    function getWlanInterface(): string {
        if (index) return index.toString();
        let instancePath = path.split('.')[4];
        if (!instancePath) instancePath = path.split('.')[3];
        return instancePath;
    }

    const { addRefreshIntoRefreshList } = useRefresh();
    addRefreshIntoRefreshList({ name: `/app/devices/${id}/wifi/`, refresh: refresh });

    const { validatePermission } = usePermissions();
    const hasPermission = validatePermission('/app/devices/:id/wifi/:instance', 'edit');
    const isSynced = true;
    return (
        <>
            <Accordion>
                <Accordion.Header>
                    <Stack width="100%" direction="row" align="center" justify="space-between">
                        <Stack direction="row" spacing="small">
                            <Tooltip>
                                <Tooltip.Trigger>
                                    <ToggleButton
                                        name="enable"
                                        value={toggleInterfaceDisable}
                                        disabled={hasNotCapability?.WiFi?.enable}
                                        onChange={(value) => {
                                            setToggleInterfaceDisabled(value);
                                            setModalEnableInterface(true);
                                        }}
                                    />
                                </Tooltip.Trigger>
                                <Tooltip.Content>
                                    {hasNotCapability?.WiFi?.enable
                                        ? t('DevicesScreen.DeviceScreen.Connectivity.Pages.notAvailable')
                                        : toggleInterfaceDisable
                                        ? t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.enabled')
                                        : t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.disabled')}
                                </Tooltip.Content>
                            </Tooltip>

                            <div className={styles.separator} />
                            <Text weight="bold">{ssid}</Text>
                        </Stack>
                        <Popover>
                            <Popover.Button className={connStyles.popoverBtn}>
                                {!isSynced ? (
                                    <CloudQuestionRegular size="24" color="var(--yellow-700)" />
                                ) : (
                                    <CloudCheckRegular size="24" color="var(--green-700)" />
                                )}
                            </Popover.Button>
                            <Popover.Panel
                                className={classnames(
                                    connStyles.popoverPanel,
                                    isSynced ? connStyles.isSynced : connStyles.isNotSynced,
                                )}
                            >
                                <AutomationPanel isSynced={isSynced} />
                            </Popover.Panel>
                        </Popover>
                    </Stack>
                </Accordion.Header>
                <Accordion.Content>
                    <Stack spacing="0" className={styles.accordionBody}>
                        <Field.Button
                            iconInfo={{
                                icon: <CloudCheckRegular size="16" color="var(--green-700)" />,
                                text: t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.saved'),
                            }}
                            label={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.ssid')}
                            text={ssid}
                            buttonLabel={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.editSsid')}
                            disable={buttonSsidDisable || !hasPermission}
                            updating={buttonSsidDisable}
                            onButtonClick={() => setModalEditSsidIsActive(true)}
                        />
                        <Field.Button
                            iconInfo={{
                                icon: <CloudCheckRegular size="16" color="var(--green-700)" />,
                                text: t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.saved'),
                            }}
                            label={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.password')}
                            text="********"
                            buttonLabel={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.editPassword')}
                            disable={buttonPasswordDisable || !hasPermission}
                            updating={buttonPasswordDisable}
                            onButtonClick={() => setModalEditPasswordIsActive(true)}
                        />

                        <Field.Button
                            label={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.channel')}
                            buttonLabel={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.editChannel')}
                            disable={buttonChannelDisable || !hasPermission || hasNotCapability?.WiFi?.channel}
                            iconInfo={
                                hasNotCapability?.WiFi?.channel
                                    ? {
                                          icon: <QuestionCircleLight size="16" color="var(--yellow-800)" />,
                                          text: t(
                                              'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.notAvailable',
                                          ),
                                      }
                                    : {
                                          icon: <CloudCheckRegular size="16" color="var(--green-700)" />,
                                          text: t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.saved'),
                                      }
                            }
                            updating={buttonChannelDisable}
                            onButtonClick={() => setModalEditChannelIsActive(true)}
                            text={
                                autoChannel
                                    ? t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.auto')
                                    : channel?.toString()
                            }
                        />
                        {channelBandWidth !== undefined ? (
                            <Field.Button
                                label={t('DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.bandWidth')}
                                buttonLabel={t(
                                    'DevicesScreen.DeviceScreen.Connectivity.Wifi.FrequencyScreen.editBandWidth',
                                )}
                                disable={buttonBandWidthDisable || !hasPermission}
                                updating={buttonBandWidthDisable}
                                text={`${channelBandWidth}${
                                    channelBandWidth === 'Auto' ? '' : channelBandWidth.includes('MHz') ? '' : 'MHz'
                                }`}
                                onButtonClick={() => setModalEditBandWidthIsActive(true)}
                            />
                        ) : null}
                    </Stack>
                </Accordion.Content>
            </Accordion>
            {modalEditSsidIsActive && (
                <ModalEditSsid
                    id={id}
                    refresh={refresh}
                    previousSsid={ssid}
                    instance={getWlanInterface()}
                    active={modalEditSsidIsActive}
                    setButtonSsidDisable={setButtonSsidDisable}
                    onClose={() => setModalEditSsidIsActive(false)}
                    setDeviceOperation={setDeviceOperation}
                />
            )}
            {modalEditPasswordIsActive && (
                <ModalEditPassword
                    id={id}
                    refresh={refresh}
                    instance={getWlanInterface()}
                    previousPassword={password}
                    currentBeaconType={beaconType}
                    beaconTypeSupported={beaconTypeSupported?.split(',')}
                    active={modalEditPasswordIsActive}
                    onClose={() => setModalEditPasswordIsActive(false)}
                    setButtonPasswordDisable={setButtonPasswordDisable}
                    setDeviceOperation={setDeviceOperation}
                />
            )}
            {modalEditChannelIsActive && (
                <ModalEditChannel
                    id={id}
                    refresh={refresh}
                    instance={getWlanInterface()}
                    active={modalEditChannelIsActive}
                    onClose={() => setModalEditChannelIsActive(false)}
                    setButtonChannelDisable={setButtonChannelDisable}
                    values={{
                        channel: channel,
                        possibleChannels: possibleChannels,
                        autoChannelEnable: autoChannelEnable,
                    }}
                    setDeviceOperation={setDeviceOperation}
                />
            )}
            {modalEnableInterface && (
                <ModalEnableInterface
                    id={id}
                    refresh={refresh}
                    instance={getWlanInterface()}
                    active={modalEnableInterface}
                    newValue={toggleInterfaceDisable}
                    onClose={() => setModalEnableInterface(false)}
                    setToggleInterfaceDisabled={setToggleInterfaceDisabled}
                    setDeviceOperation={setDeviceOperation}
                />
            )}
            {modalEditBandWidthIsActive && (
                <ModalEditBandWidth
                    bandWidth={bandWidth}
                    possibleChannelBandWidths={possibleChannelBandWidths}
                    id={id}
                    refresh={refresh}
                    instance={getWlanInterface()}
                    active={modalEditBandWidthIsActive}
                    onClose={() => setModalEditBandWidthIsActive(false)}
                    setEditButtonDisable={setButtonBandWidthDisable}
                    value={channelBandWidth}
                    setDeviceOperation={setDeviceOperation}
                />
            )}
        </>
    );
}
