import Modal from '@/components/Modal/Modal';
import useSocket from '@/hooks/useSocket';
import { ActionButton, Form, Stack, Text } from '@vg-react/components';
import {} from '@vg-react/icons/solid';
import { CheckSolid, XmarkSolid } from '@vg-react/icons/v6/solid';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import request from '../../../../../../util/request';
import { WebSocketData } from '../../NatRedirection/Types';
import { WebUserData } from '../Types';
interface ModalProps {
    onClose: () => void;
    user: WebUserData;
    setIsSentUser: (value: boolean) => void;
    setDescriptionDevice: (...args: any) => void;
    refresh: () => void;
    defaultDescriptionDevice: { message: string; status: string };
}

export default function ModalConfirmWebUserEnable({
    defaultDescriptionDevice,
    onClose,
    user,
    setIsSentUser,
    refresh,
    setDescriptionDevice,
}: ModalProps) {
    const { t } = useTranslation();
    const { id } = useParams();
    const { socket } = useSocket();

    function submit() {
        setDescriptionDevice({
            message: user.userEnable
                ? t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.enabling')
                : t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.disabling'),
            status: 'in progress',
        });
        setIsSentUser(true);
        onClose();
        request
            .patch(`/app/devices/${id}/security/webUser`, {
                userEnable: !user.userEnable,
                path: user.path,
            })
            .then(() => {
                toast.info(
                    t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.callingDevice'),
                );
                setDescriptionDevice({ message: 'Alterando o usuário web', status: 'in progress' });
                socket?.on(`acs:device:webUser:${id}`, (data: WebSocketData) => {
                    if (data.status === 'in progress') {
                        setDescriptionDevice({ message: data.message, status: 'in progress' });
                        return;
                    }

                    setTimeout(() => {
                        setDescriptionDevice(defaultDescriptionDevice);
                        typeof refresh === 'function' && refresh();
                    }, 5000);

                    if (data.status === 'success') {
                        toast.success(data.message);
                    } else toast.error(data.message);

                    setDescriptionDevice({ message: data.message, status: data.status });
                    setIsSentUser(false);
                    socket?.off(`acs:device:webUser:${id}`);
                });
            })
            .catch((error) => {
                toast.error(error.response.data);
                setDescriptionDevice({
                    message: t('DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.error'),
                    status: 'error',
                });
                setTimeout(() => {
                    setDescriptionDevice(null);
                    refresh();
                }, 5000);
            });
    }

    return (
        <Modal size="small" active onClose={() => onClose()}>
            <Modal.Header generateCloseButton>
                <Modal.Title>
                    {!user.userEnable
                        ? t(
                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.enableUser',
                          )
                        : t(
                              'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.disableUser',
                          )}
                </Modal.Title>
            </Modal.Header>
            <Form onSubmit={submit}>
                <Modal.Body>
                    <Text weight="light" variant="p" size="xs">
                        {!user.userEnable
                            ? t(
                                  'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.confirmEnableUser',
                              )
                            : t(
                                  'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.confirmDisableUser',
                              )}
                    </Text>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton onClick={() => onClose()} quiet>
                            {t(
                                'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.cancel',
                            )}
                        </ActionButton>
                        <ActionButton type="submit">
                            <Stack spacing="var(--spacing-75)" justify="center" direction="row" align="center">
                                {!user.userEnable ? (
                                    <>
                                        <CheckSolid size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.enable',
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <XmarkSolid size="16" color="currentColor" />
                                        {t(
                                            'DevicesScreen.DeviceScreen.Connectivity.WebManagement.ModalConfirmWebUserEnable.disable',
                                        )}
                                    </>
                                )}
                            </Stack>
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
