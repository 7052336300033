import { Filter, FilterControl, Input, Stack } from '@vg-react/components';
import { SearchRegular } from '@vg-react/icons/regular';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import ColumnsControl, { Column } from '../ColumnsControl/ColumnsControl';
import { DataTableColumn } from './DataTable';

interface DataTableHeaderProps<T> {
    search: string | undefined;
    filters: Filter[];
    columnsOrder: string[];
    isSearchable?: boolean;
    customFilter?: ReactElement;
    headerContainer?: ReactElement;
    allowColumnReorder?: boolean;
    columns: DataTableColumn<T>[];
    onSearchChange: (query: string) => void;
    onFiltersChange: (filters: Filter[]) => void;
    onColumnsOrderChange: (columnsOrder: string[]) => void;
    onColumnsVisibilityChange: (columns: DataTableColumn<T>[]) => void;
}

export function DataTableHeader<T>({
    search,
    filters,
    columns,
    customFilter,
    isSearchable,
    columnsOrder,
    onSearchChange,
    headerContainer,
    onFiltersChange,
    onColumnsOrderChange,
    onColumnsVisibilityChange,
    allowColumnReorder = true,
}: DataTableHeaderProps<T>) {
    const { t } = useTranslation();
    const handleColumnsVisibilityChange = (newColumns: Column[]) => {
        const columnsWithNewVisibility = columns.map((column) => {
            const newColumn = newColumns.find((newColumn) => newColumn.accessor === column.accessor);
            return {
                ...column,
                visible: newColumn?.visible,
            };
        });

        onColumnsVisibilityChange?.(columnsWithNewVisibility);
    };

    const columnsToBeFiltered = columns.filter((column) => column.filterable && column.visible);

    const columnstToBeSearched = columns.filter((column) => column.searchable && column.visible);

    const columnsMappedToBeFiltered = columnsToBeFiltered.map((column) => ({
        operators: column.operators || [],
        accessor: column.accessor,
        label: column.label,
        type: column.type,
        enum: column.enum,
    }));

    const columnsToBeControlled: Column[] = columns.map((column) => ({
        label: column.label,
        visible: column.visible,
        accessor: column.accessor,
        disabled: Boolean(filters.find((filter) => filter.accessor === column.accessor)),
    }));

    return (
        <Stack align="center" direction="row" spacing="0">
            {(columnstToBeSearched.length > 0 || isSearchable) && (
                <Input
                    width="330px"
                    value={search}
                    name="column-search"
                    placeholder={t('DataTableComponent.Header.searchPlaceholder')}
                    onChange={(event) => onSearchChange(event.target.value)}
                    leftIcon={<SearchRegular size="14px" color="var(--accent-900)" />}
                />
            )}
            {headerContainer && headerContainer}
            <Stack style={{ marginLeft: 'auto' }} align="center" spacing="small" direction="row" justify="flex-end">
                <ColumnsControl
                    onColumnsVisibilityChange={handleColumnsVisibilityChange}
                    onColumnsOrderChange={onColumnsOrderChange}
                    allowReorder={allowColumnReorder}
                    columns={columnsToBeControlled}
                    columnsOrder={columnsOrder}
                />
                {customFilter
                    ? customFilter
                    : columnsToBeFiltered.length > 0 && (
                          <FilterControl
                              filters={filters}
                              onFiltersChange={onFiltersChange}
                              columns={columnsMappedToBeFiltered}
                          />
                      )}
            </Stack>
        </Stack>
    );
}
