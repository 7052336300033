import { CSSProperties, ReactNode, forwardRef } from 'react';
import { classnames } from '../../util/classnames';
import styles from './table.module.scss';

interface TableHeadProps {
    /**
     * The content of the table head, normally one or more `TableRow`.
     */
    children: ReactNode;
    /**
     * Defines a custom class name for the head.
     */
    className?: string;
    /**
     * Defines a custom style for the head.
     */
    style?: CSSProperties;
}

/**
 * The head of the table. It renders a `thead` element with table head styles.
 * @example
 * <TableHead>
 *     <TableRow>
 *         ...
 *     </TableRow>
 *     <TableRow>
 *         ...
 *     </TableRow>
 * </TableHead>
 */

const TableHead = forwardRef<HTMLTableSectionElement, TableHeadProps>(({ children, className, style }, ref) => {
    return (
        <thead ref={ref} className={classnames(styles.head, className)} style={style}>
            {children}
        </thead>
    );
});

TableHead.displayName = 'TableHead';

export default TableHead;
