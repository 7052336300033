import { GetDeviceResponse as Device } from '@/api/devices/device';
import useCapability from '@/atoms/capabilityAtom';
import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import { classnames } from '@/util/classnames';
import request from '@/util/request';
import { ActionButton, Badge, Spinner, Stack, Text } from '@vg-react/components';
import { CheckLight } from '@vg-react/icons/light';
import { CheckCircleSolid, CogSolid } from '@vg-react/icons/solid';
import { CircleDashedLight, ClockLight, LocationPinLight, RepeatLight, RouteLight } from '@vg-react/icons/v6/light';
import { XmarkRegular } from '@vg-react/icons/v6/regular';
import {
    CubeSolid,
    LocationPinSolid,
    PlaySolid,
    RightLongSolid,
    RouteSolid,
    TriangleExclamationSolid,
} from '@vg-react/icons/v6/solid';
import Tags from '@yaireo/tagify/dist/react.tagify';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Capabilities } from '../../index/Diagnostics.screen';
import { SettingsPing } from '../components/SettingsPing';
import { SettingsTraceroute } from '../components/SettingsTraceroute';
import styles from './Latency.module.scss';

export type Status = 'in progress' | 'success' | 'error' | 'default';

export const statusIcons: { [key in Status]: JSX.Element } = {
    'in progress': <Spinner size="small" color="var(--gray-800)" style={{ position: 'relative' }} />,
    success: <CheckLight size="14" color="var(--green-900)" />,
    error: <XmarkRegular size="14" color="var(--red-900)" />,
    default: <></>,
};

const updateHostsWhitelist = (hosts: { name: string; id: number }[]) => {
    const stringifiedHosts = localStorage.getItem('allHosts');
    const allHosts = stringifiedHosts
        ? JSON.parse(stringifiedHosts)
        : ['google.com', 'facebook.com', 'youtube.com', 'terra.com.br', 'tiktok.com', 'netflix.com'];

    const newHosts = hosts.map((host) => host.name);
    const updatedHosts = [...new Set([...allHosts, ...newHosts])];
    localStorage.setItem('allHosts', JSON.stringify(updatedHosts));
};

const updateLastUsedHosts = (hosts: { name: string; id: number; selected: boolean }[]) => {
    localStorage.setItem('lastUsedHosts', JSON.stringify(hosts));
};

const updatePreviousSelection = (ping: boolean, traceroute: boolean) => {
    localStorage.setItem('previousSelection', JSON.stringify({ ping, traceroute }));
};

export default function LatencyScreen() {
    const { t } = useTranslation();
    const { id } = useParams();
    const [capability] = useCapability();
    const { data: device } = useOutletContext<{ data: Device }>();
    const isPingSupported = !capability.Ping;
    const isTracerouteSupported = !capability.TraceRoute;

    const stringifiedPreviousSelection =
        localStorage.getItem('previousSelection') || '{"ping":true,"traceroute":false}';
    const previousSelection = JSON.parse(stringifiedPreviousSelection);
    const navigate = useNavigate();
    const defaultDescription = t('DevicesScreen.DeviceScreen.Diagnostics.Latency.description');
    const [pingRepetitions, setPingRepetitions] = useState('5');
    const [tracerouteMaxHops, setTracerouteMaxHops] = useState('15');
    const [pingTimeout, setPingTimeout] = useState('250');
    const [tracerouteTimeout, setTracerouteTimeout] = useState('1000');
    const [isPingSelected, setIsPingSelected] = useState(isPingSupported && previousSelection.ping);
    const [isTracerouteSelected, setIsTracerouteSelected] = useState(
        isTracerouteSupported && previousSelection.tracerouteOnly,
    );
    const [isTestRunning, setIsTestRunning] = useState(false);

    const stringifiedHosts = localStorage.getItem('lastUsedHosts');
    const stringifiedallHosts = localStorage.getItem('allHosts');
    const lastUsedHosts = stringifiedHosts
        ? JSON.parse(stringifiedHosts)
        : [
              {
                  id: Math.random(),
                  name: 'google.com',
                  selected: true,
              },
          ];

    const allHosts = stringifiedallHosts
        ? JSON.parse(stringifiedallHosts)
        : ['google.com', 'facebook.com', 'youtube.com', 'terra.com.br', 'tiktok.com', 'netflix.com'];
    const [hosts, setHosts] = useState(lastUsedHosts);
    const [deviceOperation, setDeviceOperation] = useState<{ message: string; status: Status }>({
        message: defaultDescription,
        status: 'default',
    });

    useEffect(() => {
        if (isTestRunning) {
            updateLastUsedHosts(hosts);
            updatePreviousSelection(isPingSelected, isTracerouteSelected);
            setDeviceOperation({
                message: t('DevicesScreen.DeviceScreen.Diagnostics.Latency.testRunning'),
                status: 'in progress',
            });

            const pingReq = new Promise((resolve) => {
                if (isPingSelected && isPingSupported) {
                    resolve(
                        request.post(`/app/devices/${id}/diagnostics/ping/start`, {
                            destinations: hosts.map((host: any) => host.name),
                            repetitions: pingRepetitions,
                            timeout: pingTimeout,
                        }),
                    );
                } else {
                    resolve({});
                }
            });

            const tracerouteReq = new Promise((resolve) => {
                if (isTracerouteSelected && isTracerouteSupported) {
                    resolve(
                        request.post(
                            `/app/devices/${id}/diagnostics/traceroute/start${isPingSelected ? '?wait=true' : ''}`,
                            {
                                destination: hosts,
                                maxHopCount: tracerouteMaxHops,
                                timeout: tracerouteTimeout,
                            },
                        ),
                    );
                } else {
                    resolve({});
                }
            });

            Promise.all([pingReq, tracerouteReq])
                .then(() => {
                    const tests = [
                        isPingSelected && isPingSupported && 'ping=1',
                        isTracerouteSelected && isTracerouteSupported && 'traceroute=1',
                    ].filter(Boolean);
                    setTimeout(() => {
                        if (location.pathname === `/devices/${id}/diagnostics/latency/`) {
                            navigate(`results?${tests.join('&')}`);
                        }
                    }, 3000);
                })
                .catch(() => {
                    setDeviceOperation({ message: defaultDescription, status: 'default' });
                });
        }
    }, [isTestRunning]);

    useEffect(() => {
        updateHostsWhitelist(hosts);
    }, [hosts]);

    const baseTagifySettings = {
        maxTags: 5,
        placeholder: t('DevicesScreen.DeviceScreen.Diagnostics.Latency.destinations'),
        dropdown: {
            enabled: 1,
        },
    };

    return (
        <Screen
            style={{
                height: '100%',
                backgroundColor: 'var(--foreground-color)',
                padding: 20,
                borderRadius: 'var(--radius-medium)',
            }}
        >
            <Stack spacing="medium" height="100%" width="100%">
                <Stack spacing="var(--spacing-75)">
                    <Text size="md" weight="bold" color="var(--gray-900)">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.title')}
                    </Text>
                    <Stack direction="row" spacing="var(--spacing-50)" justify="start" align="start">
                        {statusIcons[deviceOperation.status]}
                        <Text
                            size="xs"
                            weight="regular"
                            color={
                                deviceOperation.status === 'success'
                                    ? 'var(--green-900)'
                                    : deviceOperation.status === 'error'
                                    ? 'var(--red-900)'
                                    : 'var(--gray-600)'
                            }
                            style={{ fontStyle: deviceOperation.status !== 'default' ? 'italic' : 'normal' }}
                        >
                            {deviceOperation.message}
                        </Text>
                    </Stack>
                </Stack>
                <Stack align="center" justify="center" style={{ height: '100%' }} spacing="5%">
                    <Text weight="bold" color="var(--lightness-500)">
                        {(!isTestRunning && t('DevicesScreen.DeviceScreen.Diagnostics.Latency.selectTests')) || null}
                    </Text>
                    <Stack align="center" direction="row" justify="center">
                        {isTestRunning && !isPingSelected ? (
                            <div style={{ height: 170, width: 270 }} />
                        ) : (
                            <Ping
                                repetitions={{
                                    value: pingRepetitions,
                                    setValue: setPingRepetitions,
                                }}
                                timeout={{
                                    value: pingTimeout,
                                    setValue: setPingTimeout,
                                }}
                                selected={{
                                    value: isPingSelected,
                                    setValue: setIsPingSelected,
                                }}
                                className={
                                    isTestRunning
                                        ? isPingSelected && isTracerouteSelected
                                            ? styles.bothSelected
                                            : isPingSelected && !isTracerouteSelected
                                            ? styles.pingOnly
                                            : ''
                                        : ''
                                }
                                isTestRunning={isTestRunning}
                            />
                        )}
                        {isTestRunning && !isTracerouteSelected ? (
                            <div style={{ height: 170, width: 270 }} />
                        ) : (
                            <Traceroute
                                maxHops={{
                                    value: tracerouteMaxHops,
                                    setValue: setTracerouteMaxHops,
                                }}
                                timeout={{
                                    value: tracerouteTimeout,
                                    setValue: setTracerouteTimeout,
                                }}
                                selected={{
                                    value: isTracerouteSelected,
                                    setValue: setIsTracerouteSelected,
                                }}
                                className={
                                    isTestRunning
                                        ? isPingSelected && isTracerouteSelected
                                            ? styles.bothSelected
                                            : !isPingSelected && isTracerouteSelected
                                            ? styles.tracerouteOnly
                                            : ''
                                        : ''
                                }
                                isTestRunning={isTestRunning}
                                isSupported={isTracerouteSupported}
                            />
                        )}
                    </Stack>
                    <Stack style={{ minWidth: '40%' }} direction="row" align="center" justify="center">
                        <Tags
                            defaultValue={lastUsedHosts.map((host: { name: string }) => host.name)}
                            settings={baseTagifySettings}
                            whitelist={allHosts}
                            readOnly={isTestRunning}
                            autoFocus={true}
                            onChange={(e) => {
                                setHosts(
                                    e.detail.tagify.value.map((host) => ({
                                        id: Math.random(),
                                        name: host.value,
                                        selected: true,
                                    })),
                                );
                            }}
                            className={styles.tagInput}
                        />
                    </Stack>
                    <Stack direction="column" align="center" spacing="0" justify="center">
                        {/* <LoadingAnimation /> */}
                        <ActionButton
                            style={{ top: 11 }}
                            color="primary"
                            size="large"
                            type="button"
                            className={classnames(styles.startButton, isTestRunning ? styles.buttonRunning : '')}
                            onClick={() => {
                                if (device.status === 'offline') {
                                    toast.error(t('DevicesScreen.DeviceScreen.Diagnostics.Latency.deviceOffline'));
                                } else {
                                    setIsTestRunning(!isTestRunning);
                                }
                            }}
                            disabled={isTestRunning || (!isPingSelected && !isTracerouteSelected) || hosts.length === 0}
                        >
                            {!isTestRunning ? (
                                <>
                                    <PlaySolid size="18" color="currentColor" />
                                    <Text size="sm" weight="bold" color="currentColor" lineHeight="md">
                                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.runTest')}
                                    </Text>
                                </>
                            ) : (
                                <>
                                    <Spinner size="medium" color="var(--lightness-400)" />
                                    <Text size="sm" weight="bold" color="var(--lightness-400)" lineHeight="md">
                                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.runningTest')}
                                    </Text>
                                </>
                            )}
                        </ActionButton>
                        {!isTestRunning &&
                            (device?.diagnosticHistory?.ping || device?.diagnosticHistory?.traceroute) && (
                                <Link to="history" style={{ position: 'relative', top: 20 }}>
                                    <Stack direction="row" spacing="small" align="center" justify="center">
                                        <Text color="var(--accent-900)" size="xs" weight="bold">
                                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.history')}
                                        </Text>
                                        <RightLongSolid size="15" color="var(--accent-900)" />
                                    </Stack>
                                </Link>
                            )}
                    </Stack>
                </Stack>
            </Stack>
        </Screen>
    );
}

interface PingProps {
    repetitions: {
        value: string;
        setValue: (value: string) => void;
    };
    timeout: {
        value: string;
        setValue: (value: string) => void;
    };
    selected: {
        value: boolean;
        setValue: (value: boolean) => void;
    };
    className: string;
    isTestRunning: boolean;
}

const Ping = ({ repetitions, timeout, selected, className, isTestRunning }: PingProps) => {
    const { t } = useTranslation();
    return (
        <Stack
            id="ping"
            className={classnames(
                styles.cardContainer,
                selected.value ? styles.selected : styles.notSelected,
                className,
            )}
            align="center"
            justify="center"
            spacing="var(--spacing-200)"
        >
            <div
                className={styles.selectButton}
                onClick={() => !isTestRunning && selected.setValue(!selected.value)}
                style={{ position: 'absolute', top: 7, right: 7, cursor: 'pointer' }}
            >
                {selected.value ? (
                    <CheckCircleSolid size="22" color="var(--accent-900)" />
                ) : (
                    <CircleDashedLight size="22" color="var(--accent-600)" />
                )}
            </div>
            <div style={{ paddingTop: 5, position: 'absolute', top: 7, left: 7 }}>
                {selected.value && !isTestRunning && (
                    <>
                        <CogSolid size="20" color="white" />
                        <SettingsPing repetitions={repetitions} timeout={timeout} />
                    </>
                )}
            </div>
            <Stack direction="column" spacing="small" align="center" justify="center">
                {selected.value ? (
                    <LocationPinSolid size="32" color="var(--accent-900)" />
                ) : (
                    <LocationPinLight size="32" color="var(--accent-900)" />
                )}

                <Text color="var(--accent-970)" weight="bold" size="lg">
                    {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.ping')}
                </Text>
            </Stack>
            <Stack direction="row">
                <Badge>
                    <Stack
                        title={t('DevicesScreen.DeviceScreen.Diagnostics.Latency.repetitions')}
                        direction="row"
                        spacing="var(--spacing-50)"
                        align="center"
                        justify="center"
                    >
                        <CubeSolid size="12" color="var(--accent-900)" />
                        {repetitions.value} {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.packets')}
                    </Stack>
                </Badge>
                <Badge>
                    <Stack title="Timeout" direction="row" spacing="var(--spacing-50)" align="center" justify="center">
                        <ClockLight size="12" color="var(--accent-900)" />
                        {timeout.value}ms
                    </Stack>
                </Badge>
            </Stack>
        </Stack>
    );
};

interface TracerouteProps {
    maxHops: {
        value: string;
        setValue: (value: string) => void;
    };
    timeout: {
        value: string;
        setValue: (value: string) => void;
    };
    selected: {
        value: boolean;
        setValue: (value: boolean) => void;
    };
    isTestRunning: boolean;
    className: string;
    isSupported: boolean;
}

const Traceroute = ({ maxHops, timeout, selected, className, isTestRunning, isSupported }: TracerouteProps) => {
    const { t } = useTranslation();

    return (
        <Stack
            className={classnames(
                styles.cardContainer,
                selected.value ? styles.selected : styles.notSelected,
                !isSupported && styles.notSupported,
                className,
            )}
            align="center"
            justify="center"
            spacing="var(--spacing-200)"
        >
            {isSupported ? (
                <>
                    <div
                        className={styles.selectButton}
                        onClick={() => !isTestRunning && selected.setValue(!selected.value)}
                        style={{ position: 'absolute', top: 7, right: 7, cursor: 'pointer' }}
                    >
                        {selected.value ? (
                            <CheckCircleSolid size="22" color="var(--accent-900)" />
                        ) : (
                            <CircleDashedLight size="22" color="var(--accent-600)" />
                        )}
                    </div>
                    <div style={{ paddingTop: 5, position: 'absolute', top: 7, left: 7 }}>
                        {selected.value && !isTestRunning && (
                            <>
                                <CogSolid size="20" color="white" />
                                <SettingsTraceroute maxHops={maxHops} timeout={timeout} />
                            </>
                        )}
                    </div>
                    <Stack direction="column" spacing="small" align="center" justify="center">
                        {selected.value ? (
                            <RouteSolid size="32" color="var(--accent-900)" />
                        ) : (
                            <RouteLight size="32" color="var(--accent-900)" />
                        )}

                        <Text color="var(--accent-970)" weight="bold" size="lg">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.traceroute')}
                        </Text>
                    </Stack>
                    <Stack direction="row">
                        <Badge>
                            <Stack
                                title={t('DevicesScreen.DeviceScreen.Diagnostics.Latency.repetitions')}
                                direction="row"
                                spacing="var(--spacing-50)"
                                align="center"
                                justify="center"
                            >
                                <RepeatLight size="12" color="var(--accent-900)" />
                                {maxHops.value} hops
                            </Stack>
                        </Badge>
                        <Badge>
                            <Stack
                                title={t('DevicesScreen.DeviceScreen.Diagnostics.Latency.timeout')}
                                direction="row"
                                spacing="var(--spacing-50)"
                                align="center"
                                justify="center"
                            >
                                <ClockLight size="12" color="var(--accent-900)" />
                                {timeout.value}ms
                            </Stack>
                        </Badge>
                    </Stack>
                </>
            ) : (
                <Stack direction="column" spacing="var(--spacing-75)" align="center" justify="center">
                    <TriangleExclamationSolid size="26" color="var(--lightness-300)" />
                    <Text color="var(--lightness-300)" weight="regular" size="xs">
                        {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.notSupported')}
                    </Text>
                </Stack>
            )}
        </Stack>
    );
};
