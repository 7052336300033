import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import useRefresh from '@/hooks/useRefresh';
import { Stack, Text } from '@vg-react/components';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import { Node } from 'reactflow';
import Flow, { RootNode } from '../components/core/Flow';
import { RightPanel } from '../components/core/RightPanel';
import styles from './Topology.module.scss';

const TopologyScreen = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const { data, isFetching, refresh } = useFetch<{ data: RootNode[] }>(`/app/devices/${id}/topology`);
    const { addRefreshIntoRefreshList } = useRefresh();
    const [selectedNode, setSelectedNode] = useState<Node[]>([]);
    const [nodeData, setNodeData] = useState<Node>();
    addRefreshIntoRefreshList({ name: `app/devices/${id}/topology`, refresh: refresh });

    useEffect(() => {
        if (selectedNode.length) {
            setNodeData(selectedNode[0]);
        } else {
            setTimeout(() => {
                setNodeData(undefined);
            }, 200);
        }
    }, [selectedNode]);

    return (
        <>
            <Screen
                style={{
                    height: '100%',
                    paddingBlock: 20,
                    borderRadius: 'var(--radius-medium)',
                    backgroundColor: 'var(--foreground-color)',
                }}
            >
                <Stack style={{ height: '100%' }} spacing="var(--spacing-500)">
                    <Stack direction="row" justify="space-between" align="center">
                        <Stack spacing="var(--spacing-75)">
                            <Text size="md" weight="bold" color="var(--gray-900)">
                                {t('DevicesScreen.DeviceScreen.Connectivity.Topology.title')}
                            </Text>
                            <Stack direction="row" align="center" justify="start" spacing="var(--spacing-50)">
                                <Text
                                    size="xs"
                                    weight="regular"
                                    color={'var(--gray-600)'}
                                    style={{ fontStyle: 'normal' }}
                                >
                                    {t('DevicesScreen.DeviceScreen.Connectivity.Topology.description')}
                                </Text>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack
                        direction="row"
                        style={{ position: 'relative', overflow: 'hidden', height: '100%' }}
                        justify="end"
                        align="center"
                    >
                        <div style={{ width: '100%', height: '100%' }}>
                            {isFetching ? (
                                <Stack justify="space-around" align="center" className={styles.spinnerBox}>
                                    <Stack className={styles.circleBorder}>
                                        <Stack className={styles.circleCore} />
                                    </Stack>
                                </Stack>
                            ) : (
                                <Flow rootNodes={data?.data} onSelectNode={(node) => setSelectedNode(node)} />
                            )}
                        </div>
                        <CSSTransition
                            mountOnEnter
                            unmountOnExit
                            timeout={800}
                            in={!!selectedNode.length}
                            classNames={{
                                exit: styles.rightSideExit,
                                enter: styles.rightSideEnter,
                                exitDone: styles.rightSideExitDone,
                                enterDone: styles.rightSideEnterDone,
                            }}
                        >
                            <RightPanel nodeData={nodeData} />
                        </CSSTransition>
                    </Stack>
                </Stack>
            </Screen>
        </>
    );
};

export default TopologyScreen;
