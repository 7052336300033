import { Popover } from '@headlessui/react';
import React, { CSSProperties, ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import styles from './Popover.module.scss';
import { PanelContext, PanelContextProps } from './PopoverRoot';

export interface PopoverPanelProps extends React.InputHTMLAttributes<HTMLInputElement> {
    /**
     * Define content of popover modal element
     */
    children: ReactNode;
    /**
     * styles for popover
     */
    style?: CSSProperties;
    /**
     * classnames to append to default ones
     */
    className?: string;
}

/**
 * PopoverPanel component
 */
const PopoverPanel: React.FC<PopoverPanelProps> = ({ children, style, className }: PopoverPanelProps) => {
    const { x, y, floating, strategy, arrowRef, xArrow, yArrow, hasArrow }: PanelContextProps =
        React.useContext(PanelContext);

    return (
        <Popover.Panel
            className={classnames(styles.panelBox, className)}
            ref={floating}
            style={{
                position: strategy,
                top: y ?? 0,
                left: x ?? 0,
                width: 'fit-content',
                ...style,
            }}
        >
            {hasArrow ? (
                <div
                    style={{
                        left: xArrow != null ? `${xArrow}px` : '',
                        top: yArrow != null ? `${yArrow}px` : '-5px',
                    }}
                    className={styles.arrow}
                    ref={arrowRef}
                ></div>
            ) : (
                ''
            )}
            <div>{children}</div>
        </Popover.Panel>
    );
};

export default PopoverPanel;
