import Screen from '@/components/Screen/Screen';
import useFetch from '@/hooks/useFetch';
import { TraceRoute } from '@/types/TraceRoute';
import { Stack, Text, Tooltip } from '@vg-react/components';
import { ChevronLeftLight } from '@vg-react/icons/v6/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Ping, { PingResult } from '../components/Ping';
import Traceroute from '../components/Traceroute';
import styles from './Latency.module.scss';

export default function ResultsScreen() {
    const { t } = useTranslation();
    const { id } = useParams();

    const { data: pingState, isFetching: isPingFetching } = useFetch<{
        data: {
            destinations: PingResult[];
            repetitions: number;
            timeout: number;
            timestamp: Date;
        };
        state: 'not_started_yet' | 'pending' | 'failed' | 'success';
    }>(`/app/devices/${id}/diagnostics/ping/state`);

    const { data: tracerouteState, isFetching: isTracerouteFetching } = useFetch<TraceRoute.ResultDto>(
        `/app/devices/${id}/diagnostics/traceroute/state`,
    );

    const [isPingRunning, setIsPingRunning] = useState<boolean>(
        pingState?.state === 'pending' || pingState?.state === 'not_started_yet',
    );

    const [isTracerouteRunning, setIsTracerouteRunning] = useState<boolean>(
        tracerouteState?.state === 'pending' || tracerouteState?.state === 'not_started_yet',
    );

    const stringifiedPreviousSelection =
        localStorage.getItem('previousSelection') || '{"ping":true,"traceroute":false}';
    const previousSelection = JSON.parse(stringifiedPreviousSelection);

    const [query] = useSearchParams();

    const execPingRoute = query.get('ping') !== null;

    const execTraceRoute = query.get('traceroute') !== null;

    return (
        <Screen
            style={{
                height: '100%',
                backgroundColor: 'var(--foreground-color)',
                padding: 20,
                borderRadius: 'var(--radius-medium)',
            }}
        >
            <Stack spacing="medium" height="100%" width="100%">
                <Stack spacing="var(--spacing-75)">
                    <Stack direction="row" spacing="var(--spacing-75)" align="center">
                        <Tooltip>
                            <Tooltip.Trigger>
                                <Link to={`/devices/${id}/diagnostics/latency/`} className={styles.returnButton}>
                                    <ChevronLeftLight size="16" color="var(--gray-900)" />
                                </Link>
                            </Tooltip.Trigger>
                            <Tooltip.Content>
                                {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Results.back')}
                            </Tooltip.Content>
                        </Tooltip>
                        <Text size="md" weight="bold" color="var(--gray-900)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Results.title')}
                        </Text>
                    </Stack>

                    <Stack direction="row" spacing="var(--spacing-50)" justify="start" align="start">
                        <Text size="xs" weight="regular" color={'var(--gray-600)'} style={{ fontStyle: 'normal' }}>
                            {!isPingRunning && !isTracerouteRunning
                                ? t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Results.success')
                                : t('DevicesScreen.DeviceScreen.Diagnostics.Latency.Results.info')}
                        </Text>
                    </Stack>
                </Stack>
                <div
                    className={styles.resultsContainer}
                    style={{ gridTemplateColumns: previousSelection.ping && previousSelection.traceroute ? '' : '1fr' }}
                >
                    {execPingRoute && pingState?.state && (
                        <Ping
                            state={pingState}
                            timestamp={pingState?.data?.timestamp}
                            isFetching={isPingFetching}
                            isPingRunning={(value: boolean) => setIsPingRunning(value)}
                        />
                    )}
                    {(execTraceRoute && (
                        <Traceroute
                            state={tracerouteState as any}
                            isFetching={isTracerouteFetching}
                            isTracerouteRunning={(value: boolean) => setIsTracerouteRunning(value)}
                        />
                    )) ||
                        null}
                </div>
            </Stack>
        </Screen>
    );
}
