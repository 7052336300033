import { CSSProperties, useId } from 'react';
import { classnames } from '../../util/classnames';
import { useForm } from '../Form/useForm';
import css from './ToggleButton.module.scss';

export interface ToggleButtonProps {
    /**
     * Set Toggle Button size.
     * @default 'medium'
     */
    size?: 'small' | 'medium' | 'large';

    /**
     * Label to be shown on the side of Toggle Button
     */
    label?: string;

    /**
     * name of the field for form control
     */
    name: string;

    /**
     * Label side
     * @default 'right'
     */
    labelSide?: 'left' | 'right';

    /**
     * Set if Toggle Button has true or false value
     * @dafault false
     */
    value?: boolean;

    /**
     * Wheter the Toggle Button is disabled or not
     * @default false
     */
    disabled?: boolean;

    /**
     * Callback function to control from outside
     */
    onChange?: (value: boolean) => void;

    /**
     * Defines css properties to toggle element
     */
    style?: CSSProperties;
    onClick?: () => void;

    /**
     * Defines css properties when the Toggle Button is disabled
     */
    disabledStyle?: CSSProperties;
}

/**
 * Toggle Button component
 *
 * If inside a Form, value will be automatically captured.
 *
 * Outside of a Form, must be used as a Controlled Component.
 *
 * @example
 * //Inside a form (the value will be output on form callbacks)
 * <Form>
 *     <ToggleButton name='example' value={true} label='Toggle Button'></ToggleButton>
 * </Form>
 *
 * // Outside a form
 * const [state, setState] = useState(false);
 *
 * <ToggleButton name='example' value={state} onChange={setState} label='Toggle Button'></ToggleButton>
 */
export default function ToggleButton({
    name,
    label,
    style,
    value = false,
    size = 'medium',
    disabled = false,
    labelSide = 'right',
    onClick,
    onChange,
    disabledStyle = { cursor: 'not-allowed' },
}: ToggleButtonProps) {
    const id = useId();
    const { value: valueForm, onChange: onChangeForm } = useForm<boolean>(name, value);

    function handleOnChange() {
        if (onChange) onChange(!value);
        else onChangeForm(!valueForm);
    }

    return (
        <div className={css.container} style={style}>
            {label && labelSide === 'left' && (
                <label style={disabled ? { pointerEvents: 'none' } : {}} className={css.label} htmlFor={id}>
                    {label}
                </label>
            )}
            <div style={disabled ? disabledStyle : {}}>
                <label className={classnames(disabled && css.disabled, css.toggleLabel, css[size])}>
                    <input
                        id={id}
                        type="checkbox"
                        onClick={onClick}
                        onChange={handleOnChange}
                        className={`${css.input} ${css[size]}`}
                        checked={onChange ? value : valueForm}
                    />
                    <span className={`${css.span} ${css[size]}`} />
                </label>
            </div>
            {label && labelSide === 'right' && (
                <label style={disabled ? { pointerEvents: 'none' } : {}} className={css.label} htmlFor={id}>
                    {label}
                </label>
            )}
        </div>
    );
}
