import { classnames } from "@/util/classnames";
import { Stack } from "@vg-react/components";
import { RouterRegular } from "@vg-react/icons/v6/regular";
import { Handle, NodeProps, Position } from "reactflow";
import styles from '../../index/Topology.module.scss';

export function Agent(props: NodeProps) {
    let iconColor;
    if (props.selected) {
        iconColor = 'white';
    } else {
        iconColor = props.data.online ? 'var(--gray-700)' : 'var(--lightness-600)';
    }
    return (
        <Stack className={classnames(styles.agent, props.selected && styles.selected, props.data.online && styles.online)} align="center" justify="center">
            <Handle id='right' type='source' position={Position.Right} style={{ display: 'none' }} />
            <RouterRegular size="20" color={iconColor} />
        </Stack>
    )
}