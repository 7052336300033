import Modal from '@/components/Modal/Modal';
import request from '@/util/request';
import { ActionButton, Form, Input, Stack, Text } from '@vg-react/components';
import { PlusLight } from '@vg-react/icons/v6/light';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { z } from 'zod';
import RadioIntegration from './Radio/RadioIntegration';

type FormData = {
    integrationName: string;
    clientId: string;
    clientSecret: string;
    url: string;
};

const defaultValues: FormData = {
    integrationName: 'ixc',
    clientId: '',
    clientSecret: '',
    url: '',
};

const initialErrors = {
    integrationName: '',
    clientId: '',
    clientSecret: '',
    url: '',
};

interface ModalCreateIntegrationProps {
    activeModal: boolean;
    onClose: () => void;
    refreshIntegrations: () => void;
}

export default function ModalCreateIntegration({
    onClose,
    activeModal,
    refreshIntegrations,
}: ModalCreateIntegrationProps) {
    const [loading, setLoading] = useState(false);
    const [disable, setDisable] = useState(true);
    const [fieldss, setFields] = useState<FormData>(defaultValues);
    const [errors, setErrors] = useState<FormData>(initialErrors);
    const { t } = useTranslation();

    const schema = z.object({
        integrationName: z.string(),
        clientId: z
            .string()
            .trim()
            .min(10, { message: t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.clientIdError') })
            .optional(),
        clientSecret: z
            .string()
            .trim()
            .min(10, { message: t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.clientSecretError') })
            .optional(),
        url: z
            .string()
            .url({ message: t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.urlError') })
            .trim()
            .optional(),
    });

    function verifyErrors(fields: Partial<FormData>) {
        type Key = keyof FormData;

        for (const key in fields) {
            if (fields[key as keyof FormData] === '') fields[key as keyof FormData] = undefined;
        }

        const result = schema.safeParse(fields);

        if (result.success) {
            setErrors({
                integrationName: '',
                clientId: '',
                clientSecret: '',
                url: '',
            });
            return false;
        }

        setErrors((previous) => {
            const zodErrors = result.error.flatten().fieldErrors;
            const newErrors = initialErrors;
            Object.keys(previous).forEach((key) => {
                newErrors[key as Key] = zodErrors[key as Key]?.[0] ?? '';
            });
            return newErrors;
        });
        return true;
    }

    function onChange(fields: FormData) {
        setFields(fields);
        const existsErros = verifyErrors(fields);
        setDisable(true);
        if (fields.clientId && fields.clientSecret && fields.url && !existsErros) setDisable(false);
    }

    async function onSubmit(fields: FormData) {
        if (!fields.clientId || !fields.clientSecret || !fields.url) return;

        const body = {
            clientId: fields.clientId,
            clientSecret: fields.clientSecret,
            name: fields.integrationName,
            url: fields.url,
        };
        setLoading(true);
        request
            .post('/app/integrations/system/create', body)
            .then((res) => {
                toast.success(res.data.message);
                setTimeout(() => {
                    setLoading(false);
                    refreshIntegrations();
                    onClose();
                }, 1000);
            })
            .catch((err) => {
                toast.error(err.response.data.message);
                setLoading(false);
            });
    }

    return (
        <Modal size="medium" active={activeModal} onClose={onClose}>
            <Form<FormData> onChange={onChange} initialValues={defaultValues} onSubmit={onSubmit}>
                <Modal.Header generateCloseButton>
                    <Text weight="bold" size="lg">
                        {t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.createIntegration')}
                    </Text>
                </Modal.Header>

                <Modal.Body>
                    <Stack spacing="var(--spacing-200)">
                        <Stack spacing="var(--spacing-300)">
                            <RadioIntegration />
                        </Stack>
                        <Stack spacing="var(--spacing-200)">
                            <Text weight="bold" size="xs">
                                {t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.integrationData')}
                            </Text>

                            <Input
                                required
                                width="100%"
                                label="URL"
                                name="url"
                                inputSize="medium"
                                description={t(
                                    'ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.urlDescription',
                                )}
                                placeholder="https://ixc.meudominio.com.br"
                                invalid={errors.url !== ''}
                                helpText={errors.url}
                            />

                            <Stack direction="row" spacing="var(--spacing-200)" align="center" justify="center">
                                <Text weight="bold" size="xs">
                                    {t(
                                        'ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.authenticationCredentials',
                                    )}
                                </Text>
                                <div
                                    style={{
                                        flex: '1',
                                        height: 1,
                                        backgroundColor: 'var(--lightness-900)',
                                    }}
                                />
                            </Stack>

                            <Input
                                required
                                width="100%"
                                label="Client ID"
                                name="clientId"
                                inputSize="medium"
                                description={t(
                                    'ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.clientIdDescription',
                                )}
                                placeholder=""
                                invalid={errors.clientId !== ''}
                                helpText={errors.clientId}
                            />

                            <Input
                                required
                                width="100%"
                                label="Client secret"
                                name="clientSecret"
                                inputSize="medium"
                                description={t(
                                    'ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.clientSecretDescription',
                                )}
                                placeholder=""
                                invalid={errors.clientSecret !== ''}
                                helpText={errors.clientSecret !== '' ? errors.clientSecret : ''}
                            />
                        </Stack>
                    </Stack>
                </Modal.Body>
                <Modal.Footer>
                    <Stack direction="row" justify="end">
                        <ActionButton quiet width="small" onClick={onClose}>
                            {t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.cancel')}
                        </ActionButton>
                        <ActionButton type="submit" loading={loading} disabled={disable}>
                            <PlusLight size="16" color="currentColor" />
                            {t('ToolsScreen.IntegrationsScreen.ModalCreateIntegrationComponent.createIntegration')}
                        </ActionButton>
                    </Stack>
                </Modal.Footer>
            </Form>
        </Modal>
    );
}
