import { resources } from '@/i18n';
import { ReactNode, createContext, useState } from 'react';

export const BreadcrumbContext = createContext({} as BreadcrumbContextProps);
interface ProviderChildren {
    children: ReactNode | ReactNode[];
}

export type loadingState = {
    loading: boolean;
    setLoading: (loading: boolean) => void;
};

export type BreadcrumbContextProps = {
    breadcrumbName: BreadCrumbNameProps;
    setBreadcrumbName: (breadcrumbName: BreadCrumbNameProps) => void;
};

type BreadCrumbNameProps = {
    name: keyof (typeof resources)['pt']['translation']['Breadcrumb'];
    path: string;
};

const BreadcrumbContextProvider = ({ children }: ProviderChildren) => {
    const [breadcrumbName, setBreadcrumbName] = useState<BreadCrumbNameProps>({ name: 'home', path: '' });

    return (
        <BreadcrumbContext.Provider value={{ breadcrumbName, setBreadcrumbName }}>
            {children}
        </BreadcrumbContext.Provider>
    );
};

export default BreadcrumbContextProvider;
