import { CSSProperties, ReactNode } from 'react';
import { classnames } from '../../util/classnames';
import styles from './table.module.scss';

interface TableRowProps {
    /**
     * The content of the table row. Normally `TableCell` elements.
     */
    children: ReactNode;
    /**
     * Defines if the row is selected.
     */
    selected?: boolean;
    /**
     * Defines if the row can be hovered.
     */
    hover?: boolean;
    /**
     * Defines a function to be called when the row is clicked.
     */
    onClick?: () => void;
    /**
     * Defines a function to be called when the row is double clicked.
     */
    onDoubleClick?: () => void;
    /**
     * Defines a custom class name for the row.
     */
    className?: string;
    /**
     * Defines a custom style for the row.
     */
    style?: CSSProperties;
}

/**
 * A row of the table. It renders a `tr` element with table row styles.
 * @example
 * <TableRow>
 *     <TableCell>
 *         ...
 *      </TableCell>
 *      <TableCell>
 *          ...
 *      </TableCell>
 * </TableRow>
 */
export default function TableRow({
    children,
    selected = false,
    hover = false,
    onClick,
    onDoubleClick,
    className,
    style,
}: TableRowProps) {
    return (
        <tr
            className={classnames(styles.row, selected && styles.selected, hover && styles.hover, className)}
            style={style}
            onDoubleClick={onDoubleClick}
            onClick={onClick}
        >
            {children}
        </tr>
    );
}
