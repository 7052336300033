import { classnames } from "@/util/classnames";
import { Stack, Text } from "@vg-react/components";
import { LaptopMobileRegular, WifiRegular } from "@vg-react/icons/v6/regular";
import { Handle, NodeProps, Position } from "reactflow";
import styles from '../../index/Topology.module.scss';

export function Host(props: NodeProps) {
    let hostColor;
    if (props.selected) {
        hostColor = 'white';
    } else {
        hostColor = props.data.online ? 'var(--gray-700)' : 'var(--lightness-600)';
    }
    return (
        <Stack>
            <Stack className={classnames(styles.host, props.selected && styles.selected, props.data.online && styles.online, props.data.parentType && styles[props.data.parentType + "Parent"])} align="center" justify="center">
                <Handle id='right' type='source' position={Position.Right} style={{ display: 'none' }} />
                <LaptopMobileRegular size="20" color={hostColor} />
                {props.data.hostInterfaceType === '802.11' &&
                    <WifiRegular size="10" color={hostColor} style={{ position: 'absolute', top: 5, left: 5 }} />       
                }
            </Stack>
            <Text style={{ position: 'absolute', top: 55, left: -31 }} weight="bold" color="var(--lightness-600)"> {props.id.substring(0,17).toUpperCase()}</Text>
        </Stack>
    )
}