import { speedTestAtom } from '@/atoms/speedTestAtom';
import { classnames } from '@/util/classnames';
import { Stack, Text } from '@vg-react/components';
import { SitemapRegular } from '@vg-react/icons/v6/regular';
import { useAtom } from 'jotai';
import { useTranslation } from 'react-i18next';
import { Result, TestHistoryData } from '../index/SpeedTestResult';
import styles from '../index/SpeedTestResult.module.scss';
import { ResultAdvancedCard } from './ResultAdvancedCard';

interface AdvancedResultProps {
    areThereHost: boolean;
    result: Result | null;
    speedDownContractIntegration?: number;
    speedUpContractIntegration?: number;
    isRunningDownload: boolean;
    isRunningUpload: boolean;
    testHistory: TestHistoryData[];
}

export const AdvancedResult = ({
    areThereHost,
    result,
    speedDownContractIntegration,
    speedUpContractIntegration,
    isRunningDownload,
    isRunningUpload,
    testHistory,
}: AdvancedResultProps) => {
    const { t } = useTranslation();
    const [valueSpeedTestAtom, _] = useAtom(speedTestAtom);
    const windowWidth = window.innerWidth;
    const isError = () => {
        if ((!speedDownContractIntegration && !speedUpContractIntegration) || !valueSpeedTestAtom) return false;

        if (
            valueSpeedTestAtom?.download.selected &&
            valueSpeedTestAtom?.upload.selected &&
            result?.data?.download.status === 'failed' &&
            result?.data?.upload.status === 'failed'
        )
            return true;

        if (
            !valueSpeedTestAtom?.download.selected &&
            valueSpeedTestAtom?.upload.selected &&
            result?.data?.upload.status === 'failed'
        )
            return true;

        if (
            valueSpeedTestAtom?.download.selected &&
            !valueSpeedTestAtom?.upload.selected &&
            result?.data?.download.status === 'failed'
        )
            return true;

        return false;
    };

    return (
        <Stack
            className={classnames(
                styles.advanceResultContainer,
                areThereHost ? styles.advanceResultWithHost : undefined,
            )}
            style={{
                width: areThereHost ? '50%' : windowWidth < 1000 ? '95%' : '80%',
            }}
        >
            <Stack
                style={{
                    flex: 1,
                }}
            >
                <Stack
                    direction="row"
                    spacing="var(--spacing-50)"
                    align="center"
                    justify="center"
                    height="100%"
                    width="97%"
                >
                    {valueSpeedTestAtom?.download.selected && (
                        <ResultAdvancedCard
                            result={result}
                            type="Download"
                            testHistory={testHistory}
                            speedContracted={speedDownContractIntegration}
                        />
                    )}

                    {valueSpeedTestAtom?.download.selected && valueSpeedTestAtom?.upload.selected && (
                        <div
                            style={{
                                width: '1px',
                                height: '50%',
                                backgroundColor: 'var(--lightness-900)',
                            }}
                        />
                    )}

                    {valueSpeedTestAtom?.upload.selected && (
                        <ResultAdvancedCard
                            result={result}
                            type="Upload"
                            testHistory={testHistory}
                            speedContracted={speedUpContractIntegration}
                        />
                    )}
                </Stack>

                {speedDownContractIntegration && speedUpContractIntegration && !isError() && (
                    <Stack
                        direction="row"
                        spacing="var(--spacing-100)"
                        align="center"
                        justify="center"
                        style={{
                            backgroundColor: 'var(--lightness-900)',
                            padding: 'var(--spacing-100)',
                            borderRadius: 'var(--radius-medium)',
                        }}
                    >
                        <SitemapRegular size="22" color="var(--lightness-300)" />
                        <Text size="xs" weight="regular" color="var(--lightness-300)">
                            {t('DevicesScreen.DeviceScreen.Diagnostics.SpeedTest.AdvancedResultComponent.comparison')}
                        </Text>
                    </Stack>
                )}
            </Stack>
        </Stack>
    );
};
